import React,{useEffect, useState,useRef,useCallback,useContext} from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import BranchContext from "../context/branch";
import Note from "./devicedelete";
import {getdevicebyuser,deleteface} from "../../services/facescan";
import RenderDevicebyUser from './renderdevicebyuser';
import dlv from 'dlv';
import { toast } from "react-toastify";
import ProgressBar from './../common/progressbar';

const UserDevice = ({  button1,
  isOpen,
  header,
  onCancel,
  onConfirm,
  message,
  list,
  onDisAprove,
  value,
  onChange
   }) => {

    const ojb = value;
    let pempid = dlv(ojb, 'empid',0);
    let pempname = dlv(ojb, 'empname','');

  const [empdata, setempdata] = useState({
    empid:pempid,empname:pempname
  });
  
  //console.log(value);
  const { currentBranch } = useContext(BranchContext);
  const [notes, setNotes] = useState([]);
  const [devicelist, setdevicelist] = useState([]);
  const [chkSave, setchkSave] = useState(false);
  const [error, seterror] = useState('');
  const [showprogress, setshowprogress] = useState(false)
  const [completed, setcompleted] = useState(50)


  useEffect(() => {
    if (!currentBranch) return;
    if (!pempid) return;
  
    setempdata(
{empid:pempid,empname:pempname});

  }, [currentBranch, pempid,pempname]);

  useEffect(() => {
    if (!currentBranch) return;
  
   if (notes.length>0){
setchkSave(true);
   }else{
setchkSave(false);
   }

  }, [currentBranch, notes]);
  

  
  useEffect(() => {
    if (!currentBranch) return;
 
    if (!empdata.empid) return;
  
   

        const loadData = async () => {

            const { data: result } = await getdevicebyuser(empdata.empid);
            if ( result.status==='success') {
                setdevicelist(result.data.list);
                     
            }

    };
    loadData();
  }         
  , [currentBranch,empdata.empid]);

const handleOnClick =(id,name) => {
  //console.log(id,name);

const found = notes.find(item => item._id === id );
if(!found) {
  addNote({_id:id,name:name});
  
}
}
function addNote(newNote) {
  setNotes(prevNotes => {
    return [...prevNotes, newNote];
  });
}



const savedata = () => {
  setshowprogress(true)
  const saveobject = { face_id: [notes], empid:pempid  }
 // console.log(saveobject)
  SaveData(saveobject);


}

const  SaveData = async (doc) => {

  try {

  const {data: result} = await deleteface(doc);

 //console.log(empid)

  if (result.status==='success') {
    toast.success("บันทึกเสร็จเรียบร้อย");
     Savesuccess();
     setshowprogress(false)
  //  console.log(result.data)
    }
  else {
  toast.error(result.message)
//  console.log(result.message)
  }
} 
catch (error) {
 // toast.error("Error");
}


}
const Savesuccess =() =>{
  setNotes([]);
  seterror('')
  setempdata(
    {empid:'',empname:''
    }) 
    onConfirm();
}

const onClosemodal = () => {
  onCancel();
  setNotes([]);
  seterror('')
  setempdata(
    {empid:'',empname:''
    })

}
function deleteNote(id) {
  setNotes(prevNotes => {
    return prevNotes.filter((noteItem, index) => {
      return index !== id;
    });
  });
}

//console.log(empdata.empid)
  return (

    <div>
      <Modal
        isOpen={isOpen}
        toggle={onClosemodal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalHeader> 
        {/* {header} */}

        {pempid && <div className="row no-gutters align-items-center justify-content-left">
                            <div className="col-auto text-center mb-2 p-1 text-center">
                              <img
                                src={"https://wms.advanceagro.net/WSVIS/api/Face/GetImage?CardID=" + pempid + "&size=320"}
                                className="rounded-circle mr-1"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title=""
                                alt=""
                                style={{
                                  maxHeight: "80px",
                                  Width: "100%",
                                  maxWidth: "40px",
                                }}
                              ></img>
                   </div>   

                    {pempid &&
      
         <p className="font-weight-normal badge badge-primary"  style={{ fontSize: "18px" }}>
          {pempname}
       </p>

        }      
                    </div>}

       
        </ModalHeader>
        <ModalBody>
      
       { showprogress && <ProgressBar bgcolor={"#FF0066"} completed={completed} />}  
      <p className="text-success">
        แสดงข้อมูล {devicelist ? devicelist.length : 0}  /  {devicelist.length} รายการ
      </p>


      <RenderDevicebyUser
      list = {devicelist}
      error={error}
      handleOnClick={handleOnClick}
      search={true}
      />

  
          {!list && message}


        </ModalBody>


        {<div>
        
        <div className="row">
        <div className="col-12">
          <div className="card mb-2">
            <div className="card-body p-2">
            <div className="row ml-2">
                <div className="col">
                <div className="row ml-2">
                <div className="col">
        {notes.map((noteItem, index) => {
        return (
          
          <Note
            key={index}
            id={index}
            _id={noteItem._id}
            name={noteItem.name}
            onDelete={deleteNote}

          />
        );
      })}
      </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      
      </div>}

        
        { error && <div className="col-md-12 alert alert-danger">  { error}</div>}

        {list && list.comment &&
            <Button color="danger">
              {list.comment}
            </Button>
          }
        <ModalFooter>
        

        
          {chkSave && (
            <Button color="primary" onClick={savedata}>
              บันทึก
            </Button>
          )}
          <Button color="secondary" onClick={onClosemodal}>
            ยกเลิก
          </Button>
        </ModalFooter>
      </Modal>
    </div>


  );
};

export default UserDevice;
