import React, { useState, useEffect, useContext } from "react";
import { getSumaryRegister } from "./../../services/grabconcept";
import BranchContext from "./../context/branch";
import DatePicker from "react-datepicker";
import DetailCustomer from "./detailcustomer";
import moment from "moment";

//import DetailsCard from "./../wms/detailscard";
const Showdetail = () => {
  const { currentBranch } = useContext(BranchContext);
  const [register, setregister] = useState([]);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  // const {  result } = getWeightData();
  useEffect(() => {
    const getdata = async () => {
      getSumaryRegister(
        moment(startDate, "YYYY-MM-DD").add(0, "day").format("YYYY-MM-DD"),
        moment(endDate, "YYYY-MM-DD").add(0, "day").format("YYYY-MM-DD"),
        currentBranch && currentBranch._id
      ).then((result) => {
        //console.log(result.data)
        if (result.data && result.data.length > 0) {
          setregister(result.data);

          // console.log(result.data);
        } else {
          setregister([]);
        }
      });
    };
    getdata();
  }, [startDate, endDate, currentBranch]);

  //const data = register;

  //console.log(register);

  if (!register) {
    return <></>;
  }
  if (currentBranch._id == 'vms'){
    return <>
    </>
  }

  return (
    <div className="mt-4">
      <div className="row">
        <div className="col mb-1">
          <div className="card mb-1 text-dark p-0">
            <div className="card-body p-0">
              <div className="col-auto mb-1">
                <div className="container-fluid">
                  <div className="row justify-content-end">
                    <div className="col-auto mr-auto">
                      <h4>รายการชั่ง</h4>
                    </div>

                    <div className="col-auto mb-1">
                      <div className="container-fluid">
                        <div className="row justify-content-end align-items-center">
                          <div className="col-auto mb-1 px-0">
                            <ul className="row p-0 m-0 ">
                              <div
                                className="col-auto m-3 mt-2"
                                style={{ marginTop: 5, marginBottom: 5 }}
                              >
                                จากวันที่..
                                <DatePicker
                                  todayButton="Today"
                                  dateFormat="dd/MM/yyyy"
                                  selected={startDate}
                                  onChange={(date) => setStartDate(date)}
                                />
                              </div>
                              <div
                                className="col-auto m-3 mt-2"
                                style={{ marginTop: 5, marginBottom: 5 }}
                              >
                                ถึงวันที่..
                                <DatePicker
                                  todayButton="Today"
                                  dateFormat="dd/MM/yyyy"
                                  selected={endDate}
                                  onChange={(date) => setEndDate(date)}
                                />
                              </div>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p className="text-success">
        แสดงข้อมูล {register ? register.length : 0} รายการ บนระบบ
      </p>
      <DetailCustomer data={register} />
    </div>
  );
};

export default Showdetail;
