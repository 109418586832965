import React,{useState,useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import makeAnimated from "react-select/animated";
import { FormInput } from "./../common/forminput";
import Toggle from './../common/toggle';
import "./../../register.css";
import { components } from "react-select";
import MySelect from "./../common/myselect";
import {saveRegister} from './../../services/wmsmaster';
import {getBranchSelected} from './../../services/branchselected';
import PopupAlert from './../common/popupAlert';
import { toast } from "react-toastify";
//import socketIOClient from './../../services/socketioService';
 //socketIOClient = socketIOClient(ENDPOINT, { transports: ['polling'] })
 // "apiUrl": "https://api-wms.advanceagro.net"
export const Registerprofile = (props) => {
  const [colourOptions, setcolourOptions] = useState([]);

  const { register, errors, handleSubmit,watch } = useForm({
    mode: 'all'
  });
  const onSubmit = data => {
    if (!optionSelected.optionSelect  && (toggle.report.isChecked ===true 
       || toggle.wms.isChecked===true  || toggle.queue.isChecked===true|| toggle.grs.isChecked===true)) {
      setmessageErros( { askAlert: {enable: true, message: "ยังไม่ระบุสาขา\nกรุณาระบุก่อนค่ะ"}})
      return;
    
     
    } else if (toggle.wms.isChecked ===false  && toggle.grs.isChecked ===false
      && toggle.queue.isChecked ===false && toggle.grab.isChecked ===false
      && toggle.report.isChecked ===false && toggle.vms.isChecked ===false
      ){
        setmessageErros( { askAlert: {enable: true, message: "ยังไม่ระบุสิทธิ์ที่ต้องการ\nกรุณาระบุก่อนค่ะ"}})
        return;

    }
//console.log(data);
 const selected = optionSelected.optionSelect;
 //selected , toggle,
 
const anothObject = { selected , toggle, ...data };
//console.log(anothObject);
   // const doc = (JSON.stringify(anothObject));

    //alert(JSON.stringify(anothObject));
   // console.log(doc);
    SaveData(anothObject);

  };


  const  SaveData = async (doc) => {

    try {

    const {data: result} = await saveRegister(doc);

    if (result.status==='success') {
      toast.success("บันทึกเสร็จเรียบร้อย กรุณารอการอนุมัติอีกครั้ง");
      const urlGoBack = "/login/";        
          
      setTimeout(props.history.replace(urlGoBack), 1000);
          }
           else {
    toast.error(result.message)
               }
  } catch (error) {
    toast.error("Error");
  }
}
  
  const password = useRef({});
  password.current = watch("password", "");

  const [messageErros, setmessageErros] = useState({
    askAlert: {enable: false, message: ""}
 });

 const handlePopupAlertClick = () => {
  setmessageErros( { askAlert: {enable: false, message: ""}})     
   }
 
  useEffect(() => {
   
    getBranchSelected().then (result=>{ 
  //   console.log(result.data);

    if (result.data){
      setcolourOptions(result.data)
    }  
                 });
               }, 
    [])

  const Option = props => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const MultiValue = props => (
    <components.MultiValue {...props}>
      <span key={props.data.value}>{props.data.label}</span>
    </components.MultiValue>
  );
  const animatedComponents = makeAnimated();

 
//   const [response, setResponse] = useState("");

//  useEffect(() => {

//   const getsocket =() =>{

//     socketIOClient.on('connection');
    
//     socketIOClient.emit("data", "501,999,6");

//     socketIOClient.on('data',(message) =>{
//    // setResponse(message);
//     console.log('return=',message)
//      }
     
//      )
//      return () => socketIOClient.disconnect();
//   }
//   getsocket();
//  }, 
//  [])
 
  //  const socket = socketIOClient(ENDPOINT);

  //  socket.on("FromAPI", data => {
  //    setResponse(data);
  //  });

   //return () => socket.disconnect();
// }, []);
// useEffect(() => {
//   const getsocket = socketIOClient.on('update-work-content', 'test')
  
// }
// getsocket();

// ,[]);
//console.log(socketIOClient)

  // "apiUrl": "https://api-wms.advanceagro.net"

  
  const [toggle, settoggle] = useState(
      {
        wms:{isChecked: false, text: "ระบบการชั่ง"},
        queue:{isChecked: false, text: "ระบบจองคิว"},
        grab:{isChecked: false, text: "งาน grab"},
        vms:{isChecked: false, text: "ระบบขออนุมัติเข้าโรงงาน"},
        grs:{isChecked: false, text: "ระบบรับสินค้าไม้ท่อนไม้สับ"},
        report:{isChecked: false, text: "รายงานห้องชั่ง"}
      }
      );

      const [optionSelected, setoptionSelected] = useState({
        optionSelect: null
      });
    // function handleChangeLanguage(e) {
    //   const isChecked = e.target.checked;
//console.log(e.preventDefault);

    //   //console.log(isChecked);
    //   const text =
    //   isChecked === true ? "ระบบจองคิว" : "ระบบจองคิว";
    //   const language = {
    //   text: text,
    //   isChecked: isChecked,
    //   };
    //   setLanguage(language);
    //   }
 
     const handleChange = (value) => {
   

      setoptionSelected({optionSelect: value})
      //console.log(optionSelected);
  }

    //  const handleBlur = () =>{

    //  }
   
//console.log(optionSelected.optionSelect);
      const handleChangeToggle =(e) =>{

       
      //  socketIOClient.emit('FaceE85Win', 'emit' + e.target); 

        const {id ,checked} = e.target;
      const name = toggle[id].text;
     const Id = {
        text: name,
        isChecked: checked,
        };
        settoggle(prevToggle => {
          return {
             ...prevToggle,
            [id]: Id
          };
        });
      }
  return (
<form onSubmit={e => e.preventDefault()}>

<PopupAlert isOpen={messageErros.askAlert.enable} onOk={handlePopupAlertClick} message={messageErros.askAlert.message}  />
         
<div className="content-wrapper">
  <div className="row"  >
  <div className="col-12 col-sm-6 col-md-6" >

    <section className="content">
        <div className="container-fluid">
        <div className="row" >       
       
    <div className="col-lg-12">
    <div className="card mb-4" >
    <p className="bg-info text-white"> 
    <span className="info-box-icon bg-info">
    <i className="fa fa-users" />
    {/* <p>
      It's <time dateTime={response}>{response}</time>
    </p> */}

      </span>
       <span> ข้อมูลการเข้าระบบ </span>
                </p>

    <div className="card-body col-auto mb-4">

    
      <FormInput 
        id="login"
        name="login"
        type="text"
        label="login"
        placeholder ="ชื่อเข้าระบบ"
        register={register({
        required: "this is a required",
        maxLength: {
        value: 100,
        message: "Max length is 100"
        },
        minLength: {
          value: 4,
          message: "Min length is 4"
        }
        })}
        error ={errors.login}
      />

      <FormInput 
        id="password"
        name="password"    
        type="password"
        label="password"
        placeholder ="รหัสผ่าน"
        register={register({
        required: "you must specify a password",
        maxLength: {
        value: 16,
        message: "Max length is 16"
        },
        minLength: {
        value: 8,
        message: "Min length is 8"
          }
          })}
        error ={errors.password}
      />
       <FormInput 
        id="confirmpassword"
        name="confirmpassword"
        type="password"
        label="confirmpassword"
        placeholder ="ยืนยันรหัสผ่าน"
        register={register({
          validate: value =>
          value === password.current || "The passwords do not match"
        })}
        error ={errors.confirmpassword}
      />

 </div>
  
  </div>
  </div>
  <div>
  </div>  
      </div>
      </div>
  
      </section>
</div>

<div className="col-12 col-sm-6 col-md-6" >

<section className="content">
        <div className="container-fluid">
        <div className="row" >       
       
    <div className="col-lg-12">
    <div className="card mb-4" >

    <p className="bg-info text-white"> 
                  <span className="info-box-icon bg-info">
                    <i className="fa fa-users" />
                  </span>
                 <span> ข้อมูลส่วนบุคคล </span>
                </p>

    <div className="card-body mb-4">
    <div className="col-xs-12">
     <div className="row">
      <div className="col-sm-6 my-0">
                      <FormInput 
        id="firstname"
        name="firstname"
        type="text"
        label="firstname"
        placeholder ="ชื่อ"
        register={register({
        required: "this is a required",
        maxLength: {
        value: 500,
        message: "Max length is 500"
        },
        minLength: {
          value: 2,
          message: "Min length is 2"
        }
        })}
        error ={errors.firstname}
      />
       </div>
        <div className="col-sm-6 my-0">
                      <FormInput 
        id="lastname"
        name="lastname"
        type="text"
        label="lastname"
        placeholder ="สกุล"
        register={register({
        required: "this is a required",
        maxLength: {
        value: 100,
        message: "Max length is 100"
        },
        minLength: {
          value: 2,
          message: "Min length is 2"
        }
        })}
        error ={errors.lastname}
      />

                      </div>
                    </div>
                  </div>

  


<FormInput 
        id="address"
        name="address"
        type="text"
        label="address"
        placeholder ="ที่อยู่"
        register={register({
        required: "this is a required",
        maxLength: {
        value: 1000,
        message: "Max length is 1000"
        },
        minLength: {
          value: 2,
          message: "Min length is 2"
        }
        })}
        error ={errors.address}
      />

<div className="col-xs-12">
                    <div className="row">
                      <div className="col-sm-6 my-0">
                      <FormInput 
        id="mobile"
        name="mobile"
        type="text"
        label="mobile"
        placeholder ="เบอร์ติดต่อ"
        register={register({
        required: "this is a required",
        maxLength: {
        value: 100,
        message: "Max length is 1000"
        },
        minLength: {
          value: 2,
          message: "Min length is 2"
        }
        })}
        error ={errors.mobile}
      />
                      </div>
                      <div className="col-sm-6 my-0">
                      <FormInput 
        id="email"
        name="email"
        type="text"
        label="email"
        placeholder ="อีเมล"
        register={register({
        required: "this is a required",
        maxLength: {
        value: 100,
        message: "Max length is 1000"
        },
        minLength: {
          value: 2,
          message: "Min length is 2"
        },
        pattern: {
          value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
          message: "Invalid email address"
        }
        })}
        error ={errors.email}
      />


                      </div>

             
                    </div>
                  </div>





 </div>
  
  </div>
  </div>
  <div>
  </div>  
      </div>
      </div>
  
      </section>


        </div>
        <div className="col-12 col-sm-6 col-md-6" >

<section className="content">
        <div className="container-fluid">
        <div className="row" >       
       
    <div className="col-lg-12">
    <div className="card mb-4" >

    <p className="bg-info text-white"> 
                  <span className="info-box-icon bg-info">
                    <i className="fa fa-users" />
                  </span>
                 <span> ข้อมูลบริษัท </span>
                </p>

  <div className="card-body mb-4">
  <FormInput 
        id="company"
        name="company"
        type="text"
        label="company"
        placeholder ="บริษัท"
        register={register({
        required: "this is a required",
        maxLength: {
        value: 1000,
        message: "Max length is 1000"
        },
        minLength: {
          value: 2,
          message: "Min length is 2"
        }
        })}
        error ={errors.company}
      />


<FormInput 
        id="taxid"
        name="taxid"
        type="text"
        label="taxid"
        placeholder ="เลขนิติบุคคล"
        register={register({
        required: "this is a required",
        maxLength: {
        value: 100,
        message: "Max length is 100"
        },
        minLength: {
          value: 2,
          message: "Min length is 2"
        }
        })}
        error ={errors.taxid}
      />

    <FormInput 
        id="comaddress"
        name="comaddress"
        type="text"
        label="ที่อยู่"
        placeholder ="ที่อยู่"
        register={register({
        required: "this is a required",
        maxLength: {
        value: 1000,
        message: "Max length is 1000"
        },
        minLength: {
          value: 2,
          message: "Min length is 2"
        }
        })}
        error ={errors.comaddress}
      />

<FormInput 
        id="telephone"
        name="telephone"
        type="text"
        label="เบอร์โทร"
        placeholder ="เบอร์โทร"
        register={register({
        required: "this is a required",
        maxLength: {
        value: 1000,
        message: "Max length is 1000"
        },
        minLength: {
          value: 2,
          message: "Min length is 2"
        }
        })}
        error ={errors.telephone}
      />
  </div>
  
  </div>
  </div>
  <div>
  </div>  
      </div>
      </div>
  
      </section>


        </div>


        <div className="col-12 col-sm-6 col-md-6" >

<section className="content">
        <div className="container-fluid">
        <div className="row" >       
       
    <div className="col-lg-12">
    <div className="card mb-4" >

    <p className="bg-info text-white"> 
                  <span className="info-box-icon bg-info">
                    <i className="fa fa-users" />
                  </span>
                 <span> ส่วนการเข้าถึง</span>
                </p>

    <div className="card-body mb-4">


    <div className="col-xs-12">
                    <div className="row">
                      <div className="col-sm-6 my-1">
                      <Toggle
      name="wms"
      checked={toggle.wms.isChecked}
      onChange={handleChangeToggle}
      text={toggle.wms.text}
        />
                      </div>
                      <div className="col-sm-6 my-1">
                      <Toggle
      name="queue"
      checked={toggle.queue.isChecked}
      onChange={handleChangeToggle}
      text={toggle.queue.text}
    />
                      </div>
                    </div>
                  </div>

        <div className="col-xs-12">
                    <div className="row">
                      <div className="col-sm-6 my-1">
                      <Toggle
      name="report"
      checked={toggle.report.isChecked}
      onChange={handleChangeToggle}
      text={toggle.report.text}
        />
                      </div>
                      <div className="col-sm-6 my-1">
                      <Toggle
      name="grs"
      checked={toggle.grs.isChecked}
      onChange={handleChangeToggle}
      text={toggle.grs.text}
    />
                      </div>
                    </div>
                  </div>

                  <div className="col-xs-12">
                    <div className="row">
                      <div className="col-sm-6 my-1">
                      <Toggle
      name="grab"
      checked={toggle.grab.isChecked}
      onChange={handleChangeToggle}
      text={toggle.grab.text}
        />
                      </div>
                      <div className="col-sm-6 my-1">
                      <Toggle
      name="vms"
      checked={toggle.vms.isChecked}
      onChange={handleChangeToggle}
      text={toggle.vms.text}
    />
                      </div>
                    </div>
                  </div>

    



         </div>
  
  </div>

  <MySelect
        options={colourOptions}
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{ Option, MultiValue, animatedComponents }}
        onChange={handleChange}
        allowSelectAll={true}
        value={optionSelected.optionSelect}
      />

  {/* <Select
          id="color"
          options={options}
          multi={true}
          onChange={handleChange}
          onBlur={handleBlur}
          value={props.value}
        />      */}
                 
  </div>
  <div>
  </div>  
      </div>
      </div>
  
      </section>


        </div>

      <input type="submit" onClick={handleSubmit(onSubmit)}/>
     
      </div>
      </div>
      
    </form>
  );
}
export default Registerprofile;
