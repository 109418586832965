import React,{useState,useEffect,useContext} from 'react';
import DatePicker from "react-datepicker";
import Select from './select';
import BranchContext from './../context/branch';
import {  getQueuetime, getStore} from './../../services/grabconcept';
import dlv from 'dlv';
import moment from 'moment';
import Input from './input';

const Queuecard = ({name,typename,store,head,allEditData,handleChangeStore,handleChangequeuetime,onChange,selected,onchangremark}) => {
  //console.log(props);

  const {currentBranch} = useContext(BranchContext);
  const obj = allEditData[0];
//console.log(obj);
  let editStoreId = dlv(obj, 'store_id',0);
  let editQueueDate = dlv(obj, 'queue_date',new Date());
  //let editQueueTime = dlv(obj, 'queue_time',"");
  if (editQueueDate==null){
    editQueueDate=new Date();
  }
  //console.log(editStoreId);
  
  const [storeId, setstoreId] = useState(editStoreId);
  const [timeId, settimeId] = useState("");
  const [remark, setremark] = useState('');
  const [storeList, setstoreList] = useState([]);
  const [startDate, setStartDate] = useState(editQueueDate);
  const [queuetime, setqueuetime] = useState([]);


  useEffect(() => {
    if (! currentBranch ) return; 

    const loadData = async () => {

      const { data: result } = await getStore(currentBranch && currentBranch._id); 

    // getStore(( currentBranch._id)).then (result=>{              
    //   setstoreList(result.data); 
    if (result) {  
      setstoreList(result);  
    }            
   
};
loadData();  
                
  //  });
}, 
 [ currentBranch])

 
 useEffect(() => {
  if (! currentBranch ) return; 

  const loadData = async () => {

    const { data: result } = await getQueuetime(moment(startDate, "YYYY-MM-DD").add(0, 'day').format("YYYY-MM-DD"),(currentBranch && currentBranch._id)); 
  // getQueuetime(moment(startDate, "YYYY-MM-DD").add(0, 'day').format("YYYY-MM-DD"),(currentBranch && currentBranch._id)).then(result => {
  // setqueuetime(result.data); 
  // });

  if (result) {  
    setqueuetime(result);  
  }
};         
  loadData();  
},
  [startDate,currentBranch ])
                                  

const onChangeDate =(event) => {
 // console.log(event);
 setStartDate(event);
 onChange(event);
}

  const onhandleChangeStoreID = (event) => {
    setstoreId(event.currentTarget.value);
    handleChangeStore(event.currentTarget.value)

  }
  const onhandleChangequeuetime = ({currentTarget:input}) => {
    settimeId(input.value);
    handleChangequeuetime(input.value)

  }
  const handleRemarkTextchange = ({currentTarget:input}) =>{
    setremark(input.value)
    onchangremark(input.value);
  }
if (!currentBranch){
  return (
    <div>

    </div>
  )
}

  return (


    <section className="content">
      <div className="container-fluid">
        <div className="row" >
          <div className="col-lg-12">

            <div className="card mb-4" >
              {/* <div className="card-header">{head}</div> */}
              <p className="bg-success text-white">
                <i className="fa fa-calendar" />

                <span> {head} </span>
              </p>
             
              <div className="card-body">
                <div className="form-group" >
                  <label htmlFor="typename">{typename}</label>
                  <div>
                    <DatePicker
                      todayButton="Today"
                      dateFormat="dd/MM/yyyy"
                      selected={selected}
                      onChange={onChangeDate}

                    />
                  </div>
                </div>
                <div className="form-group" >
                 
                  <div className="col-xs-12">
                    <div className="row">
                      <div className="col-sm-6">

                      <Select
                      label ={name}
                        data={queuetime}
                        value={timeId}
                        textProperty="Times"
                        valueProperty="Times"
                        onChange={onhandleChangequeuetime}
                      />
                      
                      </div>

                      <div className="col-sm-6 my-1">  
                   <Input 
                  name="remark"
                  value={remark}
                  label={"หมายเหตุ"}
                  onChange={handleRemarkTextchange}
                  
              />
                 </div>  

                    </div>
                 

                  
                      <Select 
                      label ={store}
                      data={storeList}
                      value={storeId}
                        textProperty="store_name"
                        valueProperty="Store_id"
                        onChange={onhandleChangeStoreID}
                      />
                   
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Queuecard;