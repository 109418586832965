import http from "./httpService";

//const apiUrl ="https://wms.advanceagro.net/WSVIS/api"

export async function SaveJobData(doc) {
  return http.post("/vms/SaveJobData", doc);
}
export async function ConfirmSaveRequest(doc) {
  return http.post("/vms/confirmrequest", doc);
}
export async function SaveSupplierData(doc) {
  return http.post("/vms/SaveSupplierData", doc);
}
export async function Approved(doc) {
  //console.log(doc);
  return http.post("/vms/Approved", doc);
}

export async function getShutdownlist(id = "0") {
  return http.get("/VMS/ShutdownPlan?_id=" + id);
}
export async function getShutdownPlan(id = "0") {
  return http.get("/VMS/GetShutdown?_id=" + id);
}
export async function getBusiness() {
  return http.get("/vms/SelectBusiness");
}
export async function getAreas(id) {
  // console.log(id)
  return http.get("/vms/SelectArea?BUID=" + id);
}
export async function getSupplier(id = "0") {
  return http.get("/VMS/GetSupplier");
}
export async function getSupplierPlan(id = "0") {
  return http.get("/VMS/GetSupplierShutdownPlan?_id=" + id);
}
export async function getAccoutRequest() {
  return http.get("/VMS/GetAcccountRequst");
}
export async function getvmsRegister(startdate = "", id = "1") {
  return http.get(
    "/VMS/RegisterByDate?startdate=" + startdate + "&AreaID=" + id
  );
}
export async function getvmsByPermit(startdate = "", id = "1") {
  return http.get(
    "/VMS/RegisterGroupPermit?startdate=" + startdate + "&AreaID=" + id
  );
}
//string StartDate="",string EndDate ="",string AreaID="1",string Status = ""
export async function getvmsWaiting(
  id = "1",
  pageSize = 1000,
  pageNumber = 1,
  startDate = "",
  endDate = "",
  Status = "",
  permittype=0,
  search="",comsearch=""

) {
  //  console.log(comsearch);
  return http.get(
    "/VMS/ForApprovelist?AreaID=" +
      id +
      "&pageSize=" +
      pageSize +
      "&pageNumber=" +
      pageNumber +
      "&StartDate=" +
      startDate +
      "&EndDate=" +
      endDate +
      "&Status=" +
      Status + 
      "&PermitType=" + 
      permittype +
      "&search=" + 
      search +"&comsearch=" +comsearch
  );
}
export async function getSafetypass(
  name = "",
  type = 0,
  comid = 0,
  pageSize = 1000,
  pageNumber = 1
) {
  return http.get(
    "/VMS/getSafetyPasslist?name=" +
      name +
      "&type="+type +
      "&comid=" +
      comid +
      "&pageSize=" +
      pageSize +
      "&pageNumber=" +
      pageNumber
  );
}
export async function getCockinout(
  id = "1",
  startdate = "",
  enddate = "",
  Id = "",
  pageSize = 1000,
  pageNumber = 1
) {
  //console.log(id,startdate,enddate,Id,pageSize,pageNumber);
  return http.get(
    "/VMS/Cockinout?AreaID=" +
      id +
      "&StartDate=" +
      startdate +
      "&EndDate=" +
      enddate +
      "&id=" +
      Id +
      "&pageSize=" +
      pageSize +
      "&pageNumber=" +
      pageNumber
  );
}
export async function getchemicalcom(
  search = "",
  comtype ="0",
  pageSize = 1000,
  pageNumber = 1
) {
 
  return http.get(
    "/VMS/ComChemicallist?search=" +
      search +
      "&pageSize=" +
      pageSize +
      "&pageNumber=" +
      pageNumber +" &chkmical="+ comtype
  );
}
export async function getaccountlist(
  search = "",
  pageSize = 1000,
  pageNumber = 1
) {
 
  return http.get(
    "/VMS/GetAccountList?search=" +
      search +
      "&pageSize=" +
      pageSize +
      "&pageNumber=" +
      pageNumber
  );
}
export async function SearchSupplier(id = "") {
  // console.log(id);
  return http.get("/VMS/Searchsupplier?search=" + id);
}
export async function getInoutperhour(startDate = "") {
  return http.get("/VMS/GetInoutPerHour?StartDate=" + startDate);
}
export async function getSafetyType() {
  return http.get("/VMS/getCardType");
}
export async function getSafetyRegisters(id = 0) {
  return http.get("/VMS/RegisterDetials?_id=" + id);
}
export async function getDetail(id = 0) {
  return http.get("/VMS/RegisterDetials?_id=" + id);
}
export async function getDoccodeID(id = "") {
  return http.get("/VMS/GetDetials?DocCode=" + id);
}
export async function getStatus() {
  return http.get("/VMS/GetStatus");
}
export async function getPermitType() {
  return http.get("/VMS/GetPermitType");
}
export default {
  getShutdownlist,
  getBusiness,
  getAreas,
  SaveJobData,
  getShutdownPlan,
  getSupplier,
  getSupplierPlan,
  SaveSupplierData,
  getAccoutRequest,
  ConfirmSaveRequest,
  getvmsRegister,
  getvmsByPermit,
  getvmsWaiting,
  getCockinout,
  SearchSupplier,
  getSafetypass,
  getInoutperhour,
  getSafetyType,
  getSafetyRegisters,
  getDetail,
  getDoccodeID,
  getStatus,
  getPermitType,
  getchemicalcom,
  getaccountlist
};
