import React from 'react';
const ValuesTable = (props) => {
    const {weightlist } = props;
    return ( 
    <table className="table table-striped">
    <thead className="thead-dark">
        <tr>
           
            <th>สาขา</th>
            <th >รหัสพนักงาน</th>
            <th>ฃื่อพนักงาน</th>
            <th>วันที่บันทึกข้อมูล</th>
            <th>ทะเบียนรถ</th>
            <th>เลขที่ใบชั่ง</th>
            <th>สินค้า</th>
            <th >น้ำหนักขาเข้า</th>
            <th >น้ำหนักขาออก</th>
            <th >น้ำหนักสุทธิ</th>
            <th></th>
            
        </tr>
    </thead>
    <tbody>
        {weightlist.map(values => 
        <tr key={values.no}>
      
        <td>{values.branchname}</td>
        <td >{values.empid}</td>
        <td>{values.name}</td>
        <td>{values.recorddate}</td>
        <td>{values.truckid}</td>
        <td>{values.weightno}</td>
        <td>{values.product}</td>
        <td >{values.weightin}</td>
        <td >{values.weightout}</td>
        <td>{values.weightnet}</td>
        <td> <button className="btn btn-primary m-2 d-none d-sm-block">view</button> </td>
        </tr>)}
        
    </tbody>
   
</table> );
}
 
export default ValuesTable;
