import React from "react";


const LivePos = (props) => {
    const { id, name, price, quantity,onChange } = props;
    console.log(props);
    const itemNumber = quantity;

 const handleChange = (id, itemNumber) => {
   onChange(id, itemNumber);
  };

    return (
      <tr>
        <td className="col-md-2"> {name}</td>
        <td className="col-md-1"> ${price}</td>
        <td className="col-md-2">
          <button
            className="btn btn-sm pull-left"
            onClick={() => handleChange(id, --itemNumber)}
          >
            <i className="glyphicon glyphicon-minus" />
          </button>

          <div className="col-md-6">
            <input type="number" value={itemNumber}  />
          </div>

          <button
            className="btn btn-sm pull-right"
            onClick={() => handleChange(id, ++itemNumber)}
          >
            <i className="glyphicon glyphicon-plus" />
          </button>
        </td>
        <td className="col-md-2">$0.00</td>
        <td className="col-md-2">{price}</td>
        <td className="col-md-2">
          <button
            className="btn btn-danger"
            onClick={() => handleChange(id, "delete")}
          >
            <i className="glyphicon glyphicon-trash" />
          </button>
        </td>
      </tr>
    );
  }

export default LivePos;