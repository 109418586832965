import React,{useState} from 'react';
import RenderButton from '../common/button';
import DatePicker from "react-datepicker";


const Headder = ({onChange}) => {

    const [all, setall] = useState('btn btn-primary');
    const [active, setactive] = useState('btn btn-outline-dark');
    const [error, seterror] = useState('btn btn-outline-dark');
    
  
    const [startDate, setStartDate] = useState(new Date());

    const handleonClick = ({ currentTarget: input }) => {
        if (input.value==0){
            disabledlabel();
            setall('btn btn-primary'); 
        }else if (input.value==1){
            disabledlabel();
            setactive('btn btn-primary'); 
        }else if (input.value==2){
            disabledlabel();
            seterror('btn btn-primary'); 
        }
        onChange(input.value);
    }
  


const disabledlabel = () => {
    setall('btn btn-outline-dark')
    setactive('btn btn-outline-dark') ;
    seterror('btn btn-outline-dark') ;
   
  
}



    return ( 
<div className='container'>
            <div className="row">
              {/* <div className='m-4'>
                 
                <div className='mt-2' style={{ marginTop: 20, marginBottom: 20 }} >
                  <DatePicker
                    todayButton="Today"
                    dateFormat="dd/MM/yyyy"
                    selected={startDate}
                    onChange={handleChange} />
                </div>

              </div>    */}

              <div className="row justify-content-end align-items-center">
                  <div className="col-auto mb-3 px-0"><ul className="btn-group p-0 m-0 ">
                     <RenderButton 
                     label ="All"
                     value="0"
                     onClick ={handleonClick}
                     className = {all}
                      />
                    <RenderButton 
                     value="1"
                     label ="ใบหน้าบนเครื่อง"
                     onClick ={handleonClick}
                     className = {active}
                      />
                         <RenderButton 
                           value="2"
                          label ="ใบหน้าไม่ผ่าน"
                     onClick ={handleonClick}
                     className = {error}
                      />
                       
                      </ul>
                      </div>
                      </div>


            </div>
          </div>

     );
}
 
export default Headder;

