import React from "react";

function Note(props) {

   // console.log(props);
    function handleClick() {
    props.onDelete(props.id);
  }

  // if (!props.id) {
  //   return null;

  // }

  return (
   
     
         <>
            
                <span className="badge  badge-primary">
                  {props.name}
                </span>
                  <span
                    style={{ cursor: "pointer"  }}
                  className="badge  badge-danger mr-2"
                  onClick={handleClick}>X</span> 
            
         
    </>

    
  );
}

export default Note;