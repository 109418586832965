import React,{useState,useContext, useRef,useCallback,useEffect} from 'react';
import Renderpermitlist from './rederpermitlist'
import BranchContext from "./../../components/context/branch";
import FontAwesome from 'react-fontawesome';
import {getgate,GetRequestGate} from './../../services/gate';
import Select from './../wms/select';
import Header from './header';
import ModalPermit from './addpermit'

const PermitGate = () => {
    const [name, setname] = useState('');
    const [requestlist, setrequestlist] = useState([]);
    const [gatelist, setgatelist] = useState([]);
    const [total, settotal] = useState(0);
    const [hasMore, setHasMore] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);

    const [gateId, setgateId] = useState(0);
    const [pageSize,setPageSize] = useState(30);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false)
    const [lastupdate, setlastupdate] = useState(new Date());
    const {currentBranch } = useContext(BranchContext);
    const [isOpen, setisOpen] = useState(false);
    const [data, setdata] = useState([]);
    const [messageerror, setmessageerror] = useState("");
    const [header, setheader] = useState("")


    const handleonClick =() => {

    }

    useEffect(() => {
  
      setrequestlist([]);
    }, [currentBranch, pageSize,gateId,lastupdate]);

    useEffect(() => {
      if (!currentBranch) return;
          const loadData = async () => {
  
              const { data: result } = await getgate();
              if ( result.status==='success') {
                setgatelist(result.data.list)
                           
              }
  
      };
      loadData();
    }         
    , [currentBranch]);

    useEffect(() => {
      if (!currentBranch) return;
   
      if (!pageSize || !pageNumber  ) return;
      setLoading(true)
      setError(false)

          const loadData = async () => {

              const { data: result } = await GetRequestGate(gateId,pageSize,pageNumber,name);
              if ( result.status==='success') {
                settotal(result.data.total);
           
                  setrequestlist(prevData => {   
           return [...new Set([...prevData, ...result.data.list])]
                        })
                 setHasMore(result.data.list.length > pageSize-1)
                 setLoading(false)                 
              }

      };
      loadData();
    }         
    , [currentBranch,gateId,name,pageSize,pageNumber,lastupdate]);

    const observer = useRef();
    const lastBookElementRef = useCallback(
      (node) => {
         // console.log('node = ' ,node);
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((entries) => {
          //  console.log(entries[0].isIntersecting,hasMore);
  
          if (entries[0].isIntersecting && hasMore) {
            setPageNumber((prevPageNumber) => prevPageNumber + 1);
          }
        });
        if (node) observer.current.observe(node);
      },
      [loading, hasMore]
    );
    const handleChange =(id, detail) => {
      setheader('ขออนุมัติ ผ่านเข้าออกประตูฉุกเฉิน')
        setisOpen(true);
        
        
      }

      const handleGateChange =(event) => {
 
        //console.log(event);
         setgateId(event);
         setPageNumber(1);
        
    }
    const handleCancleSubmit = () => {

        setisOpen(false);
     }

     const handleConfirmSubmit = () => {
      setlastupdate(new Date());
       setisOpen(false);
      // console.log('confirm')
   }
   const handleTextchange = (event) => {
    setname(event.currentTarget.value)

   }

    return ( 
<>
<ModalPermit
        isOpen={isOpen}
        button1="บันทึก"
        onCancel={handleCancleSubmit}
        onChange={handleTextchange} 
        onconfirm={handleConfirmSubmit}
        list = {data}
        header ={header}      
       
        error={messageerror}
      />
<div className="container m-3">
<div className="row">
<div className="col-lg-4  col-sm-10 col-xs-12" >

<i>
                พื้นที่ <span className="label label-default"></span>
              </i>
              <div className="mt-2" style={{ marginTop: 20, marginBottom: 20 }}>
                <Header onChange={handleGateChange} />
              </div>
{/* 
<Select
  label='พื้นที่'
  data={gatelist}
  value={gateId}
  textProperty="name"
  valueProperty="_id"
  onChange={handleChangeId}
/> */}

</div>
</div>
<div className="col-12 col-sm-6 col-md-6 mt-2" >
          <div className="container">
            <button
              className="btn btn-primary"
              onClick={() => handleChange(0, "new")}
            >
              <FontAwesome name="plus" />
              <i /> Add New
                          </button>
            <br />
            <br />
          
          </div>
        </div>
        </div>
       
<p className="text-success">
        แสดงข้อมูล {requestlist ? requestlist.length : 0}  /  {total} รายการ
      </p>
<Renderpermitlist
list = {requestlist}
handleOnClick = {handleonClick}
error={error}
lastBookElementRef={lastBookElementRef}

/>
</>

     );
}
 
export default PermitGate;