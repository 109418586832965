import React,{useEffect, useState,useContext, useRef,useCallback} from "react";
import Renderdevice from './renderdevice'
import {getdevice,getfacesite,getfacegroup} from './../../services/facescan';
import BranchContext from "./../../components/context/branch";
import Select from "./../wms/select";
import Input from "./../wms/input";
import FontAwesome from 'react-fontawesome';
import ModalDevice from './adddevice'
import Header from "./header";
import HeaderGroup from "./headergroupdevice";
import ScrollToTop from "./../common/ScrollToTop";
import ProgressBar from './../common/progressbar';

const FaceDevice = () => {


  const { currentBranch } = useContext(BranchContext);
  const [search, setsearch] = useState('');
   const [name, setname] = useState('');
   const [lastupdate, setlastupdate] = useState(new Date());
  const [groupid, setgroupid] = useState(3);
  const [siteid, setsiteid] = useState(0);
  const [id, setid] = useState(0);
  //const [value, setValue] = useState([1]);
  const [hasMore, setHasMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false)
  const [pageSize,setPageSize] = useState(15);
  const [total,setTotal] = useState(0);
  const [devicelist, setsdevicelist] = useState([]);
  const [grouplist, setgrouplist] = useState([]);
  const [sitelist, setsitelist] = useState([]);
  const [isOpen, setisOpen] = useState(false);
  const [data, setdata] = useState([]);
  const [messageerror, setmessageerror] = useState("");
  const [header, setheader] = useState("");
  const [showonscroll, setshowonscroll] = useState(false)
  const [showprogress, setshowprogress] = useState(false)
  const [completed, setcompleted] = useState(50)

  useEffect(() => {
  
    setsdevicelist([]);
  }, [currentBranch, pageSize,search,siteid,groupid,id,lastupdate,showonscroll]);


  useEffect(() => {
    if (!currentBranch) return;
        const loadData = async () => {

            const { data: result } = await getfacesite();
            if ( result.status==='success') {
              setsitelist(result.data.list)
                         
            }

    };
    loadData();
  }         
  , [currentBranch]);


  useEffect(() => {
    if (!currentBranch) return;
        const loadData = async () => {

            const { data: result } = await getfacegroup();
            if ( result.status==='success') {
              setgrouplist(result.data.list)
                         
            }

    };
    loadData();
  }         
  , [currentBranch]);

    useEffect(() => {
        if (!currentBranch) return;
     
        if (!pageSize || !pageNumber  ) return;
        setLoading(true)
        setError(false)
        setshowprogress(true)
            const loadData = async () => {
 
                const { data: result } = await getdevice(id,siteid,search,groupid,pageSize,pageNumber);
                if ( result.status==='success') {
                    setTotal(result.data.total);
                    setshowprogress(false)
                    setsdevicelist(prevData => {   
             return [...new Set([...prevData, ...result.data.list])]
                          })
                   setHasMore(result.data.list.length > pageSize-1)
                   setLoading(false)                 
                }

        };
        loadData();
      }         
      , [currentBranch,id,siteid,search,groupid,pageSize,pageNumber,lastupdate,showonscroll]);

      const observer = useRef();
      const lastBookElementRef = useCallback(
        (node) => {

          if (node && node.offsetTop > 0){
            setshowonscroll(true);
           }
           // console.log('node = ' ,node);
          if (loading) return;
          if (observer.current) observer.current.disconnect();
          observer.current = new IntersectionObserver((entries) => {
            //  console.log(entries[0].isIntersecting,hasMore);
    
            if (entries[0].isIntersecting && hasMore) {
              setPageNumber((prevPageNumber) => prevPageNumber + 1);
            }
          });
          if (node) observer.current.observe(node);
        },
        [loading, hasMore]
      );
        
     const handlegroupChange = (event) => {
      setgroupid(event);
      //console.log(name);
      setsearch(name)
      setPageNumber(1);
     }

     const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        setPageNumber(1);
        setsearch(name)
      }
    };
     const handlesiteChange = (event) => {
       
    setsiteid(event.currentTarget.value);
    setsearch(name)
    setPageNumber(1);
     }

     const handleChange = (event) => {
      setsiteid(event);
      setPageNumber(1);
      setsearch(name)
     
      //    console.log('Click event',pageNumber);
    };
     const handleOnClick =(id, action) => {
      if (action ==='new' && id ===0){
        setheader("เพิ่มเครื่องใหม่")
        setisOpen(true);
        setdata([])
        }else if (action ==='edit'){  
          setdata([]);
        const subdata = (devicelist.filter(x=> x.face_id ===id))
        setheader("แก้ไขเครื่อง")
          setdata(subdata)
          setisOpen(true);
        }
    }
     const handleTextchange = (event) => {
      setname(event.currentTarget.value)

     }
     const handleSearchClick = () => {
      setPageNumber(1);
       setsearch(name);

     }
     const handleCancleSubmit = () => {

        setisOpen(false);
     }

     const handleConfirmSubmit = () => {
      setlastupdate(new Date());
       setisOpen(false);
      // console.log('confirm')
   }
   const scrollToTop = () => { 
    setPageNumber(1);
    setshowonscroll(false);
  }

    // console.log(sitelist)

    if (currentBranch._id !=='0'){
      return <>
      </>
      }
  return <>


<div className="container">
<ModalDevice
        isOpen={isOpen}
        button1="บันทึก"
        onCancel={handleCancleSubmit}
        onChange={handleTextchange} 
        onconfirm={handleConfirmSubmit}
        list = {data}
        header ={header}      
        grouplist={grouplist}
        sitelist ={sitelist}
        error={messageerror}
      />

        <div className="row">

           
          <div className="col-lg-6  col-sm-10 col-xs-12">
            <div className="form-group">
            <Input
                    name="devicename"
                    value={name}
                    label="ชื่อเครื่อง"
                    placeholder="ระบุชื่อเครื่อง เพื่อค้นหา"
                    onChange = {handleTextchange}
                    onClick = {handleSearchClick}
                    onKeyPress={handleKeyPress}
                    search="ค้นหา"
                  />
            </div>
          </div>
          <div className="col-lg-6  col-sm-10 col-xs-12">
            <div className="form-group">
            <i>
                กลุ่ม <span className="label label-default"></span>
              </i>
              <div className="mt-2" style={{ marginTop: 20, marginBottom: 20 }}>
        <HeaderGroup
      onChange={handlegroupChange}
        />
          </div>

            {/* <Select
                label="ประเภท"
                data={grouplist}
                value={groupid}
                textProperty="name"
                valueProperty="_id"
                onChange={handlegroupChange}
              /> */}

              {/* <Select
                label="พื้นที่"
                data={sitelist}
                value={siteid}
                textProperty="name"
                valueProperty="_id"
                onChange={handlesiteChange}
              /> */}
            </div>
          </div>
          <div className="col-lg-6  col-sm-10 col-xs-12">
            <div className="form-group">

           
              <i>
                พื้นที่ <span className="label label-default"></span>
              </i>
              <div className="mt-2" style={{ marginTop: 20, marginBottom: 20 }}>
                <Header onChange={handleChange} />
              </div>
           
        
         
            </div>
          </div>
         
        </div>
        {/* <Header onChange={handleChange} handleDateChange={handleDateChange} /> */}
      </div>

      <div className="col-12 col-sm-6 col-md-6 mt-2" >
          <div className="container">
            <button
              className="btn btn-primary"
              onClick={() => handleOnClick(0, "new")}
            >
              <FontAwesome name="plus" />
              <i /> Add New
                          </button>
            <br />
            <br />
          
          </div>
        </div>


      <p className="text-success">
        แสดงข้อมูล {devicelist ? devicelist.length : 0}  /  {total} รายการ
      </p>
      { showprogress && <ProgressBar bgcolor={"#6a1b9a"} completed={completed} />}
      {pageNumber > 1 && showonscroll &&
        <ScrollToTop 
        onClick ={scrollToTop}
        />}

   <Renderdevice
  list = {devicelist}
  handleOnClick = {handleOnClick}
 error={error}
  lastBookElementRef={lastBookElementRef}
            />
  </>
};

export default FaceDevice;
