import React ,{useState,useEffect,useContext }from 'react'
import Select from './select'
import Input from './input';
import dlv from 'dlv';
import {getProductType,getProduct,getaim} from './../../services/grabconcept';
import BranchContext from './../context/branch';
import Joi from "joi-browser";
import ModelSelect from './../common/modalselect';
//import RegisterForm from './customercard';

// import {
//   Card, CardImg, CardText, CardBody,
//   CardTitle, CardSubtitle, Button
// } from 'reactstrap';

const Productcard = (props)=> {
const {handleProOption2,handleProOption1,id,DO,labelProOption1,proname,
  typename,head,handleChangeProductType,handleChangeProduct,labelProOption2,
  handleChangeAim,onChange,aimname,allEditData,paramsMatchId, registerError}=props;

 const {currentBranch}  = useContext(BranchContext);

    const ojb = allEditData[0];
  
   let editProductTypeId = dlv(ojb, 'type_id',0);
   let editProductID = dlv(ojb, 'ProductID',0);
   let editProOption1 = dlv(ojb, 'option1',"");
   let editProOption2 = dlv(ojb, 'option2',"");
   let editAimId = dlv(ojb, 'receive_aim','00');
   let editDo = dlv(ojb, 'DO','');
   const [vForm, setvForm] = useState(false);
   

  const[productType, setproductType] = useState([]);
  const [product, setproduct] = useState([]);
  const [productTypeId, setProductTtpeId] = useState(editProductTypeId);
  const [productid, setproductid] = useState(editProductID);
  const [option1, setoption1] = useState(editProOption1);
  const [option2, setoption2] = useState(editProOption2);
  const [aimNo, setaimNo] = useState([]);
  const [aimId, setaimId] = useState(editAimId);
  const [doId, setdoId] = useState(editDo);
  
  const [openModalSearch, setOpenModalSearch] = useState(false);
  const [openModalSearchProtype, setOpenModalSearchProtype] = useState(false);

  const [stateData, setstateData] = useState({ 
    data: {
      DO: "",
    },
      errors: {enable: false, message: ""}
   });
    

 useEffect(() => {
      if (! currentBranch ) return; 
      const loadData = async () => {

        const { data: result } = await getProductType(currentBranch && currentBranch._id);  
      if (result) {  
        setproductType(result);  
      }            
     
  };
  loadData(); 
                 }, 
      [currentBranch])


  useEffect(() => {
    if (! currentBranch ) return;

    const loadData = async () => {

      const { data: result } = await getProduct(productTypeId,(currentBranch && currentBranch._id));  
    if (result) {  
      setproduct(result);  
    }            
   
};
loadData(); 

    // getProduct(productTypeId,(currentBranch && currentBranch._id)).then (result=>{              
    // setproduct(result.data);                   
    // });
}, 
 [productTypeId,currentBranch])


 useEffect(() => {
  if (! currentBranch ) return; 

  const loadData = async () => {

    const { data: result } = await getaim(currentBranch && currentBranch._id);  
  if (result) {  
    setaimNo(result);  
  }            
 
};
loadData(); 

  // getaim((currentBranch._id)).then (result=>{              
  // setaimNo(result.data);  

  // });
}, 
[ currentBranch])


useEffect(() => {
 
if (vForm) return;
if (!currentBranch) return;


const onload = () => {

  if (registerError) {
    const data = { ...stateData.data};
    setstateData( {data,errors: { enable: false, message: registerError.DO }});
    setvForm(true);
  } else 
  {
   // setvForm(true);
  }
 
 
}
onload();

})


let schema1 = {
  DO: Joi.string().min(0).max(99999999).required().label("DO"),
  
};

const validateProperty = ({ name, value }) => {
  try {
    const obj = { [name]: value };
    const schema = { [name]: schema1[name] };
    const options = { abortEarly: true };
    const { error } = Joi.validate(obj, schema, options);
    return error ? error.details[0].message : null;
  } catch(err) {
    // console.log('validateProperty', name, value, err);

    return err.message;
  }
  
};

const handelOption1 = ({ currentTarget: input }) =>{
setoption1(input.value);
handleProOption1(input.value);
}

const handelOption2 = ({ currentTarget: input }) =>{
  setoption2(input.value);
  handleProOption2(input.value);
  }
function handleChangProductID(event){
  setproductid(event.currentTarget.value)
  handleChangeProduct(event.currentTarget.value);
}
  const handleTextchange = ({ currentTarget: input }) => {
    const data = { ...stateData.data};
    const errors = { ...stateData.errors}
    if ( input.name in data) { 
   const errorMessage = validateProperty(input);
      if (errorMessage)
       errors[input.name] = errorMessage;
       else
       delete errors[input.name];
      data[input.name] = input.value;

    setstateData( {data,errors: { enable: false, message: errorMessage }});
    if (errorMessage && !input.value ==='') return;
     setdoId(input.value);
     onChange(input.value);
     }
  }
  const handleChangeAimNo =(event) =>{
    setaimId(event.currentTarget.value);
    handleChangeAim(event.currentTarget.value);
  }
 const handleChangeProductTypeID=(event)=>{
setProductTtpeId(event.currentTarget.value)
handleChangeProductType(event.currentTarget.value)
}

const handleSearchClick = () => {
  setOpenModalSearch(true);
 
}

const handleSearchTypeClick =() => {
  setOpenModalSearchProtype(true);
}
const handleConfirmSubmit = ({currentTarget:input}) => {
   setOpenModalSearch(false);
   setproductid(input.value);
   handleChangeProduct(input.value);
 }

 const handleConfirmTypeSubmit = ({currentTarget:input}) => {
  setOpenModalSearchProtype(false);
  setProductTtpeId(input.value);
  handleChangeProductType(input.value);
}
 
 const handleCancleSubmit =()=>{
   setOpenModalSearch(false);
 }

 const handleCancleTypeSubmit =()=>{
  setOpenModalSearchProtype(false);
}

//console.log(option1);

if (!currentBranch || !id  || (!paramsMatchId=='new' && !editProductTypeId )) {
  return (
    <div>

    </div>
  )
}
  return (

   
    <section className="content">
    <div className="container-fluid">

       <ModelSelect isOpen={openModalSearch} label ="ค้นหาสินค้า"
          onCancel={handleCancleSubmit}
          onSelected={handleConfirmSubmit} 
          list = {product}
          textProperty="ProductName"
          valueProperty="Product_ID"  
          />
       <ModelSelect isOpen={openModalSearchProtype} label ="ค้นหาประเภทสินค้า"
          onCancel={handleCancleTypeSubmit}
          onSelected={handleConfirmTypeSubmit} 
          list = {productType}
          textProperty="type_name"
          valueProperty="Type_ID" 
          />
    <div className="row" >       
   
<div className="col-lg-12">
<div className="card mb-4" >
<p className="bg-warning text-white">
<i className="fa fa-shopping-basket">
</i>
<span> {head} </span>
</p>
<div className="card-body"> 

  <Select 
          data ={productType}
         label = {typename}
         value={productTypeId} 
         textProperty="type_name"
         valueProperty="Type_ID"    
         onChange={handleChangeProductTypeID}
         onClick ={handleSearchTypeClick}
         search="ค้นหา"
         />
<Select 
        data ={product}
        label = {proname}
         value={productid}
         textProperty="ProductName"
         valueProperty="Product_ID"   
         onChange={handleChangProductID} 
         onClick ={handleSearchClick}
         search="ค้นหา"
         />
          <div className="col-xs-12">
        <div className="row">
         <div className="col-sm-6 my-1">
<Select data ={aimNo}
        label={aimname}
         value={aimId}
         textProperty="aim_name"
         valueProperty="aim_no"   
         onChange={handleChangeAimNo} />
         </div>
         <div className="col-sm-6 my-1">  
          <Input 
                  name="PO"
                  value={option2}
                  label= {labelProOption2}
                  onChange={handelOption2}
                  
              />
         </div>  

         </div>
         </div>
         <div className="col-xs-12">
        <div className="row">
  <div className="col-sm-6 my-1">  
          <Input 
                  name="DO"
                  value={doId}
                  label={DO}
                  onChange={handleTextchange}
                  error ={stateData.errors.message}
              />
         </div>  

                {currentBranch && currentBranch._id=="505" &&  <div className="col-sm-6 my-1"> <Input 
                  name="option1"
                  value={option1}
                  label={labelProOption1}
                  onChange={handelOption1}
                 
           /> </div>  }
</div>
</div>
</div>
</div>
</div>
<div>
</div>  
    </div>
    </div>
    </section>
  );
};

export default Productcard;