import http from "./httpService";

//const apiEndpoint = "General/CustomerType";

export async function getCustomerType(id) {
  return http.get("/General/CustomerType2?BranchID=" + id);
}
export async function getProductType(id) {
  return http.get("/General/ProductType?BranchID=" + id);
}

export async function getProduct(productid, id) {
  return http.get("/General/Product?Type_id=" + productid + "&BranchID=" + id);
}
export async function getTruckType(id) {
  return http.get("/General/TruckType?BranchID=" + id);
}
export async function getStore(id) {
  return http.get("/General/Store?BranchID=" + id);
}
export async function getCustomer(id) {
  return http.get("/general/CustomerID?BranchID=" + id);
}
export async function getProvince(id) {
  return http.get("/general/Province?BranchID=" + id);
}
export async function getQueuetime(date, id) {
  return http.get("/Queue/Queue_Time?Date=" + date + "&BranchID=" + id);
}
export async function getBranchList() {
  return http.get("/JAWIS/getBranch ");
}
export async function getaim(id) {
  return http.get("/General/Inorder?branchid=" + id);
}
export async function getRegisterlist(
  start = "",
  end = "",
  branchid = "",
  cusid = "0",search="",pageSize =1000,pageNumber = 1
) {
  // console.log('cusid=',pageNumber);
  return http.get(
    "/JAWIS/RegisterList?startdate=" +
      start +
      "&enddate=" +
      end +
      "&branchid=" +
      branchid +
      "&cusid=" +
      cusid +"&pageSize="+ pageSize +"&pageNumber="+ pageNumber +"&search="+ search
  );
}
export async function getregister(webid, id) {
  // console.log(webid);
  return http.get("/Queue/WebRegisterEdit?branchid=" + id + "&webid=" + webid);
}

export async function getSumaryRegister(start = "", end = "", id) {
  // console.log(start,id,end);
  return http.get(
    "/JAWIS/getSumaryRegister?id=" +
      id +
      "&StartDate=" +
      start +
      "&EndDate=" +
      end
  );
}
export async function getWeightlist(
  start = "",
  end = "",
  branchid = "",
  typeid = "0",search="",pageSize =1000,pageNumber = 1
) {
 // console.log('cusid=',search);
  return http.get(
    "/JAWIS/Weightlist?startdate=" +
      start +
      "&enddate=" +
      end +
      "&branchid=" +
      branchid +
      "&typeid=" +
      typeid +"&pageSize="+ pageSize +"&pageNumber="+ pageNumber +"&search="+ search
  );
}
export default {
  getCustomerType,
  getProductType,
  getTruckType,
  getProduct,
  getStore,
  getCustomer,
  getQueuetime,
  getBranchList,
  getaim,
  getRegisterlist,
  getregister,
  getSumaryRegister,
};
