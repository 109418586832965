import http from "./httpService";

const apiUrl = "https://wms.advanceagro.net/wsface/api";
export async function SaveAccessGate(doc){
  return http.post( apiUrl +"/face/SaveAccessGate", doc);
}
export async function getgate(
    id = 0,
) 
{
  return http.get( 
    apiUrl +
      "/face/GetGate?_id=" +
      id 
  )
  ;
}
export async function getCarType(
    id = 0,
) 
{
  return http.get( 
    apiUrl +
      "/face/getCarType?_id=" +
      id 
  )

  ;
}
export async function getProvince(
    id = 0,
) 
{
  return http.get( 
    apiUrl +
      "/face/getProvince?_id=" +
      id 
  )

  ;
}


export async function GetRequestGate(
  id = "0",
  pageSize = 1000,
  pageNumber = 1,
  search=""

){
  //   console.log(id,pageSize,pageNumber);
  return http.get(
    apiUrl + "/face/GetRequestGate?GateID=" +
      id +
      "&pageSize=" +
      pageSize +
      "&pageNumber=" +
      pageNumber +
      "&name=" + 
      search
  );
}
export default {
  getgate,getCarType,getProvince,SaveAccessGate,GetRequestGate
};
