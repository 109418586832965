import React from 'react';
import Chart from 'react-apexcharts';

const Columnchart = ({ options, data: series,label ,type,title,width ="100%",height='100'}) => {

//console.log(options.length);
 
if (!options || options.length===0 ) 
return (<div></div>)


      
    return ( 


      <div>
    {options && <Chart
    options={options} 
    series={options.series} 
    type={type}
    width ={width}
    height = {height}  
    
    />}
        </div>
    
   
     );
}
 
export default Columnchart;