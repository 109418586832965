import React,{useState} from "react";
import { Modal, Button } from "react-bootstrap";
//import './../../pos.css'


const AddProduct = ({isOpen,oncanCel}) => {
    const [newName, setnewName] = useState('');
    const [newPrice, setnewPrice] = useState(0);
    const [newQuantity, setnewQuantity] = useState(0);


    const onSave = () =>{

    }
    const handleoncanCel = () =>{
    oncanCel();
  
    }

   const handleName = () =>{

   }
   const handlePrice = () =>{

   }

   const handleQuantity = () =>{

   }

    return (  

        <Modal show={isOpen}>
        <Modal.Header>
          <Modal.Title>Edit Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form-horizontal" 
            name="newProductForm">
            <div className="form-group input-group">
              <label className="col-md-4 control-label" htmlFor="name">
                Name
              </label>
              <div className="col-md-8">
                <input
                  name="name"
                  placeholder="Name"
                  onChange={handleName}
                  className="form-control"
                  value={newName}
                />
              </div>
            </div>

    

            <div className="form-group input-group">
              <label className="col-md-4 control-label" htmlFor="price">
                Price
              </label>
              <div className="col-md-8">
                <input
                  name="price"
                  placeholder="Price"
                  className="form-control"
                  onChange={handlePrice}
                  value={newPrice}
                  type="number"
                  step="any"
                  min="0"
                />
              </div>
            </div>
            <div className="form-group input-group">
              <label
                className="col-md-4 control-label"
                htmlFor="quantity_on_hand"
              >
                Quantity On Hand
              </label>
              <div className="col-md-8">
                <input
                  name="quantity_on_hand"
                  placeholder="Quantity On Hand"
                  onChange={handleQuantity}
                  value={newQuantity}
                  className="form-control"
                />
              </div>
            </div>
            <div className="form-group input-group">
              <label className="col-md-4 control-label" htmlFor="image">
                Upload Image
              </label>
              <div className="col-md-8">
                <input type="file" name="image" />
              </div>
            </div>
            <br /> <br /> <br />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={oncanCel}>
            Close
          </Button>
          <Button onClick={onSave}>
            Update
        </Button>
        </Modal.Footer>
      </Modal>

    );
}
 
export default AddProduct;