import React from 'react';



const Rendercockinout= ({list,snackMessage,handleChange,lastBookElementRef,loading,error}) => {

//console.log(list);

    return ( 

        <div>
        <div className="container"> 

          <table className="table table-hover table-striped">
          <thead className="thead-dark">
              <tr>
              <th scope="col">item</th>
                 <th scope="col">Name</th>
                 <th scope="col">RequstDate</th>
                <th scope="col">permittype</th>
                <th scope="col">AreaName</th>
                <th scope="col">company</th>
               
              </tr>
            </thead>
            <tbody>{
                 list &&
                 list.map((item,index) => {

                  if (list.length === index +1) {
                    return    <tr ref = {lastBookElementRef}  key={item.No}>
                    <td  style={{ fontSize: "14px" }} >{item.No} </td>
               <td  style={{ fontSize: "14px" }}>{item.Fname} {item.Lname} 
                   <div className="row">
                       <small style={{ fontSize: "14px" }}>
                         In : {item.DateIN}
                       </small>
                     </div>  
                     <div className="row">
                       <small style={{ fontSize: "14px" }}>
                       Out: {item.DateOUT}
                       </small>
                     </div>    
               </td>          
               <td  style={{ fontSize: "14px" }}>{item.StartDate} </td>
                  
                   <td style={{ fontSize: "14px" }}>{item.permittype}
                   <div className="row">
                       <small style={{ fontSize: "14px" }}>
                         {item.DocCode}
                       </small>
                     </div>  

                   
                   </td>
                   <td style={{ fontSize: "14px" }}>{item.AreaName} 
                   
                   <div className="row">
                       <small style={{ fontSize: "14px" }}>
                       {item.DepartMentName}
                       </small>
                     </div>  

                     <div className="row">
                       <small style={{ fontSize: "14px" }}>
                      {item.Gate}
                       </small>
                     </div>  
                   </td> 
                   <td  style={{ fontSize: "14px" }}>{item.ComName} </td>                 
                 </tr>

                  }else {
                  return    <tr key={item.No}>
                         <td  style={{ fontSize: "14px" }} >{item.No} </td>
                    <td  style={{ fontSize: "14px" }}>{item.Fname} {item.Lname} 
                   
                        
                        <div className="row">
                            <small style={{ fontSize: "14px" }}>
                              In : {item.DateIN}
                            </small>
                          </div>  

                          <div className="row">
                            <small style={{ fontSize: "14px" }}>
                            Out: {item.DateOUT}
                            </small>
                          </div>  
                    
                   
                    </td>
                   
                    <td  style={{ fontSize: "14px" }}>{item.StartDate} </td>
                       
                        <td style={{ fontSize: "14px" }}>{item.permittype}
                        <div className="row">
                            <small style={{ fontSize: "14px" }}>
                              {item.DocCode}
                            </small>
                          </div>  

                        
                        </td>
                        <td style={{ fontSize: "14px" }}>{item.AreaName} 
                        
                        <div className="row">
                            <small style={{ fontSize: "14px" }}>
                            {item.DepartMentName}
                            </small>
                          </div>  

                          <div className="row">
                            <small style={{ fontSize: "14px" }}>
                           {item.Gate}
                            </small>
                          </div>  
                        </td> 
                        <td  style={{ fontSize: "14px" }}>{item.ComName} </td>                 
                      </tr>
                      }
                      }
                    
                    )
                
                }</tbody>

          </table>
        </div>
        <div>{loading && 'Loading...'}</div>
      <div>{error && 'Error'}</div>
        <div id="snackbar">{snackMessage}</div>
      </div>

     );
}
 
export default Rendercockinout;