import React,{useEffect, useState} from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import Select from "../wms/select";
import dlv from "dlv";
import { useForm } from "react-hook-form";
import { FormInput } from "../common/forminput";
import {savedevice,getfacesite,getfacegroup} from '../../services/facescan';
import FontAwesome from 'react-fontawesome';
import { toast } from "react-toastify";

const AddDevice = ({
  button1,
  isOpen,
  header,
  onCancel,
  onconfirm,

  list

}) => {

  const ojb = list[0];

  const editid= dlv(ojb, 'face_no','');
  let editname = dlv(ojb, 'face_name','');
  let editip = dlv(ojb, 'face_ip','');
  let editaccount = dlv(ojb, 'face_user','admin');

  let editpassword = dlv(ojb, 'face_pass','');
  let editport = dlv(ojb, 'face_port','8000');
  let editgroupid = dlv(ojb, 'group_id',0); 
  let editsiteid = dlv(ojb, 'site_id',0); 
  
 //console.log(editd,editname)

 const PreloadedValues = {

  id: editid,
  device: editname,
  port: editport,
  deviceaccount: editaccount,
  devicepassword: editpassword,

   ip:editip
};


  const [groupid, setgroupid] = useState(editgroupid);
  const [siteid, setsiteid] = useState(editsiteid);
  const [error,seterror] = useState('');


  const [autoid, setautoid] = useState(0);


  const [grouplist, setgrouplist] = useState([]);
   const [sitelist, setsitelist] = useState([]);


  const { register, errors, handleSubmit, setValue, watch } = useForm({
    mode: "all",
    // defaultValues: PreloadedValues,
  });

  const [messageErros, setmessageErros] = useState({
    askAlert: { enable: false, message: "" },
  });

 // console.log(autoid);

//  const password = useRef({});

//   password.current = watch("password", "");

useEffect(() => {
  if (list.length) {

  //  console.log(list[0]);
    setValue([{ device: list[0].face_name }, 
      { ip: list[0].face_ip },{port:list[0].face_port},{
        deviceaccount:list[0].account}, {devicepassword:list[0].face_pass},
      {port:list[0].face_port},{id:list[0].face_no}
      ]);
      setgroupid(list[0].group_id);
      setsiteid(list[0].site_id);
      setautoid(list[0].face_id)
  }else{
    setgroupid(0);
    setsiteid(0);
    setautoid(0);
  }
}, [list,setValue]);  


  useEffect(() => {
   
        const loadData = async () => {

     const { data: result } = await getfacesite('add');
    if ( result.status==='success') {
   setsitelist(result.data.list)
                         
            }

    }
    loadData();
  }         
  , []);


  useEffect(() => {
    
        const loadData = async () => {

            const { data: result } = await getfacegroup('add');
            if ( result.status==='success') {
              setgrouplist(result.data.list)
          // setvForm(true);        
            }

    };
    loadData();
  }         
  , []);



const handlegroupChange = (event) => {
 setgroupid(event.currentTarget.value);
 if ( event.currentTarget.value!==0){
seterror('')
 }
}
const handlesiteChange = (event) => {
  setsiteid(event.currentTarget.value);
  if ( event.currentTarget.value!==0){
    seterror('')
     }
 }


          const onSubmit = (data) => {
          
           seterror('')

           if (siteid==0){
            seterror("กรุณาเลือกพื้นที่ก่อนค่ะ")
            return;
           } else if (groupid==0){
            seterror("กรุณาเลือกกลุ่มก่อนค่ะ")
            return;
           }

           
          
          let action ='add';
          if (autoid >0 ){
            action ="edit"
          }
          
          const saveobject = { autoid, action ,siteid , groupid , ...data };
            SaveData(saveobject)
           
            
          };
          
          const  SaveData = async (doc) => {

            try {
        
            const {data: result} = await savedevice(doc);
        
            if (result.status==='success') {

              toast.success("บันทึกเสร็จเรียบร้อย");
            //  console.log(result)
            onconfirm();

              }
            else {
            toast.error(result.message)
            }
          } 
          catch (error) {
           // toast.error("Error");
          }
        }

  // const worklist = list.WorkerList;

 //console.log(list);

 //  console.log('id=',id,editid)
  // if (!vForm){
  //   return (
  //     <div>
  
  //     </div>
  //   )
  // }
  
  
  return (

    <form onSubmit={(e) => e.preventDefault()}>
    <div>
      <Modal
        isOpen={isOpen}
        toggle={onCancel}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalHeader> 
        <FontAwesome name ='pencil-square-o'  >  </FontAwesome> {header}
      
        
        </ModalHeader>
        <ModalBody>
      <div className="row">
       <div className="col-sm-6 col-xs-12">
         <div className="form-group">
              {/* <Input
                label="ชื่อเครื่อง"
                name="device"
                value={name}
                placeholder="ระบุชื่อเครื่อง"
                onChange={handleChangName}
                'test', e.target.value
              /> */}

          <FormInput
          
                                id="device"
                                name="device"
                                type="text"
                                label="ชื่อเครื่อง"
                                placeholder="ระบุชื่อเครื่อง"
                                onChange = {e => setValue()} 
                                defaultValue={PreloadedValues.device}
                                register={register({
                                  required: "กรุณาระบุชื่อเครื่อง",
                                  maxLength: {
                                    value: 100,
                                    message: "Max length is 100",
                                  },
                                  minLength: {
                                    value: 1,
                                    message: "ระบุอย่างน้อย 1 ตัวอักษร",
                                  },
                                })}
                                error={errors.device}
                              />

            </div>
            </div>

            <div className="col-sm-6 col-xs-12">
         <div className="form-group">
             

<FormInput
                                id="id"
                                name="id"
                                type="text"
                                label="หมายเลขเครื่อง"
                                placeholder="ระบุหมายเลขเครื่อง"
                                onChange = {e => setValue()} 
                                defaultValue={PreloadedValues.id}
                                register={register({
                                  required: "กรุณาระบุหมายเลขเครื่อง",
                                  maxLength: {
                                    value: 100,
                                    message: "Max length is 100",
                                  },
                                  minLength: {
                                    value: 1,
                                    message: "ระบุอย่างน้อย 1 ตัวอักษร",
                                  },
                                })}
                                error={errors.id}
                              />
            </div>
            </div>
        </div>
        <div className="row">
       <div className="col-sm-6 col-xs-12">
         <div className="form-group">
             

<FormInput
                                id="ip"
                                name="ip"
                                type="text"
                                label="IP Address เครื่อง"
                                placeholder="IP Address"
                                onChange = {e => setValue()} 
                                defaultValue={PreloadedValues.ip}
                                register={register({
                                  required: "กรุณาระบุ IP Address",
                                  maxLength: {
                                    value: 100,
                                    message: "Max length is 100",
                                  },
                                  minLength: {
                                    value: 10,
                                    message: "ระบุอย่างน้อย 10 ตัวอักษร",
                                  },
                                })}
                                error={errors.ip}
                              />
            </div>
            </div>

            <div className="col-sm-6 col-xs-12">
         <div className="form-group">
             


<FormInput
                                id="port"
                                name="port"
                                type="text"
                                label="หมายเลข port"
                                placeholder="Port"
                                onChange = {e => setValue()} 
                                defaultValue={PreloadedValues.port}
                                register={register({
                                  required: "กรุณาระบุ หมายเลข port",
                                  maxLength: {
                                    value: 100,
                                    message: "Max length is 100",
                                  },
                                  minLength: {
                                    value: 4,
                                    message: "ระบุอย่างน้อย 4 ตัวอักษร",
                                  },
                                })}
                                error={errors.port}
                              />
            </div>
            </div>
        </div>
        <div className="row">
       <div className="col-sm-6 col-xs-12">
         <div className="form-group">

           



<FormInput
                                id="deviceaccount"
                                name="deviceaccount"
                                type="text"
                                label="device account"
                                placeholder="account"
                                onChange = {e => setValue()} 
                                defaultValue={PreloadedValues.deviceaccount}
                                register={register({
                                  required: "กรุณาระบุ account",
                                  maxLength: {
                                    value: 100,
                                    message: "Max length is 100",
                                  },
                                  minLength: {
                                    value: 3,
                                    message: "ระบุอย่างน้อย 3 ตัวอักษร",
                                  },
                                })}
                                error={errors.deviceaccount}
                              />
            </div>
            </div>

            <div className="col-sm-6 col-xs-12">
         <div className="form-group">
             

<FormInput
                                id="devicepassword"
                                name="devicepassword"
                                type="password"
                                label="device password"
                                placeholder="password"
                                onChange = {e => setValue()} 
                                defaultValue={PreloadedValues.devicepassword}
                                register={register({
                                  required: "กรุณาระบุ password",
                                  maxLength: {
                                    value: 100,
                                    message: "Max length is 100",
                                  },
                                  minLength: {
                                    value: 4,
                                    message: "ระบุอย่างน้อย 4 ตัวอักษร",
                                  },
                                })}
                                error={errors.devicepassword}
                              />
            </div>
            </div>
        </div>

     
        <div className="row">
       <div className="col-sm-6 col-xs-12">
         <div className="form-group">
         <Select
                label="กลุ่ม"
                data={grouplist}
                value= {groupid}
                textProperty="name"
                valueProperty="_id"
                onChange={handlegroupChange}
              />
            </div>
            </div>

            <div className="col-sm-6 col-xs-12">
         <div className="form-group">
              <Select
                label="พื้นที่"
                data={sitelist}
                value= {siteid}
                textProperty="name"
                valueProperty="_id"
                onChange={handlesiteChange}
              />
            </div>
            </div>
        </div>

        </ModalBody>
        { error && <div className="col-md-12 alert alert-danger">  { error}</div>}

        {list && list.comment &&
            <Button color="danger">
              {list.comment}
            </Button>
          }
        <ModalFooter>

      
          <Button color="primary" onClick={handleSubmit(onSubmit)}>
            {button1}
          </Button>{" "}
       
          <Button color="secondary" onClick={onCancel}>
            ยกเลิก
          </Button>
        </ModalFooter>
      </Modal>
    </div>
    </form>
  );
};

export default AddDevice;
