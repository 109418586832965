import React,{useState} from 'react';
import RenderButton from '../common/button';
import DatePicker from "react-datepicker";


const Headder = ({onChange}) => {

    const [all, setall] = useState('btn btn-primary');
    const [chemical, setchemical] = useState('btn btn-outline-dark');
    const [general, setgeneral] = useState('btn btn-outline-dark');
   
    
  
    const [startDate, setStartDate] = useState(new Date());

    const handleonClick = ({ currentTarget: input }) => {
        if (input.value==0){
            disabledlabel();
            setall('btn btn-primary'); 
        }else if (input.value==2){
            disabledlabel();
            setchemical('btn btn-primary'); 
        }else if (input.value==1){
            disabledlabel();
            setgeneral('btn btn-primary'); 
        }
        onChange(input.value);
    }
    // const handleChange = (date) => {
    //     setStartDate(date)
    //     handleDateChange(date);
    // }


const disabledlabel = () => {
    setall('btn btn-outline-dark')
    setchemical('btn btn-outline-dark') ;
    setgeneral('btn btn-outline-dark') ;
  
    
   
}



    return ( 
<div className='container'>
            <div className="row">
              {/* <div className='m-4'>
                 
                <div className='mt-2' style={{ marginTop: 20, marginBottom: 20 }} >
                  <DatePicker
                    todayButton="Today"
                    dateFormat="dd/MM/yyyy"
                    selected={startDate}
                    onChange={handleChange} />
                </div>

              </div>    */}

              <div className="row justify-content-end align-items-center">
                  <div className="col-auto mb-3 px-0"><ul className="btn-group p-0 m-0 ">

     
                     <RenderButton 
                     label ="All"
                     value="0"
                     onClick ={handleonClick}
                     className = {all}
                      />
                    <RenderButton 
                     value="1"
                     label ="อบรมทั่วไป"
                     onClick ={handleonClick}
                     className = {general}
                      />
                         <RenderButton 
                           value="2"
                          label ="อบรมสารเคมี"
                     onClick ={handleonClick}
                     className = {chemical}
                      />
          
                      </ul>
                      </div>
                      </div>


            </div>
          </div>

     );
}
 
export default Headder;

