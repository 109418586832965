import React, { Component } from "react";
import SearchItem from "./searchItem";

class SearchTable extends Component {

  render() {
    const { data, notifyUpdate } = this.props;

    return (
      <div className="row">
        {data && data.map( item => {
          return (
            <div key={item.id} className="col-12">
              <SearchItem item={item} notifyUpdate={notifyUpdate} />
            </div>
          )
        })}
      </div>
    );
  }
}

export default SearchTable;
