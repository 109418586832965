import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import { toast } from "react-toastify";
import ConfirmSubmit from './../common/confirmSubmit';
import BranchContext from "./../../components/context/branch";
import { getdevicehr, getdeviceforclone,saveclonedevice } from "./../../services/facescan";
import Devicelist from "./clonedeviceclist";
import Input from "./../wms/input";
import Header from "./headergrouphr";
import FontAwesome from "react-fontawesome";
import ScrollToTop from "./../common/ScrollToTop";
import ProgressBar from './../common/progressbar';

const DeviceClone = (props) => {
  const [Id, setId] = useState(props.match.params.id);
  const { currentBranch } = useContext(BranchContext);
  const [facename, setfacename] = useState([]);
  const [devicelist, setdevicelist] = useState([]);

  const [askConfirm , setaskConfirm] = useState(false);
  const [message, setmessage] = useState("");
  

  const [name, setname] = useState("");
  const [search, setsearch] = useState("");
  // const [status, setstatus] = useState(0);
  const [all, setall] = useState([]);
  const [siteid, setsiteid] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [pageSize, setPageSize] = useState(15);
   const [total,setTotal] = useState(0);
  // const [userlist, setuserlist] = useState([]);
  // const [lastupdate, setlastupdate] = useState(new Date());
  const [showonscroll, setshowonscroll] = useState(false);
  const [showprogress, setshowprogress] = useState(false);
  const [completed, setcompleted] = useState(50);

  useEffect(() => {
    if (!currentBranch) return;
    setdevicelist([]);
  }, [currentBranch, pageSize, search, siteid, Id,showonscroll]);

  useEffect(() => {
    if (!currentBranch) return;
    if (!Id) return;

  const loadData = async () => {
  const { data: result } = await getdevicehr(Id);
      if (result.status === "success") {
        setfacename(result.data.list);
      }
    };
    loadData();
  }, [currentBranch, Id]);

  useEffect(() => {
    if (!currentBranch) return;
    if (!Id) return;

    const loadData = async () => {
      const { data: result } = await getdeviceforclone(
        Id,
        siteid,
        search,
        pageSize,
        pageNumber
      );
      if (result.status === "success") {
        // setdevicelist(result.data.list);
        setshowprogress(false);
            
        setTotal(result.data.total);
        setdevicelist((prevData) => {
          return [...new Set([...prevData, ...result.data.list])];
        });
        setHasMore(result.data.list.length > pageSize - 1);
        setLoading(false);
      }
    };
    loadData();
  }, [currentBranch, Id, siteid, search, pageSize, pageNumber,showonscroll]);

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      //  console.log('node = ' ,node);
      if (node && node.offsetTop > 0) {
        setshowonscroll(true);
      }
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        //   console.log(entries[0].isIntersecting,hasMore);

        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const handleConfirmSubmit = () => {
    doSubmit();
  setaskConfirm(false);
     }
 const   handleCancleSubmit = () => {
    setaskConfirm(false);
    setall([]);
     }  

  const savedata = () =>{

    let addall =[];
 
 for(const dataObj of devicelist){
  
if (dataObj.chkselected){
  addall.push(parseInt( dataObj.face_id))
}
}

if (addall.length===0){
  toast.error("กรุณาเลือกเครื่องปลายทางก่อน")
} else if (addall.length >3)
{
  toast.error("ท่านเลือกเครื่องเกิน 3 เครื่อง ระบบขอสงวนสิทธิ์ในการเปิดใช้งาน")
} else {
  setall(addall);
  setmessage("ท่านยืนยัน คัดลอกใบหน้าจากเครื่อง " + facename[0].name +"  ไปยังเครื่องปลายทางอีก " + addall.length +" เครื่อง ใช่หรือไม่ ? ")
  setaskConfirm(true); 
}
}

const doSubmit = async () => {

  setshowonscroll(true);
  const doc = { face_id: Id, toface_id: all  }
  
  try {

   // console.log(doc);

    const {data: result} = await saveclonedevice(doc);
  
  
  
     if (result.status==='success') {
      toast.success("บันทึกเสร็จเรียบร้อย");
       
       setshowprogress(false)
    
      }
    else {
    toast.error(result.message)
 
    }
  } 
  catch (error) {
   // toast.error("Error");
  }



}
const scrollToTop = () => { 
  setPageNumber(1);
  setshowonscroll(false);
}
  const handleChangeToggle = (event, id) => {
    const { checked } = event.target;
    const toggle = {
      face_id: id,
      chkselected: checked,
    };
    const sublist = [...devicelist];
    const index = sublist.findIndex((x) => x.face_id === toggle.face_id);

    sublist[index].chkselected = toggle.chkselected;

    setdevicelist(sublist);
  };
  const handleChange = (event) => {
    setsearch(name);
    setsiteid(event);
    setPageNumber(1);
  };
  const handleTextchange = (event) => {
    setname(event.currentTarget.value);
  };
  const handleSearchClick = () => {
     setPageNumber(1);
    setsearch(name);
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
       setPageNumber(1);
      setsearch(name);
    }
  };
  if (currentBranch._id !== "0") return;

  return (
    <>
     <ConfirmSubmit 
         isOpen = {askConfirm} 
         header = {"ยืนยันการโอนย้ายใบหน้า"}
         onConfirm={handleConfirmSubmit} 
         onCancel={handleCancleSubmit} 
         message={message}
         size="lg" 
         button1 = "บันทึก"
         />  
      <p className="text-success">Clone เครื่อง</p>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="card mb-2">
              <div className="card-body p-2">
                <div className="row align-items-center justify-content-between">
                  <div className="col-auto ml-2">
                    <span aria-hidden="true" className="fa fa-clone" />
                    <span
                      className="badge badge-warning"
                      style={{ fontSize: "15px" }}
                    >
                      {"Clone User"}
                    </span>
                    <p className="nav-item nav-link text-dark text-bold font-weight-bold d-inline">
                      {facename && facename.length > 0 && facename[0].name}
                    </p>

                    <span>จำนวน {"  "}</span>
                    {facename &&
                    facename.length > 0 &&
                    facename[0].amount > 0 ? (
                      <span
                        className="badge badge-pill badge-primary"
                        style={{ fontSize: "14px" }}
                      >
                        {" "}
                        {facename[0].amount}{" "}
                      </span>
                    ) : (
                      <span
                        className="badge badge-pill badge-secondary"
                        style={{ fontSize: "14px" }}
                      >
                        {"0"}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 col-sm-6 col-md-6 mt-2">
        <div className="container">
          <button className="btn btn-success"
            onClick={() => savedata()}
          >
        
            <FontAwesome name="save" />
            <i /> บันทึก
          </button>
          <br />
          <br />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6  col-sm-10 col-xs-12">
          <div className="form-group">
            <Input
              name="devicename"
              value={name}
              label="ชื่อเครื่อง"
              placeholder="ระบุชื่อเครื่องเพื่อค้นหา"
              onChange={handleTextchange}
              onClick={handleSearchClick}
              onKeyPress={handleKeyPress}
              search="ค้นหา"
            />
          </div>
        </div>

        <div className="col-lg-6  col-sm-10 col-xs-12">
          <div className="form-group">
            <i>
              พื้นที่ <span className="label label-default"></span>
            </i>
            <div className="mt-2" style={{ marginTop: 20, marginBottom: 20 }}>
              <Header onChange={handleChange} />
            </div>
          </div>
        </div>
      </div>
      <p className="text-success">
        แสดงข้อมูล {devicelist ? devicelist.length : 0} / {total} รายการ
      </p>
      { showprogress && <ProgressBar bgcolor={"#6a1b9a"} completed={completed} />}
      {pageNumber > 1 && showonscroll &&
        <ScrollToTop 
        onClick ={scrollToTop}
        />}
      <Devicelist
        list={devicelist}
        lastBookElementRef={lastBookElementRef}
        handleOnClick={handleChangeToggle}
      />
    </>
  );
};

export default DeviceClone;
