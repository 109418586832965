import React,{useState,useEffect,useContext,useCallback,useRef} from 'react';
import Input from './../wms/input'
import BranchContext from "./../../components/context/branch";
import FontAwesome from 'react-fontawesome';
import {getchemicalcom} from "../../services/vms";
import RenderCompanyList from './renderchemicalcom'
import Header from './headercomtype'
const ChemicalComlist = () => {
    const { currentBranch } = useContext(BranchContext);
const [search, setsearch] = useState('');
const [name, setname] = useState('');
const [hasMore, setHasMore] = useState(false);
const [pageNumber, setPageNumber] = useState(1);
const [loading, setLoading] = useState(true);
const [error, setError] = useState(false)
const [comtypeid, setcomtypeid] = useState(0);

const [pageSize,setPageSize] = useState(30);
const [total,setTotal] = useState(0);
const [company, setscompany] = useState([]);




useEffect(() => {
  
    setscompany([]);
  }, [currentBranch, pageSize,comtypeid,search]);

    useEffect(() => {
        if (!currentBranch) return;
        if (currentBranch._id !=='vms') return;
        if (!pageSize || !pageNumber  ) return;
      
        setLoading(true)
        setError(false)
      
     
            const loadData = async () => {
            
                const { data: result } = await getchemicalcom(search,comtypeid,pageSize,pageNumber);
               
               
                if ( result.status==='success') {
                    setTotal(result.data.total);
             
  setscompany(prevData => {   
             return [...new Set([...prevData, ...result.data.list])]
                          })
                   setHasMore(result.data.list.length > pageSize-1)
                   setLoading(false)                 
                }
     
      
      
        };
        loadData();
      }         
      , [currentBranch,search,pageSize,pageNumber,comtypeid]);
        
      const observer = useRef();
      const lastBookElementRef = useCallback(
        (node) => {
           // console.log('node = ' ,node);
          if (loading) return;
          if (observer.current) observer.current.disconnect();
          observer.current = new IntersectionObserver((entries) => {
            //  console.log(entries[0].isIntersecting,hasMore);
    
            if (entries[0].isIntersecting && hasMore) {
              setPageNumber((prevPageNumber) => prevPageNumber + 1);
            }
          });
          if (node) observer.current.observe(node);
        },
        [loading, hasMore]
      );

      const handleComChange = (event) => {
        setcomtypeid(event);
        setPageNumber(1);
        //    console.log('Click event',pageNumber);
      };
const handleTextchange = (event) => {
setname(event.target.value)
}
const handleKeyPress = (event) => {
  if (event.key === "Enter") {
    setsearch(name)
  }
}
const handleSearchClick = (event) => {
  setsearch(name) 
}
const handleChange =(id, detail) => {
    
  }
if (currentBranch._id !=='vms'){
    return (  
        <>
        </>)
}

    return (  
        <>
  <div className="container m-3">
          <div className="row">
            <div className="col-lg-6  col-sm-6 col-xs-12" >
             
              <div className="form-group">
                <Input
                  name="company"
                
                  value={name}
                  placeholder="ค้นหา จากชื่อบริษัท"
                  onChange={handleTextchange}
                  onKeyPress={handleKeyPress}
                  onClick={handleSearchClick}
                  search="ค้นหา"
                />
              </div>
            </div>

            
            <div className="col-lg-6  col-sm-6 col-xs-12" >
            <div className="form-group">
          {/* <Header
          onChange={handleComChange}

            /> */}


            <i>
                กลุ่มบริษัท <span className="label label-default"></span>
              </i>
              <div className="mt-1" style={{ marginTop: 10, marginBottom: 20 }}>
                <Header onChange={handleComChange} />
              </div>
             </div>
              {/* <div>&nbsp;</div>
              <button className="btn btn-success ">Download To Excel</button> */}
            </div>


          </div>
        </div>
        
 
        <div className="col-12 col-sm-6 col-md-6 mt-2" >
          <div className="container">
            <button
              className="btn btn-primary"
              onClick={() => handleChange(0, "new")}
            >
              <FontAwesome name="plus" />
              <i /> Add New
                          </button>
            <br />
            <br />
          
          </div>
        </div>
        <p className="text-success">
        แสดงข้อมูล {company ? company.length : 0}  /  {total} รายการ
        </p>
            <RenderCompanyList
              list={company}
              loading={loading}
              error={error}
              lastBookElementRef={lastBookElementRef}
              />

        </>

        
    );
}
 
export default ChemicalComlist;