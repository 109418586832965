import React from "react";
import { Link } from "react-router-dom";
import "./../../card.css";


const RenderRegisterList = ({ list,lastBookElementRef }) => {
  const url =
    "http://wms.advanceagro.net/wsface/api/face/RealImage?IdentityID=";
  // console.log(list)
  return (
    <>
      {list &&
        url &&
        list.map((item,index) => {
          if (list.length === index +1)
        { return (

          <div  className="row" key={item.AutoID}>
            <div className="container-fluid">
              <div className="card border-secondary m-1 ">
                <div className="card-body">
                  <div ref = {lastBookElementRef}  className="row">
                    <div className="container col-xs-6 col-sm-4 col-md-2 lg-1 ">
                      <div style={{ fontSize: "22px" }}>
                        <span className="badge badge-pill badge-primary">
                          {item.No}
                        </span>
                      </div>
                      <div style={{ fontSize: "14px" }}>{item.Weight_no}</div>
                      <div style={{ fontSize: "14px" }}>
                        {item.Customer_Name}
                      </div>
                      <div style={{ fontSize: "14px" }}>
                        {item.CustomerTypeName}
                      </div>
                    </div>

                    <div className="container col-xs-6 col-sm-4 col-md-2 lg-1">

                    <div className="mytooltip tooltip-effect-1">
                      <span className="tooltip-item">
                        <span className="d-none d-md-inline text-black pr-2">
                          <img
                            className="rounded-circle ml-2"
                            src={url + item.Tag + "&size=320"}
                            alt =""
                            style={{
                            
                              maxHeight: 50,
                              Width: 300,
                            }}
                          />{" "}
                          {item.Driver_Name}{" "}
                        </span>
                      </span>

                      <span className="tooltip-content clearfix">
                        <img
                          className="rounded-circle ml-2"
                          src={url + item.Tag + "&size=320"}
                          alt=""
                          style={{
                            width: "100%",
                            minHeight: "300px",
                            minWidth: "300px",
                          }}
                        />

                        <span className="tooltip-text"></span>
                      </span>
                    </div>

                      {/* <div>
                        <span
                          style={{ fontSize: "14px" }}
                          className=" text-black pr-0 "
                        >
                          <img
                            className="card rounded-circle "
                            src={url + item.Tag + "&size=320"}
                            style={{
                              width: "100%",
                              maxHeight: 50,
                              maxWidth: 50,
                            }}
                          />
                          {item.Driver_Name}
                        </span>
                      </div> */}
                    </div>

                    <div className="container col-xs-6 col-sm-4 col-md-2 lg-1">
                      <div style={{ fontSize: "14px" }}>
                        <h6>
                          ใบนำส่ง :
                          <small style={{ fontSize: "14px" }}> {item.DO}</small>{" "}
                        </h6>
                      </div>
                      <div style={{ fontSize: "14px" }}>
                        <h6>
                          {" "}
                          วัตถุประสงค์ :
                          <small style={{ fontSize: "14px" }}>
                            {" "}
                            {item.aim_name}
                          </small>
                        </h6>
                      </div>
                      <div style={{ fontSize: "14px" }}>
                        <h6>
                          {" "}
                          ประเภทสินค้า :
                          <small style={{ fontSize: "14px" }}>
                            {" "}
                            {item.type_name}
                          </small>
                        </h6>
                      </div>

                      <div style={{ fontSize: "14px" }}>
                        <h6>
                          {" "}
                          สินค้า :
                          <small style={{ fontSize: "14px" }}>
                            {" "}
                            {item.ProductName}
                          </small>
                        </h6>
                      </div>
                      <div></div>
                    </div>
                    <div className="container col-xs-6 col-sm-4 col-md-2 lg-1">
                      <div style={{ fontSize: "14px" }}>
                        <h6>
                          {" "}
                          ประเภทรถ :
                          <small style={{ fontSize: "14px" }}>
                            {" "}
                            {item.truck_typename}
                          </small>
                        </h6>
                      </div>
                      <div style={{ fontSize: "14px" }}>
                        <h6>
                          {" "}
                          ทะเบียนหัว :
                          <small style={{ fontSize: "14px" }}>
                            {" "}
                            {item.first_driven_no}
                          </small>
                        </h6>
                      </div>
                      <div style={{ fontSize: "14px" }}>
                        <h6>
                          {" "}
                          ทะเบียนหาง :
                          <small style={{ fontSize: "14px" }}>
                            {" "}
                            {item.second_driven_no}
                          </small>
                        </h6>
                      </div>
                      <div style={{ fontSize: "14px" }}>
                        <h6>
                          {" "}
                          วันที่ลงสินค้า :
                          <small style={{ fontSize: "14px" }}>
                            {" "}
                            {item.queue_date}
                          </small>
                        </h6>
                      </div>
                    </div>

                    <div className="container col-xs-6 col-sm-4 col-md-2 lg-1">
                      <div>
                        <h6>
                          {" "}
                          เข้า :
                          <small style={{ fontSize: "18px" }}>
                            {" "}
                            <span className="badge badge-pill badge-info">
                              {item.receive_weightin}
                            </span>
                          </small>
                        </h6>
                      </div>

                      <div>
                        <h6>
                          {" "}
                          ออก :
                          <small style={{ fontSize: "18px" }}>
                            {" "}
                            <span className="badge badge-pill badge-warning">
                              {item.receive_weightout}
                            </span>
                          </small>
                        </h6>
                      </div>

                      <div>
                        <h6>
                          {" "}
                          สุทธิ :
                          <small style={{ fontSize: "18px" }}>
                            {" "}
                            <span className="badge badge-pill badge-success">
                              {item.receive_weightnet}
                            </span>
                          </small>
                        </h6>
                      </div>
                    </div>

                    <div className="container col-xs-6 col-sm-4 col-md-2 lg-1">
                      <div style={{ fontSize: "14px" }}>
                        <h6>
                          {" "}
                          สถานที่ลง :
                          <small style={{ fontSize: "14px" }}>
                            {" "}
                            {item.storename}
                          </small>
                        </h6>
                      </div>

                     <Link
                        style={{ width: "50px" }}
                        to={`/register/${item.AutoID}`}
                      >
                        <button
                          type="button"
                          className="btn btn-primary btn-sm m-2"
                        >
                         { item.receive_weightin > 0 ? 'View' :'Edit'}
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          
          </div>
        )} else 
        {
          return (

            <div className="row" key={item.AutoID}>
              <div className="container-fluid">
                <div className="card border-secondary m-1 ">
                  <div className="card-body">
                    <div className="row">
                      <div className="container col-xs-6 col-sm-4 col-md-2 lg-1 ">
                        <div style={{ fontSize: "22px" }}>
                          <span className="badge badge-pill badge-primary">
                            {item.No}
                          </span>
                        </div>
                        <div style={{ fontSize: "14px" }}>{item.Weight_no}</div>
                        <div style={{ fontSize: "14px" }}>
                          {item.Customer_Name}
                        </div>
                        <div style={{ fontSize: "14px" }}>
                          {item.CustomerTypeName}
                        </div>
                      </div>
  
                      <div className="container col-xs-6 col-sm-4 col-md-2 lg-1">
  
                      <div className="mytooltip tooltip-effect-1">
                        <span className="tooltip-item">
                          <span className="d-none d-md-inline text-black pr-2">
                            <img
                              className="rounded-circle ml-2"
                              src={url + item.Tag + "&size=320"}
                              alt =""
                              style={{
                              
                                maxHeight: 50,
                                Width: 300,
                              }}
                            />{" "}
                            {item.Driver_Name}{" "}
                          </span>
                        </span>
  
                        <span className="tooltip-content clearfix">
                          <img
                            className="rounded-circle ml-2"
                            alt=""
                            src={url + item.Tag + "&size=320"}
                            style={{
                              width: "100%",
                              minHeight: "300px",
                              minWidth: "300px",
                            }}
                          />
  
                          <span className="tooltip-text"></span>
                        </span>
                      </div>
  
                        {/* <div>
                          <span
                            style={{ fontSize: "14px" }}
                            className=" text-black pr-0 "
                          >
                            <img
                              className="card rounded-circle "
                              src={url + item.Tag + "&size=320"}
                              style={{
                                width: "100%",
                                maxHeight: 50,
                                maxWidth: 50,
                              }}
                            />
                            {item.Driver_Name}
                          </span>
                        </div> */}
                      </div>
  
                      <div className="container col-xs-6 col-sm-4 col-md-2 lg-1">
                        <div style={{ fontSize: "14px" }}>
                          <h6>
                            ใบนำส่ง :
                            <small style={{ fontSize: "14px" }}> {item.DO}</small>{" "}
                          </h6>
                        </div>
                        <div style={{ fontSize: "14px" }}>
                          <h6>
                            {" "}
                            วัตถุประสงค์ :
                            <small style={{ fontSize: "14px" }}>
                              {" "}
                              {item.aim_name}
                            </small>
                          </h6>
                        </div>
                        <div style={{ fontSize: "14px" }}>
                          <h6>
                            {" "}
                            ประเภทสินค้า :
                            <small style={{ fontSize: "14px" }}>
                              {" "}
                              {item.type_name}
                            </small>
                          </h6>
                        </div>
  
                        <div style={{ fontSize: "14px" }}>
                          <h6>
                            {" "}
                            สินค้า :
                            <small style={{ fontSize: "14px" }}>
                              {" "}
                              {item.ProductName}
                            </small>
                          </h6>
                        </div>
                        <div></div>
                      </div>
                      <div className="container col-xs-6 col-sm-4 col-md-2 lg-1">
                        <div style={{ fontSize: "14px" }}>
                          <h6>
                            {" "}
                            ประเภทรถ :
                            <small style={{ fontSize: "14px" }}>
                              {" "}
                              {item.truck_typename}
                            </small>
                          </h6>
                        </div>
                        <div style={{ fontSize: "14px" }}>
                          <h6>
                            {" "}
                            ทะเบียนหัว :
                            <small style={{ fontSize: "14px" }}>
                              {" "}
                              {item.first_driven_no}
                            </small>
                          </h6>
                        </div>
                        <div style={{ fontSize: "14px" }}>
                          <h6>
                            {" "}
                            ทะเบียนหาง :
                            <small style={{ fontSize: "14px" }}>
                              {" "}
                              {item.second_driven_no}
                            </small>
                          </h6>
                        </div>
                        <div style={{ fontSize: "14px" }}>
                          <h6>
                            {" "}
                            วันที่ลงสินค้า :
                            <small style={{ fontSize: "14px" }}>
                              {" "}
                              {item.queue_date}
                            </small>
                          </h6>
                        </div>
                      </div>
  
                      <div className="container col-xs-6 col-sm-4 col-md-2 lg-1">
                        <div>
                          <h6>
                            {" "}
                            เข้า :
                            <small style={{ fontSize: "18px" }}>
                              {" "}
                              <span className="badge badge-pill badge-info">
                                {item.receive_weightin}
                              </span>
                            </small>
                          </h6>
                        </div>
  
                        <div>
                          <h6>
                            {" "}
                            ออก :
                            <small style={{ fontSize: "18px" }}>
                              {" "}
                              <span className="badge badge-pill badge-warning">
                                {item.receive_weightout}
                              </span>
                            </small>
                          </h6>
                        </div>
  
                        <div>
                          <h6>
                            {" "}
                            สุทธิ :
                            <small style={{ fontSize: "18px" }}>
                              {" "}
                              <span className="badge badge-pill badge-success">
                                {item.receive_weightnet}
                              </span>
                            </small>
                          </h6>
                        </div>
                      </div>
  
                      <div className="container col-xs-6 col-sm-4 col-md-2 lg-1">
                        <div style={{ fontSize: "14px" }}>
                          <h6>
                            {" "}
                            สถานที่ลง :
                            <small style={{ fontSize: "14px" }}>
                              {" "}
                              {item.storename}
                            </small>
                          </h6>
                        </div>
  
                        <Link
                          style={{ width: "50px" }}
                          to={`/register/${item.AutoID}`}
                        >
                          <button
                            type="button"
                            className="btn btn-primary btn-sm m-2"
                          >
                        {item.receive_weightin > 0 ? 'View' :'Edit'}
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
             
            </div>
          )

        }}

        
        )}
    </>
  );
};

export default RenderRegisterList;
