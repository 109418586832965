import React from 'react';
import moment from 'moment';
import {Link} from 'react-router-dom';

const detailCustomer = (props) => {
 
  const {data} = props;
 // console.log(data);
 
  if (!data) {
    return <div></div>;
  }

    return (
      <div>
        {data &&
          data.map((item) => (  
            <div className="row" key={item.No}  >
              <div className="col mb-1">
                <div className="card mb-1 text-dark p-0">
                  <div className="card-body p-1">
                    {/* <a className="stretched-link" href="/registerlist/"/> */}
                    <div className="row no-gutters align-items-center justify-content-end">
                      <div className="col-auto ml-3 mr-auto">
                        <h6 className="text-dark">
                          <span aria-hidden="true" className="fa fa-truck" />{" "}
                           {moment(item.queuedate, "YYYY-MM-DD").add(0, 'day').format("DD-MM-YYYY")}
                        </h6>
                        <div className="col-auto p-0">
                          <h5 className="text-dark">
                            <span className="badge">
                             {item.customername}
                            </span>
                          </h5>
                          <div className="row no-gutters">
                            <div className="col-auto text-center">
                                
                              {/* <img className="rounded-circle" src="https://api-idms.advanceagro.net/hrms/employee/592120/photocard/?size=40" data-toggle="tooltip" data-placement="bottom" title style={{ width: '100%', maxHeight: 40, maxWidth: 40 }} /> */}
                            </div>
                            <div className="col-auto text-center">
                              {/* <img className="rounded-circle" src="https://api-idms.advanceagro.net/hrms/employee/601178/photocard/?size=40" data-toggle="tooltip" data-placement="bottom" title style={{ width: '100%', maxHeight: 40, maxWidth: 40 }} /> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-auto mr-2">
                        <div className="row no-gutters justify-content-center align-items-center">
                       <div className="col-auto mx-3 text-center">
                            <h5>
                              <span className="m-1"></span>
                              <span className=" badge badge-pill badge-warning">
                                {item.W_IN}
                              </span>
                            </h5>
                          
                          </div> 
                          <div className="col-auto mx-3 text-center">
                            <h5>
                              <span className="m-1"></span>
                              <span className="badge badge-pill badge-primary">
                                {item.W_OUT}
                              </span>
                            </h5>
                          
                          </div>
                           <div className="col-auto mx-3 text-center">
                            <h5>
                            <span className="m-1"></span>
                              <span className="badge badge-pill badge-success">
                                {item.SUCCESS}
                              </span>
                            </h5>
                           
                          </div>
                         

                          <div className="col-auto mx-3 text-center">
                            <h5>
                              <span className="badge badge-info">
                              <Link
                      className="badge badge-info "
                      style={{ width: "50px" }}
                      to={{ pathname: "/registerlist", linkdetails: {ddate: item.queuedate, cusid: item.customerid 
                    
                    
                    } }}
                      // to={`/registerlist/${item.AutoID}`}
                                                   >
                      Edit
                    </Link>

                              </span>
                            </h5>
                             </div>
                            




                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    );
}
 //     addd new
export default detailCustomer;
