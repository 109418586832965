import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import { toast } from "react-toastify";
import BranchContext from "./../../components/context/branch";
import {
  getvmsWaiting,
  getDoccodeID,
  getStatus,
  getPermitType,
} from "./../../services/vms";
import Renderwaitting from "./renderwaittingcard";
import ConfirmSubmit from "./../common/confirmSubmit";
import ScrollToTop from "./../common/ScrollToTop";
import { Approved } from "./../../services/vms";
import DatePicker from "react-datepicker";

import Input from "./../wms/input";
import moment from "moment";
//import InfiniteScroll from "react-infinite-scroller";

import Header from "./header";
import HeaderPermit from "./headerpermittype";
import HeaderStatus from "./headerstatus";
const Forapproved = () => {
  const { currentBranch } = useContext(BranchContext);
  const [startDate, setStartDate] = useState(new Date());
  const [value, setValue] = useState([1]);
  const [waiting, setwaiting] = useState([]);
  const [askConfirm, setaskConfirm] = useState(false);
  const [askDisagree, setaskDisagree] = useState(false);
  const [message, setmessage] = useState("");
  const [messageerror, setmessageerror] = useState("");
  const [remark, setremark] = useState("");
  const [Id, setId] = useState("");

  const [searchID, setSearchID] = useState("");
  const [search, setsearch] = useState("");

  const [hasMore, setHasMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [pageSize, setPageSize] = useState(15);
  const [data, setData] = useState([]);
  const [statuslist, setStatuslist] = useState([]);
  const [permitetypelist, setPermiteTypelist] = useState([]);
  const [endDate, setEndDate] = useState(new Date());
  const [permitId, setpermitId] = useState(0);
  const [statusId, setStatusId] = useState("0");
  const [lastcheck, setlastcheck] = useState(new Date());
  const [companyname, setcompanyname] = useState("");
  const [comsearch, setcomsearch] = useState("");
  const [total,setTotal] = useState(0);
  const [showonscroll, setshowonscroll] = useState(false)


  useEffect(() => {

    if (!currentBranch) return;
    if (currentBranch._id !=='vms') return;
    //  console.log('clear')
    setwaiting([]);
  }, [currentBranch,
    startDate,
    value,
    saveSuccess,
    pageSize,
    endDate,
    statusId,
    permitId,
    lastcheck,
    search,comsearch,showonscroll]);

  useEffect(() => {
    if (!currentBranch) return;
    if (currentBranch._id !=='vms') return;
    setLoading(true);
    setError(false);

    const loadRegister = async () => {
   
    const { data: result } = await   getvmsWaiting(
      value,
      pageSize,
      pageNumber,
      moment(startDate, "YYYY-MM-DD").add(0, "day").format("YYYY-MM-DD"),
      moment(endDate, "YYYY-MM-DD").add(0, "day").format("YYYY-MM-DD"),
      statusId,permitId,search,comsearch)

      if (result.status === "success") {
        setTotal(result.data.total);
             
        setwaiting(prevData => {   
                   return [...new Set([...prevData, ...result.data.list])]
                                })
                         setHasMore(result.data.list.length > pageSize-1)
                         setLoading(false)  

      }

    }
  //  ).then((result) => {
      loadRegister();
      
      // setwaiting((prevData) => {
      //   return [...new Set([...prevData, ...result.data])];
      // });
      // setHasMore(result.data.length > pageSize - 1);
      // setLoading(false);
   // }
    
   // );
  }, [
    currentBranch,
    startDate,
    value,
    saveSuccess,
    pageNumber,
    pageSize,
    endDate,
    statusId,
    permitId,
    lastcheck,
    search,comsearch,showonscroll
  ]);

  useEffect(() => {
    const loadRegister = async () => {
      try {
        if (!Id) return;
        const { data: result } = await getDoccodeID(Id);
        if (result.status === "success") {
          setData(result.data);
        } else {
          toast.error(result.message);
        }
      } catch (ex) {
        toast.error(ex.message);
      }
    };
    loadRegister();
  }, [Id]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const { data: result } = await getStatus();
        setStatuslist(result);
      } catch (ex) {
        toast.error(ex.message);
      }
    };
    loadData();
  }, []);

  useEffect(() => {
    const loadData = async () => {
      try {
        const { data: result } = await getPermitType();
        setPermiteTypelist(result);
      } catch (ex) {
        toast.error(ex.message);
      }
    };
    loadData();
  }, []);

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
   
     //    console.log('node = ' ,node && node.offsetTop,node);

         if (node && node.offsetTop > 0){
          setshowonscroll(true);
         }
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        //  console.log(entries[0].isIntersecting,hasMore);

        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);

        

        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const handleClick = (id, detail) => {
   // console.log(id,detail);

    if (detail === "disapproved") {
      setId(id);
      setmessage("ท่านยืนยันที่จะยกเลิกรายการ " + id + " ใช่หรือไม่");
      setaskDisagree(true);
    } else if (detail === "approved") {
      setId(id);
      setmessage("ท่านยืนยันอนุมัติรายการ " + id + " ใช่หรือไม่");

      setaskConfirm(true);
    }
  };

  const handleConfirmSubmit = () => {
    doSubmit("102");
    setaskConfirm(false);

    setmessage("");
  };

  const handleConfirmDisagree = () => {
   // console.log('nogaree')
    if (remark === "") {
      setmessageerror("กรุณาระบุเหตุผล");
      return;
    }

    doSubmit("103");
    setaskConfirm(false);


  };

  const handleCancleSubmit = () => {
    setaskConfirm(false);
    clearmessage();
    setremark("");
  };

  const handleCancleDisagree = () => {
    setaskDisagree(false);
    clearmessage();
  };
  const doSubmit = async (value) => {

    // bug 
      // toast.success("บันทึกเสร็จเรียบร้อย");
      //   clearmessage();
      //   setPageNumber(1);  
      //   setlastcheck(new Date());
      //   setremark("");

    //

    try {
      const doc = {
        DocCode: Id,
        AppCode: value,
        Remark: remark,
      };
   
      const { data: result } = await Approved(doc);
     

      if (result.status === "success") {
        setSaveSuccess(!saveSuccess);
        toast.success("บันทึกเสร็จเรียบร้อย");
        clearmessage();
        setPageNumber(1);  
        setlastcheck(new Date());
        setremark("");
      } else {
        toast.error(result.message);
        clearmessage();
       // setPageNumber(1);  
      }
    } catch (err) {
      toast.error("error");
     // setPageNumber(1);  
      clearmessage();
    }


  };

  const clearmessage = () => {
    setmessage("");
    setmessageerror("");
  };

  const handleChange = (event) => {
    setValue(event);
    setPageNumber(1);
    //    console.log('Click event',pageNumber);
  };
  const handleSearchCustomer = () => {
    setPageNumber(1);
    setsearch(searchID)
    setlastcheck(new Date());
  };
  const handleSearchCom = () => {
    setPageNumber(1);
    setcomsearch(companyname)
    setlastcheck(new Date());
  };

  // const handleDateChange = (date) => {
  //     setstartDate(date);
  // };
  const handleChangStatus = (event) => {
    setStatusId(event);
    setPageNumber(1);
  };
  const handleChangPermit = (event) => {
    setpermitId(event)
    setPageNumber(1);
    //setpermitId(event.currentTarget.value);
  };
  const handleTextchange = (event) => {
    setremark(event.currentTarget.value);
    if (event.currentTarget.value.length > 0) {
      clearmessage();
    }
  };

  const handleChangStartDate = (event) =>{
    setStartDate(event);
    setPageNumber(1);
  }
  const handleChangEndDate = (event) =>{
    setEndDate(event);
     setPageNumber(1);  
   }

  const handelCustomerChange = (event) => {
    setSearchID(event.currentTarget.value);

  };

  const handelCompanyChange = (event) => {
    setcompanyname(event.currentTarget.value);
     if (event.key === "Delete" || event.key === "Backspace") {
     
      console.log('key',companyname)
     }

  };
  const handleComKeyPress = (event) => {
  
    if (event.key === "Enter") {
      setcomsearch(companyname)
      //console.log(" enter set =")
    }
    
  };
const handleComkeydown = (event) => {
 // Consoleconsole.log(event)
  if (event.keyCode === 8) {
    if (companyname.length <=1 && !comsearch ===''){

      setcomsearch(companyname)
      //console.log("set =")
    }
}
}
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      setsearch(searchID)
    }
  };

  const scrollToTop = () => { 
    setPageNumber(1);
    setshowonscroll(false);
  }


  if (currentBranch._id !== 'vms'){
    return <>
    </>
  }

  return (
    <div className="container-fluid">
      <ConfirmSubmit
        isOpen={askConfirm}
        button1="อนุมัติ"
        onConfirm={handleConfirmSubmit}
        onDisAprove={handleConfirmDisagree}
        onCancel={handleCancleSubmit}
        onChange={handleTextchange}
        value={remark}
        message={message}
        list={data}
        error={messageerror}
      />
      {/* <ConfirmDisagree
                isOpen={askDisagree}
                onChange={handleTextchange}
                value={remark}
                onConfirm={handleConfirmDisagree}
                onCancel={handleCancleDisagree}
                message={message}
                error={messageerror}
            /> */}
      <div className="container">
        <div className="row">
          <div className="col-auto">
            <div className="m-2">
              <i>
                จากวันที่ <span className="label label-default"></span>
              </i>

              <div className="mt-2" style={{ marginTop: 20, marginBottom: 20 }}>
                <DatePicker
                  todayButton="Today"
                  dateFormat="dd/MM/yyyy"
                  selected={startDate}
                  onChange={handleChangStartDate}
                />
              </div>
            </div>
          </div>
          <div className="col-auto">
            <div className="m-2">
              <i>
                ถึงวันที่ <span className="label label-default"></span>
              </i>
              <div className="mt-2">
                <DatePicker
                  todayButton="Today"
                  dateFormat="dd/MM/yyyy"
                  selected={endDate}
                  onChange={handleChangEndDate}
                />
              </div>
            </div>
          </div>

          <div className="col-auto">
            <div className="m-2">
              <i>
                พื้นที่ <span className="label label-default"></span>
              </i>
              <div className="mt-2" style={{ marginTop: 20, marginBottom: 20 }}>
                <Header onChange={handleChange} />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6  col-sm-10 col-xs-12">
            <div className="form-group">
            <div className="m-2">
              <i>
                ประเภทใบอนุญาต <span className="label label-default"></span>
              </i>
            <HeaderPermit
            onChange={handleChangPermit}
            />
            </div>
              {/* <Select
                label="ประเภทใบอนุญาต"
                data={permitetypelist}
                value={permitId}
                textProperty="name"
                valueProperty="_id"
                onChange={handleChangPermit}
              /> */}
            </div>
          </div>
          <div className="col-lg-6  col-sm-10 col-xs-12">
            <div className="form-group">

            <div className="m-2">
              <i>
              สถานะ <span className="label label-default"></span>
              </i>
            <HeaderStatus
            onChange={handleChangStatus}
            />
            </div>

              {/* <Select
                label="สถานะ"
                data={statuslist}
                value={statusId}
                textProperty="name"
                valueProperty="_id"
                onChange={handleChangStatus}
              /> */}
            </div>
          </div>

       
        </div>

        <div className="row">

        <div className="col-lg-6  col-sm-6 col-xs-12">
            <div className="form-group">
              <Input
                name="docid"
                value={searchID}
                label="เลขที่เอกสาร"
                placeholder ="เลขเอกสาร 4 ตัวท้าย"
                onChange={handelCustomerChange}
                onKeyPress={handleKeyPress}
                onClick={handleSearchCustomer}
                error=""
                search="ค้นหา"
              />
            </div>
          </div>

          <div className="col-lg-6  col-sm-6 col-xs-12">
            <div className="form-group">
              <Input
                name="comname"
                value={companyname}
                label="บริษัท"
                placeholder ="ระบุชื่อบริษัท เพื่อค้นหา"
                onChange={handelCompanyChange}
                onKeyPress={handleComKeyPress}
                onClick={handleSearchCom}
                onKeyDown={handleComkeydown}
                error=""
                search="ค้นหา"
              />
            </div>
          </div>
        </div>
        {/* <Header onChange={handleChange} handleDateChange={handleDateChange} /> */}
      </div>

      <p className="text-success">
        แสดงข้อมูล {waiting ? waiting.length : 0}  /  {total} รายการ
      </p>

      {/* <InfiniteScroll
        //   dataLength={waiting.length}
        //   next= {reLoadData}
        //   hasMore={true}
        pageStart={0}
        loadMore={reLoadData}
        hasMore={hasMoreItems}
        loader={<div className="loader" key={0}>Loading ...</div>}
        > */}
 {pageNumber > 1 && showonscroll &&
        <ScrollToTop 
        onClick ={scrollToTop}
        />}
        <Renderwaitting
          list={waiting}
          loading={loading}
          error={error}
          lastBookElementRef={lastBookElementRef}
          handleChange={handleClick}
        />
     
      {/* </InfiniteScroll>
       */}
    </div>
  );
};

export default Forapproved;
