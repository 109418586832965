import React from 'react';
import FontAwesome from 'react-fontawesome';
import "./../../card.css";
const PermitList = ({list}) => {
    //console.log(list);
    

    return ( 
<div>
{list && (
            <div>
              <div className="container-fluid">
                <div className="row" style={{ fontSize: "14px" }}>
                  <div className="col-lg-4  col-sm-6 col-xs-12">
                    <p className="font-weight-normal">
                      เลขที่เอกสาร:{list.DocCode}
                    </p>
                  </div>
                  <div className="col-lg-4  col-sm-6 col-xs-12">
                    <p className="font-weight-normal"> PO/WO:{list.PONo}</p>
                  </div>
                  <div className="col-lg-4  col-sm-6 col-xs-12">
                      {list.PermitTypeID=="1" ?   <span className="badge badge-danger" style={{ fontSize: "14px" }}>
                      ใบอนุญาต:{list.PermitTypeName}
                    </span> : list.PermitTypeID=="3" ?  <span className="badge badge-warning" style={{ fontSize: "14px" }}>
                      ใบอนุญาต:{list.PermitTypeName}
                    </span> :<span className="badge badge-succes" style={{ fontSize: "14px" }}>
                      ใบอนุญาต:{list.PermitTypeName}
                    </span>
                      }
                    
                  </div>

                  {/* <hr style={{marginLeft: 20, marginRight: 20}} /> */}
                </div>
                <div className="row" style={{ fontSize: "14px" }}>
                  <div className="col-lg-4  col-sm-6 col-xs-12">
                    <p className="font-weight-normal">
                      เริ่มงาน: {list.StartDate}
                    </p>
                  </div>

                  <div className="col-lg  col-sm-6 col-xs-12">
                    <p className="font-weight-normal" style={{ fontSize: "14px" }}>
                      สิ้นสุด:{list.EndDate}
                     
                    </p>
                  </div>
                  <div className="col-lg  col-sm-6 col-xs-12">
                    <p className="font-weight-normal" style={{ fontSize: "14px" }}>
                      พื้นที่:{list.BUName} {list.AreaName}{" "}
                      {list.DepartmentName}{" "}
                    </p>
                  </div>
                </div>

                {list && list.Contact && 
               
                <div className="row" style={{ fontSize: "14px" }}>
               
                  <div className="col-lg-4  col-sm-6 col-xs-12">
                    <p className="font-weight-normal">
                    พนักงานที่ติดต่อ:{list.Contact[0].name}
                    </p>
                  </div>
                
                  <div className="col-lg-4  col-sm-6 col-xs-12">
                    <p className="font-weight-normal" >
                      เบอร์ติดต่อ:{list.Contact[0].Telephone}
                    </p>
                  </div>
                  
                </div>}

                {list && list.Suppervisor && (list.Suppervisor[0].ComfirmJoin)  && <table className="table table-striped table-hover">
                  <thead>
                    <tr style={{ fontSize: "14px" }}>
                    { list.PermitTypeID=="3" ? 
                          
                      <th scope="col">
                      <FontAwesome name="user"  /> ผู้ติดตาม
                      </th>
                      :
                      <th scope="col">
                      <FontAwesome name="user"  /> หัวหน้างาน
                      </th>
                    }
                     
                      
                      <th scope="col">เลขบัตร</th>
                      <th scope="col">สถานะอบรม</th>
                      <th scope="col">สถานะบทลงโทษ</th>
                      <th scope="col">ประเมิน Covid</th>
                      <th scope="col"></th>
                      <th scope="col">เข้า</th>
                      <th scope="col">ออก</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list && list.Suppervisor && list.Suppervisor[0].ComfirmJoin ? (
                      list.Suppervisor.map((values) => (
                        <tr key={values.IdentityID}  style={{ fontSize: "14px" }}>
                          <td>{values.Fname + " " + values.Lname}</td>
                          <td style={{ fontSize: "12px" }} > {values.IdentityID}</td>
                          <td style={{ fontSize: "14px" }}>
                            {values.safety === 1 ? (
                              <span className="badge badge-success">
                                {" "}
                                ผ่าน{" "}
                              </span>
                            ) : (
                              <span className="badge badge-danger">
                                {" "}
                                ไม่ผ่าน{" "}
                              </span>
                            )}
                          </td>
                          <td>
                          {values.penaltyname === "ปกติ" ? (
                              <span className="badge badge-success">
                                {" "}
                                {values.penaltyname}
                              </span>
                            ) : values.penaltyname === "ใบแดง" ? (
                              <span className="badge badge-danger">
                                {" "}
                                {values.penaltyname}
                              </span>
                            ):(
                              <span className="badge badge-warning">
                                {" "}
                                {values.penaltyname}
                              </span>
                            ) }
                          </td>
                          <td>
                            {values.CkCovid ? (
                              <span className="badge badge-success">
                                {" "}
                                ผ่านประเมิน{" "}
                              </span>
                            ) : (
                              <span className="badge badge-danger">
                                {" "}
                                ไม่ผ่าน{" "}
                              </span>
                            )}
                          </td>
                          <td>
                          <div className="mytooltip tooltip-effect-1">
                      <span className="tooltip-item">
                        <span className="d-none d-md-inline text-black pr-2">
                            <img
                            alt=""
                              className="rounded-circle ml-2"
                              src={values.url + "&size=320"}
                              style={{
                                width: "100%",
                                maxHeight: 50,
                                maxWidth: 50,
                              }}
                            />
                        </span>
                      </span>
                      

                      <span className="tooltip-content clearfix">
                      <img
                      alt=""
                              className="rounded-circle ml-2"
                              src={values.url + "&size=320"}
                              style={{
                                width: "100%",
                                minHeight: "300px",
                                minWidth: "300px",
                              }}
                            />
                             
                             <span className="tooltip-text"></span>
                      </span>
                    </div>
                          </td>
                                    <td> 
                                      { values.timein  ? <span className="badge badge-warning"> {values.timein} </span> :null}
                                    </td>
                                    <td>
                                    {values.timeout ? <span className="badge badge-warning"> {values.timeout} </span> :null}
                                    </td>
                        </tr>
                      ))
                    ) : (
                      <tr></tr>
                    )}
                  </tbody>
                </table>}

                
                {list && list.CarList && (
                  <div>
                    <div className="row" style={{ fontSize: "14px" }}>
                      <div className="col-lg-4  col-sm-6 col-xs-12">
                        <p className="font-weight-normal">
                          ทะเบียนรถ:{list.CarList[0].CarLicense}
                        </p>
                      </div>
                      <div className="col-lg-4  col-sm-6 col-xs-12">
                        <p className="font-weight-normal">
                          ประเภทรถ:{list.CarList[0].CarTypeName}
                        </p>
                      </div>
                      <div className="col-lg-4  col-sm-6 col-xs-12">
                        <p className="font-weight-normal">
                          เหตผล:{list.CarList[0].remark}
                        </p>
                      </div>
                    </div>

                    <div className="row" style={{ fontSize: "14px" }}>
                      <div className="col-lg-4  col-sm-6 col-xs-12">
                        <p className="font-weight-normal">
                          ยี่ห้อ:
                        </p>
                      </div>
                      <div className="col-lg-4  col-sm-6 col-xs-12">
                        <p className="font-weight-normal">
                         {list.CarList[0].details}
                        </p>
                      </div>
                     
                    </div>

                    <table className="table table-striped table-hover">
                      <thead>
                        <tr  style={{ fontSize: "14px" }}>
                        
                          <th scope="col">
                          <FontAwesome name="truck"  /> คนขับ
                          
                          </th>
                          <th scope="col">เลขบัตร</th>
                          <th scope="col">สถานะอบรม</th>
                          <th scope="col">สถานะบทลงโทษ</th>
                          <th scope="col">ประเมิน Covid</th>
                          <th scope="col"></th>
                      <th scope="col">เข้า</th>
                      <th scope="col">ออก</th>
                        </tr>
                      </thead>
                      <tbody>
                        {list && list.CarList ? (
                          list.CarList.map((values) => (
                            <tr key={values.IdentityID} style={{ fontSize: "15px" }}>
                              <td >{values.Fname + " " + values.Lname}</td>
                              <td style={{ fontSize: "12px" }}>{values.IdentityID}</td>
                              <td style={{ fontSize: "14px" }}>
                                {values.safety === 1 ? (
                                  <span className="badge badge-success">
                                    {" "}
                                    ผ่าน{" "}
                                  </span>
                                ) : (
                                  <span className="badge badge-danger">
                                    {" "}
                                    ไม่ผ่าน{" "}
                                  </span>
                                )}
                              </td>
                              <td>
                              {values.penaltyname === "ปกติ" ? (
                              <span className="badge badge-success">
                                {" "}
                                {values.penaltyname}
                              </span>
                            ) : values.penaltyname === "ใบแดง" ? (
                              <span className="badge badge-danger">
                                {" "}
                                {values.penaltyname}
                              </span>
                            ):(
                              <span className="badge badge-warning">
                                {" "}
                                {values.penaltyname}
                              </span>
                            ) }
                              </td>
                              <td>
                                {values.CkCovid ? (
                                  <span className="badge badge-success">
                                    {" "}
                                    ผ่านประเมิน{" "}
                                  </span>
                                ) : (
                                  <span className="badge badge-danger">
                                    {" "}
                                    ไม่ผ่าน{" "}
                                  </span>
                                )}
                              </td>
                              <td>
                              <div className="mytooltip tooltip-effect-1">
                      <span className="tooltip-item">
                        <span className="d-none d-md-inline text-black pr-2">
                            <img
                            alt=""
                              className="rounded-circle ml-2"
                              src={values.url + "&size=320"}
                              style={{
                                width: "100%",
                                maxHeight: 50,
                                maxWidth: 50,
                              }}
                            />
                        </span>
                      </span>
                      

                      <span className="tooltip-content clearfix">
                      <img
                      alt=""
                              className="rounded-circle ml-2"
                              src={values.url + "&size=320"}
                              style={{
                                width: "100%",
                                minHeight: "300px",
                                minWidth: "300px",
                              }}
                            />
                             
                             <span className="tooltip-text"></span>
                      </span>
                    </div>
                              </td>
                              <td> 

{ values.timein ? <span className="badge badge-warning"> {values.timein} </span> :null}
</td>
<td>
{values.timeout ? <span className="badge badge-warning"> {values.timeout} </span> :null}

</td>
                            </tr>
                          ))
                        ) : (
                          <tr></tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
               

                {list && list.WorkerList && <table className="table table-striped table-hover">
                  <thead>
                    <tr style={{ fontSize: "14px" }}>
                      <th scope="col">
                      <FontAwesome name="user"  /> ผู้ปฏิบัติงาน
                      </th>
                      <th scope="col">เลขบัตร</th>
                      <th scope="col">สถานะอบรม</th>
                      <th scope="col">สถานะบทลงโทษ</th>
                      <th scope="col">ประเมิน Covid</th>
                     
                      <th scope="col"></th>
                      <th scope="col">เข้า</th>
                      <th scope="col">ออก</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list && list.WorkerList ? (
                      list.WorkerList.map((values) => (
                        <tr key={values.IdentityID}  style={{ fontSize: "15px" }}>
                          <td>{values.Fname + " " + values.Lname}</td>
                          <td style={{ fontSize: "12px" }}>{values.IdentityID}</td>
                          <td style={{ fontSize: "14px" }}>
                            {values.safety === 1 ? (
                              <span className="badge badge-success">
                                {" "}
                                ผ่าน{" "}
                              </span>
                            ) : (
                              <span className="badge badge-danger">
                                {" "}
                                ไม่ผ่าน{" "}
                              </span>
                            )}
                          </td>
                          <td>
                            {values.penaltyname === "ปกติ" ? (
                              <span className="badge badge-success">
                                {" "}
                                {values.penaltyname}
                              </span>
                            ) : values.penaltyname === "ใบแดง" ? (
                              <span className="badge badge-danger">
                                {" "}
                                {values.penaltyname}
                              </span>
                            ):(
                              <span className="badge badge-warning">
                                {" "}
                                {values.penaltyname}
                              </span>
                            ) }
                          </td>
                          <td>
                            {values.CkCovid ? (
                              <span className="badge badge-success">
                                {" "}
                                ผ่านประเมิน{" "}
                              </span>
                            ) : (
                              <span className="badge badge-danger">
                                {" "}
                                ไม่ผ่าน{" "}
                              </span>
                            )}
                          </td>
                          <td>

                          <div className="mytooltip tooltip-effect-1">
                      <span className="tooltip-item">
                        <span className="d-none d-md-inline text-black pr-2">
                            <img
                            alt=""
                              className="rounded-circle ml-2"
                              src={values.url + "&size=320"}
                              style={{
                                width: "100%",
                                maxHeight: 50,
                                maxWidth: 50,
                              }}
                            />
                        </span>
                      </span>
                      

                      <span className="tooltip-content clearfix">
                      <img
                      alt=""
                              className="rounded-circle ml-2"
                              src={values.url + "&size=320"}
                              style={{
                                width: "100%",
                                minHeight: "300px",
                                minWidth: "300px",
                              }}
                            />
                             
                             <span className="tooltip-text"></span>
                      </span>
                    </div>
                          </td>
                          <td> 

{ values.timein ? <span className="badge badge-warning"> {values.timein} </span> :null}
</td>
<td>
{values.timeout ? <span className="badge badge-warning"> {values.timeout} </span> :null}

</td>
                        </tr>
                      ))
                    ) : (
                      <tr></tr>
                    )}
                  </tbody>
                </table>}

                {list && list.AssetList && <table className="table table-striped table-hover">
                  <thead>
                    <tr style={{ fontSize: "14px" }}>
                      <th scope="col">
                      <FontAwesome name="cog"  />
                      </th>
                      <th scope="col">สินค้า</th>
                      <th scope="col">จำนวน</th>
                      <th scope="col">หน่วย</th>
                      <th scope="col">PO</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list && list.AssetList ? (
                      list.AssetList.map((values) => (
                        <tr key={values.id} style={{ fontSize: "14px" }}>
                          <td>{values.id}</td>
                          <td>{values.AssetName}</td>
                          <td>
                            {values.Amount}
                          </td>
                          <td>
                            {values.UnitName}
                          </td>
                          <td>
                            {values.PO}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr></tr>
                    )}
                  </tbody>
                </table>}

              </div>
            </div>
          )}



</div>


     );
}
 
export default PermitList;