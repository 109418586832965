import React from 'react'

export const FormInput = ({register,disabled=false, error, label, placeholder, id, ...inputProps}) => {
   // console.log(error)
  return <>
    {label && <label htmlFor={id}>{label}</label>}
    <input
      ref={register}
      placeholder={placeholder}
      id={id}
      disabled ={disabled}
      {...inputProps}
    />
    {error && <div className="alert alert-danger">{error.message}</div>} 
  </>
}