import React from "react";

const SearchBox = ({ value, onChange, placeholder='Search...', className='form-control' }) => {
  //console.log(onChange);
  return (
    <input
      type="text"
      name="query"
      className={className}
      placeholder={placeholder}
      value={value}
      onChange={e => onChange(e.currentTarget.value)}
    />
  );
};

export default SearchBox;
