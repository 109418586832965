import http from "./httpService";

export async function getWeightlist(
    start = "",
    end = "",
    branchid = "",
    typeid = "0",search="",pageSize =1000,pageNumber = 1
  ) {
   // console.log('cusid=',search);
    return http.get(
      "/JAWIS/Weightlist?startdate=" +
        start +
        "&enddate=" +
        end +
        "&branchid=" +
        branchid +
        "&typeid=" +
        typeid +"&pageSize="+ pageSize +"&pageNumber="+ pageNumber +"&search="+ search
    )}
export async function WoodReceiveWeeklyAA(){
  return http.get(
    "/ips/WoodReceiveWeekly?BranchID=AA"
  )
}
export async function ReportWoodChip (reportcode='R01',startdate="",enddate="",storeId){
  return http.get(
    "/report/ReportWoodChip?ReportCode="+ reportcode +"&StartDate=" +startdate + "&EndDate=" + enddate + "&StorageID=" + storeId
  )
}
export async function getCCP(startdate="",enddate="",pageSize =1000,pageNumber = 1) {
  return http.get(
    "/jawis/getresultReceive?startdate=" + startdate + "&enddate=" + enddate +"&pageSize="+ pageSize +"&pageNumber="+ pageNumber
  );
}
export async function getEditresult(id= 0) {
  return http.get(
    "/jawis/getEditresult?id=" + id 
  );
}

export async function SaveCCP(doc) {

    return http.post("/jawis/SaveCCP", doc);

}
    export default {
        getWeightlist,
        WoodReceiveWeeklyAA,
        ReportWoodChip,
        getCCP,
        getEditresult,
        SaveCCP
        
      };
      