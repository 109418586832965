import React from "react";
import FontAwesome from "react-fontawesome";
import { Link } from "react-router-dom";
import Toggle from './togle';

const CloneDevicelist = ({ lastBookElementRef, list, snackMessage, handleOnClick }) => {

  return (
    <div className="row course-list list m-1">
      {list &&
        list.map((item, index) => {
          if (list.length === index + 1) {
            return (
              <div key={item.no}  className="col-sm-12 col-xs-12">
              <div  >
                
                 
                    <div className="card mb-2">
                      <div className="card-body p-2">
                        <div
                          ref={lastBookElementRef}
                          className="row align-items-center justify-content-between"
                        >
                          <div className="col-auto ml-2">
                            <span>
                              {" "}
                              {item.no} {"  "}
                            </span>
                            <span aria-hidden="true" className="fa fa-users" />
                            <p className="nav-item nav-link text-dark text-bold font-weight-bold d-inline">
                              {item.name}
                            </p>
                            <span>จำนวน {"  "}</span>
                            {item.amount > 0 ? (
                              <span
                                className="badge badge-pill badge-primary"
                                style={{ fontSize: "14px" }}
                              >
                                {" "}
                                {item.amount}{" "}
                              </span>
                            ) : (
                              <span
                                className="badge badge-pill badge-secondary"
                                style={{ fontSize: "14px" }}
                              >
                                {" "}
                                {item.amount}{" "}
                              </span>
                            )}
                          </div>
                          <div className="mr-2">
                          <Toggle
              text="เลือก"
              checked={item.chkselected}
              name ={item.face_id}
              onChange={handleOnClick}

/>
</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
             
            );
          } else {
            return (
              <div key={item.no}  className="col-sm-12 col-xs-12">
              <div >
               
                 
                    <div className="card mb-2">
                      <div className="card-body p-2">
                        <div className="row align-items-center justify-content-between">
                          <div className="col-auto ml-2">
                            <span>
                              {" "}
                              {item.no} {"  "}
                            </span>
                            <span aria-hidden="true" className="fa fa-users" />
                            <p className="nav-item nav-link text-dark text-bold font-weight-bold d-inline">
                              {item.name}
                            </p>
                            <span>จำนวน {"  "}</span>
                            {item.amount > 0 ? (
                              <span
                                className="badge badge-pill badge-primary"
                                style={{ fontSize: "14px" }}
                              >
                                {" "}
                                {item.amount}{" "}
                              </span>
                            ) : (
                              <span
                                className="badge badge-pill badge-secondary"
                                style={{ fontSize: "14px" }}
                              >
                                {" "}
                                {item.amount}{" "}
                              </span>
                            )}
                          </div>
                          <div
                            className="col-auto pr-4"
                            style={{ cursor: "pointer" }}
                          >
              
                          </div>

                          <div className="mr-2">
                          <Toggle
              text="เลือก"
              checked={item.chkselected}
              name ={item.face_id}
              onChange={handleOnClick}

/>
</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              
            );
          }
        })}
    </div>
  );
};

export default CloneDevicelist;
