import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import Rendercockinout from "./rendertimestamp";

import DatePicker from "react-datepicker";
import moment from "moment";
import { getlogscan } from "./../../services/facescan";


import BranchContext from "./../../components/context/branch";


const Cockinout = ({user}) => {


  const { currentBranch } = useContext(BranchContext);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [cockinout, setcockinout] = useState([]);

  //const [message, setMessage] = useState({ errors: false, messages: "" });

  const [hasMore, setHasMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [total,setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  useEffect(() => {
    setcockinout([]);
  }, [currentBranch, startDate, endDate]);

  useEffect(() => {
    if (!currentBranch) return;
    

    setLoading(true)
    setError(false)
    const loadData = async () => {
      const { data: result } = await  getlogscan(
        "",
        moment(startDate, "YYYY-MM-DD").add(0, "day").format("YYYY-MM-DD"),
        moment(endDate, "YYYY-MM-DD").add(0, "day").format("YYYY-MM-DD"),
        pageSize,
        pageNumber
      );
      
      if ( result.status==='success') {
        setTotal(result.data.total);

        setcockinout(prevData => {   
 return [...new Set([...prevData, ...result.data.list])]
              })
       setHasMore(result.data.list.length > pageSize-1)
       setLoading(false)                 
    }
    };

    loadData();
  }, [currentBranch, startDate, endDate,  pageNumber, pageSize]);



  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      //  console.log('node = ' ,node);
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        //  console.log(entries[0].isIntersecting,hasMore);

        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );


  const handleDateChange = (date) => {
    setStartDate(date);
    setPageNumber(1);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
    setPageNumber(1);
  };
 // console.log(user);
  if (currentBranch._id !=='0'){
    return <>
    </>
    }
  return (
    <div>
    
      <div className="container-fluid">

       <div className="row no-gutters align-items-center justify-content-left">
                            <div className="col-auto text-center mb-2 p-1 text-center">
                              <img
                                src={"https://wms.advanceagro.net/WSVIS/api/Face/GetImage?CardID=" + user.empid + "&size=320"}
                                className="rounded-circle mr-1"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title=""
                                alt=""
                                style={{
                                  maxHeight: "80px",
                                  Width: "100%",
                                  maxWidth: "80px",
                                }}
                              ></img>
                   </div>   
                  
      
      <p className="font-weight-normal badge badge-primary"  style={{ fontSize: "18px" }}>
      {user.fullname}
    </p>

 </div>
        <div className="row">
          <div className="col-auto">
            <div className="m-2">
              <i>
                จากวันที่ <span className="label label-default"></span>
              </i>

              <div className="mt-2" style={{ marginTop: 20, marginBottom: 20 }}>
                <DatePicker
                  todayButton="Today"
                  dateFormat="dd/MM/yyyy"
                  selected={startDate}
                  onChange={handleDateChange}
                />
              </div>
            </div>
          </div>
          <div className="col-auto">
            <div className="m-2">
              <i>
                ถึงวันที่ <span className="label label-default"></span>
              </i>
              <div className="mt-2">
                <DatePicker
                  todayButton="Today"
                  dateFormat="dd/MM/yyyy"
                  selected={endDate}
                  onChange={handleEndDateChange}
                />
              </div>
            </div>
          </div>

     
        </div>

        {/* <div className="row">
       

          <div className="col-6" style={{ marginTop: 15 }}>
            <button className="btn btn-success m-2">Download To Excel</button>
          </div>
        </div> */}

        <p className="text-success">
        แสดงข้อมูล {cockinout ? cockinout.length : 0}  /  {total} รายการ
        </p>
        
          <Rendercockinout

            list={cockinout}
            loading={loading}
            error={error}
            lastBookElementRef={lastBookElementRef}

          />
       
      </div>
    </div>
  );
};

export default Cockinout;
