import React,{useState,useEffect} from "react";
import Renderaccount from "./renderaccount";
import ConfirmSubmit from './../common/confirmSubmit';
import { toast } from "react-toastify";
import {getAccoutRequest,ConfirmSaveRequest} from '../../services/vms';




const Accountrequest = (props)  => {
 
const [accountrequest, setaccountrequest] = useState([]);
const [Id, setID] = useState(0);
const [confirmsave,setconfirmsave] = useState(false);

const [messageErros, setmessageErros] = useState({
  askConfirm: {enable: false, message: "",id:"",action:""}
});

const handleChange = (id, detail) =>{
  setID(id);
  setconfirmsave(false);

  if (detail==="disapproved"){
   

    setmessageErros( { askConfirm: {enable: true, message: "ท่านยืนที่จะยกเลิกใบขออนุมัตินี้ใช่หรือไม่",id:id,action:detail}}) 

  }else if (detail==="approved"){
  //  console.log(id,detail)
    handelOnConfirm(id,detail)
   // setmessageErros( { askConfirm: {enable: true, message: "ท่านยืนที่จะยกเลิกใใช่หรือไม่",id:id,action:detail}}) 
  }
 // console.log(id,detail)
}


useEffect(() => {
const loadData = () => {

  getAccoutRequest().then (result=>{        
    setaccountrequest(result.data);           
               });
              }
    loadData ();
               
             }, 
             [confirmsave])

      const handlePopupAlertClick = (event) => {
                if (messageErros.askConfirm.action=="disapproved"){
                  setmessageErros( { askConfirm: {enable: false, message: ""}})  
                  handelOnConfirm(Id,"disapproved");
                  }else if (messageErros.askConfirm.action=="disapproved") {
                    setmessageErros( { askConfirm: {enable: false, message: ""}})
                    handelOnConfirm(Id,"disapproved");
                  } else
                  {
                  setmessageErros( { askConfirm: {enable: false, message: ""}})

                  }
            
           
            //  console.log(Id);
              
               }

               const handelOnCancel = (event) => {
                setmessageErros( { askConfirm: {enable: false, message: ""}})  
               }


               const handelOnConfirm = async (id,action) => {
                try {
                  
                  const doc = {   
                     action: action, _id: id
                  };
             
                 const {data: result} = await ConfirmSaveRequest(doc);
              
               if (result.status==='success') {
                  toast.success("บันทึกเสร็จเรียบร้อย");
                  setconfirmsave(true);
                 
                } else {
                  toast.error(result.message)
                 
                }
             
               } catch(err) {
                 toast.error("Error")
               }
              }
               


    return (
      <div>
       <ConfirmSubmit
       isOpen={messageErros.askConfirm.enable}   onConfirm={handlePopupAlertClick} message={messageErros.askConfirm.message} 
       onCancel ={handelOnCancel}
       />
            <Renderaccount
            list = {accountrequest }
            handleChange = {handleChange}
            />
            </div>
    );
  }


export default  Accountrequest ;