import React,{useState} from 'react';
import RenderButton from '../common/button';
import DatePicker from "react-datepicker";


const Headder = ({onChange}) => {

    const [all, setall] = useState('btn btn-primary');
    const [ip1, setip1] = useState('btn btn-outline-dark');
    const [dap, setdap] = useState('btn btn-outline-dark');
    const [ip2, setip2] = useState('btn btn-outline-dark');
    const [bitec, setbitec] = useState('btn btn-outline-dark');
    const [remote, setremote] = useState('btn btn-outline-dark');
  
  
    const [startDate, setStartDate] = useState(new Date());

    const handleonClick = ({ currentTarget: input }) => {
        if (input.value==0){
            disabledlabel();
            setall('btn btn-primary'); 
        }else if (input.value==1){
            disabledlabel();
            setip1('btn btn-primary'); 
        }else if (input.value==2){
            disabledlabel();
            setdap('btn btn-primary');
        }
        else if (input.value==5){
              disabledlabel();
              setip2('btn btn-primary'); 
        }else if (input.value==6){
            disabledlabel();
            setremote('btn btn-primary'); 
        } else if (input.value==7)
        {
          disabledlabel();
          setbitec('btn btn-primary'); }
        onChange(input.value);
    }
  


const disabledlabel = () => {
    setall('btn btn-outline-dark')
    setip1('btn btn-outline-dark') ;
    setdap('btn btn-outline-dark') ;
    setbitec('btn btn-outline-dark');
    setip2('btn btn-outline-dark') ;
    setremote('btn btn-outline-dark') ;
  
}



    return ( 
<div className='container'>
            <div className="row">
              {/* <div className='m-4'>
                 
                <div className='mt-2' style={{ marginTop: 20, marginBottom: 20 }} >
                  <DatePicker
                    todayButton="Today"
                    dateFormat="dd/MM/yyyy"
                    selected={startDate}
                    onChange={handleChange} />
                </div>

              </div>    */}

              <div className="row justify-content-end align-items-center">
                  <div className="col-auto mb-3 px-0"><ul className="btn-group p-0 m-0 ">
                     <RenderButton 
                     label ="All"
                     value="0"
                     onClick ={handleonClick}
                     className = {all}
                      />
                    <RenderButton 
                     value="1"
                     label ="IP1"
                     onClick ={handleonClick}
                     className = {ip1}
                      />
                         <RenderButton 
                           value="5"
                          label ="IP2"
                     onClick ={handleonClick}
                     className = {ip2}
                      />
                        <RenderButton 
                           value="2"
                          label ="DAP"
                     onClick ={handleonClick}
                     className = {dap}
                      />
                    <RenderButton 
                           value="7"
                          label ="BITEC"
                     onClick ={handleonClick}
                     className = {bitec}
                      />
                        <RenderButton 
                           value="6"
                          label ="Remote"
                     onClick ={handleonClick}
                     className = {remote}
                      />
                         
                       
                      </ul>
                      </div>
                      </div>


            </div>
          </div>

     );
}
 
export default Headder;

