import React,{useState} from 'react';
import RenderButton from './../../components/common/button';
import DatePicker from "react-datepicker";


const Headder = ({onChange}) => {

    const [all, setall] = useState('btn btn-primary');
    const [aa, setaa] = useState('btn btn-outline-dark');
    const [pm3, setpm3] = useState('btn btn-outline-dark');
    const [nps, setnps] = useState('btn btn-outline-dark');
    const [e85, sete85] = useState('btn btn-outline-dark');
    const [agf, setagf] = useState('btn btn-outline-dark');
    const [ns304, setns304] = useState('btn btn-outline-dark');
    const [other, setother] = useState('btn btn-outline-dark');
    const [startDate, setStartDate] = useState(new Date());

    const handleonClick = ({ currentTarget: input }) => {
        if (input.value==4){
            disabledlabel();
            setpm3('btn btn-primary'); 
        }else if (input.value==1){
            disabledlabel();
            setall('btn btn-primary'); 
        }else if (input.value==2){
            disabledlabel();
            setaa('btn btn-primary'); 
        }else if (input.value==5){
            disabledlabel();
            setnps('btn btn-primary'); 
        }else if (input.value==6){
            disabledlabel();
            sete85('btn btn-primary'); 
        }else if (input.value==3){
            disabledlabel();
            setagf('btn btn-primary'); 
        }else if (input.value==7){
            disabledlabel();
            setns304('btn btn-primary'); 
        }
        onChange(input.value);
    }
    // const handleChange = (date) => {
    //     setStartDate(date)
    //     handleDateChange(date);
    // }


const disabledlabel = () => {
    setall('btn btn-outline-dark')
    setaa('btn btn-outline-dark') ;
    setpm3('btn btn-outline-dark') ;
    setagf('btn btn-outline-dark') ;
    setnps('btn btn-outline-dark') ;
    sete85('btn btn-outline-dark') ;
    setother('btn btn-outline-dark') ;
    setns304('btn btn-outline-dark') ;
}



    return ( 
<div className='container'>
            <div className="row">
              {/* <div className='m-4'>
                 
                <div className='mt-2' style={{ marginTop: 20, marginBottom: 20 }} >
                  <DatePicker
                    todayButton="Today"
                    dateFormat="dd/MM/yyyy"
                    selected={startDate}
                    onChange={handleChange} />
                </div>

              </div>    */}

              <div className="row justify-content-end align-items-center">
                  <div className="col-auto mb-3 px-0"><ul className="btn-group p-0 m-0 ">

     
                     <RenderButton 
                     label ="All"
                     value="1"
                     onClick ={handleonClick}
                     className = {all}
                      />
                    <RenderButton 
                     value="2"
                     label ="AA ป้อม 3"
                     onClick ={handleonClick}
                     className = {aa}
                      />
                         <RenderButton 
                           value="4"
                          label ="AA PM3"
                     onClick ={handleonClick}
                     className = {pm3}
                      />
                        <RenderButton 
                           value="5"
                          label ="NPS"
                     onClick ={handleonClick}
                     className = {nps}
                      />

                        <RenderButton 
                           value="6"
                          label ="E85"
                     onClick ={handleonClick}
                     className = {e85}
                      />
                         <RenderButton 
                           value="7"
                          label ="NS304"
                     onClick ={handleonClick}
                     className = {ns304}
                      />
                       <RenderButton 
                           value="3"
                          label ="AGF"
                     onClick ={handleonClick}
                     className = {agf}
                      />
                      </ul>
                      </div>
                      </div>


            </div>
          </div>

     );
}
 
export default Headder;

