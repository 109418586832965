import React,{useState} from 'react';
import FontAwesome from "react-fontawesome";

import Modaldevice from './modaldevicebyuser'
import { toast } from 'react-toastify';
import './../../App.css';
const RenderUser = ({
    lastBookElementRef,
    list,
    snackMessage,
    handleOnClick,
    search,
    onUpdate
  }) => {
    const [empData, setempData] = useState({
    empid:'',empname:''
    });
    const [isOpen, setisOpen] = useState(false);


    const [showScroll, setShowScroll] = useState(false)

    const checkScrollTop = () => {
      if (!showScroll && window.pageYOffset > 400){
       // console.log('over 400')
        setShowScroll(true)
      } else if (showScroll && window.pageYOffset <= 400){
        setShowScroll(false)
       // console.log('non 400')
      }
    };
  
    const scrollTop = () =>{
      window.scrollTo({top: 0, behavior: 'smooth'});
    };
  
    window.addEventListener('scroll', checkScrollTop)

   

const handleCancle = () => {
  setisOpen(false);
}
const handleDeviceClick = (id,name) => {
  setempData({ empid:id ,empname:name });
  setisOpen(true);
}
const onConfirm = () => {
  setisOpen(false);
  onUpdate();
}
//console.log(list);

return (  
 <div className ="row course-list list m-1">
<Modaldevice
        isOpen={isOpen}
        onCancel={handleCancle}
        value ={empData}
        header ={empData.empname} 
        onConfirm ={onConfirm}     
      //ref={lastBookElementRef}
    
      />
      {list &&
        list.map((item, index) => {
          if (list.length === index + 1) {
            return (
                   
<div ref={lastBookElementRef}  key={item.no} className="card text-dark p-0 mb-2 mr-2  col-xs-12 col-sm-2">       
<div className="container-fluid">
    <span className="badge badge-pill badge-secondary">{item.no}</span>
    
    <div className="row no-gutters align-items-center justify-content-left">
     <div className="col-auto text-center mb-2 p-1 text-center">
      <img
        src={item.imageUrl + "&size=320"}
        className="rounded-circle mr-1"
        data-toggle="tooltip"
        data-placement="bottom"
        title=""
        alt=""
        style={{
          maxHeight: "100px",
          Width: "100%",
          maxWidth: "100px",
        }}
      ></img>
    </div>



                     
    <div className="col-auto ml-2">
                              <div className="card-body p-1">
                                <div className="card-text">
                                <h6 className="card-title d-inline mr-2" style={{ fontSize: "14px" }}>
                                {item.FnameT} {" "} {item.LnameT}
                                  </h6>
                                </div>
                                <div className="card-text">
                                  <h6 className="card-text d-inline mr-2" style={{ fontSize: "14px" }}>
                                    <span
                                      aria-hidden="true"
                                      className="fa fa-briefcase"
                                    />{" "}
                                   {item.department}
                                  </h6>
                                  <h6 className="card-text d-inline" style={{ fontSize: "14px" }}>
                                    <span
                                      aria-hidden="true"
                                      className="fa fa-building"
                                    />{" "}
                                    {item.company_code}
                                  </h6>
                                </div>
                                <h6>
                                <div className="card-text" style={{ fontSize: "14px" }}>
                                  <span className="card-text">
                                    <span
                                      aria-hidden="true"
                                     
                                     /> {"ID:"}
                                 {search ?  item.ID_Emp :
                                 item.card_id
                                 }

                                  
                               { item.amount > 1 ? <span className="badge badge-pill badge-warning" style={{ cursor: "pointer" , fontSize: "12px"}}
                                 onClick={() =>
                                  handleDeviceClick(item.card_id, item.FnameT +'  ' + item.LnameT)
                                }
                               
                               >{"  "}{item.amount}
                          
                               </span>
                               
                               : item.amount ===1 ? <span className="badge badge-pill badge-info"  style={{ cursor: "pointer" , fontSize: "12px" }}
                               onClick={() =>
                                handleDeviceClick(item.card_id, item.FnameT +'  ' + item.LnameT)
                              }
                               >{"  "}{item.amount}
                            
                               </span>:
                                <span className="badge badge-pill badge-secondary" style={{ cursor: "pointer" , fontSize: "12px"}}
                              
                                >{item.amount}                       
                               </span>
                               }
                                  </span>
                                </div>
                                </h6>
                                { search ?  


                               <button className="btn btn-primary" style={{ fontSize: "12px" }}

                               onClick={() =>
                                 handleOnClick(item.ID_Emp)
                               }
                               > 
                               <span>Add</span>
                               </button> 
                                
                                :
                                 item.isnormal !=="1" ?
                                <button className="btn btn-danger" style={{ fontSize: "12px" }}
                                onClick={() =>
                                  handleOnClick(item.card_id)
                                }
                                > 
                              
                                <span>Send Again</span>
                                </button> :  <button className="btn btn-primary" style={{ fontSize: "12px" }}
                                onClick={() =>
                                  handleOnClick(item.card_id)
                                }
                                > 
                                <span>Send Again</span>
                                </button> }
                                
        
                              </div>
                            </div>
  </div>
</div>

</div> 
            );
          } else {
            return (
               

    

                  <div  key={item.no} className="card text-dark p-0 mb-2 mr-2  col-xs-12 col-sm-2">       
                        <div className="container-fluid">
                        <div className="d-flex justify-content-between mt-2">
                            <span className="badge badge-pill badge-secondary">{item.no}</span>
                          
                              </div>
                          <div className="row no-gutters align-items-center justify-content-left">
                            <div className="col-auto text-center mb-2 p-1 text-center">
                              <img
                                src={item.imageUrl + "&size=320"}
                                className="rounded-circle mr-1"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title=""
                                alt=""
                                style={{
                                  maxHeight: "100px",
                                  Width: "100%",
                                  maxWidth: "100px",
                                }}
                              ></img>
                            </div>

                     
                            <div className="col-auto ml-2">
                              <div className="card-body p-1">
                                <div className="card-text">
                                <h6 className="card-title d-inline mr-2" style={{ fontSize: "14px" }}>
                                {item.FnameT} {" "} {item.LnameT}
                                  </h6>
                                </div>
                                <div className="card-text">
                                  <h6 className="card-text d-inline mr-2" style={{ fontSize: "14px" }}>
                                    <span
                                      aria-hidden="true"
                                      className="fa fa-briefcase"
                                    />{" "}
                                   {item.department}
                                  </h6>
                                  <h6 className="card-text d-inline" style={{ fontSize: "14px" }}>
                                    <span
                                      aria-hidden="true"
                                      className="fa fa-building"
                                    />{" "}
                                    {item.company_code}
                                  </h6>
                                </div>
                                <h6>
                                <div className="card-text" style={{ fontSize: "14px" }}>
                                  <span className="card-text">
                                    <span
                                      aria-hidden="true"
                                     
                                     /> {"ID:"}
                                    {search ?  item.ID_Emp :
                                 item.card_id
                                 }


                                    { item.amount > 1 ?  <span className="badge badge-pill badge-warning" style={{ cursor: "pointer" , fontSize: "12px"}} 
                                       onClick={() =>
                                        handleDeviceClick(item.card_id, item.FnameT +'  ' + item.LnameT)
                                      }
                                    
                                    >{"  "}{item.amount}
                            
                                    </span>
                               
                               : item.amount ===1 ? <span className="badge badge-pill badge-info" style={{ cursor: "pointer" , fontSize: "12px"}}
                               onClick={() =>
                                handleDeviceClick(item.card_id, item.FnameT +'  ' + item.LnameT)
                              }
                               
                               >{item.amount}
                          
                               </span>:
                               <span className="badge badge-pill badge-secondary" style={{ cursor: "pointer" , fontSize: "12px"}}
                              
                               >{item.amount}                       
                              </span>
                               }

                                  </span>
                                </div>

                                
                                </h6>
                                {   search ?   <button className="btn btn-primary" style={{ fontSize: "12px" }}
                                onClick={() =>
                                  handleOnClick(item.ID_Emp)
                                }
                                > 
                                <span>Add</span>
                                </button> 
                                
                                : item.isnormal !=="1" ?
                                <button className="btn btn-danger" style={{ fontSize: "12px" }}
                                onClick={() =>
                                  handleOnClick(item.card_id)
                                }
                                > 
                                <span>Send Again</span>
                                </button> :   <button className="btn btn-primary" style={{ fontSize: "12px" }}
                                onClick={() =>
                                  handleOnClick(item.card_id)
                                }
                                > 
                                <span>Send Again</span>
                                </button> }
                                
        
                              </div>
                            </div>
                          </div>
                        </div>
                     
                    </div> 
                  
              
             
             
            );
          }
        })}


<FontAwesome name="arrow-circle-up" className="scrollTop" onClick={scrollTop} style={{height: 40, display: showScroll ? 'flex' : 'none'}} />
    </div>


    );
}
 
export default RenderUser;