import React from 'react';
//import Card from './Card';

import Avatar from "./Avatar";
import Detail from "./Detail";
import FontAwesome from 'react-fontawesome';


const Rendercard = ({list,handleChange,lastBookElementRef,loading,error}) => {

 // console.log(list);
    return ( 
    <div className ="row course-list list">
    
    
    {list &&  list.map((item,index) => {

    if (list.length === index +1 ){
    return    ( item.PermitType==='บัตรอบรมความปลอดภัยทั่วไป' ?
    <div key={item.NO}  className="col-xs-12 col-sm-6 col-md-4">
    <div  className="cardsup"> 
    <div className="top">
    <h2  ref = {lastBookElementRef}   className="name1">{item.Name}</h2>
        <Avatar img={item.url+'&size=320'} />
      </div>
      <div className="bottom">
        <Detail detailInfo={item.PermitType} />
        <Detail detailInfo={'บริษัท'  + item.companyname} />
        <Detail detailInfo={'วันออกบัตร ' +item.CreatDate} />
        <Detail detailInfo={'วันหมดอายุ '+ item.ExpiredDate} />
        
      </div>
      <div className="row">
  <div className="col-auto mr-auto">{'บทลงโทษ '+item.warning}</div>
  <div className="col-md-3 ml-md-auto"><button
                          className="btn btn-warning"
                          onClick={() => handleChange(item.RegisterID, "edit")}
                        >Edit
                      <FontAwesome name="edit"/>    
                        <i/>
                          </button>
  
  </div>
</div>
    </div>
    </div>
    : 
    <div key={item.NO} className="col-xs-12 col-sm-6 col-md-4"> 
    <div   className="cardchemical"> 
    <div className="top">
    <h2  ref = {lastBookElementRef}   className="name1">{item.Name}</h2>
        <Avatar img={item.url+'&size=320'} />
      </div>
      <div className="bottom">
        <Detail detailInfo={item.PermitType} />
        <Detail detailInfo={'บริษัท '  + item.companyname} />
        <Detail detailInfo={'วันออกบัตร ' +item.CreatDate} />
        <Detail detailInfo={'วันหมดอายุ '+ item.ExpiredDate} />
        
      </div>
      <div className="row">
  <div className="col-auto mr-auto">{'บทลงโทษ '+item.warning}</div>
  <div className="col-md-3 ml-md-auto"><button
                          className="btn btn-warning"
                          onClick={() => handleChange(item.RegisterID, "edit")}
                        >Edit
                      <FontAwesome name="edit"/>    
                        <i/>
                          </button>
  
  </div>
</div>
    </div>
    </div>   
    )
}else 
{
        return    (item.PermitType==='บัตรอบรมความปลอดภัยทั่วไป' ? 
        <div key={item.NO} className="col-xs-12 col-sm-6 col-md-4">  
        <div  className="cardsup">
<div className="top">
<h2 className="name1"  style={{ "wordSpacing": "500px" }}> {item.Name}</h2>
    <Avatar img={item.url+'&size=320'} />
  </div>
  <div className="bottom">
    <Detail detailInfo={item.PermitType} />
    <Detail detailInfo={'บริษัท '  + item.companyname} />
        <Detail detailInfo={'วันออกบัตร ' +item.CreatDate} />
        <Detail detailInfo={'วันหมดอายุ '+ item.ExpiredDate} />
  </div>

  <div className="row">
  <div className="col-auto mr-auto">{'บทลงโทษ '+item.warning}</div>
  <div className="col-auto"><button
                          className="btn btn-warning"
                          onClick={() => handleChange(item.RegisterID, "edit")}
                        >Edit
                      <FontAwesome name="edit"/>    
                        <i/>
                          </button>
  
  </div>
</div>
                           
</div>
</div>
: 
<div  key={item.NO} className="col-xs-12 col-sm-6 col-md-4"> 
<div className="cardchemical">
<div className="top">
<h2 className="name1"   style={{ "wordSpacing": "500px" }}> {item.Name}  </h2>
    <Avatar img={item.url+'&size=320'} />
  </div>
  <div className="bottom">
    <Detail detailInfo={item.PermitType} />
    <Detail detailInfo={'บริษัท '  + item.companyname} />
        <Detail detailInfo={'วันออกบัตร ' +item.CreatDate} />
        <Detail detailInfo={'วันหมดอายุ '+ item.ExpiredDate} />
  </div>

  <div className="row">
  <div className="col-auto mr-auto">{'บทลงโทษ '+item.warning}</div>
  <div className="col-auto"><button
                          className="btn btn-warning"
                          onClick={() => handleChange(item.RegisterID, "edit")}
                        >Edit
                      <FontAwesome name="edit"/>    
                        <i/>
                          </button>
  
  </div>
</div>
</div>                          
</div>)}

    })
    }
    <div>{loading && 'Loading...'}</div>
      <div>{error && 'Error'}</div>
    </div>
     );
}
 
export default Rendercard;