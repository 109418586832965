import React, { useState,useEffect,useContext } from "react";
import CustomerType from './customercard';
import Productcard from './productcard';
import Truckcard from './truckcard';
import Queuecard from './queuecard';
import moment from 'moment';
//import querystring from 'query-string';
import Joi from "joi-browser";
import { toast } from "react-toastify";
import { getregister} from './../../services/grabconcept';
import {saveRegister} from './../../services/register';
import ConfirmSubmit from './../common/confirmSubmit';
import BranchContext from './../context/branch';
import PopupAlert from './../common/popupAlert';
//import ModelSelect from './../common/modalselect';


//import Select from './select'


const Register =(props) => {

  // let vForm =false;
  
   const {currentBranch} = useContext(BranchContext);
   const [Id, setId] = useState(props.match.params.id);
 //  const [customerType, setcustomerType] = useState([]);
  //let id = props.match.params.id;

  
  const [validation, setValidation] = useState(false);
  const [vForm1, setVForm1] = useState(false);

 // const [weightno, setweightno] = useState("");
  
   const [startDate, setStartDate] = useState(new Date());
   const [nowDate, setNowDate] = useState(new Date());
   const [productTypeId, setProductTypeId] = useState(0);
   const [productId, setproductId] = useState(0);
   const [truckId, settruckId] = useState("");
   const [option1, setoption1] = useState("");
   const [option2, setoption2] = useState("");
   const [remark, setremark] = useState('');

   const [custypeId, setcustypeId] = useState("00");
   const [storeId, setstoreId] = useState(0);
   const [firstProvinceId, setfirstProvinceId] = useState(0);
   const [secProvinceId, setsecProvinceId] = useState(0);
  
   const [customerId, setcustomerId] = useState(0);
   const [drivenId, setdrivenId] = useState("");
   const [doId,setdoId] =  useState("");

  // const [editDoId, seteditDoId] = useState("");
   //const [editproductId, seteditproductId] = useState(0);


   const [aimId, setaimId] = useState("00");
 //  const [queuetime, setqueuetime] = useState([]);
   const [times, settimes] = useState("");
   const [askConfirm , setaskConfirm] = useState(false);
   const [message, setmessage] = useState("");
   const [drivenName, setdrivenName] = useState("");
   const [drivenTel, setdrivenTel] = useState("");
  
   const [f1, setf1] = useState("");
   const [f2, setf2] = useState("");
   const [s1, sets1] = useState("");
   const [s2, sets2] = useState("");
   const [alldata, setalldata] = useState([]);
   const [messageErros, setmessageErros] = useState({
      askAlert: {enable: false, message: ""}
   });

   const [stateData, setstateData] = useState({ 
      data: {
        DO: "",
        identityId:""
      },
      errors: {enable: false, message: ""}
     });
      

     


     let schema1 = {
      DO: Joi.string().min(0).max(99999999).required().label("ใบนำส่ง"),
      identityId : Joi.string().min(0).max(99999999).required().label("ใบขับขี่"),
      drivenName : Joi.string().min(0).max(99999999).required().label("ชื่อคนขับ"),
      drivenTel : Joi.string().min(0).max(99999999).required().label('เบอร์โทรคนขับ'),
      f1 : Joi.string().min(0).max(99999999).required().label('ทะเบียนรถ'),
      f2 : Joi.string().min(0).max(99999999).required().label('ทะเบียนรถ'),
    };

  //  console.log(drivenId);
      useEffect(() => {
        
         const loadRegister = async() => {
            try {
              
            //   console.log('in get',Id);

                if (!Id) return;
                if(Id==='new') {
                  setalldata([]);
                  setValidation(false);

                  return;
                } 
               const { data: result } = await getregister(Id,currentBranch && currentBranch._id);              
                  if (result) {  
      
                      result.filter(item => item.AutoID === Id) 
      
                     setalldata(result);
                    
                   
                } else {
                    toast.error(result.message)
                }
            } catch (ex) {  toast.error(ex.message)   }
          }


          loadRegister();
      
        
      }, 
      [Id,currentBranch])
      
      useEffect (() => {
         const getalldata =() =>{
            setId(props.match.params.id);

         if (vForm1) return ;
         if (alldata&& alldata.length ===1) {
            
      //      console.log(alldata);

         setdoId(alldata[0].DO); 
         setcustypeId(alldata[0].customerType_id);
         setcustomerId(alldata[0].supplier_id);
         setdrivenId(alldata[0].driven_no);
         setdrivenName(alldata[0].driven_name);
         setdrivenTel(alldata[0].driver_tel);
           setProductTypeId(alldata[0].type_id);
          setproductId(alldata[0].ProductID);
          setaimId(alldata[0].receive_aim);
          settruckId(alldata[0].trucktype1);
          setfirstProvinceId(alldata[0].first_driven_prov);
          setoption1(alldata[0].option1);
          setoption2(alldata[0].option2);

          const queuedate = new Date(alldata[0].queue_date1); 
          //const recorddate = new Date(alldata[0].DateRecord);
          if (alldata[0].queue_date1){
            setStartDate(queuedate); 
          }else{
         //   console.log(recorddate);
          }
         


        //  settimes(alldata[0].queue_time);             

          let editFullFirstDriven = alldata[0].first_driven_no;

          let editSecodeProvince = alldata[0].second_driven_prov;
          let editFullSecDriven = alldata[0].second_driven_no;
          //console.log(editSecodeProvince);
          setsecProvinceId(editSecodeProvince)

            if (editFullFirstDriven && !editFullFirstDriven==""){
               var res = editFullFirstDriven.split("-");
               setf1(res[0]);
               setf2(res[1]);  
            }
            if (!editFullSecDriven =="" && editFullSecDriven){   
               var res1 = editFullSecDriven.split("-");
               sets1(res1[0]);
               sets2(res1[1]);
              // console.log(res);
             }

            if (alldata[0].store_id){
               setstoreId(alldata[0].store_id);
            }
           
            if (alldata[0].queue_time){
               settimes(alldata[0].queue_time);
          
            }
           
           
          //  setweightno(alldata[0].WeightNo);
         //  console.log(queuedate);
         //  console.log(alldata[0].queue_time);
            if (alldata[0].WeightNo && !alldata[0].WeightNo==''){
              

               setValidation(true);
            }
           
         setVForm1(true);

      } else  
      {
         setValidation(false);
            if (Id==="new"){
               setVForm1(true);
         }

      }
         
      }
         getalldata();
        
      })
   

    
    const  doSubmit = async () => {
         try {
           
            const doc = {
               
             _id:Id,  customer_id: customerId, customer_type: custypeId, driven_id: drivenId, driven_name: drivenName, 
               driven_tel: drivenTel, aim_no: aimId, delivery_no: doId, branch_id:currentBranch && currentBranch._id,
               store_id: storeId, truck_type: truckId, product_id: productId, product_type:productTypeId,
               trucktype: truckId, firstdriven_prov: firstProvinceId, seconddriven_prov:secProvinceId,option1,option2:option2,
               queue_date: moment(startDate, "YYYY-MM-DD").add(0, 'day').format("YYYY-MM-DD") , queue_time: times,
                firstdriven_no: f1 +'-'+ f2, seconddriven_no: s1 +'-'+ s2,remark
            };
//console.log('doc',doc);
           const {data: result} = await saveRegister(doc);
        
         if (result.status==='success') {
            toast.success("บันทึกเสร็จเรียบร้อย");
  
           const urlGoBack = "/showdetail/";
            setTimeout(props.history.replace(urlGoBack), 1000);
          } else {
            toast.error(result.message)
          }
       
         } catch(err) {
           toast.error("Error")
         }
         
       }

const handleChageFProvinceId =(event)=> {
   setfirstProvinceId(event);
}
const handleChageSProvinceId =(event)=> {
   setsecProvinceId(event);
}
const handleChangIdentityId = (event) =>{
//const data = { ...stateData.data};
//const errors = { ...stateData.errors};

setdrivenId(event);
//console.log(event)
 //console.log(data,event)
// if ( event.name in data) {

// const errorMessage = validateProperty(event);
//   if (errorMessage)
//     errors[event.name] = errorMessage;
//    else 
//     delete errors[event.name];
//     data[event.name] = event.value;
// if (errorMessage && !event.value ==='') return;
//  setdrivenId(event.value);
//  //console.log(event.value)
//  }
}

const onDrivenNameChange =(event) => {
setdrivenName(event);
}
const onDrivenTelChange =(event) => {
setdrivenTel(event);
}
const onAimIdChange =(event) => {
   setaimId(event);
}
 const handleChangdoId = (event) => {
      setdoId(event);
   }
const onf1Change = (event) => {
   setf1(event);
}
   const onf2Change = (event) => {
      setf2(event);
   }
   const ons1Change = (event) => {
      sets1(event);
   }
   const ons2Change = (event) => {
      sets2(event);
   }
   function handleChangeQueueDate(event){
      event =   setStartDate(event);
   }

   

const handleOnsave =() =>{

   const data = { ...stateData.data};
   const errorMessage = validate();
   if (errorMessage) {
      setstateData({data,errors: { enable: false, message: errorMessage }});

   }


if (custypeId ==="00"){
      setmessageErros( { askAlert: {enable: true, message: "ยังไม่ระบุประเภทลูกค้า\nกรุณาระบุก่อนค่ะ"}})
      return;
}else
if (customerId===0){
      setmessageErros( { askAlert: {enable: true, message: "ยังไม่ระบุชื่อลูกค้า\nกรุณาระบุก่อนค่ะ"}})
      return;
}else
if (drivenId===""){
   setmessageErros( { askAlert: {enable: true, message: "ยังไม่ระบุเลขบัตรคนขับ\nกรุณาระบุก่อนค่ะ"}})
   return;
}else if (drivenName===''){        
   setmessageErros( { askAlert: {enable: true, message: "ยังไม่ระบุชื่อคนขับ\nกรุณาระบุชื่อคนขับก่อนค่ะ"}})
   return;
 }else if (drivenTel===''){        
   setmessageErros( { askAlert: {enable: true, message: "ยังไม่ระบุเบอร์ติดต่อคนขับ\nกรุณาระบุเบอร์ติดต่อก่อนค่ะ"}})
   return;
 }else if (doId===''){        
   setmessageErros( { askAlert: {enable: true, message: "ยังไม่ระบุเลขที่เอกสาร\nกรุณาระบุเลขที่เอกสารก่อนค่ะ"}})
   return;
 }else
if (productTypeId ==0 || productTypeId == null){
      setmessageErros( { askAlert: {enable: true, message: "ยังไม่ระบุประเภทสินค้า\nกรุณาระบุก่อนค่ะ"}})
      return;
}else
if (productId ==0 || productId == null){
   setmessageErros( { askAlert: {enable: true, message: "ยังไม่ระบุสินค้า\nกรุณาระบุก่อนค่ะ"}})
   return;
}else
if (aimId ==="00"){
   setmessageErros( { askAlert: {enable: true, message: "ยังไม่ระบุวัตถุประสงค์\nกรุณาระบุก่อนค่ะ"}})
   return;
}else
if (truckId ==="0" || !truckId ) {
   setmessageErros( { askAlert: {enable: true, message: "ยังไม่ระบุประเภทรถ\nกรุณาระบุก่อนค่ะ"}})
   return;
}else

if (f1 ===""){
   setmessageErros( { askAlert: {enable: true, message: "ยังไม่ระบุทะเบียนรถ\nกรุณาระบุก่อนค่ะ"}})
   return;
}else
if (f2 ===""){
   setmessageErros( { askAlert: {enable: true, message: "ยังไม่ระบุทะเบียนรถ\nกรุณาระบุก่อนค่ะ"}})
   return;
}else
if (firstProvinceId ===0){
   setmessageErros( { askAlert: {enable: true, message: "ยังไม่ระบุจังหวัดทะเบียนรถ\nกรุณาระบุก่อนค่ะ"}})
   return;
}
else
if (storeId ===0){
   setmessageErros( { askAlert: {enable: true, message: "ยังไม่ระบุสถานที่ลง\nกรุณาระบุก่อนค่ะ"}})
   return;
}else
if (times ==="" || times === undefined){
   setmessageErros( { askAlert: {enable: true, message: "ยังไม่ระบุเวลาเข้า\nกรุณาระบุก่อนค่ะ"}})
   return;
} 
 else    

{
   
   const d1 = new Date(nowDate);
   const d2 = new Date(startDate);
  // console.log(d1,d2);
   if (d1.getDate() > d2.getDate()  &&  d1.getMonth() === d2.getMonth()  && d1.getyear() === d2.get.getyear ()) {
     
      setNowDate(Date);
      setmessageErros( { askAlert: {enable: true, message: "ระบุวันที่ไม่ถูกต้อง\nกรุณาระบุวันที่ถูกต้องค่ะ"}})
      return;
   } 
   // else if (d1.getDate() === d2.getDate()  &&  d1.getMonth() === d2.getMonth()  && d1.getyear() === d2.get.getyear ()) {

   // }

}


     
      setmessage("ท่านยืนยัน ที่ทำการบันทึกรายการใช่หรือไม่")
      setaskConfirm(true); 
    
   }
   const handleChangeProductType=(event) =>{
   setProductTypeId(event);
   };
   const handleChangeTruck=(event) =>{
      settruckId(event);   
      };
   const handleChangeProduct=(event) =>{
   setproductId(event);     
         };

      const handleChangeCustypeID =(event) =>{
         setcustypeId(event);
            }
      const handleChangeStore=(event) =>{
         setstoreId(event);
            };
      
      const handleChangeCustomer = (event) => {
         setcustomerId(event);
      };
   const handleChangetimes =(event) => {
       settimes(event);     
   }

 const handleConfirmSubmit = () => {
     doSubmit();
   setaskConfirm(false);
      }
  const   handleCancleSubmit = () => {
     setaskConfirm(false);
      }   
const validateProperty = ({ name, value }) => {
        // console.log(value);
         try {
           const obj = { [name]: value };
           const schema = { [name]: schema1[name] };
           const options = { abortEarly: true };
           const { error } = Joi.validate(obj, schema, options);
           return error ? error.details[0].message : null;
         } catch(err) {
           // console.log('validateProperty', name, value, err);
       
           return err.message;
         }
         
       };

      const   validate = () => {

          const allData = {DO:doId , identityId:drivenId,drivenName,drivenTel};

        // console.log('all data',allData)
         const options = { abortEarly: false };
         const joiResult = Joi.validate(allData, schema1, options);
              const { error } = joiResult;
           //   console.log(error)
         if (!error) { 
           
         } else {
           const errors = {};
           for (let item of error.details) {
             errors[item.path[0]] = item.message;
           }
           
 
           return errors;
         }  
        
       };

       const handleProOption1 =(event) => {
         setoption1(event);
       }
       const handleProOption2 =(event) => {
         setoption2(event);
       }
    const handlePopupAlertClick = () => {
      setmessageErros( { askAlert: {enable: false, message: ""}})     
       }

const handleChangRemark =(event)=>{
   setremark(event)
}

     
  if (!(currentBranch)  || (!vForm1) ||  (currentBranch.currentBranch  && (currentBranch.currentBranch._id===''|| currentBranch.currentBranch._id==null)) || ((alldata&& alldata.length ===1) && Id==='new') || (!Id)  ){
   return(
      <p>

      </p>
   );
  }  
  if (currentBranch._id == 'vms'|| currentBranch._id =='0'){
   return <>
   </>
 }
      return(

      <div className="content-wrapper">
        <div className="row"  >
           <div className="col-12 col-sm-6 col-md-6" >
              
         <PopupAlert isOpen={messageErros.askAlert.enable} onOk={handlePopupAlertClick} message={messageErros.askAlert.message}  />
         <ConfirmSubmit 
         isOpen = {askConfirm} 
         onConfirm={handleConfirmSubmit} 
         onCancel={handleCancleSubmit} 
         message={message} 
         button1 = "OK"
         />  
        <CustomerType 
        id="CustTypeID" 
         head="ข้อมูลลูกค้า"  
         typename="ประเภทลูกค้า" 
        
         name="ชื่อลูกค้า"  
         drivenno="เลขบัตรประชาชน(คนขับ)" 
         drivenname="ชื่อคนขับ"
         registerError={stateData.errors.message}
         // customerTypes={customerType}
         handleChangeCustype = {handleChangeCustypeID}
         paramsMatchId={Id}
         onChange={handleChangIdentityId}
         allEditData={alldata}
         onDrivenNameChange ={onDrivenNameChange}
         onDrivenTelChange = {onDrivenTelChange}
  
         handleChangeCustomer={handleChangeCustomer}

         />       
        </div>
       <div className="col-12 col-sm-6 col-md-6" > 
        <Productcard  
        head="ข้อมูลสินค้า" 
        id="productid" 
        typename="ประเภทสินค้า" 
         proname="ชื่อสินค้า" 
         DO="ใบนำส่ง"
         labelProOption1="น้ำหนักต้นทาง(กก.)"
         labelProOption2="PO ต้นทาง"
         aimname="วัตถุประสงค์"
        
         registerError={stateData.errors.message}

         handleChangeProductType = {handleChangeProductType}
         handleProOption1 ={handleProOption1}
         handleProOption2 ={handleProOption2}
         handleChangeProduct = {handleChangeProduct}
         handleChangeAim={onAimIdChange}
         onChange={handleChangdoId}       
         allEditData={alldata}
         paramsMatchId={Id}

         />
        </div>
        <div className="col-12 col-sm-6 col-md-6">        
        <Truckcard id="truckid" 
          head="ข้อมูลรถ"
          typename="ประเภทรถ"  
          firstdriven="ทะเบียนรถ"        
          handleChageFProvinceId={handleChageFProvinceId}
          secdriven="ทะเบียนหาง" 
           handleChageSProvinceId = {handleChageSProvinceId}
           paramsMatchId={Id}
           allEditData={alldata}
           handleChangeTruck = {handleChangeTruck}
           onf1Change={onf1Change}
           onf2Change={onf2Change}
           ons1Change = {ons1Change}
           ons2Change= {ons2Change}
           />  
         </div>
         
         <div className="col-12 col-sm-6 col-md-6">    
        <Queuecard id="id1" 
        head="ข้อมูลการจอง" 
         typename="วันที่ลงสินค้า"         
         onChange={handleChangeQueueDate}
         allEditData={alldata}
         selected={startDate}
         onchangremark ={handleChangRemark}
         
         handleChangequeuetime = {handleChangetimes}
         paramsMatchId={Id}
          name="เวลา"
           identity="ทะเบียนหาง" 
           store="สถานที่ลง"
           handleChangeStore = {handleChangeStore}
           />  

         </div>
        
   </div>
   <div className ="card">
   <button 
   disabled = {validation}
   className="btn btn-primary" onClick={handleOnsave} >Save  </button>
   </div>
   </div>
      );
   }


export default Register;