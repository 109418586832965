import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";

import Input from "./../wms/input";
import Select from "./../wms/select";
import Header from "./headersite";
import BUHeader from "./headerbusiness";
import BranchContext from "./../../components/context/branch";
import { getEmployees,getcompanyByBU,getDepertment } from "./../../services/facescan";
import UserInfo from "./userinfo";
import ScrollToTop from "./../common/ScrollToTop";
import ProgressBar from './../common/progressbar';

const Employee = () => {
  const [name, setname] = useState("");
  const [siteid, setsiteid] = useState(0);
  const [BUid, setBUid] = useState(0);
  const [comid, setcomid] = useState(0);
  const [departId, setdepartId] = useState("0");
  const [hasMore, setHasMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false); 
  const [pageSize, setPageSize] = useState(15);
  const [total, setTotal] = useState(0);
  const [userlist, setuserlist] = useState([]);
  const [companylist, setcompanylist] = useState([]);
  const [departmentlist, setdepartmentlist] = useState([]);
  const { currentBranch } = useContext(BranchContext);
  const [search, setsearch] = useState("");
  const [showonscroll, setshowonscroll] = useState(false)
  const [showprogress, setshowprogress] = useState(false)
  const [completed, setcompleted] = useState(50)
  

  useEffect(() => {
    if (!currentBranch) return;

    setuserlist([]);
  }, [currentBranch,BUid,comid,departId, pageSize, search, siteid,showonscroll]);



  useEffect(() => {
    if (!currentBranch) return;

    const loadData = async () => {
      const { data: result } = await getcompanyByBU(
        BUid
      );
      if (result.status === "success") {
        setcompanylist(result.data.list);
      }
    };
    loadData();
  }, [currentBranch, BUid]);

  useEffect(() => {
    if (!currentBranch) return;

    const loadData = async () => {
      const { data: result } = await getDepertment(
        comid
      );
      if (result.status === "success") {
        setdepartmentlist(result.data.list);
      //  console.log(result.data.list);
      }
    };
    loadData();
  }, [currentBranch, comid,BUid]);

  useEffect(() => {
    if (!currentBranch) return;
   
    if (!pageSize || !pageNumber) return;
    setLoading(true);
   setshowprogress(true)
    const loadData = async () => {
      const { data: result } = await getEmployees(
        search,
        siteid,
        pageSize,
        pageNumber,
        BUid, encodeURIComponent(departId),comid
      );
      if (result.status === "success") {
        setTotal(result.data.total);
        setshowprogress(false)
       
        setuserlist((prevData) => {
          return [...new Set([...prevData, ...result.data.list])];
        });
        setHasMore(result.data.list.length > pageSize - 1);
        setLoading(false);
      }
    };
    loadData();
  }, [currentBranch, search, pageSize, pageNumber, siteid,showonscroll,BUid,comid,departId]);

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
     // var n = ReactDOM.findDOMNode(node);

     if (node && node.offsetTop > 0){
      setshowonscroll(true);
     }
   //    console.log(node && node.offsetTop);
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
       

        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
         // node.current.scrollIntoView({ behavior: 'smooth'})
       //   observer.current.scrollIntoView({ behavior: 'smooth' })
     
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );


  // if (observer.current){
  //   var n = ReactDOM.findDOMNode(observer.current);
  //   //const { offsetTop } = observer.current
  // console.log(n);
  // }
  
   const scrollToTop = () => { 
     setPageNumber(1);
     setshowonscroll(false);
   }

  // const scrollToTop =() =>{
  //   console.log("you click on top")

  //     window.scrollTo({
  //       top: 0,
  //       behavior: "smooth"
  //     });

  //     window.scroll({
  //       top: 0,
  //       left: 0,
  //       behavior: 'smooth',
  //     });
  //   }

//   var see = function() {
//     var ypos = window.pageYOffset;

//   //  console.log(ypos);
//         if (ypos > 30) {
    
//       //  x.style.opacity = '0.4';
    
//         } else {
    
//             alert('scrolled more than 30 !!');
//         }
//     }
    
// window.addEventListener("scroll", see);
// console.log(window.pageYOffset);

  const handleTextchange = (event) => {
    setname(event.currentTarget.value);
  };
  const handleSearchClick = () => {
    setsearch(name);
    setPageNumber(1);
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      setsearch(name);
      setPageNumber(1);
    }
  };
  const handleChange = (event) => {
    setsearch(name);
    setsiteid(event);
    setPageNumber(1);
  };

  const hadleBUChang = (event) => {
    setBUid(event);
    setsearch(name);
    setcomid(0);
    setPageNumber(1);
  }

  const handleCompanyChange = (event) => {
  //  console.log(event);
    setcomid(event.currentTarget.value)
    setsearch(name);
    setPageNumber(1);
  }
  const handleDepartmentChange = (event) => {

   setdepartId(event.currentTarget.value);
   setsearch(name);
   setPageNumber(1);

  }
  const handleOnClick = (event) => {};

  if (currentBranch._id !=='0'){
    return <></>
  }

  return (
    <div>
      <div className="row">
        <div className="col-lg-4  col-sm-6 col-xs-12">
          <div className="form-group">
            <Input
              name="name"
              value={name}
              label="ชื่อ พนักงาน"
              placeholder="ระบุชื่อ-สกุล-รหัสพนักงาน เพื่อค้นหา"
              onChange={handleTextchange}
              onClick={handleSearchClick}
              onKeyPress={handleKeyPress}
              search="ค้นหา"
            />
          </div>
        </div>

        <div className="col-lg-3  col-sm-6 col-xs-12">
          <div className="form-group">
            <i>
              Payroll Site <span className="label label-default"></span>
            </i>
            <div className="mt-2" style={{ marginTop: 20, marginBottom: 20 }}>
              <Header onChange={handleChange} />
            </div>
          </div>
        </div>

        <div className="col-lg-5  col-sm-6 col-xs-12">
          <div className="form-group">
            <i>
              กลุ่มธุรกิจ <span className="label label-default"></span>
            </i>
            <div className="mt-2" style={{ marginTop: 20, marginBottom: 20 }}>
              <BUHeader onChange={hadleBUChang} />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
      <div className="col-lg-6  col-sm-6 col-xs-12">
          <div className="form-group">

          <Select
                label="บริษัท"
                data={companylist}
                value= {comid}
                textProperty="name"
                valueProperty="_id"
                onChange={handleCompanyChange}
              />
            </div>
      </div>

      <div className="col-lg-6  col-sm-6 col-xs-12">
          <div className="form-group">

          <Select
                label="แผนก"
                data={departmentlist}
                value= {departId}
                textProperty="name"
                valueProperty="id"
                onChange={handleDepartmentChange}
              />
            </div>
      </div>
            </div>
      <p className="text-success">
        แสดงข้อมูล {userlist ? userlist.length : 0} / {total} รายการ
      </p>
      { showprogress && <ProgressBar bgcolor={"#6a1b9a"} completed={completed} />}
      {pageNumber > 1 && showonscroll &&
        <ScrollToTop 
        onClick ={scrollToTop}
        />}

      <UserInfo
        list={userlist}
        handleOnClick={handleOnClick}
        error={error}
        search={true}
        lastBookElementRef={lastBookElementRef}
      
      />
    </div>
  );
};

export default Employee;
