import http from "./httpService";

export function saveRegister(doc) {
    // if (doc._id && (!doc._id=='new')) {
    //   console.log('update',doc);
    //   //Update
    //   // const body = { ...doc };
    //   // delete body._id;
    //   // return http.put("/queue/register/" + doc._id, body);
    // } else {
    //  console.log('post',doc);
      //Create
      return http.post("/queue/RegisterOnlineReact", doc);
   // }
  }

  