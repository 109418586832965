import React from 'react';
import FontAwesome from "react-fontawesome";
const RederPermitlist = ({
    lastBookElementRef,
    list,
    snackMessage,
    handleOnClick,
    handleChangeToggle


  }) => {
     // console.log(list)
    return (  
<>
      {list &&
        list.map((item, index) => {
          if (list.length === index + 1) {
            return (
              <div className="row" key={item.no}>
                <div className="container-fluid">
                  <div className="card border-secondary m-1">
                  <div className="card-header bg-info" style={{ height: "14rm",fontSize: "14px" }}>
                   {item.no}
                  </div>
                    <div className="card-body">
                      <div ref={lastBookElementRef} className="row">
                        <div className="container col-xs-12 col-sm-4">
                      
                          <div className="border border-top-0 border-right-0 border-left-0" style={{ fontSize: "14px" }}>
                            
                            {"วันเวลาขอเข้า:"} {item.timein} 
                          
                          </div>
                          <div className="border border-top-0 border-right-0 border-left-0" style={{ fontSize: "14px" }}>
                           
                            {"วันเวลาขอออก:"} {item.timeout}
                          
                          </div>
                          <div className="border border-top-0 border-right-0 border-left-0" style={{ fontSize: "14px" }}>
                          {"ประตูที่ขอ: "} <span className="badge badge-success" style={{ fontSize: "12px" }}> {item.gatename}  </span>
                          </div>
                         
                        </div>
                        <div className="container col-xs-12 col-sm-4">
                        <div className="border border-top-0 border-right-0 border-left-0" style={{ fontSize: "14px" }}>
                            {"เหตผล :"}
                          
                            <span className="text-primary" style={{ fontSize: "14px" }}> {item.remark}    </span>  
                         
                          </div>
                          <div className="border border-top-0 border-right-0 border-left-0" style={{ fontSize: "14px" }}>
                          
                        
                          {"คนขับ : "} {item.drivenname} 
                           
                          </div>
                          <div className="border border-top-0 border-right-0 border-left-0" style={{ fontSize: "14px" }}>
                        
                          {"ผู้ทำรายการ : "} {item.recordby}   
                          
                          </div>
                        </div>
                        <div className="container col-xs-12 col-sm-4">
                        <div className="border border-top-0 border-right-0 border-left-0" style={{ fontSize: "14px" }}>
                          {"ประเภทรถ: "} {item.cartypename}  
                     
                          </div>
                          <div className="border border-top-0 border-right-0 border-left-0" style={{ fontSize: "14px" }}>
                          {"ทะเบียน : "} {item.firstDrivenno}  {"  "} {item.SecDrivenno}
                         
                          </div>

                         
      <div>   
        { item.AproveStatus ===1 ? <span className="badge badge-warning"> รออนุมัติ </span> : item.AproveStatus ===2 ? <span className="badge badge-success"> อนุมัติ </span> : <span className="badge badge-danger"> ไม่อนุมัติ</span>

        }

<div className="d-flex justify-content-end"> 
 <button 
                          className="btn btn-success btn-sm"
                          onClick={() => handleOnClick(item.face_id, "edit")}
                        >
                      <FontAwesome name="edit"/>  
                               
                        </button>
</div>
</div> 
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          } else {
            return (
              <div className="row" key={item.no}>
                <div className="container-fluid">
                  <div className="card border-secondary m-1">
                  <div className="card-header bg-info" style={{ height: "14rm",fontSize: "14px" }}>
                   {item.no}
                  </div>
                    <div className="card-body">
                      <div className="row">
                      <div className="container col-xs-12 col-sm-4">
                      
                      <div className="border border-top-0 border-right-0 border-left-0" style={{ fontSize: "14px" }}>
                        
                        {"วันเวลาขอเข้า:"} {item.timein} 
                      
                      </div>
                      <div className="border border-top-0 border-right-0 border-left-0" style={{ fontSize: "14px" }}>
                       
                        {"วันเวลาขอออก:"} {item.timeout}
                      
                      </div>
                      <div className="border border-top-0 border-right-0 border-left-0" style={{ fontSize: "14px" }}>
                      {"ประตูที่ขอ: "} <span className="badge badge-success" style={{ fontSize: "12px" }}> {item.gatename}  </span>
                      </div>
                     
                    </div>
                    <div className="container col-xs-12 col-sm-4">
                    <div className="border border-top-0 border-right-0 border-left-0" style={{ fontSize: "14px" }}>
                        {"เหตผล :"}
                      
                        <span className="text-primary" style={{ fontSize: "14px" }}> {item.remark}    </span>  
                     
                      </div>
                      <div className="border border-top-0 border-right-0 border-left-0" style={{ fontSize: "14px" }}>
                      
                    
                      {"คนขับ : "} {item.drivenname} 
                       
                      </div>
                      <div className="border border-top-0 border-right-0 border-left-0" style={{ fontSize: "14px" }}>
                    
                      {"ผู้ทำรายการ : "} {item.recordby}   
                      
                      </div>
                    </div>
                    <div className="container col-xs-12 col-sm-4">
                    <div className="border border-top-0 border-right-0 border-left-0" style={{ fontSize: "14px" }}>
                      {"ประเภทรถ: "} {item.cartypename}  
                 
                      </div>
                      <div className="border border-top-0 border-right-0 border-left-0" style={{ fontSize: "14px" }}>
                      {"ทะเบียน : "} {item.firstDrivenno}  {"  "} {item.SecDrivenno}
                     
                      </div>

                     
  <div>   
    { item.AproveStatus ===1 ? <span className="badge badge-warning"> รออนุมัติ </span> : item.AproveStatus ===2 ? <span className="badge badge-success"> อนุมัติ </span> : <span className="badge badge-danger"> ไม่อนุมัติ</span>

    }

<div className="d-flex justify-content-end"> 
<button 
                      className="btn btn-success btn-sm"
                      onClick={() => handleOnClick(item.face_id, "edit")}
                    >
                  <FontAwesome name="edit"/>  
                           
                    </button>
</div>
</div> 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
            );
          }
        })}
    </>

    );
}
 
export default RederPermitlist;