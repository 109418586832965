import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const ConfirmSubmit = ({isOpen, onCancel, onConfirm, list,onSelected}) => {
  //if (!list) return;
 // console.log('test',list);
    return ( 
     
        <div className ="card m-1"   >
        <Modal isOpen={isOpen} toggle={onCancel} >
          <ModalHeader>กรุณาเลือกสาขา</ModalHeader>
          <ModalBody>
        <div className="card-body col-auto mb-1" >
        <ul className="list-group">
    {list && list.map(item=>
    <li className="list-group-item d-flex justify-content-between align-items-center my-1"  
    key={item._id} >{item.name}
     <button name={item._id} value={item.name}
    className='btn btn-primary m-1' 
    onClick={onSelected} >select</button>
     </li>
     
     )}
     </ul>
       </div>
          </ModalBody>
          <ModalFooter>
          <Button color="secondary" onClick={onCancel}>ยกเลิก</Button>
         
          </ModalFooter>
        </Modal>
      </div>
     
     );
}
 
export default ConfirmSubmit;