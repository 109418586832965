import http from "../httpService";

const apiEndpoint = "https://api-idms.advanceagro.net/hrms/employee/search";

function search(search, index=0, row=10) {
    const encodedSearch = encodeURI(search);
    return http.get(apiEndpoint + `/${encodedSearch}/?index=${index}&row=${row}`);
}


export default {
    search
}