
import React from 'react';

const Input = ({ name, id, value, onChange,width,placehoder,maxlength,columns ,search}) => {
    const handleOnclick =()=>{

    }
    return (
        
        <div className={columns}>
            <input id= {id} type="text" className="form-control"
                data-contenttarget="#locationLayer" 
                maxLength={maxlength}
                data-container="body"
                data-contentheader={placehoder}
                data-placement="right"
                data-toggle="popover"
                value={value}
                onChange={onChange}
                placeholder={placehoder} 
                data-popover-width={width}
                htmlFor={name}
            />

            {search && <div className="input-group-addon input-group-button col-auto mb-1 px-1">
                <button type="button" 
                    id="show-contact-modal-button" 
                        className="btn btn-primary"
                        onClick={handleOnclick}
                         >
    
    {search}
    </button>
  </div>}
        </div>
      
    );
}

export default Input;