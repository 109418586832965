import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import { Link } from 'react-router-dom';
// import { toast } from 'react-toastify';

class SearchItem extends Component {
  _isMounted = false;

  state = { 
    data: {}
  }

  componentDidMount() {
    this._isMounted = true;

    const data = this.props.item;
    this.setState({ data });
  }

  componentWillUnmount() { this._isMounted = false; }

    render() { 
      const { id, name, sim, email, position, department, company, status  } = this.state.data;
        
      return ( 
        <React.Fragment>
          <div className="card mb-3" style={{width: '100%'}}>
              <div className="card-body py-2 pt-3">
                  <div className="row align-items-center">
                      <div className="col-auto text-center mb-2">
                          <img className="rounded-circle" style={{width: "80px"}} src={`https://api-idms.advanceagro.net/hrms/employee/${id}/photocard/?size=100`} alt="..." />
                      </div>
                      <div className="col-auto mb-2">
                          <div className="container">
                            <h5 className="card-title d-inline text-primary">{name}</h5>
                            {sim && <span className="card-text ml-4"><FontAwesome name="phone-square" /> {sim}</span>}
                            {email && <span className="card-text ml-4"><FontAwesome name="envelope" /> {email}</span>}
                            <h4 className="d-inline ml-4"><span className={`badge badge-${status ? 'success' : 'secondary'}`}>{status ? 'active' : 'inactive'}</span></h4>
                          </div>
                          <div className="container mt-2">
                            <h6 className="card-text d-inline mr-4"><FontAwesome name="building" /> {company}</h6>
                            {position && <h6 className="card-text d-inline mr-4"><FontAwesome name="briefcase" /> {position}</h6>}
                            {department && <h6 className="card-text d-inline mr-4"><FontAwesome name="briefcase" /> {department}</h6>}
                            <h6 className="card-text d-inline mr-4"><FontAwesome name="user" /> {id}</h6>
                          </div>
                      </div>
                  </div>
                  <Link className="stretched-link" to={`/report/team/${id}`}></Link>
              </div>
          </div>
        </React.Fragment>
        
        );
    }
}
 
export default SearchItem;