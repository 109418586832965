import React,{useState,useEffect} from 'react';
// import SearchBox from '../wms/searchbox';
// import Pagination from '../menu/pagination';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


const ModalSelect = ({isOpen, onCancel,  list,onSelected,label,name,
value,textProperty,valueProperty,placeholder="Search"}) =>{
// let count =0;
//   const [page, setpage] = useState({ 
//     totalCount:0,
//     currentPage: 1,
//     pageSize: 15});

    const [searchQuery, setsearchQuery] = useState('');
    const [alldata, setalldata] = useState([]);

       useEffect(() => {
        const LoadData = () =>{
          setalldata(list);

          // setpage({totalCount:list.length,pageSize:15,currentPage:1
          // });
         }
         LoadData();
        }, [list]);

 
  const getSearchData = () =>{
  let filtered = list;
  if (searchQuery){
    filtered = alldata.filter(item => item[textProperty].includes(searchQuery) )
    setalldata(filtered)
  }else {
    setalldata(list)
  }
 
  }
   
 const handleSearch = (event) => {
   setsearchQuery(event.target.value);
 //  console.log('key ',event.key)
    getSearchData()
    // if(event.key === 'Enter'){
    //   console.log('enter press here! ')
    // }
    
  };
    
 const handleKeyPress = (event) => {

 // console.log('key ',event.key)
    if(event.key === 'Enter'){

      if(searchQuery==''){
        getSearchData();
      }
    //  console.log('enter press here! ')

    }
  }
if (!isOpen){
  return(
    <div></div>
  )

}

return (



    <div className ="card m-1">
    <Modal isOpen={isOpen} toggle={onCancel} >
      <ModalHeader>{label}</ModalHeader>
      <div className="col-12 col-md-10 col-lg-8 mb-1">
      <div className='mt-2' style={{ marginBottom :1 }} >

    <div className="list-group-item d-flex justify-content-between align-items-center my-1">

 <input type="text" name="query" 
    className="form-control  container-fluid" 
    placeholder={placeholder}
    value = {searchQuery} 
    onChange={handleSearch}
    onKeyPress ={handleKeyPress}
     />
  
       {/* <SearchBox
       value = {searchQuery}
       onchange ={handleSearch}
        />   */}
     

  <Button color="secondary" onClick={onCancel}>ยกเลิก</Button>  
</div>
</div>
    </div>

    {/* { <Pagination
        itemsCount={list.length}
        pageSize={page.pageSize}
        currentPage={page.currentPage}
        onPageChange={handleChange}        
         />} */}
     
      
    
      <ModalBody>
    <div className="card-body" >
    <ul className="list-group">
{alldata && alldata.map(item=>
<li className="list-group-item d-flex justify-content-between align-items-center my-1"  
key={item[valueProperty]}
>
  {item[textProperty]}
 <button name={item[textProperty ]} 
 value={item[valueProperty]}
className='btn btn-primary m-1' 
onClick={onSelected} >select</button>
 </li>
 
 
 )
 
 }


 </ul>
 <div>
 
 </div>
   </div>
    
      </ModalBody>

    
      <ModalFooter>
      <Button color="secondary" onClick={onCancel}>ยกเลิก</Button>   
      </ModalFooter>
    </Modal>
  </div>
 
 );
}
ModalSelect.defaultProps ={
  textProperty:'name',
  valueProperty:'_id'}
export default ModalSelect;