import React,{useState} from 'react';
import RenderButton from '../common/button';
import DatePicker from "react-datepicker";


const Headder = ({onChange}) => {

    const [all, setall] = useState('btn btn-primary');
    const [aa, setaa] = useState('btn btn-outline-dark');
    const [e85, sete85] = useState('btn btn-outline-dark');
    const [gate5, setgate5] = useState('btn btn-outline-dark');
    
    const [startDate, setStartDate] = useState(new Date());

    const handleonClick = ({ currentTarget: input }) => {
        if (input.value==0){
            disabledlabel();
            setall('btn btn-primary'); 
        }else if (input.value==1){
            disabledlabel();
            setaa('btn btn-primary'); 
        }else if (input.value==2){
            disabledlabel();
            sete85('btn btn-primary'); 
        }else if (input.value==3){
            disabledlabel();
            setgate5('btn btn-primary'); 
        }
        onChange(input.value);
    }
  


const disabledlabel = () => {
    setall('btn btn-outline-dark')
    setaa('btn btn-outline-dark') ;
    sete85('btn btn-outline-dark') ;
    setgate5('btn btn-outline-dark') ;
}



    return ( 
<div className='container'>
            <div className="row">
              {/* <div className='m-4'>
                 
                <div className='mt-2' style={{ marginTop: 20, marginBottom: 20 }} >
                  <DatePicker
                    todayButton="Today"
                    dateFormat="dd/MM/yyyy"
                    selected={startDate}
                    onChange={handleChange} />
                </div>

              </div>    */}

              <div className="row justify-content-end align-items-center">
                  <div className="col-auto mb-3 px-0"><ul className="btn-group p-0 m-0 ">
                     <RenderButton 
                     label ="All"
                     value="0"
                     onClick ={handleonClick}
                     className = {all}
                      />
                    <RenderButton 
                     value="1"
                     label ="AA-Wood"
                     onClick ={handleonClick}
                     className = {aa}
                      />
                         <RenderButton 
                           value="2"
                          label ="E85"
                     onClick ={handleonClick}
                     className = {e85}
                      />
                        <RenderButton 
                           value="3"
                          label ="Gate 5"
                     onClick ={handleonClick}
                     className = {gate5}
                      />

                    
                      </ul>
                      </div>
                      </div>


            </div>
          </div>

     );
}
 
export default Headder;

