import React, { useState, useEffect,useContext } from "react";

//import http from "../services/httpService";
import {getSupplier} from "../../services/vms";
import BranchContext from '../context/branch';
import { Button } from "reactstrap";
//import config from "../config.json";

// import { Button, Input, Tag } from "antd";
// const { Search } = Input;




const SearchSupplier= ({ updateData,placeholder,textProperty,textProperty2,handleSave}) => {

  const {currentBranch} = useContext(BranchContext);

const [data, setData] = useState({
    search: "",
  });
  const [master, setMaster] = useState([]);
  const [alldata, setalldata] = useState([]);



  useEffect(() => {

    if (! currentBranch  ) return; 
    
    getSupplier().then (result=>{        
      setMaster(result.data); 
      setalldata(result.data) 
 //     console.log(result.data);                     
                 });
               }, 
               [])





  async function onSearchClick() {

  

    if (!data.search) {
      setMaster(alldata);
      return;
    }

    let filtered = alldata;

    if (data.search){
     // console.log(alldata);
      filtered = alldata.filter(item => item[textProperty].includes(data.search)  || item[textProperty2].includes(data.search) )
//console.log(filtered)
      setMaster(filtered)
    }else {
      setMaster(alldata)
    }
  

  }

  function handleChange({ currentTarget: input }) {
    const { name, value } = input;
    
  //  console.log("name,value=>", name, value);
    onSearchClick();
    setData((previous) => {
      return {
        previous,
        [name]: value,
      };
      

    });
  }

  // function handleOnselected(event) {
  //  // console.log(event);
  //   updateData(event);

  // }

  return (
    <div className="form-group">
      <div className="row">
        <div className="col-sm-8">
          <input   
            name="search"
            value={data.search}
            onChange={handleChange}
            type="search"
            placeholder={placeholder}
            onKeyPress ={onSearchClick}
            
          />
        </div>
      </div>
      <div className="row m-1">
        <div className="col">
          {master.length > 0 && (
            <table className="table table-hover table-responsive">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">TaxID</th>  
                 
                </tr>
              </thead>
              <tbody>
                {master.map((item) => (
                  <tr key={item.AutoID}>
                   <td  style={{ fontSize: "13px" }} >{item.ComIdentityID} </td> 
                   
                    {/* <td>{highlight(item.empId, data.search)}</td> */}
                    {/* <td>
                      <span className="text-info">
                       

                      </span>
                    </td> */}
                    <td>
                      <div className="form-group">
                        <div className="col-sm">
                          <div className="row">
                          <small className="small font-weight-lighter text-muted">
                           {item.ComName}
                           </small>
                          </div>

                          <div className="row">
                            <small className="small font-weight-lighter text-muted">
                              {item.ComTelephone}
                            </small>
                          </div>
                          <div className="row">
                            <small className="small font-weight-lighter text-muted">
                              {item.supplier_type}
                            </small>
                          </div>
                        </div>
                      </div>
                    </td>
                
                    <td>
                    
                        {/* <Button
                          name={item.AutoID}
                          type="success"
                          onClick={handleOnselected}
                        >
                          select
                        </Button> */}

                          <button
                          className="btn btn-success"
                          onClick={() => handleSave(item.AutoID)}
                      
                        > 
                        Save
                        </button>

                      
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};


// }
SearchSupplier.defaultProps ={
  textProperty:'name',
  valueProperty:'_id'}
export default SearchSupplier;
