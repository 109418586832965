import React, { useState, useContext,useEffect } from "react";
import { NavLink, withRouter } from "react-router-dom";
import FontAwesome from "react-fontawesome";
import PrivilegeContext from "../context/privilege";
import BranchContext from "../context/branch";
import dlv from 'dlv';
//import {getPrivilege} from './../../services/wms/privileg';

const SideNavBar = (props) => {
  const { currentBranch } = useContext(BranchContext);
  const privilegeContext = useContext(PrivilegeContext);

  const [collapsed, setcollapsed] = useState(true);
  const [chkHR, setchkHR] = useState(false);
  const [submenu, setSubmenu] = useState({});

  //const ojb = privilegeContext.privilege;
  


  useEffect(() => {
    const loadRegister = async () => {
      try {
        if (!privilegeContext.privilege) return;
        if (privilegeContext.privilege.length ===18){
         //console.log( privilegeContext.privilege[17]) 
         setchkHR(privilegeContext.privilege[17].chkEnable)
        }
       
      } catch (ex) {
       
      }
    };
    loadRegister();
  }, [privilegeContext.privilege]);




  const handleSubMenuClick = (item) => {
    const sub = { ...submenu };
    const toggle = sub[item] ? !sub[item] : true;
    sub[item] = toggle;

    setSubmenu(sub);
  };

  const toggleNavbar = () =>
    setcollapsed({ collapsed: !setcollapsed(collapsed) });
  const hide = () => {
    const { onSidebarToggle } = props;
    if (typeof onSidebarToggle === "function") {
      onSidebarToggle();
    }

    setcollapsed(true);
  };

  const { pathname } = props.location;
  // console.log(privilegeContext.privilege);
  // console.log(currentBranch);
  if (!privilegeContext.privilege[0] || !currentBranch) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="sidebar sidebar-sticky bg-dark text-white">
        <ul className="nav flex-column">



          {
            /* <div class="wrapper">
          <div className="sidebar">
				<div className="sidebar-menu"> */
            /* <center className="profile">
						<img src="https://api-idms.advanceagro.net/hrms/employee/29279/photocard/?size=50" alt=""/>
						{/* <p>Jessica  {"test "} </p> */
            // </center>
            /* </div>
          </div>
          </div> */
          }
        </ul>

        {(
          <h4 className="sidebar-heading px-3 m3-4 mb-1">ข้อมูลทั่วไป</h4>
        )}
     
     {/* {currentBranch._id == "0" &&
          (
            <ul className="nav flex-column mb-2 pl-3">
              <span
                className={`nav-item nav-link ${
                  pathname.includes("/permitgate")  || pathname.includes("/approvedgate") ? "active" : ""
                }`}
                style={{ cursor: "pointer" }}
                onClick={() => handleSubMenuClick("")}
              >
                <FontAwesome name="clipboard" /> ระบบผ่านประตูฉุกเฉิน{" "}
                <FontAwesome name="chevron-down" />
              </span>
              {submenu[""] && (
                <ul className="nav flex-column mb-2 pl-3">
                  <NavLink
                    className="nav-item nav-link"
                    to={{ pathname: "/permitgate" }}
                    onClick={hide}
                  >
                    <FontAwesome name="list-alt" /> ขออนุมัติผ่านประตู
                  </NavLink>
                  <NavLink
                    className="nav-item nav-link"
                    to={{ pathname: "/approvedgate" }}
                    onClick={hide}
                  >
                    <FontAwesome name="th-list" /> รายการรออนุมัติ
                  </NavLink>
                </ul>
              )}
            </ul>
          )} */}

        {/* { 
          <ul className="nav flex-column mb-2 pl-3">
            {
              currentBranch._id == "0" && (
                <NavLink
                  className="nav-item nav-link"
                  to="/customer"
                  onClick={hide}
                >
                  <FontAwesome name="pie-chart" /> ขอเพิ่มใบหน้า
                </NavLink>
              )}
          </ul>

            } */}
{/* {  currentBranch._id == "0" &&
          <ul className="nav flex-column mb-2 pl-3">
            {
              currentBranch._id == "0" && (
                <NavLink
                  className="nav-item nav-link"
                  to="/faceinout"
                  onClick={hide}
                >
                  <FontAwesome name="id-card" /> Face log
                </NavLink>
              )}
          </ul>

            } */}


        {/* { currentBranch._id == "0"   && ( privilegeContext.privilege[11].chkEnable || chkHR )  && (
          <h4 className="sidebar-heading px-3 m3-4 mb-1">Face Scan HR</h4>
        )} */}

{/* {currentBranch._id == "0" && ( privilegeContext.privilege[11].chkEnable || chkHR ) &&
          (
            <ul className="nav flex-column mb-2 pl-3">
              <span
                className={`nav-item nav-link ${
                  pathname.includes("/facedevice")  || pathname.includes("/facemanage") || pathname.includes("/empprofile") ? "active" : ""
                }`}
                style={{ cursor: "pointer" }}
                onClick={() => handleSubMenuClick("facedevice")}
              >
                <FontAwesome name="clipboard" /> Management{" "}
                <FontAwesome name="chevron-down" />
              </span>
              {submenu["facedevice"] && (
                <ul className="nav flex-column mb-2 pl-3">
                  <NavLink
                    className="nav-item nav-link"
                    to={{ pathname: "/facedevice" }}
                    onClick={hide}
                  >
                    <FontAwesome name="list-alt" /> จัดการเครื่อง
                  </NavLink>
                  <NavLink
                    className="nav-item nav-link"
                    to={{ pathname: "/facemanage" }}
                    onClick={hide}
                  >
                    <FontAwesome name="th-list" /> จัดการใบหน้า
                  </NavLink>

                  <NavLink
                    className="nav-item nav-link"
                    to={{ pathname: "/empprofile" }}
                    onClick={hide}
                  >
                    <FontAwesome name="list-alt" /> ข้อมูลพนักงาน
                  </NavLink>
                </ul>
              )}
            </ul>
          )} */}


{/* {privilegeContext.privilege[11].chkEnable &&
          (
            <ul className="nav flex-column mb-2 pl-3">
              <span
                className={`nav-item nav-link ${
                  pathname.includes("/search") ? "active" : ""
                }`}
                style={{ cursor: "pointer" }}
                onClick={() => handleSubMenuClick("/search")}
              >
                <FontAwesome name="clipboard" /> รายงาน{" "}
                <FontAwesome name="chevron-down" />
              </span>
              {submenu["/search"] && (
                <ul className="nav flex-column mb-2 pl-3">
                  <NavLink
                    className="nav-item nav-link"
                    to={{ pathname: "/search" }}
                    onClick={hide}
                  >
                    <FontAwesome name="list-alt" /> ข้อมูลเครื่อง
                  </NavLink>
                  <NavLink
                    className="nav-item nav-link"
                    to={{ pathname: "/facelist" }}
                    onClick={hide}
                  >
                    <FontAwesome name="th-list" /> ข้อมูลการบันทึก
                  </NavLink>
                </ul>
              )}
            </ul>
          )} */}

        {privilegeContext.privilege[5].chkEnable &&
         ( currentBranch._id !== "vms" && currentBranch._id !== "0" ) && (
            <h4 className="sidebar-heading px-3 mt-3 mb-1">Weight Scale</h4>
          )}

        <ul className="nav flex-column mb-2 pl-3">
          {privilegeContext.privilege[6].chkEnable &&
           ( currentBranch._id !== "vms" && currentBranch._id !== "0" ) && (
              <NavLink
                className="nav-item nav-link"
                to={{ pathname: "/register/new" }}
                onClick={hide}
              >
                <FontAwesome name="book" /> ลงทะเบียนชั่งเข้า
              </NavLink>
            )}
        </ul>
        {privilegeContext.privilege[7].chkEnable &&
          ( currentBranch._id !== "vms" && currentBranch._id !== "0" ) && (
            <ul className="nav flex-column mb-2 pl-3">
              <NavLink
                className="nav-item nav-link"
                to={{ pathname: "/showdetail" }}
                onClick={hide}
              >
                <FontAwesome name="book" /> ข้อมูลออนไลน์
              </NavLink>
            </ul>
          )}

        {privilegeContext.privilege[7].chkEnable &&
         ( currentBranch._id !== "vms" && currentBranch._id !== "0" ) && (
            <ul className="nav flex-column mb-2 pl-3">
              <span
                className={`nav-item nav-link ${
                  pathname.includes("/registerlist") ? "active" : ""
                }`}
                style={{ cursor: "pointer" }}
                onClick={() => handleSubMenuClick("registerlist")}
              >
                <FontAwesome name="clipboard" /> รายงานการชั่ง{" "}
                <FontAwesome name="chevron-down" />
              </span>
              {submenu["registerlist"] && (
                <ul className="nav flex-column mb-2 pl-3">
                  <NavLink
                    className="nav-item nav-link"
                    to={{ pathname: "/registerlist" }}
                    onClick={hide}
                  >
                    <FontAwesome name="list-alt" /> ข้อมูลการชั่ง
                  </NavLink>
                  <NavLink
                    className="nav-item nav-link"
                    to={{ pathname: "/weightreport" }}
                    onClick={hide}
                  >
                    <FontAwesome name="th-list" /> สรุปการชั่ง
                  </NavLink>
                </ul>
              )}
            </ul>
          )}

        {privilegeContext.privilege[8].chkEnable &&
         ( currentBranch._id !== "vms" && currentBranch._id !== "0" ) && (
            <h4 className="sidebar-heading px-3 mt-3 mb-1"> Grab Concept</h4>
          )}

        {privilegeContext.privilege[8].chkEnable &&
           ( currentBranch._id !== "vms" && currentBranch._id !== "0" ) && (
            <ul className="nav flex-column mb-2 pl-3">
              <span
                className={`nav-item nav-link ${
                  pathname.includes("/valueslist") ? "active" : ""
                }`}
                style={{ cursor: "pointer" }}
                onClick={() => handleSubMenuClick("valueslist")}
              >
                <FontAwesome name="clipboard" /> Wood Chip{" "}
                <FontAwesome name="chevron-down" />
              </span>
              {submenu["valueslist"] && (
                <ul className="nav flex-column mb-2 pl-3">
                  <NavLink
                    className="nav-item nav-link"
                    to={{
                      pathname: "/valueslist",
                      domainid: { id: "", type: "log" },
                    }}
                    onClick={hide}
                  >
                    <FontAwesome name="list-alt" /> ไม้ท่อน
                  </NavLink>
                  <NavLink
                    className="nav-item nav-link"
                    to={{
                      pathname: "/valueslist",
                      domainid: { id: "", type: "chip" },
                    }}
                    onClick={hide}
                  >
                    <FontAwesome name="th-list" /> ไม้สับ
                  </NavLink>
                </ul>
              )}

              {privilegeContext.privilege[10].chkEnable && (
                <NavLink
                  className="nav-item nav-link"
                  onClick={hide}
                  to={{
                    pathname: "/valueslist",
                    domainid: { id: "501", type: "" },
                  }}
                >
                  <FontAwesome name="pie-chart" /> Wood AA
                </NavLink>
              )}
            {privilegeContext.privilege[10].chkEnable && (
                <NavLink
                  className="nav-item nav-link"
                  onClick={hide}
                  to={{
                    pathname: "/receivechemical"
                  }}
                >
                  <FontAwesome name="pie-chart" /> CCP
                </NavLink>
              )}
            </ul>
          )}
        {privilegeContext.privilege[16].chkEnable && (
          <ul className="nav flex-column mb-2 pl-3">
            <span
              className={`nav-item nav-link ${
                pathname.includes("/") || pathname.includes("/") ? "active" : ""
              }`}
              style={{ cursor: "pointer" }}
              onClick={() => handleSubMenuClick("")}
            >
              <FontAwesome name="list-alt" /> Wood Receive{" "}
              <FontAwesome name={"chevron-down"} />
            </span>
            {submenu[""] && (
              <ul className="nav flex-column mb-2 pl-3">
                <NavLink
                  className="nav-item nav-link"
                  to={{ pathname: "/" }}
                  onClick={hide}
                >
                  <FontAwesome name="address-book" /> รับสินค้าไม้ท่อน
                </NavLink>
                <NavLink
                  className="nav-item nav-link"
                  to={{ pathname: "/" }}
                  onClick={hide}
                >
                  <FontAwesome name="address-book" /> รับสินค้าไม้สับ
                </NavLink>
              </ul>
            )}
          </ul>
        )}

        {privilegeContext.privilege[15].chkEnable && (
          <h4 className="sidebar-heading px-3 mt-3 mb-1">Cockpit</h4>
        )}

        {privilegeContext.privilege[12].chkEnable &&
          currentBranch._id == "vms" && (
            <h4 className="sidebar-heading px-3 mt-3 mb-1">VMS</h4>
          )}

        {privilegeContext &&
          privilegeContext.privilege[13].chkEnable &&
          currentBranch._id == "vms" && (
            <ul className="nav flex-column mb-2 pl-3">
              <span
                className={`nav-item nav-link ${
                  pathname.includes("/shutdownlist") 
                  ||
                  pathname.includes("/safetypass") || 
                  pathname.includes("/chemicalcom")
                    ? "active"
                    : ""
                }`}
                style={{ cursor: "pointer" }}
                onClick={() => handleSubMenuClick("shutdownlist")}
              >
                <FontAwesome name="list-alt" />
          
        
                    งานจัดการ
           <FontAwesome name={"chevron-down"} />
           
       

                 {/* งานจัดการ{" "}
                <FontAwesome name={"chevron-down"} /> */}
              </span>
              {submenu["shutdownlist"] && (
                <ul className="nav flex-column mb-2 pl-3">
                  <NavLink
                    className="nav-item nav-link"
                    to={{ pathname: "/shutdownlist" }}
                    onClick={hide}
                  >
                    <FontAwesome name="bar-chart" /> งานซัตดาวน์
                  </NavLink>
                  <NavLink
                    className="nav-item nav-link"
                    to={{ pathname: "/safetypass" }}
                    onClick={hide}
                  >
                    <FontAwesome name="address-book" /> อบรมผู้รับเหมา
                  </NavLink>
                  <NavLink
                    className="nav-item nav-link"
                    to={{ pathname: "/chemicalcom" }}
                    onClick={hide}
                  >
                    <FontAwesome name="bar-chart" /> รายชื่อ Vendor
                  </NavLink>
                </ul>
              )}
            </ul>
          )}

        {privilegeContext &&
          privilegeContext.privilege[14].chkEnable &&
          currentBranch._id == "vms" && (
            <ul className="nav flex-column mb-2 pl-3">
              <span
                className={`nav-item nav-link ${
                  pathname.includes("/reportregister") ||
                  pathname.includes("/cockinout")
                    ? "active"
                    : ""
                }`}
                style={{ cursor: "pointer" }}
                onClick={() => handleSubMenuClick("reportregister")}
              >
                <FontAwesome name="list-alt" /> รายงานเข้าออก{" "}
                <FontAwesome name={"chevron-down"} />
              </span>
              {submenu["reportregister"] && (
                <ul className="nav flex-column mb-2 pl-3">
                  <NavLink
                    className="nav-item nav-link"
                    to={{ pathname: "/reportregister" }}
                    onClick={hide}
                  >
                    <FontAwesome name="bar-chart" /> กราฟการขอเข้างาน
                  </NavLink>
                  <NavLink
                    className="nav-item nav-link"
                    to={{ pathname: "/cockinout" }}
                    onClick={hide}
                  >
                    <FontAwesome name="address-book" /> ผ่านเข้าออกประตู
                  </NavLink>
                </ul>
              )}
            </ul>
          )}

        {privilegeContext &&
          privilegeContext.privilege[14].chkEnable &&
          currentBranch._id == "vms" && (
            <ul className="nav flex-column mb-2 pl-3">
              {
                <span
                  className={`nav-item nav-link ${
                    pathname.includes("/forapproved") ? "active" : ""
                  }`}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleSubMenuClick("forapproved")}
                >
                  <FontAwesome name="check-square" /> รายการรออนุมัติ{" "}
                  <FontAwesome name="chevron-down" />{" "}
                </span>
              }

              {submenu["forapproved"] && (
                <ul className="nav flex-column mb-2 pl-3">
                  <NavLink
                    className="nav-item nav-link"
                    to={{ pathname: "/forapproved" }}
                    onClick={hide}
                  >
                    <FontAwesome name="blind" /> อนุมัติเข้าพื้นที่{" "}
                  </NavLink>

                  {/* <NavLink className="nav-item nav-link" to={{pathname: "/"}} 
              onClick={hide}>
              <FontAwesome name="external-link-square" /> อนุมัติงานซัตดาวน์</NavLink> */}
                </ul>
              )}
            </ul>
          )}

        {privilegeContext.privilege[11].chkEnable && (
          <h4 className="sidebar-heading px-3 mt-3 mb-1">Management</h4>
        )}

        {privilegeContext.privilege[11].chkEnable && (
          <ul className="nav flex-column mb-2 pl-3">
            {
              <span
                className={`nav-item nav-link ${
                  pathname.includes("/accountrequest")  ||   pathname.includes("/accountlist") ? "active" : ""
                }`}
                style={{ cursor: "pointer" }}
                onClick={() => handleSubMenuClick("accountrequest")}
              >
                <FontAwesome name="building" /> อนุมัติรายการ{" "}
                <FontAwesome name="chevron-down" />{" "}
              </span>
            }

            {submenu["accountrequest"] && (
              <ul className="nav flex-column mb-2 pl-3">
                <NavLink
                  className="nav-item nav-link"
                  to={{ pathname: "/accountrequest" }}
                  onClick={hide}
                >
                  <FontAwesome name="building" /> อนุมัติสิทธิ์{" "}
                </NavLink>
                <NavLink
                  className="nav-item nav-link"
                  to={{ pathname: "/accountlist" }}
                  onClick={hide}
                >
                  <FontAwesome name="building" /> รายชื่อผู้ใช้งาน{" "}
                </NavLink>


              </ul>
            )}
               {/* {submenu["accountrequest"] && (
              <ul className="nav flex-column mb-2 pl-3">
                <NavLink
                  className="nav-item nav-link"
                  to={{ pathname: "/accountlist" }}
                  onClick={hide}
                >
                  <FontAwesome name="building" /> รายชื่อผู้ใช้งาน{" "}
                </NavLink>
              </ul>
            )} */}
          </ul>
        )}

        {<h4 className="sidebar-heading px-3 mt-3 mb-1">Report</h4>}

        {currentBranch._id !== "0"  && <ul className="nav flex-column mb-2 pl-3">
          <NavLink
            className="nav-item nav-link"
            to="/Reportchart/"
            onClick={hide}
          >
            <FontAwesome name="list-alt" /> ปริมาณรถ
          </NavLink>
        </ul>}
         
        {/* <ul className="nav flex-column mb-2 pl-3">
            <nav className="nav">
        <div className="nav__list">
        <div className="nav__link collapse">
                           <FontAwesome name="list-alt" className="nav__icon"/>
                            <span className="nav__name">Team</span>
                          
                            <FontAwesome name="chevron-down" className="collapse__link"></FontAwesome>

                            <ul className="collapse__menu">
                                <a href="#" className="collapse__sublink">Data</a>
                                <a href="#" className="collapse__sublink">Group</a>
                                <a href="#" className="collapse__sublink">Members</a>
                            </ul>
                        </div>
                        </div>
                        </nav>
                        </ul>  */}

      </div>
    </React.Fragment>
  );
};

//SideNavBar.contextType= PrivilegeContext;

export default withRouter(SideNavBar);
