import React from "react";
//import FontAwesome from "react-fontawesome";

//import Modaldevice from "./modaldevicebyuser";
//import { toast } from "react-toastify";
import "./../../App.css";
const RenderTimestamp = ({
  lastBookElementRef,
  list,
  snackMessage,
  handleOnClick,
  search,
  onUpdate,
}) => {
//  console.log(list);

  return (
    <div className="row course-list list m-1">
      {list &&
        list.map((item, index) => {
          if (list.length === index + 1) {
            return (
              <div
                ref={lastBookElementRef}
                key={item.ddate}
                className="card text-dark p-0 mb-2 mr-2 col-lg-12"
              >
                <div className="container-fluid">
                { item.data.length > 0 ?

<span className="badge badge-pill badge-primary">
  {item.data.length }
</span>:
<span className="badge badge-pill badge-danger">
{0}
</span>
}

                  <div className="row no-gutters align-items-center justify-content-left">
                  <div className="col-auto text-center mb-2 p-1 text-center">
                      <h6
                        className="card-title d-inline mr-2 badge  badge-secondary"
                        style={{ fontSize: "14px" }}
                      >
                        {item.ddate}
                      </h6>
                    </div>

                    <div className="card-body p-1">
                      <div className="card-text">
                        <div className="row">
                          {item.data &&
                            item.data.map((value, i) => (
                              <div
                                className="col-sm-3 col-xs-6 border border-top-0 border-right-0 border-left-0"
                                style={{ fontSize: "14px" }}
                                key={i}
                              >
                                {"เวลา: "}{value.dtime} {"  "}
                               {"เครื่อง:"} {value.face_name}
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          } else {
            return (
              <div
                key={item.ddate}
                className="card text-dark p-0 mb-2 mr-2  col-lg-12"
              >
                <div className="container-fluid">
{ item.data.length > 0 ?

                  <span className="badge badge-pill badge-primary">
                    {item.data.length }
                  </span>:
                  <span className="badge badge-pill badge-danger">
                  {0}
                </span>
                  }

                  <div className="row no-gutters align-items-center justify-content-left">
                    <div className="col-auto text-center mb-2 p-1 text-center">
                      <h6
                        className="card-title d-inline mr-2 badge  badge-secondary"
                        style={{ fontSize: "14px" }}
                      >
                        {item.ddate}
                      </h6>
                    </div>
                    <div className="card-body p-1">
                      <div className="card-text">
                    <div className="row">
                      {item.data &&
                        item.data.map((value, i) => (
                          <div
                            className="col-sm-3 col-xs-6 border border-top-0 border-right-0 border-left-0"
                            style={{ fontSize: "14px" }}
                            key={i}
                          >
                            <span className="border border-top-0 border-right-0 border-left-0">
                              
                            </span>
                           {"เวลา: "}{value.dtime} {"  "}
                               {"เครื่อง:"} {value.face_name}
                          </div>
                        ))}
                    </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        })}
    </div>
  );
};

export default RenderTimestamp;
