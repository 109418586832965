import React from "react";

const Toggle = ({ name, checked, onChange, text }) => {
  return (
    <div>
      <div>
        <div className="custom-control custom-switch">
          <input
            type="checkbox"
            className="custom-control-input"
            id={name}
            style={{ cursor: "pointer" }}
            checked={checked}
            onChange={ (e) =>
                onChange(e,name)}
          />
          <label
            className="custom-control-label"
            htmlFor={name}
            style={{ cursor: "pointer" }}
          >
            {text}
          </label>
        </div>
      </div>
    </div>
  );
};

export default Toggle;
