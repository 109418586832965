import React from "react";
import { Link } from "react-router-dom";
import FontAwesome from "react-fontawesome";

const RenderDevice = ({
  lastBookElementRef,
  list,
  snackMessage,
  handleOnClick,
}) => {
  //  console.log(list);
  return (
    <>
      {list &&
        list.map((item, index) => {
          if (list.length === index + 1) {
            return (
              <div className="row" ref={lastBookElementRef} key={item.no}>
                <div className="container-fluid">
                  <div className="card border-secondary mt-1">
                  
                      <div  className="row ml-1">
                        <div className="container col-xs-12 col-sm-4">
                          <div
                            className="border border-top-0 border-right-0 border-left-0"
                            style={{ fontSize: "15px" }}
                          >
                            <span className="badge badge-pill badge-secondary">
                              {item.no}
                            </span>
                          </div>
                          <div style={{ fontSize: "14px" }}>
                            {" "}
                            {"ID:"} {item.face_id}
                          </div>
                          <div
                            className="border border-top-0 border-right-0 border-left-0"
                            style={{ fontSize: "14px" }}
                          >
                            {"Name:"} {item.face_name}
                          </div>
                          <div
                            className="border border-top-0 border-right-0 border-left-0"
                            style={{ fontSize: "14px" }}
                          >
                            {"IP Address:"} {item.face_ip} {"PORT:"}{" "}
                            {item.face_port}
                          </div>
                        </div>

                        <div className="container col-xs-12 col-sm-4">
                          <div
                            className="border border-top-0 border-right-0 border-left-0"
                            style={{ fontSize: "14px" }}
                          >
                            <span style={{ fontSize: "14px" }}>
                              {"Group:"} {item.group_name}
                            </span>
                          </div>
                          <div
                            className="border border-top-0 border-right-0 border-left-0"
                            style={{ fontSize: "14px" }}
                          >
                            <span style={{ fontSize: "14px" }}>
                              {"Site: "}
                              <span>{item.site_name}</span>
                            </span>
                          </div>

                          <div
                            className="border border-top-0 border-right-0 border-left-0"
                            style={{ fontSize: "14px" }}
                          >
                            <span style={{ fontSize: "14px" }}>
                              {"Status:"}
                              {item.STATUS === "ONLINE" ? (
                                <span className="badge badge-primary">
                                  {item.STATUS}
                                </span>
                              ) : (
                                <span className="badge badge-secondary">
                                  {item.STATUS}
                                </span>
                              )}
                            </span>
                          </div>
                        </div>

                        <div className="container col-xs-12 col-sm-4">
                          <div
                            className="border border-top-0 border-right-0 border-left-0"
                            style={{ fontSize: "14px" }}
                          >
                            <span style={{ fontSize: "14px" }}>
                              {"ใบหน้าบนเครื่อง:"}
                              {item.active > 0 ? (
                                <span className="badge badge-success">
                                  {item.active}
                                </span>
                              ) : (
                                <span className="badge badge-secondary">
                                  {item.active}
                                </span>
                              )}
                            </span>
                          </div>

                          <div
                            className="border border-top-0 border-right-0 border-left-0"
                            style={{ fontSize: "14px" }}
                          >
                            <span style={{ fontSize: "14px" }}>
                              {"ใบหน้าไม่ผ่าน:"}
                              {item.error > 0 ? (
                                <span className="badge badge-danger">
                                  {item.error}
                                </span>
                              ) : (
                                <span className="badge badge-secondary">
                                  {item.error}
                                </span>
                              )}
                            </span>
                          </div>
                          <div className="d-flex justify-content-end">
                            <div>
                              <Link
                                style={{ width: "50px" }}
                                to={`/userondevice/${item.face_id}`}
                              >
                                <button className="btn btn-warning btn-sm m-2">
                                  <FontAwesome name="user" />
                                </button>
                              </Link>
                            </div>
                            <div>
                              <button
                                className="btn btn-secondary btn-sm m-2"
                                onClick={() =>
                                  handleOnClick(item.face_id, "edit")
                                }
                              >
                                <FontAwesome name="edit" />
                              </button>
                            </div>

                            
                          </div>
                        </div>
                      </div>
                   
                  </div>
                </div>
              </div>
            );
          } else {
            return (
              <div className="row" key={item.no}>
                <div className="container-fluid">
                  <div className="card border-secondary mt-1">
                   
                      <div className="row ml-1">
                        <div className="container col-xs-12 col-sm-4">
                          <div
                            className="border border-top-0 border-right-0 border-left-0"
                            style={{ fontSize: "15px" }}
                          >
                            <span className="badge badge-pill badge-secondary">
                              {item.no}
                            </span>
                          </div>
                          <div
                            className="border border-top-0 border-right-0 border-left-0"
                            style={{ fontSize: "14px" }}
                          >
                            {"ID:"} {item.face_id}
                          </div>

                          <div
                            className="border border-top-0 border-right-0 border-left-0"
                            style={{ fontSize: "14px" }}
                          >
                            {"Name:"} {item.face_name}
                          </div>
                          <div
                            className="border border-top-0 border-right-0 border-left-0"
                            style={{ fontSize: "14px" }}
                          >
                            {"IP Address:"} {item.face_ip} {"PORT:"}{" "}
                            {item.face_port}
                          </div>
                        </div>

                        <div className="container col-xs-12 col-sm-4">
                          <div
                            className="border border-top-0 border-right-0 border-left-0"
                            style={{ fontSize: "14px" }}
                          >
                            <span style={{ fontSize: "14px" }}>
                              {"Group:"} {item.group_name}
                            </span>
                          </div>
                          <div
                            className="border border-top-0 border-right-0 border-left-0"
                            style={{ fontSize: "14px" }}
                          >
                            <span style={{ fontSize: "14px" }}>
                              {"Site: "}
                              <span>{item.site_name}</span>
                            </span>
                          </div>

                          <div
                            className="border border-top-0 border-right-0 border-left-0"
                            style={{ fontSize: "14px" }}
                          >
                            <span style={{ fontSize: "14px" }}>
                              {"Status:"}
                              {item.STATUS === "ONLINE" ? (
                                <span className="badge badge-primary">
                                  {item.STATUS}
                                </span>
                              ) : (
                                <span className="badge badge-secondary">
                                  {item.STATUS}
                                </span>
                              )}
                            </span>
                          </div>
                        </div>

                        <div className="container col-xs-12 col-sm-4">
                          <div
                            className="border border-top-0 border-right-0 border-left-0"
                            style={{ fontSize: "14px" }}
                          >
                            <span style={{ fontSize: "14px" }}>
                              {"ใบหน้าบนเครื่อง:"}
                              {item.active > 0 ? (
                                <span className="badge badge-success">
                                  {item.active}
                                </span>
                              ) : (
                                <span className="badge badge-secondary">
                                  {item.active}
                                </span>
                              )}
                            </span>
                          </div>

                          <div
                            className="border border-top-0 border-right-0 border-left-0"
                            style={{ fontSize: "14px" }}
                          >
                            <span style={{ fontSize: "14px" }}>
                              {"ใบหน้าไม่ผ่าน:"}
                              {item.error > 0 ? (
                                <span className="badge badge-danger">
                                  {item.error}
                                </span>
                              ) : (
                                <span className="badge badge-secondary">
                                  {item.error}
                                </span>
                              )}
                            </span>
                          </div>

                          <div className="d-flex justify-content-end">
                            <div>
                              <Link
                                style={{ width: "50px" }}
                                to={`/userondevice/${item.face_id}`}
                              >
                                <button className="btn btn-warning btn-sm m-2">
                                  <FontAwesome name="user" />
                                </button>
                              </Link>
                            </div>
                            <div>
                              <button
                                className="btn btn-secondary btn-sm m-2"
                                onClick={() =>
                                  handleOnClick(item.face_id, "edit")
                                }
                              >
                                <FontAwesome name="edit" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                   
                  </div>
                </div>
              </div>
            );
          }
        })}
    </>
  );
};

export default RenderDevice;
