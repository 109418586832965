import React,{useState} from "react";
//import { useWindowScroll } from "react-use";

const ScrollToTop = ({onClick}) =>{

  // var stickyContainer = document.getElementById("root");
  // console.log(stickyContainer ,' sticky Conrainer offsetTop =',stickyContainer.offsetTop
  // ,'windows innerHeight =',window.innerHeight
  // );
 // elem.scrollIntoView(top)
 // window.scrollBy(100,10) 

  //  const { y: pageYOffset } = useWindowScroll();

//const { y :pageYOffset } = useWindowScroll();

//const [is_visible, setis_visible] = useState(true); 
const [visible, setvisible] = useState(true); 
// const [whenclick, setwhenclick] = useState(new Date());

// useEffect(()=>{
// if (pageYOffset > 400){
// setvisible(true);
// }else {//
// setvisible(false);
// }
// },[pageYOffset])

// if (!visible){
//   return false;
//}

//console.log(pageYOffset);

// const checkScrollTop = () => {
//     console.log('check scroll')
//     if ( window.pageYOffset > 400){
//         setis_visible(true)
//     } else if (window.pageYOffset <= 400){
//         setis_visible(false)
//     }
//   };





//  const  toggleVisibility =() =>{
//      //console(window.pageYOffset);
//     if (window.pageYOffset > 300) {
//         setis_visible(true);
//     } else {
//         setis_visible(false);
//     }
//   }

// console.log(window.pageYOffset,window.pageYOffset);
// console.log(document.documentElement.clientHeight)
// console.log(pageYOffset);


// const scrollToTop = () => {
//      window.scrollTo({
//       top: 0,
//       behavior: "smooth"
//     }); 

// }


    return (
      <div className="scroll-to-top">
        {visible && (
          <div onClick={onClick}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="38.735"
              width="33.749"
            >
              <g transform="translate(-18.121 -3.364)">
                <rect
                  ry="4.928"
                  y="3.364"
                  x="18.121"
                  height="38.735"
                  width="33.749"
                  fill="#00f"
                />
                <g transform="translate(-.48 2.134)">
                  <rect
                    ry="4.928"
                    y="1.229"
                    x="18.601"
                    height="38.735"
                    width="33.749"
                    fill="url(#b)"
                  />
                  <g fill="#ececec">
                    <path d="M22.435 17.62l4.684 4.685 5.044-5.044v19.352h6.625V17.26l5.044 5.044 4.683-4.684-13.04-13.04z" />
                    <path d="M22.435 17.62l4.684 4.685 5.044-5.044v19.352h6.625V17.26l5.044 5.044 4.683-4.684-13.04-13.04z" />
                  </g>
                </g>
              </g>
            </svg>
          </div>
        )}
      </div>
    );
  }

export default ScrollToTop;