import React, { useState,useEffect } from "react";
// import Input from "./../wms/input";
import { getCCP } from "./../../services/wms";
import DatePicker from "react-datepicker";
import Renderdata from './renderdata';
import moment from "moment";

function Reportccp(props) {

 const [weigthNo, setweigthNo] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    const loadData = async () => {
      const { data: result } = await getCCP(
        moment(startDate, "YYYY-MM-DD").add(0, "day").format("YYYY-MM-DD"),
        moment(endDate, "YYYY-MM-DD").add(0, "day").format("YYYY-MM-DD")
      );

      if (result.status === "success") {
        setweigthNo(result.data.list)
    
      }
    };

    loadData();
  }, [startDate, endDate]);

  return (
    <div className="container-fluid">
   <div className="row">
          <div className="col-auto">
            <div className="m-2">
              <i>
                จากวันที่ <span className="label label-default"></span>
              </i>

              <div className="mt-2" style={{ marginTop: 20, marginBottom: 20 }}>
                <DatePicker
                  todayButton="Today"
                  dateFormat="dd/MM/yyyy"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                />
              </div>
            </div>
          </div>
          <div className="col-auto">
            <div className="m-2">
              <i>
                ถึงวันที่ <span className="label label-default"></span>
              </i>
              <div className="mt-2">
                <DatePicker
                  todayButton="Today"
                  dateFormat="dd/MM/yyyy"
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                />
              </div>
            </div>
          </div>

    
        </div>

      <Renderdata border="card border-primary mt-2" list={weigthNo} />

   
    </div>
  );
}

export default Reportccp;
