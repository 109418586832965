import React,{useEffect, useState,useRef,useCallback,useContext} from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import BranchContext from "../context/branch";
import moment from "moment";
import {getlogscan,} from "../../services/facescan";
import Rendercockinout from "./rendertimestamp";
import dlv from 'dlv';
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
const Logscan = ({  button1,
  isOpen,
  header,
  onCancel,
  onConfirm,
  message,
  list,
  onDisAprove,
  value,
  onChange
   }) => {

    const ojb = value;
    let pempid = dlv(ojb, 'empid',0);
    let pempname = dlv(ojb, 'empname','');

  const [empdata, setempdata] = useState({
    empid:pempid,empname:pempname
  });
  
  //console.log(value);
  const { currentBranch } = useContext(BranchContext);
  const [error, setError] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [hasMore, setHasMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [cockinout, setcockinout] = useState([]);
  const [total,setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(25);

//console.log(pempid);
  useEffect(() => {
  //  if (!currentBranch) return;
//   console.log(pempid);
    if (!pempid) return;
 //   console.log(pempid);
    setempdata(
{empid:pempid,empname:pempname});

  }, [currentBranch,empdata.empid, pempid,pempname]);

  useEffect(() => {
    setcockinout([]);
  }, [currentBranch, startDate, endDate,empdata.empid,pempid]);

  useEffect(() => {
    if (!currentBranch) return;
    if (!empdata.empid) return;

    setLoading(true)
    setError(false)
    const loadData = async () => {
      const { data: result } = await  getlogscan(
        empdata.empid,
        moment(startDate, "YYYY-MM-DD").add(0, "day").format("YYYY-MM-DD"),
        moment(endDate, "YYYY-MM-DD").add(0, "day").format("YYYY-MM-DD"),
        pageSize,
        pageNumber
      );
      
      if ( result.status==='success') {
        setTotal(result.data.total);

        setcockinout(prevData => {   
 return [...new Set([...prevData, ...result.data.list])]
              })
       setHasMore(result.data.list.length > pageSize-1)
       setLoading(false)                 
    }
    };

    loadData();
  }, [currentBranch, startDate, endDate,  pageNumber, pageSize, empdata.empid]);



  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      //  console.log('node = ' ,node);
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        //  console.log(entries[0].isIntersecting,hasMore);

        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const handleDateChange = (date) => {
    setStartDate(date);
    setPageNumber(1);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
    setPageNumber(1);
  };
const onClosemodal = () => {
  onCancel();

  setempdata(
    {empid:'',empname:''
    })
}


//console.log(empdata.empid)
  return (

    <div>
      <Modal
        isOpen={isOpen}
        toggle={onClosemodal}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalHeader> 
       

        {pempid && <div className="row no-gutters align-items-center justify-content-left">
                            <div className="col-auto text-center mb-2 p-1 text-center">
                              <img
                                src={"https://wms.advanceagro.net/WSVIS/api/Face/GetImage?CardID=" + pempid + "&size=320"}
                                className="rounded-circle mr-1"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title=""
                                alt=""
                                style={{
                                  maxHeight: "80px",
                                  Width: "100%",
                                  maxWidth: "80px",
                                }}
                              ></img>
                   </div>   
                   {pempid &&
      
      <p className="font-weight-normal badge badge-primary"  style={{ fontSize: "18px" }}>
       {pempname}
    </p>

     }
                   <div className="row">
          <div className="col-auto">
            <div className="m-2"  style={{ fontSize: "14px" }}>
              <i>
                จากวันที่ <span className="label label-default"></span>
              </i>

              <div className="mt-2" style={{ marginTop: 20, marginBottom: 20 ,fontSize: "14px"}}>
                <DatePicker
                  todayButton="Today"
                  dateFormat="dd/MM/yyyy"
                  selected={startDate}
                  onChange={handleDateChange}
                />
              </div>
            </div>
          </div>
          <div className="col-auto">
            <div className="m-2"  style={{ fontSize: "14px" }}>
              <i>
                ถึงวันที่ <span className="label label-default"></span>
              </i>
              <div className="mt-2"  style={{ fontSize: "14px" }}>
                <DatePicker
                  todayButton="Today"
                  dateFormat="dd/MM/yyyy"
                  selected={endDate}
                  onChange={handleEndDateChange}
                />
              </div>
            </div>
          </div>

     
        </div>

              
       </div>}

       
        </ModalHeader>
        <ModalBody>
      
         
      <p className="text-success">
        แสดงข้อมูล {cockinout ? cockinout.length : 0}  /  {total} รายการ
      </p>


      <Rendercockinout

list={cockinout}
loading={loading}
error={error}
lastBookElementRef={lastBookElementRef}

/>

  
          {!list && message}


        </ModalBody>

        
        { error && <div className="col-md-12 alert alert-danger">  { error}</div>}

        
        <ModalFooter>
        

        
        
          <Button color="secondary" onClick={onClosemodal}>
          กลับ
          </Button>
        </ModalFooter>
      </Modal>
    </div>


  );
};

export default Logscan;
