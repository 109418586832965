import React,{useState} from 'react';
import ImageSelected from './imageselected';
import {uploadFaceImage} from './../../services/facescan';
import { toast } from 'react-toastify';
import dlv from 'dlv';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const  ModalImage = ({  button1,
    isOpen,
    header,
    onCancel,
    onConfirm,
    message,
    list,
    onDisAprove,
    value,
    onChange,
    error
     }) => {

      const ojb = value;
      let pempid = dlv(ojb, 'empid',0);
      let pempname = dlv(ojb, 'empname','');
  
//console.log(value)

    const [photos, setphotos] = useState([]);
     const notifyPhotoSelector = files => {  
        // console.log(files);  
            setphotos(files);

           
          }

 const confirmsave = () =>{
    doSubmit (); 
  //  onConfirm();
 }

     const   doSubmit = async () => {
        const getPhoto = photos.find(x=>x);
       // console.log(getPhoto.setMetadata.file);
        let photoBase64=""
       // let photoBase64Original=""
        try {

//console.log(getPhoto)
//getFileEncodeBase64String

          //  photoBase64 =  await getPhoto.getFileEncodeBase64String() ;
            // photoBase64Original = await getPhoto.getFileEncodeDataURL() ;
            // console.log('base64 trim', photoBase64)
            // console.log('base64 trim', photoBase64Original)
        //  photoBase64 = await convertBase64(getPhoto)
          photoBase64 = (getPhoto) ? await getPhoto.getFileEncodeDataURL() : ""
        } catch(err) {
          //console.log('Error',err);

        }

         if(!photoBase64) return toast.error("ไม่พบข้อมูลรูปภาพ")
      
        if(photoBase64.includes('data:image/png;base64,')) photoBase64= photoBase64.replace("data:image/png;base64,", "");
        if(photoBase64.includes('data:image/jpg;base64,')) photoBase64= photoBase64.replace("data:image/jpg;base64,", "");
        if(photoBase64.includes('data:image/jpeg;base64,')) photoBase64= photoBase64.replace("data:image/jpeg;base64,", "");
       //  console.log('base64 trim', photoBase64)

       const doc = {empid: pempid, imagedata: photoBase64  };
    
       const {data: result} = await uploadFaceImage(doc);

       if (result.status==='success') {
         toast.success("upload เสร็จเรียบร้อย");
         onConfirm();
 
       } else {
         toast.error(result.message)
       }



    }

    // const convertBase64 = (file) =>{
    //   console.log(file);

    //   return new Promise((resolve, reject) =>{
    //     const fileReader = new FileReader();
    //     fileReader.readAsDataURL(file);
        
    //     fileReader.onload =()=>{
    //       resolve(fileReader.result);
    //     };
    //     fileReader.onerror = (error) =>{
    //       reject(error);
    //     };
    //   });
    // };
//console.log(photos)

    return ( 

        <div>
        <Modal
          isOpen={isOpen}
          toggle={onCancel}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <ModalHeader> 

          {pempid && <div className="row no-gutters align-items-center justify-content-left">
                            <div className="col-auto text-center mb-2 p-1 text-center">
                              <img
                                src={"https://wms.advanceagro.net/WSVIS/api/Face/GetImage?CardID=" + pempid + "&size=320"}
                                className="mr-1"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title=""
                                alt=""
                                style={{
                                  maxHeight: "150px",
                                  Width: "100%",
                                  maxWidth: "150px",
                                }}
                              ></img>
                   </div>   

                    {pempid &&
      
         <p className="font-weight-normal badge badge-primary"  style={{ fontSize: "14px" }}>
          {pempid} {"    "} {pempname}
       </p>

        }      
                    </div>}
          

  
         
          </ModalHeader>
          <ModalBody>
        
          <ImageSelected
            notifyFileUpload={notifyPhotoSelector} 
          />
  
     
    
           
  
  
          </ModalBody>
  
  
    
  
          
          { error && <div className="col-md-12 alert alert-danger">  { error}</div>}
  
          {list && list.comment &&
              <Button color="danger">
                {list.comment}
              </Button>
            }
          <ModalFooter>
          
  
          
           
              {photos && photos.length>0 && <Button color="primary" onClick={confirmsave}>
                บันทึก
              </Button>}
           
            <Button color="secondary" onClick={onCancel}>
              ยกเลิก
            </Button>
          </ModalFooter>
        </Modal>
      </div>
  
  
    );


    
}
 
export default  ModalImage;