import React from "react";
//import Imagedata from "./renderimage";
import { Link } from "react-router-dom";

const RenderList = ({ list, border = "card border-secondary mt-2"}) => {

  //console.log(list);
  return (

    <>
    {list &&
      list.map((item, index) => {
        return (
          <div  key = {item.no} className={border}>
            <div className="card-body">
              <div   className="row">
                <div className=" col-xs-6 col-sm-4 col-md-3 lg-1 ">
                  <div style={{ fontSize: 22 }}>
                    <span className="badge badge-pill badge-primary">
                      {item.no}
                    </span>
                  </div>

                  <div style={{ fontSize: 14 }}>
                    <h6>
                      เลขที่ใบชั่ง :
                      <small style={{ fontSize: 14 }}> {item.weightno}</small>
                    </h6>
                  </div>
                  <div style={{ fontSize: 14 }}>
                    <h6>
                      ทะเบียนรถ :
                      <small style={{ fontSize: 14 }}> {item.drivenno}</small>
                    </h6>
                  </div>

                  <div style={{ fontSize: 14 }}>
                    <h6>
                      วันที่ลงสินค้า :
                      <small style={{ fontSize: 14 }}>{item.datein}</small>{" "}
                    </h6>
                  </div>
                  <div />
                </div>

                <div className=" col-xs-6 col-sm-4 col-md-3 lg-1">
                  <div style={{ fontSize: 14 }}>
                    <h6>
                      Supplier :
                      <small style={{ fontSize: 14 }}>
                        {item.customername}
                      </small>
                    </h6>
                  </div>
                  <div style={{ fontSize: 14 }}>
                    <h6>
                      PO :<small style={{ fontSize: 14 }}> {item.po}</small>{" "}
                    </h6>
                  </div>
                  <div style={{ fontSize: 14 }}>
                    <h6>
                      {" "}
                      DO :<small style={{ fontSize: 14 }}>{item.doid}</small>
                    </h6>
                  </div>
                  <div style={{ fontSize: 14 }}>
                    <h6>
                      สารเคมี :
                      <small style={{ fontSize: 14 }}>
                        {item.productname}
                      </small>
                    </h6>
                  </div>
                  <div />
                </div>
                <div className=" col-xs-6 col-sm-4 col-md-3 lg-1">
                  <div>
                    <h6>
                      {" "}
                      นน.สุทธิ AA :
                      <small style={{ fontSize: 18 }}>
                        {" "}
                        <span className="badge badge-pill badge-info">
                          {item.weightnet}
                        </span>
                      </small>
                    </h6>
                  </div>
                  <div>
                    <h6>
                      {" "}
                      นน.สุทธิ Supplier :
                      <small style={{ fontSize: 18 }}>
                        {" "}
                        <span className="badge badge-pill badge-warning">
                          {item.customerweight}
                        </span>
                      </small>
                    </h6>
                  </div>
                  <div style={{ fontSize: 14 }}>
                    <h6>
                      Link Zoom :
                      <small style={{ fontSize: 14 }}>{item.linkzoom}</small>{" "}
                    </h6>
                  </div>
                  <div style={{ fontSize: 14 }}>
                    <h6>
                      หมายเหตุ :
                      <small style={{ fontSize: 14 }}>{item.comment}</small>{" "}
                    </h6>
                  </div>
                </div>
                <div className=" col-xs-6 col-sm-4 col-md-2 lg-1">
                  <div style={{ fontSize: 14 }}>
                    <h6>
                      Taskname :
                      <small style={{ fontSize: 14 }}>{item.taskname}</small>{" "}
                    </h6>
                  </div>
                  <div style={{ fontSize: 14 }}>
                    <h6>
                      ผลแลป :
                      {item.labresult == "ผ่าน" ? (
                        <small
                          className="badge badge-pill badge-success"
                          style={{ fontSize: 14 }}
                        >
                          {item.labresult}
                        </small>
                      ) : (
                        <small
                          className="badge badge-pill badge-danger"
                          style={{ fontSize: 14 }}
                        >
                          {item.labresult}
                        </small>
                      )}
                    </h6>
                  </div>
                  <div style={{ fontSize: 14 }}>
                    <h6>
                      มูลค่าเคลม :
                      <small style={{ fontSize: 14 }}>
                        {" "}
                        {item.claimvalue}
                      </small>{" "}
                    </h6>
                  </div>
                  <div style={{ fontSize: 14 }}>
                    <h6>
                      ลดหนี้ :
                      <small style={{ fontSize: 14 }}>
                        {item.reducedebt}
                      </small>{" "}
                    </h6>
                  </div>
                </div>
                <Link
                  style={{ width: "50px" }}
                  to={`/editccp/${item.id}`}
                >
                  <button
                    type="button"
                    className="btn btn-primary btn-sm m-2"
                  >
                    {item.receive_weightin > 0 ? "Edit" : "View"}
                  </button>
                </Link>
              </div>
            </div>
          </div>
        );
      })}
  </>


    // <>
    //   {list &&
    //     list.map((item, index) => {
    //       return (
    //         <div  key = {item.no}  className="card border-secondary mt-2">
    //           <div className="card-body">
    //             <div className="row">
    //               <div className=" col-xs-6 col-sm-4 col-md-2 lg-1 ">
    //                 <div style={{ fontSize: 22 }}>
    //                   <span className="badge badge-pill badge-primary">
    //                     {item.no}
    //                   </span>
    //                 </div>

    //                 <div style={{ fontSize: 14 }}>
    //                   <h6>
    //                     เลขที่ใบชั่ง :
    //                     <small style={{ fontSize: 14 }}> {item.weightno}</small>
    //                   </h6>
    //                 </div>
    //                 <div style={{ fontSize: 14 }}>
    //                   <h6>
    //                     ทะเบียนรถ :
    //                     <small style={{ fontSize: 14 }}> {item.drivenno}</small>
    //                   </h6>
    //                 </div>

    //                 <div style={{ fontSize: 14 }}>
    //                   <h6>
    //                     วันที่ลงสินค้า :
    //                     <small style={{ fontSize: 14 }}>{item.datein}</small>{" "}
    //                   </h6>
    //                 </div>
    //                 <div />
    //               </div>
                 
    //               <div className="col-xs-6 col-sm-4 col-md-2 lg-1">
    //                 <div style={{ fontSize: 14 }}>
    //                   <h6>
    //                     Supplier :
    //                     <small style={{ fontSize: 14 }}>
    //                       {item.customername}
    //                     </small>
    //                   </h6>
    //                 </div>
    //                 <div style={{ fontSize: 14 }}>
    //                   <h6>
    //                     PO :<small style={{ fontSize: 14 }}> {item.po}</small>{" "}
    //                   </h6>
    //                 </div>
    //                 <div style={{ fontSize: 14 }}>
    //                   <h6>
                      
    //                     DO :<small style={{ fontSize: 14 }}>{item.doid}</small>
    //                   </h6>
    //                 </div>
    //                 <div style={{ fontSize: 14 }}>
    //                   <h6>
    //                     สารเคมี :
    //                     <small style={{ fontSize: 14 }}>
    //                       {item.productname}
    //                     </small>
    //                   </h6>
    //                 </div>
    //                 <div />
    //               </div>
    //               <div className="col-xs-6 col-sm-4 col-md-2 lg-1">
    //                 <div>
    //                   <h6>
                       
    //                     นน.สุทธิ AA :
    //                     <small style={{ fontSize: 18 }}>
                        
    //                       <span className="badge badge-pill badge-info">
    //                         {item.weightnet}
    //                       </span>
    //                     </small>
    //                   </h6>
    //                 </div>
    //                 <div>
    //                   <h6>
                       
    //                     นน.สุทธิ Supplier :
    //                     <small style={{ fontSize: 18 }}>
                       
    //                       <span className="badge badge-pill badge-warning">
    //                         {item.customerweight}
    //                       </span>
    //                     </small>
    //                   </h6>
    //                 </div>
    //                 <div style={{ fontSize: 14 }}>
    //                   <h6>
    //                     Link Zoom :
    //                     <small style={{ fontSize: 14 }}>{item.linkzoom}</small>{" "}
    //                   </h6>
    //                 </div>
    //               </div>
    //               <div className="col-xs-6 col-sm-4 col-md-2 lg-1">
    //                 <div style={{ fontSize: 14 }}>
    //                   <h6>
    //                     ผลแลป :
    //                     {item.labresult == "ผ่าน" ? (
    //                       <small
    //                         className="badge badge-pill badge-success"
    //                         style={{ fontSize: 14 }}
    //                       >
    //                         {item.labresult}
    //                       </small>
    //                     ) : (
    //                       <small
    //                         className="badge badge-pill badge-danger"
    //                         style={{ fontSize: 14 }}
    //                       >
    //                         {item.labresult}
    //                       </small>
    //                     )}
    //                   </h6>
    //                 </div>
    //                 <div style={{ fontSize: 14 }}>
    //                   <h6>
    //                     มูลค่าเคลม :
    //                     <small style={{ fontSize: 14 }}>
    //                       {" "}
    //                       {item.claimvalue}
    //                     </small>{" "}
    //                   </h6>
    //                 </div>
    //                 <div style={{ fontSize: 14 }}>
    //                   <h6>
    //                     ลดหนี้ :
    //                     <small style={{ fontSize: 14 }}>
    //                       {item.reducedebt}
    //                     </small>{" "}
    //                   </h6>
    //                 </div>
    //               </div>
    //               <div className="col-xs-6 col-sm-4 col-md-2 lg-1">
                   
    //                   <button
    //                     type="button"
    //                     className="btn btn-primary btn-sm m-2"
    //                   >
    //                     Edit
    //                   </button>
                  
    //               </div>
                  
    //             </div>
    //           </div>
    //         </div>
    //       );
    //     })}
    // </>
  );
};

export default RenderList;
