import React,{useState,useEffect,useContext} from "react";
import Rendershutdown from "./rendershutdown";
import Modalshutdown from './modaladdshutdown'

import {getShutdownlist} from './../../services/vms';
import BranchContext from "./../../components/context/branch";



const Shutdownlist = (props)  => {
 
const [shutdownlist, setshutdownlist] = useState([]);
const [addItemModal, setaddItemModal] = useState(false);
const { currentBranch } = useContext(BranchContext);

const handleChange = (planid, detail) =>{

  if (detail==="new"){
    setaddItemModal(true);


  }else if (detail==="add") {

    const urlGoBack = "/supplierjob/"+planid ;
     setTimeout(props.history.replace(urlGoBack), 1000);
  }
 // console.log(planid,detail)
}
const handleSave = () =>{
  setaddItemModal(false);
  
}
const handlecancel = () =>{

  
  setaddItemModal(false)
}

useEffect(() => {
const loadData = () => {

  getShutdownlist().then (result=>{        
    setshutdownlist(result.data);           
               });
              }
    loadData ();
               
             }, 
             [addItemModal])


//console.log(shutdownlist);
if (currentBranch._id !== 'vms'){
  return <>
  </>
}
    return (
      <div>
        <Modalshutdown
        isOpen ={addItemModal}
        oncanCel ={handlecancel}
        onSave = {handleSave}
        title ="เพิ่มข้อมูลงาน ซัตดาวน์"
        />
            <Rendershutdown
            list = {shutdownlist }
            handleChange = {handleChange}

            />
            </div>
    );
  }


export default  Shutdownlist ;