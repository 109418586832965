import React from 'react';
import FontAwesome from 'react-fontawesome';


const Renderaccount = ({list,snackMessage,handleChange}) => {

//console.log(list);

    return ( 

        <div>
        <div className="container"> 
        

  


          <table className="table table-hover">
            <thead >
              <tr>
                <th scope="col">account</th>
                <th scope="col">details</th>
                <th scope="col">WMS</th>
                <th scope="col">Grab</th>
                <th scope="col">VMS</th>
                <th />
              </tr>
            </thead>
            <tbody>{
                 list &&
                 list.map((item) => (
                      <tr key={item.autoid}>
                    
                        <td className="col-sd-4" style={{ fontSize: "14px" }}>{item.account}</td>
                        <td className="col-md-2" style={{ fontSize: "14px" }}>{item.firstname +' '+ item.lastname}
                        
                       
                          <div className="row">
                          <small className="small font-weight-lighter text-muted">
                           {item.account}
                           </small>
                          </div>
                          
                        </td>

                        {/* <div className="form-group">
                        <div className="col-md">
                          <div className="row">
                          <small className="small font-weight-lighter text-muted">
                           {item.firstname}
                           </small>
                          </div>

                          <div className="row">
                            <small className="small font-weight-lighter text-muted">
                              {item.firstname}
                            </small>
                          </div>
                          <div className="row">
                            <small className="small font-weight-lighter text-muted">
                              {item.firstname }
                            </small>
                          </div>
                        </div>
                      </div> */}



                        <td className="col-md-2" style={{ fontSize: "14px" }} >
                        <input type="checkbox" 
                        checked={ item.WMSRequest}
                        onChange={ () => handleChange(item.autoid, "click") } 
                        ></input>
                        </td>   
                        <td className="col-md-2" style={{ fontSize: "14px" }} >
                        <input type="checkbox" 
                        checked={ item.GrabRequest}
                        onChange={ () => handleChange(item.autoid, "click") } 
                        ></input>
                        </td>   
                        <td className="col-md-2" style={{ fontSize: "14px" }} >
                        <input type="checkbox" 
                        checked={ item.VMSReqeust}
                        onChange={ () => handleChange(item.autoid, "click") } 
                        ></input>
                        </td>   
                        <td className="col-md-2">
                        <button
                          className="btn btn-success"
                          onClick={() => handleChange(item.autoid, "approved")}
                        >
                      <FontAwesome name="check-circle"/>  
                      <i>Approved</i>  
                       
                          </button>
                        </td>

                        <td className="col-md-2">
                        <button
                          className="btn btn-danger"
                          onClick={() => handleChange(item.autoid, "disapproved")}
                        >
                      <FontAwesome name="ban"/>  
                      <i>Disapproved</i>  
                       
                          </button>
                        </td>
                      </tr>
                    ))
                
                }</tbody>

          </table>
        </div>
   
        <div id="snackbar">{snackMessage}</div>
      </div>

     );
}
 
export default Renderaccount;