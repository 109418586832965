import React from "react";
import Modal from "react-bootstrap/Modal";
import SearchEmployee from "../common/searchDriven";
//import { Button } from "antd";
import { Button} from 'reactstrap';
const ModalSearch = ({isOpen, onCancel, value, list,onSelected,label,placeholder}) => {


 // const [isOpen, setIsOpen] = useState(false);
  function handleUpdateData(value) {
   // console.log("value from Modal Page=>", value);
    onSelected(value);

  }
  return (
    // <div className="container">
    //   <button variant="danger" onClick={() => setIsOpen(true)}>
    //     เลือกพนักงาน
    //   </button>
      <Modal
        show={isOpen}
        onHide={onCancel}
        size="col-12 col-md-10 col-lg-8 mb-1"
        aria-labelledby="contained-modal-title-vcenter"
        centered
                         >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {label}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SearchEmployee updateData={handleUpdateData}
          placeholder ={placeholder}
          textProperty ="FullName"
          value ={value}
          />
        </Modal.Body>
        <Modal.Footer>
        <Button color="secondary" onClick={onCancel}>ยกเลิก</Button> 
        </Modal.Footer>
      </Modal>
    // </div>
  );
};

export default ModalSearch;
