import React,{useState,useEffect} from "react";
import { Modal, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Select from './select';
import { toast } from "react-toastify";
import moment from 'moment';
import {getBusiness,getAreas,SaveJobData} from './../../services/vms';

const Modalshutdown = ({isOpen,oncanCel,title,onSave}) => {

    const [name, setnewName] = useState('');
    const [id,setid] = useState(0);
    const [BUID, setBUID] = useState(0);
    const [AreaID, setAreaID] = useState(0);
    const [business, setbusiness] = useState([]);
    const [area, setarea] = useState([]);
    const [startdate, setstartdate] = useState(new Date());
    const [enddate, setsenddate] = useState(new Date());
    // const [nowDate, setNowDate] = useState(new Date());

    const [messageErros, setmessageErros] = useState({
      askAlert: {enable: false, message: ""}
   });
   
    useEffect(() => {
      getBusiness().then (result=>{        
        setbusiness(result.data);   
      //  console.log(result.data)        
                   });
                 }, 
                 [])

    useEffect(() => {
      getAreas((BUID)).then (result=>{        
                    setarea(result.data);   
                   
                               });
                             }, 
                             [BUID])        

const handleChangoncanCel = () => {
  clearalldate();
  oncanCel();
}

    const handelonSave = () =>{
      if (name==''){
        setmessageErros( { askAlert: {enable: true, message: "ยังไม่ระบุชื่องาน\nกรุณาระบุก่อนค่ะ"}})
        return;
      } else if (BUID==0 ){
        setmessageErros( { askAlert: {enable: true, message: "ยังไม่ระบุสายงาน\nกรุณาระบุก่อนค่ะ"}})
        return;
      } else if (AreaID==0 ){
        setmessageErros( { askAlert: {enable: true, message: "ยังไม่ระบุพื้นที่\nกรุณาระบุก่อนค่ะ"}})
        return;
      }else     {
  
        const d1 = new Date(startdate);
        const d2 = new Date(enddate);

        
        if (d1.getDate() > d2.getDate()  &&  (d1.getMonth() === d2.getMonth() || d1.getMonth() > d2.getMonth() ) && (d1.getyear() === d2.get.getyear ()  || d1.getyear() > d2.get.getyear ()) ) {
         
           setmessageErros( { askAlert: {enable: true, message: "ระบุวันที่ไม่ถูกต้อง\nกรุณาระบุวันที่ถูกต้องค่ะ"}})
           return;
        }
      }

      doSubmit();
       
    }
    
    const  doSubmit = async () => {
      try {
        
         const doc = {   
          _id:id,  BUID: BUID, AreaID: AreaID, Name: name, 
            startDate: moment(startdate, "YYYY-MM-DD").add(0, 'day').format("YYYY-MM-DD") , 
            endDate: moment(enddate, "YYYY-MM-DD").add(0, 'day').format("YYYY-MM-DD") , 
         };

        const {data: result} = await SaveJobData(doc);
     
      if (result.status==='success') {
         toast.success("บันทึกเสร็จเรียบร้อย");

         onSave();
         clearalldate();
        
       } else {
         toast.error(result.message)
         setmessageErros( { askAlert: {enable: true, message:result.message}})
       }
    
      } catch(err) {
        toast.error("Error")
      }
      
    }


    const clearalldate = () =>{
      setnewName('')
      setBUID(0);
      setAreaID(0);
      setmessageErros( { askAlert: {enable: false, message: ""}}) 
    }
    const onChangeDate =(event) => {
      setstartdate(event)
       
    }
    const onChangeEndDate =(event) => {
      setsenddate(event)
       
    }
   const handleName = ({ currentTarget: input }) =>{
  //   const data = { ...stateData.data};
  //   const errors = { ...stateData.errors}
  //   if ( input.name in data) { 
  //  const errorMessage = validateProperty(input);
  //     if (errorMessage)
  //      errors[input.name] = errorMessage;
  //      else
  //      delete errors[input.name];
  //     data[input.name] = input.value;

  //   setstateData( {data,errors: { enable: false, message: errorMessage }});
  //   if (errorMessage && !input.value ==='') return;

    setnewName(input.value);
  //  }

   }
   const handleSelectedBUID =(event) =>{
    setBUID(event.currentTarget.value)
   }
   const handleSelectedAreaID = (event) =>{
     setAreaID(event.currentTarget.value)
   }

//console.log(name)
    return (  

        <Modal show={isOpen}>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form-horizontal" 
            name="newProductForm">
            <div className="form-group input-group">
              <label className="col-md-3 control-label" htmlFor="jobname">
                ชื่องาน
              </label>
              <div className="col-md-8">
                <input
                  name="jobname"
                  placeholder="ชื่องาน"
                  onChange={handleName}
                  className="form-control"
                  value={name}
                />
              {/* {stateData.errors.message.name && <div className="alert alert-danger">{stateData.errors.message.name}</div>}        */}

              </div>
            </div>

          <Select
           data={ business}
           value={BUID} 
           label ="สายงาน"
           textProperty="BUName"
           onChange={handleSelectedBUID}
           valueProperty="BUID"
            />
          <Select
           data={area}
           label ="พื้นที่"
           value={AreaID} 
           textProperty="AreaName"
           onChange={handleSelectedAreaID}
           valueProperty="AreaID"
          />
             
            <div className="form-group input-group">
              <label
                className="col-md-3 control-label"
                htmlFor="area"
              >
                วันที่เริ่มงาน
              </label>
              <div className="col-md-8">
              <DatePicker
                      todayButton="Today"
                      dateFormat="dd/MM/yyyy"
                      selected={startdate}
                      onChange={onChangeDate}

                    />
              </div>
            </div>

            <div className="form-group input-group">
              <label
                className="col-md-3 control-label"
                htmlFor="area"
              >
                วันที่สิ้นสุด
              </label>
              <div className="col-md-8">
                     <DatePicker
                      todayButton="Today"
                      dateFormat="dd/MM/yyyy"
                      selected={enddate}
                      onChange={onChangeEndDate}

                    />
              </div>
            </div>
            <br /> <br /> <br />
          </form>
        </Modal.Body>
    { messageErros.askAlert.enable  &&  <div className="col-md-12 alert alert-danger">  { messageErros.askAlert.message}</div> }
        <Modal.Footer>
          <Button className="btn btn-secondary"  onClick={handleChangoncanCel}>
            Close
          </Button>
          <Button onClick={handelonSave}>
            Save
        </Button>
        </Modal.Footer>
      </Modal>

    );
}
 
export default Modalshutdown;