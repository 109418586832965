import React from "react";
import Toggle from "./../common/toggle";

const DeviceTogle = ({list,handleOnClick,textProperty,valueProperty,valueCheck }) => {


    return ( 

        <div className ="row course-list list m-1">
{list &&
 list.map((item) => (  
<div  key={item._id} className="col-xs-6 col-sm-4 col-lg-3 col-xl-2">
            <Toggle
            key={item[valueProperty]}
            name={item[valueProperty]}
            checked={item[valueCheck]}
            onChange={ (e) =>
                handleOnClick(e,item[valueProperty],item[textProperty])}
            text={item.name}
            /> 
</div>

          ))}


     </div> 
        )
        
        ;
        
} 
DeviceTogle.defaultProps ={
    textProperty:'name',
    valueProperty:'_id',
    valueCheck:'chkselected'}
    ;
 
export default DeviceTogle;