import React,{useState,useEffect,useContext, useRef,useCallback} from 'react';
import Input from './../wms/input';
import Renderaccount from './rederaccountlist';
import {getaccountlist} from './../../services/vms';
import BranchContext from "./../../components/context/branch";

const AccountList = () => {
const [name, setname] = useState('');
const [accountlist, setaccountlist] = useState([]);
const [total, settotal] = useState(0);
const [hasMore, setHasMore] = useState(false);
const [pageNumber, setPageNumber] = useState(1);
const [pageSize,setPageSize] = useState(30);
const [loading, setLoading] = useState(true);
const [error, setError] = useState(false)
const [lastupdate, setlastupdate] = useState(new Date());
const { currentBranch } = useContext(BranchContext);


useEffect(() => {
  
    setaccountlist([]);
  }, [currentBranch, pageSize,name,lastupdate]);


  useEffect(() => {
    if (!currentBranch) return;
 
    if (!pageSize || !pageNumber  ) return;
    setLoading(true)
    setError(false)

        const loadData = async () => {

            const { data: result } = await getaccountlist(name,pageSize,pageNumber);
            if ( result.status==='success') {
                settotal(result.data.total);
         
                setaccountlist(prevData => {   
         return [...new Set([...prevData, ...result.data.list])]
                      })
               setHasMore(result.data.list.length > pageSize-1)
               setLoading(false)                 
            }

    };
    loadData();
  }         
  , [currentBranch,name,pageSize,pageNumber,lastupdate]);


  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
       // console.log('node = ' ,node);
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        //  console.log(entries[0].isIntersecting,hasMore);

        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );


const handleChange = () =>{

}
const handleSearchClick = () =>{

}

const handleKeyPress = () =>{

}
const handleonClick = () =>{

}
const handleChangeToggle =(e) =>{
//     const {id ,checked} = e.target;
//   const name = toggle[id].text;
//  const Id = {
//     text: name,
//     isChecked: checked,
//     };
//     settoggle(prevToggle => {
//       return {
//          ...prevToggle,
//         [id]: Id
//       };
//     });
  }

    return (  
<>
<div className="row">

<div className="col-lg-4  col-sm-10 col-xs-12">
 <div className="form-group">
 <Input
         name="devicename"
         value={name}
         label="ชื่อ"
         placeholder ="ระบุชื่อเพื่อค้นหา"
         onChange = {handleChange}
         onClick = {handleSearchClick}
         onKeyPress={handleKeyPress}
         search="ค้นหา"
       />
 </div>
</div>


</div>


<p className="text-success">
        แสดงข้อมูล {accountlist ? accountlist.length : 0}  /  {total} รายการ
      </p>



   <Renderaccount
  list = {accountlist}
  handleOnClick = {handleonClick}
  error={error}
  lastBookElementRef={lastBookElementRef}
  handleChangeToggle ={handleChangeToggle}
  />
</>

    );
}
 
export default AccountList;