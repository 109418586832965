import React, { useState, useEffect,useContext } from "react";

//import http from "../services/httpService";
import {getDrivenprofiles} from "../../services/drivenService";
import BranchContext from './../context/branch';
import { Button } from "reactstrap";
//import config from "../config.json";

// import { Button, Input, Tag } from "antd";
// const { Search } = Input;




const SearchDriven = ({ updateData,placeholder,textProperty,value ,textProperty2}) => {

  const {currentBranch} = useContext(BranchContext);

const [data, setData] = useState({
    search: value,
  });
  const [master, setMaster] = useState([]);
  const [alldata, setalldata] = useState([]);
  const [formload, setformload] = useState(false);



  useEffect(() => {

    if (! currentBranch  ) return; 
    
    getDrivenprofiles((currentBranch && currentBranch._id)).then (result=>{        
      setMaster(result.data); 
      setalldata(result.data) 
                        
                 });
               }, 
               [currentBranch])


      useEffect(() => {
        if (!currentBranch ) return;
        if (alldata.length ===0) return;
        if (formload) return;

        if (data.search && alldata){
          onSearchClick();
        setformload(true);
        }
      

      },[alldata,formload])        

//   useEffect(() => {
//     // if (data.search.length < 4) {
//     //   return;
//     // }
//     async function api() {
//       try {
//   const resService = getDrivenprofiles('505');
// console.log(resService);

//         if (!resService) {
//           return;
//         }
//          const employeeList = resService.data;
//         //setMaster(employeeList);
//         console.log(employeeList);

//       } catch (error) {
//         console.log(error);
//       }
//     }

//     try {
//       api();
//     } catch (error) {}
//   }, [data.search]);

  async function onSearchClick() {

  //console.log('get searchadata');

    if (!data.search) {
      setMaster(alldata);
      return;
    }

    let filtered = alldata;

    if (data.search){

      filtered = alldata.filter(item => item[textProperty].includes(data.search) || item[textProperty2].includes(data.search))

      setMaster(filtered)
    }else {
      setMaster(alldata)
    }
   // console.log(master)

    //console.log('search data ',data.search);


    // try {
    //   const resService = getDrivenprofiles('505');
    //   //await http.get(config.employee.search + data.search);
    //   if (!resService) {
    //     return;
    //   }
    //   const employeeList = resService.data.data.search;
    //   setMaster(employeeList);
    // } catch (error) {
    //   console.log(error);
    // }

  }

  function handleChange({ currentTarget: input }) {
    const { name, value } = input;
    
  //  console.log("name,value=>", name, value);
    onSearchClick();
    setData((previous) => {
      return {
        previous,
        [name]: value,
      };
      

    });
  }

  function handleOnselected({ currentTarget: input }) {
    const { name } = input;
   // console.log('input',name);

    const result = master.filter((i) => i.IdentityID === name);

   // console.log("result=>", result[0]);
    const data = result[0];
    updateData(data);
  }

  return (
    <div className="form-group">
      <div className="row">
        <div className="col-sm-5">
          <input   
            name="search"
            value={data.search}
            onChange={handleChange}
            type="search"
            placeholder={placeholder}
            onKeyPress ={onSearchClick}
            
          />
        </div>
      </div>
      <div className="row m-2">
        <div className="col">
          {master.length > 0 && (
            <table className="table table-hover table-responsive">
              <thead>
                <tr>
                  <th scope="col">ชื่อ-สกุล</th>
               
                  <th scope="col">Details</th>               
                </tr>
              </thead>
              <tbody>
                {master.map((item) => (
                  <tr key={item.IdentityID}>
                   <td>{item.FullName} </td> 
                   
                    {/* <td>{highlight(item.empId, data.search)}</td> */}
                    {/* <td>
                      <span className="text-info">
                       

                      </span>
                    </td> */}
                    <td>
                      <div className="form-group">
                        <div className="col-sm">
                          <div className="row">
                           {item.IdentityID}
                          </div>
                          <div className="row">
                            <small className="small font-weight-lighter text-muted">
                              {item.FullName}
                            </small>
                          </div>
                          <div className="row">
                            <small className="small font-weight-lighter text-muted">
                              {item.Telephone}
                            </small>
                          </div>
                        </div>
                      </div>
                    </td>
                
                    <td>
                    
                        <Button
                          name={item.IdentityID}
                          type="primary"
                          onClick={handleOnselected}
                        >
                          select
                        </Button>
                      
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

// function highlight(text, highlight) {
//   const parts = text.split(new RegExp(`(${highlight})`, "gi"));
//   return (
//     <span>
//       {" "}
//       {parts.map((part, i) => (
//         <span
//           key={i}
//           style={
//             part.toLowerCase() === highlight.toLowerCase()
//               ? {
//                   fontWeight: "bold",
//                   backgroundColor: "#ffff66",
//                 }
//               : {}
//           }
//         >
//           {part}
//         </span>
//       ))}{" "}
//     </span>
//   );
// }
SearchDriven.defaultProps ={
  textProperty:'name',
  valueProperty:'_id',
  textProperty2:'IdentityID'
}
export default SearchDriven;
