import React, {useEffect,useState}  from 'react';

import ValuesTable from './valuesTable';
//import Pagination from '../menu/pagination';
//import { paginate } from '../../utils/paginate';
import SearchBox from '../common/searchBox';
import { toast } from 'react-toastify';
import {getReportJawis,getBranchReportJawis,downLoadExcelGrap} from '../../services/reportJawis.js';

import ListGroup from '../common/listGroup';
import DatePicker from "react-datepicker";
import moment from 'moment';
import jsfiledownload from 'js-file-download'; 
  // "apiUrl": "https://api-wms.advanceagro.net"
//import simAdminService from "../../../services/sim/admin";

import "react-datepicker/dist/react-datepicker.css";
 

moment.updateLocale('th', {week: {
    dow: 1,
  },
})


let count =0;


function Valueslist (props){

    const data = props; 
    let domain_id = data.location.domainid;
    let type=""; 
    
if (!(domain_id=== undefined)){
  type=domain_id.type;
  //console.log("type job=",type);
  domain_id = domain_id.id;
}
    

   
    const [weightList, setList] = useState([]) ;
    const [branch, setBranch] = useState([])
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [selectBranch, setselectBranch] = useState(null);
    const [isDownload, setisDownload] = useState(true);


   
    const [page, setpage] = useState({ 
        totalCount:0,
        currentPage: 1,
        pageSize: 15});
    
// function handleChange(event){ 
   
//     setpage({totalCount:count,pageSize:15,currentPage:event
//     });
//   };

 

useEffect(  () => {

 getReportJawis(moment(startDate, "YYYY-MM-DD").add(0, 'day').format("YYYY-MM-DD"),moment(endDate, "YYYY-MM-DD").add(0, 'day').format("YYYY-MM-DD"),domain_id,type).then (result=>{
    
        const {data} =result.data
    
      if (!(data.list === null)) {
        setList(data.list);
        setisDownload(false);
      } else {
        setList([]);
        setisDownload(true);
      }
          
      //  count = data.list.length && weightList ;
       count = !(data.list=== null) ? data.list.length : 0;
      if (count>0) {
          setpage({totalCount:count,pageSize:15,currentPage:1
        });
      }
         
        });
      }, 
      [startDate,endDate,domain_id,type])  
      
 useEffect (()=>{
        getBranchReportJawis(moment(startDate, "YYYY-MM-DD").add(0, 'day').format("YYYY-MM-DD"),moment(endDate, "YYYY-MM-DD").add(0, 'day').format("YYYY-MM-DD"),domain_id,type).then (result=>{
       
          const {data} =result.data;
         

         setBranch(data.list);    
            });

      },[startDate,endDate,domain_id,type])

      
  function handleBranchSelect(clickBranch){
       setselectBranch(clickBranch)

  };

  
    
 const downloadReport = async () => { 
  
    
    try {
      if( weightList.length>0 ) {
       // console.log("click",isDownload,weightList); 
    
        if(isDownload) return

         
     
       
         const output =  await downLoadExcelGrap(moment(startDate, "YYYY-MM-DD").add(0, 'day').format("YYYY-MM-DD"),moment(endDate, "YYYY-MM-DD").add(0, 'day').format("YYYY-MM-DD"),domain_id,type);
         
          const {data: result, headers} = output


          //.log(output);
          setisDownload(false);

          if( result.status==='error') {
              toast.error(result.message)
          } else
           {
              if ( headers['x-suggested-filename']==='nodata') {
                  toast.error("No Report")
              } else {
                  jsfiledownload(result, headers['x-suggested-filename'] || `report.xlsx`)
              }
          }


      } else {
        toast.error("Invalid Download Info")
      }
    } catch(err) {
     
        toast.error("ผิดพลาด " + err.message)
    }
  };

      const filtered = selectBranch && !(selectBranch._id==='0') ? weightList.filter(m => m.branchname === selectBranch.name) : weightList;

      const weightlist = filtered;//paginate(filtered,page.currentPage,page.pageSize);

      return (
        <div className='row container-fluid'>
            <div className="col-6">
                <div className='container'>      
                  <ListGroup items ={branch} 
                   selectedItem ={selectBranch}     
                    onItemSelect ={handleBranchSelect}/>
                </div>
              </div>
        <div className= "col-12">
          <div className='container'>    
            <div className = "row">
              <div className='m-3'>
                <div className='mt-2' style={{ marginTop: 20 ,marginBottom :20 }} >
                   <DatePicker
                   todayButton="Today"
                   selected={startDate}
                   onChange={date => setStartDate(date)}
                   />  
                 </div>
              </div>

           
        <div className='m-3'>
        <div className='mt-2' style={{ marginTop: 20 ,marginBottom :20 }} >
         <DatePicker
           todayButton="Today"
           selected={endDate}
           onChange={date => setEndDate(date)}      
           />  

        </div>
      </div>
     <div className='mt-2' style={{ marginTop: 20  }} >


      <button className="btn btn-success m-2" 
      onClick={downloadReport}
      
      >Download To Excel</button>

      </div>

      </div>
    </div>  
 
      <div className='mt-2' style={{ marginBottom :20 }} >
       <SearchBox
        />  
      </div>
      
      
    

      <p>แสดงข้อมูล { filtered ? filtered.length : 0} รายการ บนระบบ</p>

              {/* {weightlist.length && <ValuesTable 
              weightlist={weightList} />} */}
                

                 <ValuesTable 
              weightlist={weightlist} />

          {/* {filtered.length && <Pagination
          itemsCount={filtered.length}
        pageSize={page.pageSize}
        currentPage={page.currentPage}
        onPageChange={handleChange}        
         />} */}
         
        </div>
         </div>
       
      
    );
}



export default Valueslist;
