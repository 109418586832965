import React from 'react';
import FontAwesome from "react-fontawesome";
import Toggle from './../common/toggle';

const RederAccountlist = ({
    lastBookElementRef,
    list,
    snackMessage,
    handleOnClick,
    handleChangeToggle


  }) => {
     // console.log(list)
    return (  
<>
      {list &&
        list.map((item, index) => {
          if (list.length === index + 1) {
            return (
              <div className="row" key={item.no}>
                <div className="container-fluid">
                  <div className="card border-secondary m-1">
                    <div className="card-body">
                      <div ref={lastBookElementRef} className="row">
                        <div className="container col-xs-12 col-sm-6">
                        <div className="border border-top-0 border-right-0 border-left-0" style={{ fontSize: "15px" }}>
                            <span className="badge badge-pill badge-secondary">
                              {item.no}
                            </span>
                          </div>
                          <div className="border border-top-0 border-right-0 border-left-0" style={{ fontSize: "14px" }}>
                            {" "}
                            {"EmpID:"} {item.EmpID}
                          
                          </div>
                          <div className="border border-top-0 border-right-0 border-left-0" style={{ fontSize: "14px" }}>
                          {"account:"} {item.account}
                          </div>
                          <div className="border border-top-0 border-right-0 border-left-0" style={{ fontSize: "15px" }}>
                            {"Name :"}{" "}
                            
                            
                            <span className="badge badge-pill badge-success"> {item.firstname} {" "}   {" "}  {item.lastname}    </span>  
                         
                          </div>
                        </div>

                        <div className="container col-xs-12 col-sm-6">
                          <div className="border border-top-0 border-right-0 border-left-0" style={{ fontSize: "14px" }}>
                         

<div className="col-sm-6 my-1">
    <Toggle
    name={item.id+"wms"}
    checked={item.WMS} 
    disabled={true}                             
    onChange={handleChangeToggle}
    text="สิทธิ์เข้า ห้องชั่ง" />
</div>
</div>
<div className="border border-top-0 border-right-0 border-left-0" style={{ fontSize: "14px" }}>

<div className="col-sm-6 my-1">
    <Toggle
    name={item.id+"vms"}
    checked={item.VMS}
    disabled={true}  
    onChange={handleChangeToggle}
  
    text="สิทธิ์เข้า VMS " />
</div>
</div>

<div className="border border-top-0 border-right-0 border-left-0" style={{ fontSize: "14px" }}>
<div className="col-sm-6 my-1">
    <Toggle
    name={item.id+"admin"}
    checked={item.ADMIN}
    disabled={true}  
    onChange={handleChangeToggle}
    text="สิทธิ์จัดการ" />
</div>
</div>
                          <div className="d-flex justify-content-end"> 
 <button
                          className="btn btn-secondary btn-sm"
                          onClick={() => handleOnClick(item.face_id, "edit")}
                        >
                      <FontAwesome name="edit"/>  
                               
                        </button>
</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          } else {
            return (
              <div className="row" key={item.no}>
                <div className="container-fluid">
                  <div className="card border-secondary m-1">
                    <div className="card-body">
                      <div className="row">
                        <div className="container col-xs-12 col-sm-6">

                          <div className="border border-top-0 border-right-0 border-left-0" style={{ fontSize: "15px" }}>
                            <span className="badge badge-pill badge-secondary">
                              {item.no}
                            </span>
                          </div>
                          <div className="border border-top-0 border-right-0 border-left-0" style={{ fontSize: "14px" }}>
                            {" "}
                            {"EmpID:"} {item.EmpID}
                          
                          </div>
                          <div className="border border-top-0 border-right-0 border-left-0" style={{ fontSize: "14px" }}>
                          {"account:"} {item.account}
                          </div>
                          <div className="border border-top-0 border-right-0 border-left-0" style={{ fontSize: "15px" }}>
                            {"Name :"}{" "}
                            
                            
                            <span className="badge badge-pill badge-success"> {item.firstname} {" "}   {" "}  {item.lastname}    </span>  
                         
                          </div>
                        </div>

                        <div className="container col-xs-12 col-sm-6">
                          <div className="border border-top-0 border-right-0 border-left-0" style={{ fontSize: "14px" }}>

                            <div className="col-sm-6 my-1">
                                <Toggle
                                name={item.id+"wms"}
                                checked={item.WMS}
                                disabled={true}                            
                                onChange={handleChangeToggle}
                                text="สิทธิ์เข้า ห้องชั่ง" />
                           </div>
                          </div>
                          <div className="border border-top-0 border-right-0 border-left-0" style={{ fontSize: "14px" }}>
                          
                          <div className="col-sm-6 my-1">
                                <Toggle
                                name={item.id+"vms"}
                                checked={item.VMS}
                              disabled={true}
                                onChange={handleChangeToggle}
                              
                                text="สิทธิ์เข้า VMS " />
                           </div>
                          </div>

                          <div className="border border-top-0 border-right-0 border-left-0" style={{ fontSize: "14px" }}>
                          <div className="col-sm-6 my-1">
                                <Toggle
                                name={item.id+"admin"}
                                checked={item.ADMIN}
                                disabled={true}
                                onChange={handleChangeToggle}
                                text="สิทธิ์จัดการ" />
                           </div>
                          </div>
                          
<div className="d-flex justify-content-end"> 
 <button 
                          className="btn btn-secondary btn-sm"
                          onClick={() => handleOnClick(item.face_id, "edit")}
                        >
                      <FontAwesome name="edit"/>  
                               
                        </button>
</div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        })}
    </>

    );
}
 
export default RederAccountlist;