import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const PopupAlert = ({isOpen, onOk, message}) => {
    return ( 
        <div>
        <Modal isOpen={isOpen} toggle={onOk} >
          <ModalHeader>แจ้งเตือน</ModalHeader>
          <ModalBody style={{whiteSpace: 'pre'}}>
             {message}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={onOk}>OK</Button>
          </ModalFooter>
        </Modal>
      </div>
     );
}
 
export default PopupAlert;