import React,{useState} from 'react';
import LivePos from "./livepos";
import AddProduct from './addproduct';


const Itemlist = () => {

    const [id, setid] = useState("1");
    const [name, setname] = useState("");
    const [price, setprice] = useState(0);
    const [quantity, setquantity] = useState(0);
    


const currentItem = {
      id: id,
      name: name,
      price: price,
      quantity: quantity
    };
//     var items = this.state.items;
//     items.push(currentItem);
//     this.setState({ items: items });
const [items, setitems] = useState([]);
// //
// setitems(item);

//console.log(item);
console.log(items);

const [addItemModal, setaddItemModal] = useState(false);

const handleChange = (id, value) => {
       
        if (value === "delete") {
          var newitems = items.filter(function(item) {
            return item.id !== id;
          });
          setitems(newitems);
         
        } else {
          for (var i = 0; i < items.length; i++) {
            if (items[i].id === id) {
              items[i].quantity = value;

              setitems(items);
             
            }
          }
        }
      };

      const onClickAddProduct =() => {
        setaddItemModal(true);
      }

      const handleoncanCel = () => {
        setaddItemModal(false);
      }
    const renderLivePos = () => {
       // console.log(items.length)
        if (items.length === 0) {
           
          return <p> No products added</p>;
        } else {
          return items.map( item => 
          < LivePos key={item.id}  {...item  } onChange = {handleChange} />
            
         );
        }
      };

    return ( 
        <div>
              <div className="container">
                <AddProduct 
                isOpen={addItemModal}
                oncanCel ={handleoncanCel}
                />
        
 <table className="pos table table-responsive table-striped table-hover">
            <thead>
              <tr>
                <td colSpan="6" className="text-center">
                  <span className="pull-left">
                    <button
                      onClick={onClickAddProduct}
                      className="btn btn-default btn-sm"
                    >
                      <i className="glyphicon glyphicon-plus" /> Add Item
                    </button>
                  </span>
       
                </td>
              </tr>
              <tr className="titles">
                <th>Name</th>
                <th>Price</th>
                <th>Quantity</th>
                <th>Tax</th>
                <th>Total</th>
                <th />
              </tr>
            </thead>
            <tbody>{renderLivePos()}</tbody>
          </table>
          </div>
        </div>
    );
}
 
export default Itemlist;