import React from 'react';

const RenderDevicebyUser = ({list,handleOnClick}) => {

  //  console.log(list);
    return (  
<>
{list &&
          list.map((item) => ( 
            <div className="row" key={item._id}>
            <div className="container-fluid">
              <div className="card border-secondary m-1">
                
                  <div className="row">
                    <div className="container-fluid col-xs-2 col-sm-1">
                      <div
                        
                        style={{ fontSize: "14px" }}
                      >
                        <span className="badge badge-pill badge-secondary">
                          {item._id}
                        </span>
                      </div>
                      </div>
                      <div className="container col-xs-6 col-sm-6">
                      <div
                       
                        style={{ fontSize: "14px" }}
                      >
                        <span >
                          {item.name}
                        </span>
                      </div>
                      </div>
                      <div className="container col-xs-6 col-sm-2">
                      <span className="badge badge-pill badge-danger"
                         style={{ fontSize: "12px",cursor: "pointer"  }}
                       onClick={() =>
                        handleOnClick(item.id, item.name)
                      }
                      > Delete </span>
                      </div>
                      
                      </div>
                      </div>
                      </div>
                      </div>
          ))}

{/* {list &&
         list.map((item,index) => {
            return (
                <div className="row" key={item._id}>
                <div className="container-fluid">
                  <div className="card border-secondary m-1">
                    <div className="card-body">
                      <div className="row">
                        <div className="container col-xs-3 col-sm-1">
                          <div
                            className="border border-top-0 border-right-0 border-left-0"
                            style={{ fontSize: "15px" }}
                          >
                            <span className="badge badge-pill badge-secondary">
                              {item._id}
                            </span>
                          </div>
                          </div>
                          <div className="container col-xs-9 col-sm-11">
                          <div
                            className="border border-top-0 border-right-0 border-left-0"
                            style={{ fontSize: "15px" }}
                          >
                            <span className="badge badge-pill badge-secondary">
                              {item.name}
                            </span>
                          </div>
                          </div>

                          </div>
                          </div>
                          </div>
                          </div>
                          </div>)})} */}
</>

    );
}
 
export default RenderDevicebyUser;