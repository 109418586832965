import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import BranchContext from "./../../components/context/branch";
import Input from "./../wms/input";
import { getEmployee, SendFaceMultiuser } from "./../../services/facescan";
import Userinfo from "./renderuser";
import dlv from "dlv";
import Note from "./note";
import { toast } from "react-toastify";
const Searchface = ({
  button1,
  isOpen,
  header,
  onCancel,
  onConfirm,
  message,
  list,
  onDisAprove,
  value,
  onChange,
  error,
}) => {
  //console.log(value)
  const ojb = value;
  let face_id = dlv(ojb, "face_id", 0);
  let face_name = dlv(ojb, "facename", "");

  const [values, setvalues] = useState("");
  const [hasMore, setHasMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [facedata, setFaceData] = useState({
    face_id: 0,
    face_name: "",
  });

  const { currentBranch } = useContext(BranchContext);
  const [search, setsearch] = useState("");
  const [pageSize, setPageSize] = useState(4);
  const [total, setTotal] = useState(0);
  const [notes, setNotes] = useState([]);

  const [userlist, setuserlist] = useState([]);

  //console.log(face_id);

  useEffect(() => {
    if (!currentBranch) return;
    if (!face_id) return;
    if (!face_name) return;
    setFaceData({ face_id: face_id, face_name: face_name });
  }, [currentBranch, face_id, face_name]);

  useEffect(() => {
    if (!currentBranch) return;

    setuserlist([]);
  }, [currentBranch, pageSize, search, facedata]);

  useEffect(() => {
    if (!currentBranch) return;

    if (!pageSize || !pageNumber) return;
    setLoading(true);

    const loadData = async () => {
      const { data: result } = await getEmployee(search, pageSize, pageNumber);
      if (result.status === "success") {
        setTotal(result.data.total);
        //console.log(result.data.list)
        setuserlist((prevData) => {
          return [...new Set([...prevData, ...result.data.list])];
        });
        setHasMore(result.data.list.length > pageSize - 1);
        setLoading(false);
      }
    };
    loadData();
  }, [currentBranch, search, pageSize, pageNumber, facedata]);

  const savedata = () => {
    const saveobject = { EmpID: [notes], face_id: face_id };
    // console.log(saveobject)
    SaveData(saveobject);
  };

  const SaveData = async (doc) => {
    try {
      const { data: result } = await SendFaceMultiuser(doc);

      //console.log(result)

      if (result.status === "success") {
        toast.success("บันทึกเสร็จเรียบร้อย");
        Savesuccess();
        //  console.log(result.data)
      } else {
        toast.error(result.message);
        //  console.log(result.message)
      }
    } catch (error) {
      // toast.error("Error");
    }
  };

  const handleTextchange = (event) => {
    setvalues(event.currentTarget.value);
  };
  const handleSearchClick = () => {
    setsearch(values);
    setPageNumber(1);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      setsearch(values);
      setPageNumber(1);
    }
  };
  const handleOnClick = (event) => {
    const found = notes.find((item) => item.empid === event);
    if (!found) {
      addNote({ empid: event });
    }
  };
  function addNote(newNote) {
    setNotes((prevNotes) => {
      return [...prevNotes, newNote];
    });
  }

  //console.log(notes);
  const onClosemodal = () => {
    onCancel();
    setPageNumber(1);
    setsearch("");
    setvalues("");
    setFaceData({ face_id: 0, face_name: "" });

    setNotes([]);
  };
  const Savesuccess = () => {
    onConfirm();
    setPageNumber(1);
    setsearch("");
    setvalues("");
    setFaceData({ face_id: 0, face_name: "" });

    setNotes([]);
  };

  function deleteNote(id) {
    setNotes((prevNotes) => {
      return prevNotes.filter((noteItem, index) => {
        return index !== id;
      });
    });
  }

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      // console.log('node = ' ,node);
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        //  console.log(entries[0].isIntersecting,hasMore);

        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  //console.log(facedata);

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={onClosemodal}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalHeader>
          {header}

          {facedata && facedata.face_name && (
            <p
              className="font-weight-normal badge badge-warning"
              style={{ fontSize: "16px" }}
            >
              {facedata.face_name}
            </p>
          )}
        </ModalHeader>
        <ModalBody>
          <div className="mb-2 col-xs-12 col-sm-6">
            <Input
              name="name"
              value={values}
              label="รายชื่อ"
              placeholder="ชื่อ-สกุล หรือรหัสพนักงานเพื่อค้นหา"
              onChange={handleTextchange}
              onClick={handleSearchClick}
              onKeyPress={handleKeyPress}
              search="ค้นหา"
            />
          </div>

          <p className="text-success">
            แสดงข้อมูล {userlist ? userlist.length : 0} / {total} รายการ
          </p>

          <Userinfo
            list={userlist}
            handleOnClick={handleOnClick}
            error={error}
            lastBookElementRef={lastBookElementRef}
            search={true}
          />

          {!list && message}
        </ModalBody>
        {
          <div>
            <div className="row">
              <div className="col-12">
                <div className="card mb-2">
                  <div className="card-body p-2">
                    <div className="row ml-2">
                      <div className="col">
                        <div className="row ml-2">
                          <div className="col">
                            {notes.map((noteItem, index) => {
                              return (
                                <Note
                                  key={index}
                                  id={index}
                                  title={noteItem.empid}
                                  empid={noteItem.empid}
                                  onDelete={deleteNote}
                                />
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        {error && <div className="col-md-12 alert alert-danger"> {error}</div>}

        {list && list.comment && <Button color="danger">{list.comment}</Button>}
        <ModalFooter>
          <Button color="primary" onClick={savedata}>
            {button1}
          </Button>{" "}
          {list && (
            <Button color="danger" onClick={onDisAprove}>
              ไม่อนุมัติ
            </Button>
          )}
          <Button color="secondary" onClick={onClosemodal}>
            ยกเลิก
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Searchface;
