import React from "react";

function Note(props) {

   // console.log(props);
    function handleClick() {
    props.onDelete(props.id);
  }

  return (
   
       
         <>
              
                  <img
                    src={"https://wms.advanceagro.net/WSVIS/api/Face/GetImage?CardID="+ props.empid + "&size=320"}
                    className="rounded-circle mr-1"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title =""
                    alt=""
                    style={{ width: "100%", maxHeight: 40, maxWidth: 40 }}
 
                  />
                  <button
                  className="btn btn-danger btn-sm"
                  onClick={handleClick}>DELETE</button>
            
         
    </>

    
  );
}

export default Note;