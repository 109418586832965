import React from "react";

const Toggle = ({ name, checked, onChange, text ,disabled=false}) => {


return (
<div>
<div>
<div className="custom-control custom-switch">
    <input
    type="checkbox"
    className="custom-control-input"
    id={name}
    style={{ cursor: "pointer" }}
    checked={checked}
    disabled={disabled}
    onChange={onChange}
    />
    <label
    className="custom-control-label"
htmlFor={name}
style={{ cursor: "pointer" }}
>
{text}
</label>
</div>
</div>
</div>
);
};

export default Toggle;
