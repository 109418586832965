import React, { useEffect, useState, useContext , useRef,
  useCallback, } from "react";
import { getRegisterlist } from "./../../services/grabconcept";

import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import moment from "moment";
import BranchContext from "./../context/branch";
import Input from "./../wms/input";
import Renderregister from  "./renderregisterlist";
import "./../../card.css";

const Registerlist = (props) => {
  let datalink = props.location.linkdetails;
  let cusId = 0;
  //console.log(datalink);
  if (datalink) {
    cusId = datalink.cusid;
  }
  //console.log(cusId);http://wms.advanceagro.net/wsface/api/face/RealImage?IdentityID=
  
  const { currentBranch } = useContext(BranchContext);

  const [register, setregister] = useState([]);
  const [cusid, setCusid] = useState(cusId);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [vForm, setvForm] = useState(false);
  const [endDate, setEndDate] = useState(new Date());
  const [hasMore, setHasMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [pageSize, setPageSize] = useState(25);

  useEffect(() => {
    if (vForm) return;
    const getLinkdata = () => {
      if (datalink && !vForm) {
        const queuedate = new Date(datalink.ddate);
        // if (datalink.cusid) {
        setvForm(true);
        //  }

        setStartDate(queuedate);
        setEndDate(queuedate);
        setCusid(datalink.cusid);

        //  console.log(queuedate);
      } else {
        setvForm(true);
      }
    };
    getLinkdata();
  });

  
  useEffect(() => {
    setregister([]);
  }, [vForm,startDate, endDate, currentBranch,search ,cusid,pageSize]);

  useEffect(() => {

    if (!vForm) return;
    if (!currentBranch) return;
    setLoading(true)
    setError(false)
    getRegisterlist(
      moment(startDate, "YYYY-MM-DD").add(0, "day").format("YYYY-MM-DD"),
      moment(endDate, "YYYY-MM-DD").add(0, "day").format("YYYY-MM-DD"),
      currentBranch && currentBranch._id,
      cusid,search,pageSize,pageNumber
    ).then((result) => {
      //  console.log(result.data);
      setregister((prevData) => {
        return [...new Set([...prevData, ...result.data])];
      });
      setHasMore(result.data.length > pageSize - 1);
      setLoading(false);

     // setregister(result.data);
    });
  }, [vForm,startDate, endDate, currentBranch,search ,cusid,pageSize,pageNumber]);

  const handleChangStartDate = (event) =>{
    setStartDate(event);
    setPageNumber(1);
  }
  const handleChangEndDate = (event) =>{
    setEndDate(event);
     setPageNumber(1);  
   }
  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
       // console.log('node = ' ,node);
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        //  console.log(entries[0].isIntersecting,hasMore);

        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const handleTextchange = (event) => {
    setSearch(event.target.value);
    setPageNumber(1);

  };
  const handleKeyPress = () => {

  };
  const handleSearchDrinvenClick = () => {

  };
//console.log(currentBranch._id);
//console.log(register);
  if (!vForm) {
    return <div></div>;
  }
  if (currentBranch._id == 'vms'){
    return <>
    </>
  }

  return (
    <div className="row container-fluid">
   

      <div className="col-12">
        <div className="container">
          <div className="row">
            
            <div className="col-auto">
              <div className="m-2">
                <i>
                  จากวันที่ <span className="label label-default"></span>
                </i>

                <div
                  className="mt-2"
                  style={{ marginTop: 20, marginBottom: 20 }}
                >
                  <DatePicker
                    todayButton="Today"
                    dateFormat="dd/MM/yyyy"
                    selected={startDate}
                    onChange={handleChangStartDate}
                  />
                </div>
              </div>
            </div>
            <div className="col-auto">
              <div className="m-2">
                <i>
                  ถึงวันที่ <span className="label label-default"></span>
                </i>
                <div className="mt-2">
                  <DatePicker
                    todayButton="Today"
                    dateFormat="dd/MM/yyyy"
                    selected={endDate}
                    onChange={handleChangEndDate}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 col-sm-6 col-md-6 col-xs-12">
            <div className="form-group">
              <Input
                name="weightno"
                value={search}
                placeholder="ทะเบียนรถ เลขที่ใบชั่ง"
                onChange={handleTextchange}
                onKeyPress={handleKeyPress}
                onClick={handleSearchDrinvenClick}
                search="ค้นหา"
              />
            </div>
          </div>
          
          <div className="col-lg-6 col-sm-6 col-md-6 col-xs-12">
          <div>&nbsp;</div>
            <button className="btn btn-success ">Download To Excel</button>
          </div>
         
        </div>
        <p className="text-success">
        แสดงข้อมูล {register ? register.length : 0} รายการ บนระบบ
        </p>
        <Renderregister
        list = {register}
        loading={loading}
        error={error}
        lastBookElementRef ={lastBookElementRef}
        
        />
{/* 

        <table className="table table-striped">
          <thead className="thead-dark">
            <tr>
           
              <th>ชื่อลูกค้า</th>
              <th>พจส.</th>
              <th>สินค้า</th>
              <th>ใบนำส่ง</th>
              <th>ประเภทรถ</th>
              <th>ทะเบียนหัว</th>
              <th>ทะเบียนหาง</th>
              <th>วันที่ ลงสินค้า</th>
             
              <th>สถานที่ลง</th>
              <th>น้ำหนัก ขาเข้า </th>
              <th>น้ำหนัก ขาออก</th>
              <th width="150px"></th>
            </tr>
          </thead>
          <tbody>
            {register &&
              register.map((values,index) => {
                if (register.length === index +1) {
                  return  (
                    <tr ref = {lastBookElementRef}  key={values.AutoID}>
                     
                      <td style={{ fontSize: "13px" }}>{values.Customer_Name}</td>
                      <td>
                      <span className="d-none d-md-inline text-black pr-2">
                     <img className="rounded-circle ml-2" 
                   src={url+ values.Tag+'&size=320'}
                   style={{width: '100%', maxHeight: 50, maxWidth: 40}} />  {values.Driver_Name} </span>

                    
                    

                      </td>
                      <td>{values.type_name + "/" + values.ProductName}</td>
    
                      <td>{values.DO}</td>
                      <td>{values.truck_typename}</td>
                      <td>{values.first_driven_no}</td>
                      <td>{values.second_driven_no}</td>
                      <td>{values.queue_date}</td>
                    
                      <td>{values.storename}</td>
                      <td>{values.receive_weightin}</td>
                      <td>{values.receive_weightout}</td>
    
                      <td>
                        <div className="d-inline">
                          <Link
                            className="btn btn-warning  btn-sm"
                            style={{ width: "30px" }}
                            to={`/register/${values.AutoID}`}
                          >
                            View
                          </Link>
                        </div>
                      </td>
                    </tr>
                  )
                }else {

              return  (
                <tr key={values.AutoID}>
                
                  <td style={{ fontSize: "13px" }}>{values.Customer_Name}</td>
                  <td>
                    <div className="mytooltip tooltip-effect-1">
                      <span className="tooltip-item">
                        <span className="d-none d-md-inline text-black pr-2">
                          <img
                            className="rounded-circle ml-2"
                            src={url + values.Tag + "&size=320"}
                            style={{
                            
                              maxHeight: 50,
                              Width: 300,
                            }}
                          />{" "}
                          {values.Driver_Name}{" "}
                        </span>
                      </span>

                      <span className="tooltip-content clearfix">
                        <img
                          className="rounded-circle ml-2"
                          src={url + values.Tag + "&size=320"}
                          style={{
                            width: "100%",
                            minHeight: "300px",
                            minWidth: "300px",
                          }}
                        />

                        <span className="tooltip-text"></span>
                      </span>
                    </div>
                  </td>
                  <td>{values.type_name + "/" + values.ProductName}</td>
                  <td>{values.type_name + "/" + values.ProductName}</td>

                  <td>{values.DO}</td>
                  <td>{values.truck_typename}</td>
                  <td>{values.first_driven_no}</td>
                  <td>{values.second_driven_no}</td>
                  <td>{values.queue_date}</td>
                
                  <td>{values.storename}</td>
                  <td>{values.receive_weightin}</td>
                  <td>{values.receive_weightout}</td>

                  <td>
                    <div className="d-inline">
                      <Link
                        className="btn btn-warning  btn-sm"
                        style={{ width: "50px" }}
                        to={`/register/${values.AutoID}`}
                      >
                        View
                      </Link>
                    </div>
                  </td>
                </tr>
              )}
              })}
          </tbody>
        </table> */}
      </div>
      <div>{loading && 'Loading...'}</div>
      <div>{error && 'Error'}</div>
    </div>
  );
};

export default Registerlist;
