import React from "react";
import Modal from "react-bootstrap/Modal";
import SearchVendor from "./searchvendor";
//import { Button } from "antd";
import { Button} from 'reactstrap';

const ModalSearch = ({isOpen, onCancel, onKeyPress, value,onSelected,label,placeholder}) => {


 // const [isOpen, setIsOpen] = useState(false);
  function handleUpdateData(value) {
 //   console.log("value from Modal Page=>", value);
   onSelected(value);
   
  }
  return (
    // <div className="container">
    //   <button variant="danger" onClick={() => setIsOpen(true)}>
    //     เลือกพนักงาน
    //   </button>
      <Modal
        show={isOpen}
        onHide={onCancel}
        value = {value}  
        size="col-12 col-md-10 col-lg-8 mb-1"
        aria-labelledby="contained-modal-title-vcenter"
        centered
                         >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {label}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SearchVendor updateData={handleUpdateData}
          placeholder ={placeholder}
          value = {value}
          textProperty ="name"
          />
        </Modal.Body>
        <Modal.Footer>
        <Button color="secondary" onClick={onCancel}>ยกเลิก</Button> 
        </Modal.Footer>
      </Modal>
    // </div>
  );
};

export default ModalSearch;