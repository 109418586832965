import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import Rendercockinout from "./rendercockinout";
import Header from "./header";
import DatePicker from "react-datepicker";
import moment from "moment";
import { getCockinout } from "./../../services/vms";
import ModalSearch from "./modalsupplier";

import BranchContext from "./../../components/context/branch";
import Input from "./../wms/input";

const Cockinout = () => {
  const { currentBranch } = useContext(BranchContext);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [cockinout, setcockinout] = useState([]);
  const [value, setValue] = useState(1);
  const [Id, setId] = useState("");
  const [isOpen, setisOpen] = useState(false);
  const [message, setMessage] = useState({ errors: false, messages: "" });

  const [hasMore, setHasMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [pageSize, setPageSize] = useState(25);

  useEffect(() => {
    setcockinout([]);
  }, [currentBranch, startDate, endDate, value, Id]);

  useEffect(() => {
    if (!currentBranch) return;
    //console.log('call api')

    setLoading(true)
    setError(false)
    const loadData = async () => {
      //  console.log('resualt',Id,pageSize,pageNumber)
      getCockinout(
        value,
        moment(startDate, "YYYY-MM-DD").add(0, "day").format("YYYY-MM-DD"),
        moment(endDate, "YYYY-MM-DD").add(0, "day").format("YYYY-MM-DD"),
        Id,
        pageSize,
        pageNumber
      ).then((result) => {
        //   console.log('resualt',result.data)
        setcockinout((prevData) => {
          return [...new Set([...prevData, ...result.data])];
        });
        setHasMore(result.data.length > pageSize - 1);
        setLoading(false);
        // console.log('resualt',result.data)
      });
    };

    loadData();
  }, [currentBranch, startDate, endDate, value, pageNumber, Id,pageSize]);

  // const clearArray =() => {
  //   setcockinout([]);
  //  }

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      //  console.log('node = ' ,node);
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        //  console.log(entries[0].isIntersecting,hasMore);

        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const handleKeyPress = (event) => {
    //console.log(event);

    if (event.key === "Enter") {
      if (Id.length > 12) {
        handleSearchDrinvenClick();
      } else {
        setisOpen(true);
      }
    }
  };
  const handleTextchange = (event) => {
    setId(event.currentTarget.value);

    //console.log(event.key);
  };

  const handleSearchDrinvenClick = () => {
    // console.log('you click')

    if (Id.length < 13) {
      setisOpen(true);
      return;
    }

    // loadData();
  };

  //   const loadData = () => {
  //     clearArray();

  //     getCockinout(
  //       value,
  //       moment(startDate, "YYYY-MM-DD").add(0, "day").format("YYYY-MM-DD"),
  //       moment(endDate, "YYYY-MM-DD").add(0, "day").format("YYYY-MM-DD"),
  //       Id,pageSize,pageNumber
  //     ).then((result) => {

  //       setcockinout(result.data);
  //        setHasMore(result.data.length > pageSize-1)
  //        setLoading(false)

  // console.log(result.data);

  //     });
  //   };
  const handleOnselected = (value) => {
    setisOpen(false);
    const { IdentityID } = value;
    setId(IdentityID);
    //  loadData();
    //console.log(IdentityID);
  };
  const handelCancel = () => {
    setisOpen(false);
  };

  const handleChange = (event) => {
    setValue(event);
    setPageNumber(1);
  };
  const handleDateChange = (date) => {
    setStartDate(date);
  };
  return (
    <div>
      <ModalSearch
        onCancel={handelCancel}
        isOpen={isOpen}
        value={Id}
        onSelected={handleOnselected}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-auto">
            <div className="m-2">
              <i>
                จากวันที่ <span className="label label-default"></span>
              </i>

              <div className="mt-2" style={{ marginTop: 20, marginBottom: 20 }}>
                <DatePicker
                  todayButton="Today"
                  dateFormat="dd/MM/yyyy"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                />
              </div>
            </div>
          </div>
          <div className="col-auto">
            <div className="m-2">
              <i>
                ถึงวันที่ <span className="label label-default"></span>
              </i>
              <div className="mt-2">
                <DatePicker
                  todayButton="Today"
                  dateFormat="dd/MM/yyyy"
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                />
              </div>
            </div>
          </div>

          <div className="col-auto">
            <div className="m-2">
              <i>
                พื้นที่ <span className="label label-default"></span>
              </i>
              <div className="mt-2" style={{ marginTop: 20, marginBottom: 20 }}>
                <Header
                  onChange={handleChange}
                  handleDateChange={handleDateChange}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <Input
                name="Name"
                value={Id}
                placeholder="เลขบัตร ชื่อ สกุล"
                onChange={handleTextchange}
                onKeyPress={handleKeyPress}
                onClick={handleSearchDrinvenClick}
                error={message.messages}
                search="ค้นหา"
              />
            </div>
          </div>

          <div className="col-6" style={{ marginTop: 15 }}>
            <button className="btn btn-success m-2">Download To Excel</button>
          </div>
        </div>

        <p className="text-success">
          แสดงข้อมูล {cockinout ? cockinout.length : 0} รายการ บนระบบ
        </p>
        <div className="row">
          <Rendercockinout
            list={cockinout}
            loading={loading}
            error={error}
            lastBookElementRef={lastBookElementRef}
          />
        </div>
      </div>
    </div>
  );
};

export default Cockinout;
