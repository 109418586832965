import React,{useEffect, useState} from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {getgate,getCarType,getProvince,SaveAccessGate} from './../../services/gate';
 import Select from "../wms/select";
import dlv from "dlv";
import { useForm } from "react-hook-form";
import moment from 'moment';
import { FormInput } from "../common/forminput";
//import { FormSelect } from "../common/formselect";
//import {savedevice,getfacesite,getfacegroup} from '../../services/facescan';
import FontAwesome from 'react-fontawesome';
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";

const AddDevice = ({
  button1,
  isOpen,
  header,
  onCancel,
  onconfirm,

  list

}) => {

  const ojb = list[0];

  const editid= dlv(ojb, 'face_no','');
  let editname = dlv(ojb, 'face_name','');
  let editip = dlv(ojb, 'face_ip','');
  let editaccount = dlv(ojb, 'face_user','admin');

  let editpassword = dlv(ojb, 'face_pass','');
  let editport = dlv(ojb, 'face_port','8000');
  let editgroupid = dlv(ojb, 'group_id',0); 
  let editsiteid = dlv(ojb, 'site_id',0); 
  
 //console.log(editd,editname)

 const PreloadedValues = {

  id: editid,
  device: editname,
  port: editport,
  deviceaccount: editaccount,
  devicepassword: editpassword,

   ip:editip
};


  const [cartypeId, setcartypeid] = useState(editgroupid);
  const [gateid, setgateid] = useState(editsiteid);
  const [error,seterror] = useState('');
  const [date, setDate] = useState();

  const [autoid, setautoid] = useState(0);

  const [fProvinceID, setfProvinceID] = useState(0);
  const [sProvicenID, setsProvicenID] = useState(0);
  const [gatelist, setgatelist] = useState([]);
   const [cartypelist, setcartypelist] = useState([]);
   const [fristProvincelist, setFristProvincelist] = useState([]);
   const [secondProvincelist, setSecondProvincelist] = useState([]);
   const [startDate, setStartDate] = useState(new Date());
   const [endDate, setEndDate] = useState(new Date());

  const { register, errors, handleSubmit, setValue } = useForm({
    mode: "all",
    // defaultValues: PreloadedValues,
  });

  const [messageErros, setmessageErros] = useState({
    askAlert: { enable: false, message: "" },
  });


useEffect(() => {
  if (list.length) {

  //  console.log(list[0]);
    setValue([{ device: list[0].face_name }, 
      { ip: list[0].face_ip },{port:list[0].face_port},{
        deviceaccount:list[0].account}, {devicepassword:list[0].face_pass},
      {port:list[0].face_port},{id:list[0].face_no}
      ]);
    //  setgroupid(list[0].group_id);
      setgateid(list[0].site_id);
      setautoid(list[0].face_id)
  }else{
   // setgroupid(0);
    setgateid(0);
    setautoid(0);
  }
}, [list]);  


  useEffect(() => {
   
        const loadData = async () => {

            const { data: result } = await getgate();
            if ( result.status==='success') {
              setgatelist(result.data.list)
                         
            }

    };
    loadData();
  }         
  , []);


  useEffect(() => {
    
        const loadData = async () => {

            const { data: result } = await getCarType();
            if ( result.status==='success') {
              setcartypelist(result.data.list)
          // setvForm(true);        
            }

    };
    loadData();
  }         
  , []);

  useEffect(() => {
    
    const loadData = async () => {

        const { data: result } = await getProvince();
        if ( result.status==='success') {
          setFristProvincelist(result.data.list)
      // setvForm(true);        
        }

};
loadData();
}         
, []);

useEffect(() => {
    
  const loadData = async () => {

      const { data: result } = await getProvince();
      if ( result.status==='success') {
        setSecondProvincelist(result.data.list)
    // setvForm(true);        
      }

};
loadData();
}         
, []);

const handlesiteChange = (event) => {
  setgateid(event.currentTarget.value);
  if ( event.currentTarget.value!==0)
  {
    seterror('')
  }
 }
          const onSubmit = (data) => {      
           seterror('')
           if (cartypeId==0){
            seterror('กรุณาระบุ ประเภทรถ')
            return;
           }   
          else if (gateid==0){
            seterror('กรุณาระบุ พื้นที่ก่อน')
              return;
          }else if (fProvinceID == 0) 
          { 
            seterror('กรุณาระบุ จังหวัดก่อน')
            return;
          }


          const saveobject = { _id:autoid, fdrivenprov:fProvinceID, ldrivenprov:sProvicenID,  
            cartypeid:cartypeId, GateId:gateid ,
            startdate : moment(startDate, "YYYY-MM-DD HH:MM:ss").add(0, 'day').format("YYYY-MM-DD HH:MM:ss"), 
            enddate : moment(endDate, "YYYY-MM-DD HH:MM:ss").add(0, 'day').format("YYYY-MM-DD HH:MM:ss")
            , ...data };
            SaveData(saveobject)    
           // console.log(JSON.stringify(saveobject))        
          };    
          const  SaveData = async (doc) => {
            try { 
             const {data: result} = await SaveAccessGate(doc);     
             if (result.status==='success') {
               toast.success("บันทึกเสร็จเรียบร้อย");
          
              onconfirm();
              }
             else {
             toast.error(result.message)
            // console.log(result.message);
            }
          } 
          catch (error) {
            toast.error(error)
          //  console.log(error);
          }
        }
  const handelChangeCarType =(event) => {
      setcartypeid(event.currentTarget.value)
      if ( event.currentTarget.value!==0)
  {
    seterror('')
  }
  }

  const handelChangeFProvince =(event) => {
    setfProvinceID(event.currentTarget.value)
    if ( event.currentTarget.value!==0)
  {
    seterror('')
  }
}

const handelChangeSProvince =(event) => {
  setsProvicenID(event.currentTarget.value)
}

  return (

    <form onSubmit={(e) => e.preventDefault()}>
    <div>
      <Modal
        isOpen={isOpen}
        toggle={onCancel}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalHeader> 
        <FontAwesome name ='pencil-square-o'  >  </FontAwesome> {header}
      
        
        </ModalHeader>
        <ModalBody>
      <div className="row">
       <div className="col-sm-6 col-xs-12">
         <div className="form-group">
 {/* <FormSelect 
     name="gateid" 
     textProperty="name"
     valueProperty="_id"
     options={gatelist} /> */}
              <Select
                label="พื้นที่"
                data={gatelist}
                value= {gateid}
                textProperty="name"
                valueProperty="_id"
                onChange={handlesiteChange}
              />
              



            </div>
            </div>

            <div className="col-sm-6 col-xs-12">
         <div className="form-group">
             

<FormInput 
                                id="remark"
                                name="remark"
                                type="text"
                                label="เหตุผลในการขอ"
                                placeholder="ระบุเหตุผล"
                                onChange = {e => setValue()} 
                                defaultValue={PreloadedValues.remark}
                                register={register({
                                  required: "กรุณาระบุเหตุผลในการขอ",
                                  maxLength: {
                                    value: 1000,
                                    message: "Max length is 1000",
                                  },
                                  minLength: {
                                    value: 2,
                                    message: "ระบุอย่างน้อย 2 ตัวอักษร",
                                  },
                                })}
                                error={errors.remark}
                              />
            </div>
            </div>
        </div>
        <div className="mt-1">

</div>
  <div className="card text-center border-secondary">
  <div className="card-header bg-warning">
  <FontAwesome name ='truck'  > </FontAwesome>  วันเวลา
</div>
<div className="card-body">

<div className="row">
<div className="col-sm-6 col-xs-12">                        
<div className="form-group">

<label htmlFor="typename">วันเวลาที่จะเข้า</label>
                          <div>

                          {/* <Controller
            name="date"
            defaultValue={date}
            control={control}
            onChange={value => value[0]}
            onChangeName="onDateChange"
            valueName="date"
            as={
              <DatePicker >
                {({ inputProps, focused }) => (
                  <input value={date} {...inputProps} />
                )}
              </DatePicker>
            }
          /> */}
                          <DatePicker
              isClearable
              innerRef={register({ required: true })}
              name="datetime1"
              className={"form-control"}
              selected={startDate}
              onChange={val => {
                setStartDate(val);
                setValue("start", val);
              }}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="time"
              dateFormat="MM-dd-yyyy h:mm"
            />
          </div>
          </div>
          </div>

          <div className="col-sm-6 col-xs-12">
       <div className="form-group">
       <label htmlFor="typename">วันเวลาที่จะออก</label>
                          <div>
                          <DatePicker
              isClearable
              innerRef={register({ required: true })}
              name="datetime2"
              className={"form-control"}
              selected={endDate}
              onChange={val => {
                setEndDate(val);
                setValue("end", val);
              }}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="time"
              dateFormat="MM-dd-yyyy h:mm"
            />
          </div> 
       
          </div>
          </div>
      </div>
      

      </div>
          </div>
          <div className="mt-1">

          </div>


        <div className="card text-center border-secondary">
    <div className="card-header bg-info">
    <FontAwesome name ='user'  > </FontAwesome> ข้อมูลคนขับ
  </div>
  <div className="card-body">

         <div className="row">
       <div className="col-sm-6 col-xs-12">                        
         <div className="form-group">
         <FormInput
                                id="fname"
                                name="fname"
                                type="text"
                              
                                placeholder="ระบุชื่อคนขับ"
                                onChange = {e => setValue()} 
                                defaultValue={PreloadedValues.fname}
                                register={register({
                                  required: "กรุณาระบุ ชื่อคนขับ",
                                  maxLength: {
                                    value: 1000,
                                    message: "Max length is 1000",
                                  },
                                  minLength: {
                                    value: 2,
                                    message: "ระบุอย่างน้อย 2 ตัวอักษร",
                                  },
                                })}
                                error={errors.fname}
                              />
            </div>
            </div>

            <div className="col-sm-6 col-xs-12">
            <div className="form-group">
<FormInput
                                id="lname"
                                name="lname"
                                type="text"
                              
                                placeholder="ระบุนามสกุล"
                                onChange = {e => setValue()} 
                                defaultValue={PreloadedValues.firstdriven}
                                register={register({
                                  required: "กรุณาระบุ สกุลคนขับ",
                                  maxLength: {
                                    value: 1000,
                                    message: "Max length is 1000",
                                  },
                                  minLength: {
                                    value: 2,
                                    message: "ระบุอย่างน้อย 2 ตัวอักษร",
                                  },
                                })}
                                error={errors.lname}
                              />
            </div>
            </div>
        </div>

        <div className="row">
       <div className="col-sm-6 col-xs-12">

      
                                
         <div className="form-group">
<FormInput
                                id="drivenno"
                                name="drivenno"
                                type="text"
                               
                                placeholder="ระบุ รหัสพนักงาน/เลขบัตร"
                                onChange = {e => setValue()} 
                                defaultValue={PreloadedValues.drivenno}
                                register={register({
                                  required: "กรุณาระบุ รหัสพนักงาน/เลขบัตร",
                                  maxLength: {
                                    value: 13,
                                    message: "Max length is 13",
                                  },
                                  minLength: {
                                    value: 2,
                                    message: "ระบุอย่างน้อย 2 ตัวอักษร",
                                  },
                                })}
                                error={errors.drivenno}
                              />
            </div>
            </div>

            <div className="col-sm-6 col-xs-12">
         <div className="form-group">
             
        
            </div>
            </div>
        </div>


        </div>
            </div>
  
  <div className="mt-1">

  </div>
    <div className="card text-center border-secondary">
    <div className="card-header bg-secondary">
    <FontAwesome name ='truck'  > </FontAwesome>  ข้อมูลรถ
  </div>
  <div className="card-body">

         <div className="row">
       <div className="col-sm-6 col-xs-12">                        
         <div className="form-group">
         <Select
               
                data={cartypelist}
                value= {cartypeId}
                textProperty="name"
                valueProperty="_id"
                onChange={handelChangeCarType}
              />
            </div>
            </div>

            <div className="col-sm-6 col-xs-12">
       
            </div>
        </div>

        <div className="row">
       <div className="col-sm-6 col-xs-12">

      
                                
         <div className="form-group">
<FormInput
                                id="fdrivenno"
                                name="fdrivenno"
                                type="text"
                              
                                placeholder="ระบุ ทะเบียนหัว"
                                onChange = {e => setValue()} 
                                defaultValue={PreloadedValues.fdrivenno}
                                register={register({
                                  required: "กรุณาระบุ ทะเบียนรถหัว",
                                  maxLength: {
                                    value: 10,
                                    message: "Max length is 10",
                                  },
                                  minLength: {
                                    value: 2,
                                    message: "ระบุอย่างน้อย 2 ตัวอักษร",
                                  },
                                })}
                                error={errors.fdrivenno}
                              />
            </div>
            </div>

            <div className="col-sm-6 col-xs-12">
         <div className="form-group">
             
         <Select
               
                data={fristProvincelist}
                value= {fProvinceID}
                textProperty="name"
                valueProperty="_id"
                onChange={handelChangeFProvince}
              />
            </div>
            </div>
        </div>
        <div className="row">
       <div className="col-sm-6 col-xs-12">
         <div className="form-group">
<FormInput
                                id="ldrivenno"
                                name="ldrivenno"
                                type="text"
                            
                                placeholder="ระบุทะเบียนหาง"
                                onChange = {e => setValue()} 
                                defaultValue={PreloadedValues.ldrivenno}
                                register={register()}
                                error={errors.ldrivenno}
                              />
            </div>
            </div>

            <div className="col-sm-6 col-xs-12">
         <div className="form-group">
             

         <Select
           
                data={secondProvincelist}
                value= {sProvicenID}
                textProperty="name"
                valueProperty="_id"
                onChange={handelChangeSProvince}
              />
            </div>
            </div>
        </div>

        </div>
            </div>
           
           
   

        </ModalBody>
        { error && <div className="col-md-12 alert alert-danger">  { error}</div>}

        {list && list.comment &&
            <Button color="danger">
              {list.comment}
            </Button>
          }
        <ModalFooter>

      
          <Button color="primary" onClick={handleSubmit(onSubmit)}>
            {button1}
          </Button>{" "}
       
          <Button color="secondary" onClick={onCancel}>
            ยกเลิก
          </Button>
        </ModalFooter>
      </Modal>
    </div>
    </form>
  );
};

export default AddDevice;
