import React, { useEffect, useState, useContext , useRef,
  useCallback, } from "react";
import {getProductType } from "../../services/grabconcept";
import {getWeightlist} from "../../services/wms";
import SearchBox from "../common/searchBox";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import moment from "moment";
import BranchContext from "../context/branch";
import Input from "./input";
import Select from "./select";
const Weightlist = (props) => {
  let datalink = props.location.linkdetails;
  let cusId = 0;
  //console.log(datalink);
  if (datalink) {
    cusId = datalink.cusid;
  }
  //console.log(cusId);

  const { currentBranch } = useContext(BranchContext);

  const [register, setregister] = useState([]);

  const [cusid, setCusid] = useState(cusId);
  const [search, setSearch] = useState("");
  const [confirmsearch, setconfirmsearch] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [vForm, setvForm] = useState(false);
  const [endDate, setEndDate] = useState(new Date());
  const [hasMore, setHasMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [productType, setProductType] = useState([]);
  const [productTypeId, setProductTypeId] = useState(0);

  const [pageSize, setPageSize] = useState(25);

  useEffect(() => {
    if (vForm) return;
    const getLinkdata = () => {
      if (datalink && !vForm) {
        const queuedate = new Date(datalink.ddate);
        // if (datalink.cusid) {
        setvForm(true);
        //  }

        setStartDate(queuedate);
        setEndDate(queuedate);
        setCusid(datalink.cusid);

        //  console.log(queuedate);
      } else {
        setvForm(true);
      }
    };
    getLinkdata();
  });

  useEffect(() => {
    setregister([]);
  }, [vForm,startDate,endDate,confirmsearch, currentBranch,productTypeId ,pageSize]);

  useEffect(() =>  {
    // console.log(startDate);
    if (!vForm) return;
    if (!currentBranch) return;
    setLoading(true)
    setError(false)
    getWeightlist(
      moment(startDate, "YYYY-MM-DD").add(0, "day").format("YYYY-MM-DD"),
      moment(endDate, "YYYY-MM-DD").add(0, "day").format("YYYY-MM-DD"),
      currentBranch && currentBranch._id,
      productTypeId,confirmsearch,pageSize,pageNumber
    ).then((result) => {
    
      setregister((prevData) => {
        return [...new Set([...prevData, ...result.data])];
      });
      setHasMore(result.data.length > pageSize - 1);
      setLoading(false);

    
    });
  }, [vForm,startDate, endDate,confirmsearch, currentBranch,productTypeId,pageSize,pageNumber]);

  
 useEffect(() => {
  if (! currentBranch ) return; 
  getProductType((currentBranch._id)).then (result=>{ 
    setProductType(
     result.data);  
   
               });
             }, 
  [currentBranch])
 // console.log(register)
  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      //  console.log('node = ' ,node);
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        //  console.log(entries[0].isIntersecting,hasMore);

        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const handleTextchange = (event) => {
    setSearch(event.target.value);

  };
  const handleKeyPress = () => {

  };
  const handleSearchDrinvenClick = () => {
   // console.log('set confimr =',search)
    setconfirmsearch(search);
  };
  const handleChangeProductTypeID=(event)=>{
    setProductTypeId(event.currentTarget.value)
    setPageNumber(1);
   
    }
    const handleSearchTypeClick = () => {
    }
    const handleStartDate = (event)=>{
    setStartDate(event)
    setPageNumber(1)
    }
    const handleEndDate = (event)=>{
      setEndDate(event)
      setPageNumber(1)
      }
  if (!vForm) {
    return <div></div>;
  }
  if (currentBranch.id == 'vms'){
    return <>
    </>
  }
  return (
    <div className="row container-fluid">
     

      <div className="col-12">
        <div className="container">
          <div className="row">
            <div className="col-auto">
              <div className="m-2">
                <i>
                  จากวันที่ <span className="label label-default"></span>
                </i>

                <div
                  className="mt-2"
                  style={{ marginTop: 20, marginBottom: 20 }}
                >
                  <DatePicker
                    todayButton="Today"
                    dateFormat="dd/MM/yyyy"
                    selected={startDate}
                    onChange={handleStartDate}
                  />
                </div>
              </div>
            </div>
            <div className="col-auto">
              <div className="m-2">
                <i>
                  ถึงวันที่ <span className="label label-default"></span>
                </i>
                <div className="mt-2">
                  <DatePicker
                    todayButton="Today"
                    dateFormat="dd/MM/yyyy"
                    selected={endDate}
                    onChange={handleEndDate}
                  />
                </div>
              </div>
            </div>
            <div className="col-auto">
              <div className="m-2">
            <Select 
         data ={productType}
         label = "ประเภทสินค้า"
         value={productTypeId} 
         textProperty="type_name"
         valueProperty="Type_ID"    
         onChange={handleChangeProductTypeID}
         onClick ={handleSearchTypeClick}
         search="ค้นหา"
         />
          </div>
        </div>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <Input
                name="weightno"
                value={search}
                placeholder="ทะเบียนรถ เลขที่ใบชั่ง"
                onChange={handleTextchange}
                onKeyPress={handleKeyPress}
                onClick={handleSearchDrinvenClick}
                search="ค้นหา"
              />
            </div>
          </div>

          <div className="col-6" style={{ marginTop: 15 }}>
            <button className="btn btn-success m-2">Download To Excel</button>
          </div>
        </div>
       
        <p className="text-success">
        แสดงข้อมูล {register ? register.length : 0} รายการ บนระบบ
        </p>
        <table className="table table-striped">
          <thead className="thead-dark">
            <tr>
              {/* <th >ประเภทลูกค้า</th> */}
              <th>ชื่อลูกค้า</th>
              <th>ประเภทลูกค้า</th>
              <th>ประเภท/สินค้า</th>
              <th>ใบนำส่ง</th>
            
              <th>ทะเบียนหัว</th>
              <th>ทะเบียนหาง</th>
              <th>วันที่ลงสินค้า</th>
              {/* <th >วัตถุประสงค์</th> */}
           
              <th>น้ำหนัก ขาเข้า </th>
              <th>น้ำหนัก ขาออก</th>
              {/* <th width="150px"></th> */}
            </tr>
          </thead>
          <tbody>
            {register &&
              register.map((values,index) => {
                if (register.length === index +1) {
                  return  (
                    <tr ref = {lastBookElementRef}  key={values.No}>
                      {/* <td>{values.CustomerTypeName}</td> */}
                      <td>{values.Customer_Name}</td>
                      <td>{values.CustomerTypeName}</td>
                      <td>{values.ProductName}</td>
    
                      <td>{values.Delivery_No}</td>
                     
                      <td>{values.First_Driven_NO}</td>
                      <td>{values.Second_driven_no}</td>
                      <td>{values.DateIn}</td>
                      {/* <td>{values.aim_name}</td> */}
                  
                      <td>{values.receive_weightin}</td>
                      <td>{values.receive_weightout}</td>
    
                      {/* <td>
                        <div className="d-inline">
                          <Link
                            className="btn btn-warning  btn-sm"
                            style={{ width: "50px" }}
                            to={`/register/${values.AutoID}`}
                          >
                            View
                          </Link>
                        </div>
                      </td> */}
                    </tr>
                  )
                }else {

              return  (
                <tr key={values.No}>
                  {/* <td>{values.CustomerTypeName}</td> */}
                  <td >{values.Customer_Name}</td>
                  <td>{values.CustomerTypeName}</td>
                      <td>{values.ProductName}</td>
    
                      <td>{values.Delivery_No}</td>
                     
                      <td>{values.First_Driven_NO}</td>
                      <td>{values.Second_driven_no}</td>
                      <td>{values.DateIn}</td>
                      {/* <td>{values.aim_name}</td> */}
                  
                      <td>{values.receive_weightin}</td>
                      <td>{values.receive_weightout}</td>

                  {/* <td>
                    <div className="d-inline">
                      <Link
                        className="btn btn-warning  btn-sm"
                        style={{ width: "50px" }}
                        to={`/register/${values.AutoID}`}
                      >
                        View
                      </Link>
                    </div>
                  </td> */}
                </tr>
              )}
              })}
          </tbody>
        </table>
      </div>
      <div>{loading && 'Loading...'}</div>
      <div>{error && 'Error'}</div>
    </div>
  );
};

export default Weightlist;
