import React,{useState,useEffect} from "react";
import Rendershutdown from "./rendershutdown";
import Modalshutdown from './modaladdshutdown'
import {getShutdownPlan,getSupplier,getSupplierPlan,SaveSupplierData} from '../../services/vms';
import Rendersupplier from "./rendersupplier";
import SupplierAdd from './supplieradd';
import { toast } from "react-toastify";
const Supplierjob = (props)  => {

//  console.log(props);

const [supllierplan, setsupplierplan] = useState([]);

const [Id, setId] = useState(props.match.params.id);
const [SupID,setSupID] = useState(0)

const [successID,setSuccessID] = useState(0);

const [shudownplan ,setshutdownplan] = useState(
{
  planname:'',BUName:'',AreaName:'',StartDate: '',Enddate: '',
}
);

const [messageErros, setmessageErros] = useState({
  askAlert: {enable: false, message: ""}
});

const handleChange = (planid, detail) =>{

  if (detail==="new"){

  }
  //console.log(planid,detail)
}

const handleOnConfirm= (id) => {
 // console.log(id);
  setSupID(id);
  confirmSave(id);

//doSubmit();

}

const confirmSave = (id) => {
  
 // console.log(SupID);
//console.log(id);
  if (!(Id)  ||  Id===0) {
    setmessageErros( { askAlert: {enable: true, message: "ยังไม่ระบุงาน\nกรุณาระบุก่อนค่ะ"}})
    return;

  }
  if (!(id) || id ===0 ) {
    setmessageErros( { askAlert: {enable: true, message: "ยังไม่ระบุ Vendor \nกรุณาระบุก่อนค่ะ"}})
    return;


  }

  doSubmit(id);
}

const  doSubmit = async (id) => {
  try {
    
     const doc = {   
       _id:"New",  planId: Id, SupplierID: id
     };

    const {data: result} = await SaveSupplierData(doc);
 
  if (result.status==='success') {
     toast.success("บันทึกเสร็จเรียบร้อย");

     setmessageErros( { askAlert: {enable: false, message: ""}})
     setSuccessID(id);
   } else {
     toast.error(result.message)
     setmessageErros( { askAlert: {enable: true, message:result.message}})
   }

  } catch(err) {
    toast.error("Error")
  }

}

useEffect(() => {
const loadData = () => {

  if (!Id) return;

  getShutdownPlan((Id)).then (result=>{ 
    
    
   
    if (result.data.status==="success"){
    
      setshutdownplan(result.data.data.shutdownplan);

    }


         
               });
              }
    loadData ();
 
      
     
            
             }, 
             [])


             useEffect(() => {
              const loadSupplierPlan = () => {
               // console.log('UserEffect', Id);
                if (!Id) return;
               
                getSupplierPlan((Id)).then (result=>{        
                  setsupplierplan(result.data);           
                             });
                            }
                            loadSupplierPlan ();
                             
                           }, 
                           [successID])

//console.log(Id);

    return (
      <div className="container-fluid">
       <div className="content-wrapper">
        <div className="row"  >
       

        <div className="container-fluid">
        <div className="row" >
          <div className="col-lg-12">

            <div className="card mb-4" >
            
              <p className="text-light bg-dark">
                <i className="fa fa-user-circle-o" />

                <span> เพิ่มข้อมูลผู้รับเหมา  </span>
              </p>
             
              <div className="card-body">
                <div className="form-group" >
              <SupplierAdd
                value =  {shudownplan.planname +'  '+ shudownplan.BUName +' พื้นที่ '+ shudownplan.AreaName+ ' เริ่มงาน ' +shudownplan.StartDate+' สิ้นสุด '+shudownplan.Enddate}
                onConfirm = {handleOnConfirm}
      
      />
      </div>
      </div>
      
     
      </div>
      </div>
</div>
</div>
</div>
{ messageErros.askAlert.enable  &&  <div className="col-md-12 alert alert-danger">  { messageErros.askAlert.message}</div> }
</div>
     <Rendersupplier
      list = {supllierplan }
     handleChange = {handleChange}

    />
    </div>
    );
  }


export default  Supplierjob ;