import React from 'react';

const Select = (props) => {
  const {data,value,onChange,textProperty,valueProperty ,label,name,search,onClick} = props;
 
//const [state, setstate] = useState(initialState);

  const handleOnclick=() =>{
   // onClick();
//console.log('you click');
    //onClick();
  }

  if (!data){
      return (<div>

      </div>)
  }

    return ( 


        <div className="form-group input-group">
        <label
          className="col-md-3 control-label"
          htmlFor="area"
        >
          {label}
        </label>
        <div className="col-md-8">
            <select className="form-control" 
             id="exampleFormControlSelect1" 
             value={value} onChange={onChange}>
               {data.map(item=>
                  <option key={item[valueProperty]}
                 value={item[valueProperty]}>
                 {item[textProperty]}
                 </option>)}
        </select>
        </div>
          {search && <div className="col-md-3 input-group-addon input-group-button col-auto mb-1 px-1">
          <button type="button" 
         id="show-contact-modal-button" 
         className="btn btn-primary"
          onClick={handleOnclick}> 
          {search}
        </button>
         </div>}
         </div>
//   <div className="form-group">
    
    
//     { label && <label htmlFor={name}>{label}</label> }
//     <div className="input-group">
//     {/* {search &&  <div className="row" > <button >ค้นหา</button></div>   } */}
//     <select className="form-control" 
//       id="exampleFormControlSelect1" 
//       value={value} onChange={onChange}>
//       {data.map(item=>
//   <option key={item[valueProperty]}
//    value={item[valueProperty]}>
//   {item[textProperty]}
//   </option>)}
// </select>
// {search && <div className="input-group-addon input-group-button col-auto mb-1 px-1">
//   <button type="button" 
//   id="show-contact-modal-button" 
//   className="btn btn-primary"
//   onClick={handleOnclick}
//   >
    
//     {search}
//     </button>
//   </div>}
// </div>
// </div>


    );
        
}
Select.defaultProps ={
  textProperty:'name',
  valueProperty:'_id'
};
export default Select;
