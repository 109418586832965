import React, { useState, useEffect } from "react";
import {SearchSupplier} from './../../services/vms';

import { Button } from "reactstrap";

const SearchVendor = ({ updateData,value,isOpen }) => {
  const [data, setData] = useState({
    search: value
  });
  const [master, setMaster] = useState([]);




  useEffect(() => {
    if (data.search.length < 4) {
      return;
    }
    async function api() {
      try {

       
        const resService = await SearchSupplier(data.search);
       // console.log(resService);
        if (!resService) {
          return;
        }
       // console.log(resService.data);
        const employeeList = resService.data;
     
        setMaster(employeeList);
      } catch (error) {
      //  console.log(error);
      }
    }
    try {
      api();
    } catch (error) {}
  }, [data.search]);

  async function onSearchClick() {
    if (!data.search) {
      return;
    }
    try {
      const resService = await SearchSupplier(data.search);
    //  console.log(resService);
      if (!resService) {
        return;
      }
      const employeeList = resService.data;
      setMaster(employeeList);
    } catch (error) {
   //   console.log(error);
    }
  }

  function handleChange({ currentTarget: input }) {
    const { name, value } = input;
 //   console.log("name,value=>", name, value);
    setData((previous) => {
      return {
        previous,
        [name]: value,
      };
    });
  }

  function handleOnselected({ currentTarget: input }) {
    //  console.log(input);
     const { name } = input;
   //  console.log(master);
     const result = master.filter((i) => i.IdentityID === name);
   //  console.log("result=>", result[0]);
    const data = result[0];
     updateData(data);
    // isOpen(false);
  }

  return (
    <div className="form-group">
      <div className="row">
        <div className="col-sm-8">
          <input
            name="search"
            value={data.search}
            onChange={handleChange}
            type="textarea"
            placeholder="ชื่อ สกุล เลขบัตร"
            onKeyPress={onSearchClick}
          />
        </div>
      </div>
      <div className="row sm-4">
        <div className="col">
          {master.length > 0 && (
            <table className="table table-hover table-responsive">
              <thead>
                <tr>
                 
                  <th scope="col">FullName</th>
                  <th scope="col">contact</th>
                  <th scope="col">select</th>
                
                </tr>
              </thead>
              <tbody>
                {master.map((item) => (
                  <tr key={item.IdentityID}>
                   
                    <td className="col-sm-8">
                      <span className="text-info">
                        {(item.FnameT)  }   {(item.LnameT)}
                      </span>
                      
                    </td>
                    <td>
                      <div className="form-group">
                        <div className="col-sm">
                          <div className="row">
                            {item.Telephone}
                           
                          </div>
                        
                        </div>
                      </div>
                    </td>
                    <td>
                    
                    <Button
                      name={item.IdentityID}
                      type="primary"
                      onClick={handleOnselected}
                    >
                      select
                    </Button>
                  
                </td>
                   
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};



export default SearchVendor;
