import http from "./httpService";

const apiEndpoint = "https://wms.advanceagro.net/wsface/api"

export async function getDrivenprofiles(id){
    return http.get(apiEndpoint+"/face/GetDrivenList?id=" + id);

    
}

export default {
    getDrivenprofiles
}
