import React,{ useState,useEffect} from "react";
import {getEditresult,SaveCCP} from '../../services/wms'
import Input from '../wms/input';
import PopupAlert from '../common/popupAlert';
import ConfirmSubmit from '../common/confirmSubmit';
import { toast } from "react-toastify";
const Reportccpedit = (props) => {

  const [Id, setId] = useState(0);
const [alldata, setalldata] = useState([]);
const [recuce, setrecuce] = useState('');
const [claim, setclaim] = useState('');
const [messageErros, setmessageErros] = useState({
  askAlert: {enable: false, message: ""}
});
const [askConfirm , setaskConfirm] = useState(false);
const [message, setmessage] = useState("");


  useEffect (() => {

    //if (!Id) return;

    const getId =() =>{
       setId(props.match.params.id);
      } 
      getId();
    }, [props.match.params.id]);



    useEffect (() => {
      if (!Id) return;

      const loadRegister = async () => {
   
        const { data: result } = await   getEditresult(Id);
        if (result.status === "success") {
        setalldata(result.data.list);
        }
      }
      loadRegister();
      }, [Id]);


const handelCliamChange =({currentTarget:input}) => {
setclaim(input.value)
}
  
const handlereduesChange =({currentTarget:input}) => {
  setrecuce(input.value)
}
const handlePopupAlertClick = () => {
  setmessageErros( { askAlert: {enable: false, message: ""}})     
   }


const handleSave = () => {
  if (recuce ===""){
    setmessageErros( { askAlert: {enable: true, message: "ยังไม่ระบุข้อมูลลดหนี้\nกรุณาระบุก่อนค่ะ"}})
    return;
}  else if ( claim ===''

){
 setmessageErros( { askAlert: {enable: true, message: "ยังไม่ระบุข้อมูลการเคลม\nกรุณาระบุก่อนค่ะ"}})
    return;
}


setmessage("ท่านยืนยัน ที่ทำการบันทึกรายการใช่หรือไม่")
setaskConfirm(true); 

}

const  doSubmit = async () => {
  try {
    
     const doc = {
        
      id:Id,  claimvalue: claim, recucevalue:recuce
     };
//console.log('doc',doc);
    const {data: result} = await SaveCCP(doc);
 
  if (result.status==='success') {
     toast.success("บันทึกเสร็จเรียบร้อย");

    const urlGoBack = "/receivechemical/";
     setTimeout(props.history.replace(urlGoBack), 1000);
   } else {
     toast.error(result.message)
   }

  } catch(err) {
    toast.error("Error")
  }
  
}
const handleConfirmSubmit = () => {
  doSubmit();
setaskConfirm(false);
   }
const   handleCancleSubmit = () => {
  setaskConfirm(false);
   }  

  return (
<div className="container-fluid">
<PopupAlert isOpen={messageErros.askAlert.enable} onOk={handlePopupAlertClick} message={messageErros.askAlert.message}  />
<ConfirmSubmit 
         isOpen = {askConfirm} 
         onConfirm={handleConfirmSubmit} 
         onCancel={handleCancleSubmit} 
         message={message} 
         button1 = "OK"
         />  

      <div className="row d-flex justify-content-center mt-2">
        <div className="col-12 col-sm-12">
          {alldata && alldata.length > 0 && <section className="content">
            <div className="row">
              <div className="col-lg-12">
                <p className="bg-success text-white ">
                  <span className="info-box-icon bg-success ">
                    <i className="fa fa-users ml-2" />
                  </span>
                  <span> ข้อมูลสินค้า </span>
                </p>
                <div className="row">
                  <div className=" col-xs-6 col-sm-4 col-md-6 lg-1 ">
                    <div style={{ fontSize: 20 }}>
                      <span className="badge badge-pill badge-primary">1</span>
                    </div>
                    <div style={{ fontSize: 14 }}>
                      <h6>
                        เลขที่ใบชั่ง :
                        <small style={{ fontSize: 14 }}> {alldata[0].weightno}</small>
                      </h6>
                    </div>
                    <div style={{ fontSize: 14 }}>
                      <h6>
                        ทะเบียนรถ :
                        <small style={{ fontSize: 14 }}>
                        {alldata[0].drivenno}
                        </small>
                      </h6>
                    </div>
                    <div style={{ fontSize: 14 }}>
                      <h6>
                        วันที่ลงสินค้า :
                        <small style={{ fontSize: 14 }}>{alldata[0].datein}</small>
                      </h6>
                    </div>
                    <div style={{ fontSize: 14 }}>
                      <h6>
                        Supplier :
                        <small style={{ fontSize: 14 }}>
                          {alldata[0].customername}
                        </small>
                      </h6>
                    </div>
                    <div style={{ fontSize: 14 }}>
                      <h6>
                        PO :<small style={{ fontSize: 14 }}> {alldata[0].po}</small>
                      </h6>
                    </div>
                    <div style={{ fontSize: 14 }}>
                      <h6>
                        หมายเหตุ :
                        <small style={{ fontSize: 14 }}> {alldata[0].remark}</small>
                      </h6>
                    </div>
                    <div />
                  </div>
                  <div className="col-xs-6 col-sm-4 col-md-6 lg-1">
                    <div style={{ fontSize: 14 }}>
                      <h6>
                        DO :<small style={{ fontSize: 14 }}>{alldata[0].doid}</small>
                      </h6>
                    </div>
                    <div style={{ fontSize: 14 }}>
                      <h6>
                        สินค้า :
                        <small style={{ fontSize: 14 }}>{alldata[0].productname}</small>
                      </h6>
                    </div>
                    <div>
                      <h6>
                        นน.สุทธิ AA :
                        <small style={{ fontSize: 18 }}>
                          <span className="badge badge-pill badge-info">
                            {alldata[0].weightnet}
                          </span>
                        </small>
                      </h6>
                    </div>
                    <div>
                      <h6>
                        นน.สุทธิ Supplier :
                        <small style={{ fontSize: 18 }}>
                          <span className="badge badge-pill badge-warning">
                           {alldata[0].customerweight}
                          </span>
                        </small>
                      </h6>
                    </div>
                    <div style={{ fontSize: 14 }}>
                      <h6>
                        ผลแลป :<small style={{ fontSize: 14 }} />
                        <span className="badge badge-pill badge-success">
                         {alldata[0].labresult}
                        </span>
                      </h6>
                    </div>
                    <div style={{ fontSize: 14 }}>
                      <h6>
                        Link Zoom :<small style={{ fontSize: 14 }} />
                      </h6>
                    </div>
                    <div style={{ fontSize: 14 }}>
                      <h6>
                        Taskname :<small style={{ fontSize: 14 }} />{alldata[0].taskname}
                      </h6>
                    </div>
                    <div />
                  </div>
                </div>

                <p className="bg-info text-white mt-2">
                  <span className="info-box-icon bg-info ">
                    <i className="fa fa-users ml-2" />
                  </span>
                  <span> ข้อมูลแลป</span>
                </p>
                <div className="col-xs-12">
                  <div className="row">
                    <div className="col-sm-6 my-1">
                    

<Input 
                  name="claim"
                  value={claim}
                  label= {"มูลค่าเคลม"}
                  onChange={handelCliamChange}
                  
              />

                    </div>
                    <div className="col-sm-6 my-1">
                   
                <Input 
                  name="redue"
                  value={recuce}
                  label= {"ลดหนี้"}
                  onChange={handlereduesChange}
                  
                />
        

                    </div>
                  </div>
                </div>

                <div className="card mt-4">
                  <button onClick={handleSave} className="btn btn-primary">Save </button>
                </div>
              </div>
              <div />
            </div>
            </section>}
        </div>
      </div>
    </div>
  );
};

export default Reportccpedit;
