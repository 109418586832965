import React,{useState,useEffect,useContext} from 'react'
import DatePicker from "react-datepicker";
import ChartColumn from './../../components/chart/column';
//import ChartColumn from './../../components/chart/column';
import ReportCreate from './../chart/reportCreator';

import moment from 'moment';
import RenderButton from './../../components/common/button';
import BranchContext from './../../components/context/branch';

        
//import {getdata,getdataCompaire} from './../../services/wms/truckperhour';
import {gettruckperhour,gettruckoutperhour,gettruckcompare} from './../../services/apichart';

const Reportchart = () => {

    const {currentBranch } = useContext(BranchContext);
    const [startDate, setStartDate] = useState(new Date());

    const [chartData, setchartData] = useState([]);
    const [chartDataout, setchartDataout] = useState([]);
    const [chartDatacompare, setchartDatacompare] = useState([]);
    const [value, setValue] = useState([1]);
    
  
    const[ amount , setAmount] = useState([]);
    const[ amountout , setAmountout] = useState([]);
    const[ amountcompare , setAmountcompare] = useState([]);
    const [valuename, setvaluename] = useState('จำนวนรถ');
    const [textname, settextname] = useState('รถเข้าโรงงานแต่ละช่วงเวลา');
    const [charttype, setcharttype] = useState('bar');
    const [unitname, setunitname] = useState('คัน');

    const[ data1 , setData1] = useState({});

    const[ data2 , setData2] = useState({});

    const[ data3 , setData3] = useState({});

    const[ chartdata , setchartdata] = useState([]);
    const[ chartdataout , setchartdataout] = useState([]);
    const[ chartdatacompare , setchartdatacompare] = useState([]);
    const [fclassname, setfclassname] = useState('btn btn-primary');
    const [sclassname, setcslassname] = useState('btn btn-outline-dark');

    useEffect(() => {
      if (! currentBranch ) return; 
   
    gettruckperhour(moment(startDate, "YYYY-MM-DD").add(0, 'day').format("YYYY-MM-DD"),currentBranch && currentBranch._id).then
     (result=>{  
      
       setchartdata(result.data);        
       
      });
  }, 
   [currentBranch,startDate])


   useEffect(() => {
    if (! currentBranch ) return; 
 
  gettruckoutperhour(moment(startDate, "YYYY-MM-DD").add(0, 'day').format("YYYY-MM-DD"),currentBranch && currentBranch._id).then
   (result=>{  
    
    setchartdataout(result.data);   
  //  console.log(result.data);     
     
    });
}, 
 [currentBranch,startDate])


 useEffect(() => {
  if (! currentBranch ) return; 

  gettruckcompare(moment(startDate, "YYYY-MM-DD").add(0, 'day').format("YYYY-MM-DD"),currentBranch && currentBranch._id).then
 (result=>{  
  
  setchartdatacompare(result.data);   
 //console.log(result.data);     
   
  });
}, 
[currentBranch,startDate])


 useEffect(() => {

    if ((!chartdata) || !currentBranch) return;
    const chart =() =>{
      let phour =[];
      let pamount =[];
  


      for(const dataObj of chartdata){
          phour.push(( dataObj.categories))
          pamount.push(parseInt( dataObj.data))
      }

      const  data =  { series : 
        
        [{ name :'จำนวนรถ' , data : pamount}
        ]
        , yLabels : phour  ,headers :'ข้อมูลรถเข้าออกตามช่วงเวลา',
        type: 'bar',stacked:false,
      }

      setData2(data);
      
// const charttype ='bar';
// const data = ChartData(pamount,charttype);
//console.log(data);
// setAmount(pamount);
    
    //  setchartData({
    //     series: [{
    //       name: valuename,
    //       data: pamount
    //     }],
    //       chart: {
    //       height: 350,
    //       type: charttype,
    //     },
    //     plotOptions: {
    //       bar: {
    //         dataLabels: {
    //           position: 'top', // top, center, bottom
    //         }
            
            
    //         ,
    //         colors: {
    //           ranges: [{
    //               from: 0,
    //               to: 10,
    //               color: "#FFB200"
    //           }],
    //           backgroundBarColors: [],
    //           backgroundBarOpacity: 1,
    //           backgroundBarRadius: 0,
    //       }

    //       }
    //     },
    //     dataLabels: {
    //       enabled: true,
    //       formatter: function (val) {
    //         return val ;
    //       },
    //       offsetY: -20,
    //       style: {
    //         fontSize: '12px',
    //         colors: ["#304758"]
    //       }
    //     },        
    //     xaxis: {
    //       categories: phour,
    //      // position: 'top',
    //       axisBorder: {
    //         show: false
    //       },
    //       axisTicks: {
    //         show: false
    //       },
    //       crosshairs: {
    //         fill: {
    //           type: 'gradient',
    //           gradient: {
    //             colorFrom: '#D8E3F0',
    //             colorTo: '#BED1E6',
    //             stops: [0, 100],
    //             opacityFrom: 0.4,
    //             opacityTo: 0.5,
    //           }
    //         }
    //       },
    //       tooltip: {
    //         enabled: true,
    //       }
    //     },
    //     yaxis: {
    //       axisBorder: {
    //         show: false
    //       },
    //       axisTicks: {
    //         show: false,
    //       },
    //       labels: {
    //         show: false,
    //         formatter: function (val) {
    //           return val ;
    //         }
    //       }
        
    //     },
    //     title: {
    //       text: textname,
    //       floating: true,
    //       offsetY: 330,
    //       align: 'center',
    //       style: {
    //         color: '#444'
    //       }
    //     }

    //   }
    //  )
    
    }
    chart();

   },[chartdata,currentBranch,valuename,textname,charttype])

   useEffect(() => {

    if ((!chartdatacompare) || !currentBranch) return;
    const chart =() =>{
      let phour =[];
      let AmountA =[];
      let AmountB =[];


      for(const dataObj of chartdatacompare){

          phour.push(( dataObj.categories))
          AmountA.push(parseInt( dataObj.data1))
          AmountB.push(parseInt( dataObj.data2))

      }


      const  data =  { series : 
        
        [{ name :'รถชั่งออกแล้ว(คัน)' , data : AmountA},
        { name :'รถค้างในโรงงาน(คัน)', data:AmountB}]
        , yLabels : phour  ,headers :'ข้อมูลรถเข้าออกตามช่วงเวลา',
        type: 'bar',stacked:false,
      }
      setData1(data);
 
 
     //  console.log(data);

// setAmountcompare(AmountA);
    
//      setchartDatacompare({
//       series: [
//         {
//         name: 'รถชั่งออกแล้ว(คัน)',
//         data: AmountA
//       }, {
//         name: 'รถค้างในโรงงาน(คัน)',
//         data: AmountB
//       }
//     ]
//     ,
//         chart: {
//         type: 'bar',
//         height: 350,
//         stacked: true,
//         toolbar: {
//             show: true
//           }
//       },
//       dataLabels: {
//         enabled: true,
//         formatter: function (val) {
//           return val;
//         },
//         offsetY: -20,
//         style: {
//           fontSize: '12px',
//           colors: ["#304758"]
//         }
//       },
//       plotOptions: {
//         bar: {
        
//         }
//       }
//       ,
//       responsive: [{
//         breakpoint: 480,
//         options: {
//           legend: {
//             position: 'bottom',
//             offsetX: -10,
//             offsetY: 0
//           }
//         }
//       }],
//       xaxis: {
//         categories: phour,
//       },
//       fill: {
//         opacity: 1
//       },
//       legend: {
//         position: 'right',
//         offsetX: 0,
//         offsetY: 50
//       },

//       }
//      )
    
    }
    chart();

   },[chartdatacompare,currentBranch,valuename])
   
 

   useEffect(() => {

    

    if ((!chartdataout) || !currentBranch) return;
    const chart =() =>{
      let phour =[];
      let pamount =[];



      for(const dataObj of chartdataout){
          phour.push(( dataObj.categories))
          pamount.push(parseInt( dataObj.data))
      }

      const  data =  { series : 
        
        [{ name :'จำนวนรถชั่งออก' , data : pamount}
        ]
        , yLabels : phour  ,headers :'ข้อมูลรถเข้าออกตามช่วงเวลา',
        type: 'bar',stacked:false,
      }
      setData3(data);

// setAmountout(pamount);
    
//      setchartDataout({
//         series: [{
//           name: valuename,
//           data: pamount
//         }],
//           chart: {
//           height: 350,
//           type: charttype,
//         },
//         plotOptions: {
//           bar: {
//             dataLabels: {
//               position: 'top', // top, center, bottom
//             },

//             colors: {
//               ranges: [{
//                   // from: 0,
//                   // to: 10,
//                   // color: "#FFB200"
//               }],
//               backgroundBarColors: [],
//               backgroundBarOpacity: 1,
//               backgroundBarRadius: 0,
//           }
//           }
//         },
//         dataLabels: {
//           enabled: true,
//           formatter: function (val) {
//             return val ;
//           },
//           offsetY: -20,
//           style: {
//             fontSize: '12px',
//             colors: ["#304758"]
//           }
//         },        
//         xaxis: {
//           categories: phour,
//          // position: 'top',
//           axisBorder: {
//             show: false
//           },
//           axisTicks: {
//             show: false
//           },
//           crosshairs: {
//             fill: {
//               type: 'gradient',
//               gradient: {
//                 colorFrom: '#D8E3F0',
//                 colorTo: '#BED1E6',
//                 stops: [0, 100],
//                 opacityFrom: 0.4,
//                 opacityTo: 0.5,
//               }
//             }
//           },
//           tooltip: {
//             enabled: true,
//           }
//         },
//         yaxis: {
//           axisBorder: {
//             show: false
//           },
//           axisTicks: {
//             show: false,
//           },
//           labels: {
//             show: false,
//             formatter: function (val) {
//               return val;
//             }
//           }
        
//         },
//         title: {
//           text: 'รถออกโรงงานแต่ละช่วงเวลา',
//           floating: true,
//           offsetY: 330,
//           align: 'center',
//           style: {
//             color: '#444'
//           }
//         }

//       }
//      )
    
    }
    chart();

   },[chartdataout,currentBranch,valuename,charttype])




    const handleChangeF = ({currentTarget: input}) => 
    
    {
        if (fclassname=='btn btn-primary'){
            setfclassname('btn btn-outline-dark') ;
            setcslassname('btn btn-primary');
        }else {
            setfclassname('btn btn-primary') ;
            setcslassname('btn btn-outline-dark');
        }
        
        setValue(input.value)
        console.log(input.value)
    
    };
    const handleChangeS = ({ currentTarget: input }) => 
    {
        if (fclassname=='btn btn-primary'){
            setfclassname('btn btn-outline-dark') ;
            setcslassname('btn btn-primary');
        }else {
            setfclassname('btn btn-primary') ;
            setcslassname('btn btn-outline-dark');
        }
        setValue(input.value)
       // console.log(input.value)
    
    };
//console.log(data1);
 //   console.log('after== ',chartDatacompare);
 //   console.log('after option ',options);
    if ((!currentBranch)  || (!chartdata)|| data1.series==undefined || data2.series==undefined && amountcompare.length ==0) {
     // data1.series==undefined
     // || data2.series==undefined
    //  console.log('loop1 ',chartData.series,currentBranch);
        return (<div></div>)
   
    
    }

    if (currentBranch._id == 'vms'){
      return <>
      </>
    }
    
return (  
        <div className='row container-fluid'> 
        <div className="col-12">
          <div className='container'>
            <div className="row">
              <div className='m-4'>
                 
                <div className='mt-2' style={{ marginTop: 20, marginBottom: 20 }} >
                  <DatePicker
                    todayButton="Today"
                    dateFormat="dd/MM/yyyy"
                    selected={startDate}
                    onChange={date => setStartDate(date)} />
                </div>

              </div>   

              <div className="row justify-content-end align-items-center">
                  <div className="col-auto mb-3 px-0"><ul className="btn-group p-0 m-0 ">

     
                     <RenderButton 
                     label ="Real Inout"
                     value="1"
                     onClick ={handleChangeF}
                     className = {fclassname}
                      />
                    <RenderButton 
                     value="2"
                     label ="Real/Booking"
                     onClick ={handleChangeS}
                     className = {sclassname}
                      />
                
                      </ul>
                      </div>
                      </div>


            </div>
          </div>

          <ReportCreate
          data ={data1}
          type="bar"
         height= "350"
         stacked = {true}
          />


        <ReportCreate
         type="bar"
         height= "350"
          data ={data2}
         
          />
          {/* <ChartColumn 
          options = {chartData}
          type="bar"
          height= "350"
          
          /> */}

          <ReportCreate 
           options = {data3}
           type="bar"
           height= "350"
          
          />
           {/* <ChartColumn 
           options = {chartDatacompare}
           type="bar"
           height= "350"       
          /> */}
        </div>

       
      </div>

    );


}

export default Reportchart;
