import React,{useState,useEffect,useContext} from 'react'
import DatePicker from "react-datepicker";
import ChartColumn from './../../components/chart/column';
//import ChartColumn from './../../components/chart/column';


import ReportCreate from './../chart/reportCreator';
import moment from 'moment';
import RenderButton from './../../components/common/button';
import BranchContext from './../../components/context/branch';
import Header from "./header";
        
//import {getdata,getdataCompaire} from './../../services/wms/truckperhour';
import {getvmsRegister,getvmsByPermit,getInoutperhour} from './../../services/vms';

const Reportregister = () => {

    const {currentBranch } = useContext(BranchContext);
    const [startDate, setStartDate] = useState(new Date());
    const [chartDatacompare, setchartDatacompare] = useState([]);
    const [chartDatapermit , setchartDatapermit] = useState([]);
    const[ chartDatahour , setchartchartDatahour] = useState([]);
    const [value, setValue] = useState(1);
    //const [total,settotal] = useState(0);

    const[ amountpermit , setamountpermit] = useState([]);
    const[ amountcompare , setAmountcompare] = useState([]);
   
    const[ data1 , setData1] = useState({});
    const[ data2 , setData2] = useState({});
    const[ data3 , setData3] = useState({});

    const[ chartdata , setchartdata] = useState([]);
    const[ chartdatapermit , setchartdatapermit] = useState([]);
    const[ chartdatacompare , setchartdatacompare] = useState([]);
    const[ chartdatahour , setchartchartdatahour] = useState([]);

    // const [all, setall] = useState('btn btn-primary');
    // const [aa, setaa] = useState('btn btn-outline-dark');
    // const [pm3, setpm3] = useState('btn btn-outline-dark');
    // const [nps, setnps] = useState('btn btn-outline-dark');
    // const [e85, sete85] = useState('btn btn-outline-dark');
    // const [agf, setagf] = useState('btn btn-outline-dark');
    // const [other, setother] = useState('btn btn-outline-dark');




 useEffect(() => {
  if (! currentBranch ) return; 

  const loadData = async () => {
    const { data: result } = await getvmsRegister(moment(startDate, "YYYY-MM-DD").add(0, 'day').format("YYYY-MM-DD"),value);
    if (result) {
     // console.log(result);
      setchartdatacompare(result);   
    }
  };  
  loadData();

//       getvmsRegister(moment(startDate, "YYYY-MM-DD").add(0, 'day').format("YYYY-MM-DD"),value).then
//  (result=>{  
  
//   setchartdatacompare(result.data);   
   
   
 // });
}, 
[currentBranch,startDate,value])

useEffect(() => {
    if (! currentBranch ) return; 

    const loadData = async () => {
      const { data: result } = await getvmsByPermit(moment(startDate, "YYYY-MM-DD").add(0, 'day').format("YYYY-MM-DD"),value);
      if (result) {
        setchartdatapermit(result);   
      }
    };  
    loadData();
  
  //   getvmsByPermit(moment(startDate, "YYYY-MM-DD").add(0, 'day').format("YYYY-MM-DD"),value).then
  //  (result=>{  
    
  //   setchartdatapermit(result.data);   
 
  //   });

  }, 
  [currentBranch,startDate,value])


useEffect(() => {
  if (! currentBranch ) return; 


  const loadData = async () => {
    const { data: result } = await getInoutperhour(moment(startDate, "YYYY-MM-DD").add(0, 'day').format("YYYY-MM-DD"));
    if (result) {
      setchartchartdatahour(result);   
    }
  };  
  loadData();

//   getInoutperhour(moment(startDate, "YYYY-MM-DD").add(0, 'day').format("YYYY-MM-DD")).then
//  (result=>{  
  
//   setchartchartdatahour(result.data);   
 
   
//   });
}, 
[currentBranch,startDate])

const handleChange = (event) => {
  setValue(event);
 
  //    console.log('Click event',pageNumber);
};



   useEffect(() => {

    if ((!chartdatacompare) || !currentBranch) return;
    const chart =() =>{
      let phour =[];
      let AmountA =[];
      let AmountB =[];
      let AmountC =[];

      for(const dataObj of chartdatacompare){

          phour.push(( dataObj.DDATE))
          AmountA.push(parseInt( dataObj.Waiting))
          AmountB.push(parseInt( dataObj.Approve))
          AmountC.push(parseInt( dataObj.DisApprove))

      }


      const  data =  { series : 
        
        [{ name :'รออนุมัติ' , data : AmountA},
        { name :'อนุมัติแล้ว', data:AmountB},
        { name :'ไม่อนุมัติ', data:AmountC},
      ]
        , yLabels : phour  ,headers :'ข้อมูลใบขออนุมัติเข้าโรงงาน',
        type: 'bar',stacked:false,
      }
      setData2(data);


// setAmountcompare(AmountA);
    
//      setchartDatacompare({
//       series: [{
//         name: 'อนุมัติแล้ว',
//         data: AmountB
//       }, {
//         name: 'รออนุมัติ',
//         data: AmountA
//       },
//       {
//         name: 'ไม่อนุมัติ',
//         data: AmountC
//       }
//     ],
//         chart: {
//         type: 'bar',
//         height: 350,
//         stacked: true,
//         toolbar: {
//             show: true
//           }
//       },
//       dataLabels: {
//         enabled: true,

//         // formatter: function (val) {
//         //   return val;
//         // }
//         formatter: function(value, { seriesIndex, dataPointIndex, w}) {
//           return w.globals.stackedSeriesTotals[dataPointIndex];
//         }
//         ,
//         offsetY: -20,
//         style: {
//           fontSize: '12px',
//           colors: ["#304758"]
//         }
//       },
//       plotOptions: {
//         bar: {
//           position: 'top', // top, center, bottom
//         }
//       }
//       ,
//       responsive: [{
//         breakpoint: 480,
//         options: {
//           legend: {
//             position: 'top',
//             offsetX: -10,
//             offsetY: 0
//           }
//         }
//       }],
//       xaxis: {
//         categories: phour,
//       },
      
//       fill: {
//         opacity: 1
//       },
//       legend: {
//         position: 'right',
//         offsetX: 0,
//         offsetY: 50
//       },
//       title: {
//         text: 'ใบขอเข้าโรงงาน',
//         floating: true,
//         offsetY: 330,
//         align: 'center',
//         style: {
//           color: '#444'
//         }
//       }

//       }
//      )
    
    }
    chart();

   },[chartdatacompare,currentBranch])
   
 

   useEffect(() => {

    

    if ((!chartdatapermit) || !currentBranch) return;

   // console.log(chartdatapermit);

    const chart =() =>{
      let phour =[];
     
      let AmountA =[];
      let AmountB =[];
      let AmountC =[];
      let AmountD =[];


      for(const dataObj of chartdatapermit){
          phour.push(( dataObj.DDate))

          AmountA.push(parseInt( dataObj.WORKREQUEST))
          AmountB.push(parseInt( dataObj.WORKPERSON))
          AmountC.push(parseInt( dataObj.DELIVEYREQUEST))
          AmountD.push(parseInt( dataObj.DELIVERYPERSON))

      }
    

      const  data =  { series : 
        
        [{ name :'ขอทำงาน(ใบ)' , data : AmountA},
        { name :'ขอทำงาน(คน)', data:AmountB},
        { name :'ส่งของ(ใบ)', data:AmountC},
        { name :'ส่งของ(คน)', data:AmountD}
      ]
        , yLabels : phour  ,headers :'ข้อมูลใบงานเทียบจำนวนคน/ใบคำขอ',
        type: 'bar',stacked:false,
      }
      setData3(data);

// setchartDatapermit({
//     series: [{
//         name: 'ขอทำงาน(ใบ)',
//         data: AmountA
//       }, {
//         name: 'ขอทำงาน(คน)',
//         data: AmountB
//       },
//       {
//         name: 'ส่งของ(ใบ)',
//         data: AmountC
//       },
//       {
//         name: 'ส่งของ(คน)',
//         data: AmountD
//       }
      
//     ],
//         chart: {
//         type: 'bar',
//         height: 350,
       
//         toolbar: {
//             show: true
//           }
//       },
//       plotOptions: {
//         bar: {
//           position: 'top', // top, center, bottom
//         }
//       },
//         // plotOptions: {
//         //   bar: {
//         //     dataLabels: {
//         //       position: 'top', // top, center, bottom
//         //     },

//         //     colors: {
//         //       ranges: [{
//         //           from: 0,
//         //           to: 10,
//         //           color: "#FFB200"
//         //       }],
//         //       backgroundBarColors: [],
//         //       backgroundBarOpacity: 1,
//         //       backgroundBarRadius: 0,
//         //   }
//         //   }
//         // },
//         dataLabels: {
//           enabled: true,
//           formatter: function (val) {
//             return val ;
//           },
//           offsetY: -20,
//           style: {
//             fontSize: '12px',
//             colors: ["#304758"]
//           }
//         },        
//         xaxis: {
//           categories: phour,
//          // position: 'top',
//           axisBorder: {
//             show: false
//           },
//           axisTicks: {
//             show: false
//           },
//           crosshairs: {
//             fill: {
//               type: 'gradient',
//               gradient: {
//                 colorFrom: '#D8E3F0',
//                 colorTo: '#BED1E6',
//                 stops: [0, 100],
//                 opacityFrom: 0.4,
//                 opacityTo: 0.5,
//               }
//             }
//           },
//           tooltip: {
//             enabled: true,
//           }
//         },
//         yaxis: {
//           axisBorder: {
//             show: false
//           },
//           axisTicks: {
//             show: false,
//           },
//           labels: {
//             show: false,
//             formatter: function (val) {
//               return val;
//             }
//           }
        
//         },
//         title: {
//           text: 'ใบขออนุญาตเข้าโรงงาน',
//           floating: true,
//           offsetY: 330,
//           align: 'center',
//           style: {
//             color: '#444'
//           }
//         }

//       }
   //  )
    
    }
    chart();

   },[chartdatapermit,currentBranch])


   useEffect(() => {

    

    if ((!chartdatahour) || !currentBranch) return;

   // console.log(chartdatapermit);

    const chart =() =>{
      let phour =[];
     
      let AmountA =[];
      let AmountB =[];
      


      for(const dataObj of chartdatahour){
          phour.push(( dataObj.hour))

          AmountA.push(parseInt( dataObj.AmountIN))
          AmountB.push(parseInt( dataObj.AmountOut))
          

      }

      const  data =  { series : 
        
        [{ name :'เข้างาน' , data : AmountA},
        { name :'ออกงาน', data:AmountB}]
        , yLabels : phour  ,headers :'ข้อมูลการบันทึกใบหน้าผ่านป้อม 3',
        type: 'bar',stacked:true      
      }
      setData1(data);

   // console.log(AmountB);
// setchartchartDatahour({
//     series: [{
//         name: 'เข้างาน',
//         data: AmountA
//       }, {
//         name: 'ออกงาน',
//         data: AmountB
//       }
      
//     ],
//         chart: {
//         type: 'bar',
//         height: 350,
//         stacked: true,
//         toolbar: {
//             show: true
//           }
//       },
//       plotOptions: {
//         bar: {
//           position: 'top', // top, center, bottom
//         }
//       },
  
//         dataLabels: {
//           enabled: true,
//           formatter: function (val) {
//             return val ;
//           },
//           offsetY: -20,
//           style: {
//             fontSize: '12px',
//             colors: ["#304758"]
//           }
//         },        
//         xaxis: {
//           categories: phour,
       
//           axisBorder: {
//             show: false
//           },
//           axisTicks: {
//             show: false
//           },
//           crosshairs: {
//             fill: {
//               type: 'gradient',
//               gradient: {
//                 colorFrom: '#D8E3F0',
//                 colorTo: '#BED1E6',
//                 stops: [0, 100],
//                 opacityFrom: 0.4,
//                 opacityTo: 0.5,
//               }
//             }
//           },
//           tooltip: {
//             enabled: true,
//           }
//         },
//         yaxis: {
//           axisBorder: {
//             show: false
//           },
//           axisTicks: {
//             show: false,
//           },
//           labels: {
//             show: true,
//             formatter: function (val) {
//               return val;
//             }
//           }
        
//         },
//         title: {
//           text: 'เข้าออกตามช่วงเวลา',
//           floating: true,
//           offsetY: 330,
//           align: 'center',
//           style: {
//             color: '#444'
//           }
//         }

//       }
//      )
    
     }
    chart();

   },[chartdatahour,currentBranch])

 //  console.log(chartDatapermit)

// const disabledlabel = () => {
//             setall('btn btn-outline-dark')
//             setaa('btn btn-outline-dark') ;
//             setpm3('btn btn-outline-dark') ;
//             setagf('btn btn-outline-dark') ;
//             setnps('btn btn-outline-dark') ;
//             sete85('btn btn-outline-dark') ;
//             setother('btn btn-outline-dark') ;
// }

    // const handleonClick = ({ currentTarget: input },event) => 
    // {
     
    //     if (input.value==4){
    //         disabledlabel();
    //         setpm3('btn btn-primary'); 
    //     }else if (input.value==1){
    //         disabledlabel();
    //         setall('btn btn-primary'); 
    //     }else if (input.value==2){
    //         disabledlabel();
    //         setaa('btn btn-primary'); 
    //     }else if (input.value==5){
    //         disabledlabel();
    //         setnps('btn btn-primary'); 
    //     }else if (input.value==6){
    //         disabledlabel();
    //         sete85('btn btn-primary'); 
    //     }else if (input.value==3){
    //         disabledlabel();
    //         setagf('btn btn-primary'); 
    //     }
    //     setValue(input.value)
    // };
//console.log(currentBranch.id)
    if (((!currentBranch)  || (!chartdata)   && amountcompare.length ==0) || data1.series==undefined
    || data2.series==undefined
    ) {

        return (<></>)

    
    }
    if (currentBranch._id !== 'vms'){
      return <>
      </>
    }
    
return (  
        <div className='row container-fluid'> 
        <div className="col-12">
          <div className='container'>
            <div className="row">
              <div className='m-4'>
                 
                <div className='mt-2' style={{ marginTop: 20, marginBottom: 20 }} >
                  <DatePicker
                    todayButton="Today"
                    dateFormat="dd/MM/yyyy"
                    selected={startDate}
                    onChange={date => setStartDate(date)} />
                </div>

              </div>   


              <div className="col-auto">
            <div className="m-2">
              <i>
                พื้นที่ <span className="label label-default"></span>
              </i>
              <div className="mt-2" style={{ marginTop: 20, marginBottom: 20 }}>
                <Header onChange={handleChange} />
              </div>
            </div>
          </div>

              {/* <div className="row justify-content-end align-items-center">
                  <div className="col-auto mb-3 px-0"><ul className="btn-group p-0 m-0 ">

     
                     <RenderButton 
                     label ="All"
                     value="1"
                     onClick ={handleonClick}
                     className = {all}
                      />
                    <RenderButton 
                     value="2"
                     label ="AA ป้อม 3"
                     onClick ={handleonClick}
                     className = {aa}
                      />
                         <RenderButton 
                           value="4"
                          label ="AA PM3"
                     onClick ={handleonClick}
                     className = {pm3}
                      />
                        <RenderButton 
                           value="5"
                          label ="NPS"
                     onClick ={handleonClick}
                     className = {nps}
                      />

                        <RenderButton 
                           value="6"
                          label ="E85"
                     onClick ={handleonClick}
                     className = {e85}
                      />
                       <RenderButton 
                           value="3"
                          label ="AGF"
                     onClick ={handleonClick}
                     className = {agf}
                      />
                      </ul>
                      </div>
                      </div> */}


            </div>
          </div>
          <div className="mt-1">

        
          <ReportCreate
            data = {data2}
            stacked ={true}
          />
            </div>
           {/* <ChartColumn 
           options = {chartDatacompare}
           type="bar"
           height= "280"       
          /> */}
            <div className="mt-1">

           
            <ReportCreate
            data = {data3}
           
          />   
           </div> 
            {/* <ChartColumn 
           options = {chartDatapermit}
           type="bar"
           height= "300"       
          /> */}
            {/* <ChartColumn 
           options = {chartDatahour}
           width= "100%"
           type="bar"
           height= "250"       
          /> */}
           <div className="mt-1">

         
          <ReportCreate
          data = {data1}
         
          />
        </div>
        </div>

       
      </div>

    );


}

export default Reportregister;
