import React, { useEffect, useState,useContext, useRef,useCallback } from "react";
import Header from "./headerstatus";
import Input from "./../wms/input";
import RenderButton from './../common/button';
import {getuserondevice,getdevice,updatefacedata} from "./../../services/facescan";
import BranchContext from "./../../components/context/branch";
import RenderUser from "./renderuser";
import { toast } from "react-toastify";
import ScrollToTop from "./../common/ScrollToTop";

import ProgressBar from './../common/progressbar';
const UserOndevice = (props) => {
  //console.log(props);
  const [Id, setId] = useState(props.match.params.id);
  const {currentBranch } = useContext(BranchContext);
  const [name, setname] = useState("");
  const [search, setsearch] = useState("");
  const [status, setstatus] = useState(0);
  const [all, setall] = useState('btn btn-warning');

  const [facename, setfacename] = useState("");
  const [hasMore, setHasMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false)
  const [pageSize,setPageSize] = useState(30);
  const [total,setTotal] = useState(0);
  const [userlist, setuserlist] = useState([]);
  const [lastupdate, setlastupdate] = useState(new Date());
  const [showonscroll, setshowonscroll] = useState(false)
  const [showprogress, setshowprogress] = useState(false)
  const [completed, setcompleted] = useState(50)
 // const [showScroll, setShowScroll] = useState(false)


  // const checkScrollTop = () => {
  //   if (!showScroll && window.pageYOffset > 400){
  //     console.log('over 400')
  //     setShowScroll(true)
  //   } else if (showScroll && window.pageYOffset <= 400){
  //     setShowScroll(false)
  //     console.log('non 400')
  //   }
  // };

  // const scrollTop = () =>{
  //   window.scrollTo({top: 0, behavior: 'smooth'});
  // };

  // window.addEventListener('scroll', checkScrollTop)


  //console.log(Id);

  useEffect(() => {
  
    setuserlist([]);
  }, [currentBranch, pageSize,search,Id,status,lastupdate,showonscroll]);


  useEffect(() => {
    if (!currentBranch) return;
    if (!Id) return;


        const loadData = async () => {

            const { data: result } = await getdevice(Id);
            if ( result.status==='success') {
               setfacename(result.data.list[0].face_name);

             //  console.log(result.data.list[0].face_name)
         
                           
            }

    };
    loadData();
  }         
  , [currentBranch,Id]);


  useEffect(() => {
    if (!currentBranch) return;
    if (!Id) return;
    if (!pageSize || !pageNumber  ) return;
    setLoading(true)
    setError(false)
     setshowprogress(true)
        const loadData = async () => {

            const { data: result } = await getuserondevice(Id,status,search,pageSize,pageNumber);
            if ( result.status==='success') {
                setTotal(result.data.total);
         setshowprogress(false);
                setuserlist(prevData => {   
         return [...new Set([...prevData, ...result.data.list])]
                      })
               setHasMore(result.data.list.length > pageSize-1)
               setLoading(false)                 
            }

    };
    loadData();
  }         
  , [currentBranch,Id,status,search,pageSize,pageNumber,lastupdate,showonscroll]);

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {

      if (node && node.offsetTop > 0){
        setshowonscroll(true);
       }

      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
    
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const handleOnClick =( cardid) => {

    const doc = { face_id :Id, card_id : cardid
    }
    //console.log(doc);
    SaveData(doc);
  }

  const  SaveData = async (doc) => {

    try {

    const {data: result} = await updatefacedata(doc);
   // console.log(doc)

    if (result.status==='success') {
      toast.success("บันทึกเสร็จเรียบร้อย");
      setstatus(0);
      }
    else {
    toast.error(result.message)
    }
  } 
  catch (error) {
   // toast.error("Error");
  }
}
  const handleTextchange = (event) => {
    setname(event.currentTarget.value);

  };
  const handleChange = (event) => {
   // setsearch(name)
    setstatus(event);
    setPageNumber(1);
    //    console.log('Click event',pageNumber);
  };
  const handleSearchClick = () => {
    setsearch(name);
    setPageNumber(1);
  };
  const onUpdate =() =>{
    setlastupdate(new Date());
  }
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      setsearch(name)
    }
  };

  const scrollToTop = () => { 
    setPageNumber(1);
    setshowonscroll(false);
  }
 // console.log(userlist)
  if (currentBranch._id !=='0'){
    return <>
    </>
    }
  return (
    <div>
      <div className="row">
        <div className="col-lg-6  col-sm-6 col-xs-12">
          <div className="form-group">
            <Input
              name="devicename"
              value={name}
              label="รายชื่อ"
              placeholder="ระบุชื่อ/รหัสพนักงาน เพื่อค้นหา"
              onChange={handleTextchange}
              onClick={handleSearchClick}
              onKeyPress={handleKeyPress}
              search="ค้นหา"
            />
          </div>
        </div>
        <div className="col-lg-6  col-sm-6 col-xs-12">
          <div className="form-group">
            <i>
              สถานะ <span className="label label-default"></span>
            </i>
            <div className="mt-2" style={{ marginTop: 20, marginBottom: 20 }}>
            <Header
            onChange={handleChange}
            />
            </div>
          </div>
        </div>
        <div className="col-lg-6  col-sm-6 col-xs-12">
              

            
                  <div className="col-auto mb-3 px-0"><ul className="btn-group p-0 m-0 ">
                     <RenderButton 
                     label ={facename}
                     value="0"
            
                     className = {all}
                      />
                    
                         
                       
                      </ul>
                      </div>
                      </div>


           


      </div>



      <p className="text-success">
        แสดงข้อมูล {userlist ? userlist.length : 0}  /  {total} รายการ
      </p>
      { showprogress && <ProgressBar bgcolor={"#FF0066"} completed={completed} />}
      {pageNumber > 1 && showonscroll &&
        <ScrollToTop 
        onClick ={scrollToTop}
        />}
      <RenderUser
      list = {userlist}
      handleOnClick = {handleOnClick}
       error={error}
      lastBookElementRef={lastBookElementRef}
      onUpdate ={onUpdate}
      
      />
 

   </div>
  );
};

export default UserOndevice;
