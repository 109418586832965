import React,{useState,useEffect,useContext,useCallback,useRef} from 'react';
import Input from './../wms/input'
import Card from "./rendercard";
import Select from './../wms/select';
import "./../../card.css";
import {getSafetypass,getSafetyType,getSafetyRegisters} from "../../services/vms";
import BranchContext from "./../../components/context/branch";
import FontAwesome from 'react-fontawesome';
import Header from './headercardtype'
import HeaderCard from './headercase'

const Safetypass = (props) => {
const { currentBranch } = useContext(BranchContext);
const [vender, setvender] = useState('');
const [search, setSearch] = useState('');
const [safetyId, setSafetyId] = useState(0);
const [cardId, setcardId] = useState(0);
const [saftetyTypelist, setSafetyTypelist] = useState([]);
const [supplierlist, setsupplierlist] = useState([]);
const [hasMore, setHasMore] = useState(false);
const [pageNumber, setPageNumber] = useState(1);
const [loading, setLoading] = useState(true);
const [error, setError] = useState(false)

const [pageSize,setPageSize] = useState(10);
const [total,setTotal] = useState(0);
const [data, setData] = useState(null)

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setData(await getSafetyRegisters())
  //   } 
  //   fetchData()
  // }, []) 


useEffect(() => {
  setsupplierlist([]);
}, [currentBranch,pageSize,search,safetyId,cardId]);

 
useEffect(() => {

  if (! currentBranch  ) return; 


  
  getSafetyType().then (result=>{ 
      
    setSafetyTypelist(result.data);      
              
           
             // vForm1=true;
                    
               });
             }, 
             [currentBranch])




useEffect(() => {
  if (!currentBranch) return;
  if (!pageSize || !pageNumber  ) return;

  setLoading(true)
  setError(false)

  const loadRegister = async () => {
   
    const { data: result } = await   getSafetypass(
      search,cardId,safetyId,pageSize,pageNumber)

      if (result.status === "success") {
        setTotal(result.data.total);
     //      console.log(result.data)  
        setsupplierlist(prevData => {   
                   return [...new Set([...prevData, ...result.data.list])]
                                })
                         setHasMore(result.data.list.length > pageSize-1)
                         setLoading(false)  

      }

    }
  //  ).then((result) => {
      loadRegister();

    // getSafetypass(search,safetyId,pageSize,pageNumber).then((result) => {  
    //   setsupplierlist(prevData => {   
    //   return [...new Set([...prevData, ...result.data])]
    //                 })
    //          setHasMore(result.data.length > pageSize-1)
    //          setLoading(false)       
    //          });


  
   }

, [currentBranch,search,cardId,pageSize,pageNumber,safetyId]);
//console.log(supplierlist);
const observer = useRef()
const lastBookElementRef = useCallback(node => {
 //  console.log('node = ' ,node);
  if (loading) return
  if (observer.current) observer.current.disconnect()
  observer.current = new IntersectionObserver(entries => {
   // console.log(entries[0].isIntersecting,hasMore);

    if (entries[0].isIntersecting && hasMore) {
      setPageNumber(prevPageNumber => prevPageNumber + 1)
    }
  })
  if (node) observer.current.observe(node)
}, [loading, hasMore])

const handleClick = (id,detail) => {


//console.log(id,detail)
if (id>0 && detail !=='new') {
  getDetails(id);
    
  }

}
const getDetails =(id) => {


  
  const fetchData = async () => {

    const { data: result } = await getSafetyRegisters(id);              
                 if (result) {  
    
    //                //   console.log(result);
    
                    // result.filter(item => item.RegisterID === id) 
                    
                     setData(result);
   // setData(await getSafetyRegisters(id))
   if (result){
    console.log(result);
    const urlGoBack = "/safetyrecord/"+ id;
    setTimeout(props.history.replace(urlGoBack,result), 1000);
  }
  } 
}
fetchData();

// const urlGoBack = "/safetyrecord/"+ id;
//     setTimeout(props.history.replace(urlGoBack), 1000);



}
//console.log(data);

const handleTextchange =(event) => {

  setvender(event.currentTarget.value);
 // checkdigit();
}
const handleKeyPress = (event) => {
   if(event.key === 'Enter'){
    checkdigit();
    }
 // 
//   console.log(vender)
// if (vender.length> 4){
//   setSearch(vender);
// }
}
const handleChange =(id, detail) => {
 
  if (id===0 && detail==='new') {
    //console.log(id,detail);
    const urlGoBack = "/safetyrecord/new";
    setTimeout(props.history.replace(urlGoBack,[]), 1000);
  }
  
}
const handleChangeId =(event) =>{
  setSafetyId(event);
  setPageNumber(1);
}
const handleChangeCard=(event) =>{
  setcardId(event);
  setPageNumber(1);
}
const checkdigit = () => {
  //console.log(vender)
  
if (vender.length>3){

setSearch(vender);
}else {
  if (search.length > 0 && vender.length===0){
  //  console.log('search = ',search)
    setSearch('');
  }
}
}

const handleSearchClick = () => {
  checkdigit();
}
//console.log(currentBranch._id);
if (currentBranch._id !== 'vms'){
  return <>
  </>
}

    return ( 
      <div>
        <div className="container m-3">
          <div className="row">
            <div className="col-lg-4  col-sm-10 col-xs-12" >
             
              <div className="form-group">
                <Input
                  name="name"
                
                  value={vender}
                  placeholder="ชื่อ สกุล"
                  onChange={handleTextchange}
                  onKeyPress={handleKeyPress}
                  onClick={handleSearchClick}
                  search="ค้นหา"
                />
              </div>
            </div>

            <div className="col-lg-4  col-sm-10 col-xs-12" >
            <i>
                ประเภทบัตรอบรม <span className="label label-default"></span>
              </i>
            <Header
             onChange={handleChangeId}
            />
              {/* <Select
                label='ประเภทบัตรอบรม'
                data={saftetyTypelist}
                value={safetyId}
                textProperty="name"
                valueProperty="_id"
                onChange={handleChangeId}
              /> */}

            </div>
            <div className="col-lg-4  col-sm-6 col-xs-12" >
            <i>
                ประเภทใบเตือน <span className="label label-default"></span>
              </i>
            <HeaderCard
             onChange={handleChangeCard}
            />
              {/* <div>&nbsp;</div>
              <button className="btn btn-success ">Download To Excel</button> */}
            </div>


          </div>
        </div>
        
 

        {/* <div className="content-wrapper">
     <div className="row"  >

    <div className="col-12 col-sm-6 col-md-6" > */}
        <div className="col-12 col-sm-6 col-md-6 mt-2" >
          <div className="container">
            <button
              className="btn btn-primary"
              onClick={() => handleChange(0, "new")}
            >
              <FontAwesome name="plus" />
              <i /> Add New
                          </button>
            <br />
            <br />
          
          </div>
        </div>
        <p className="text-success">
        แสดงข้อมูล {supplierlist ? supplierlist.length : 0}  /  {total} รายการ
        </p>
            <Card
              list={supplierlist}
              loading={loading}
              error={error}
              lastBookElementRef={lastBookElementRef}
              handleChange={handleClick} />
              
      </div>

     );
}
 
export default Safetypass;