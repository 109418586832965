import React, {useContext,useState,useEffect} from "react";
import { Link, NavLink } from "react-router-dom";
import ConfirmLogout from './../common/confirmLogout';
import Selectbranch from './../common/selectBranch';
import {getBranchList} from './../../services/grabconcept';
import auth from "../../services/authService";
import Clock from 'react-live-clock';

import BranchContext from './../context/branch';


const NavBar = (props) => {
 
//let count=0;
//console.log(props.history);

const currentBranch = useContext(BranchContext);

//console.log('Branch  Context ',branchContext.currentBranch && branchContext.currentBranch.value

//);

  const [branchList, setbrachList] = useState([]) ;
  const [collapsed ,setcollapsed] = useState(true) ;
  const [isModalLogoutOpen ,setisModalLogoutOpen] = useState(false) ;
  const [isModalSelectBrancOpen ,setisModalSelectBrancOpen] = useState(false) ;
  const [vForm, setvForm] = useState(false);

  const [_isMounted ,set_isMounted] = useState(false) ;
  const [stateData, setstateData] = useState({ 
        askConfirm: {enable: false, message: ""},
        message:[]
       });
       //const [branchId, setBranchId] = useState({ _id:'501',name:'AA'})

//   const pstate = { 
//     collapsed: true,
//     isModalLogoutOpen: false,
//     askConfirm: {enable: false, message: ""},
//     message:[],
//     _isMounted : false
//    }


//  const  componentDidMount =() => {
//     set_isMounted(true);
//    // console.log('context',this.context);
//   }

//  const componentWillUnmount =() =>
//   { set_isMounted(false);
//  }

  const toggleNavbar =() => {
    const { onSidebarToggle } = props;

    if( typeof onSidebarToggle ==='function') {
      onSidebarToggle();
    }
  }


  

  
   
  //hide = () => this.setState({ collapsed: true});
 const hide = () => setcollapsed(true);
const  doLogout = async () => {
    setisModalLogoutOpen(true);
    setcollapsed(true);
  //  this.setState({isModalLogoutOpen: true, collapsed: true})
   }
const onLogoutCancel =() => {
    setisModalLogoutOpen(false); 
}

//   onLogoutCancel = () => {
//     setisModalLogoutOpen(false);
//    // this.setState({isModalLogoutOpen: false})
//   }

const onLogoutConfirm = () => {
    setisModalLogoutOpen(false); 
}
//   onLogoutConfirm = async () => {
//    // this.setState({isModalDeleteOpen: false})
//    setisModalLogoutOpen(false);
//     this.props.history.replace("/logout");
//   }

const handleConfirmSubmit = (e) => {
 //    console.log('frm NavBarForm',e.currentTarget.name)
//     console.log('frm NavBarForm',e.currentTarget.name.value)
const {name,value} = e.currentTarget

    setstateData({askConfirm: { enable: false, message: ""}});

const objClone = {
    _id:name,name:value
}
auth.setCurrentBranchID(objClone._id);
auth.setCurrentBranchName(objClone.name);
//console.log('ObjClone',objClone)


currentBranch.onSelected(objClone)

 
//  setTimeout(props.history.replace("/search/"), 1000);
// console.log('call branch',props.history)
   // props.handelSelectedBranch();
    //this.setState({askConfirm: { enable: false, message: ""}})

  }

  


  useEffect(  () => {

    getBranchList().then (result=>{
       
           const {data} =result.data
       try {
        if (!(data.list === null)) {
          setbrachList(data.list);
        
       } else {
          setbrachList([]);
        
       }
       }catch {

       }
    //   console.log(data.list); 
       
       getBranchList();   
         
        //   count = !(data.list=== null) ? data.list.length : 0;
        //  if (count>0) {
        //     setstateData({askConfirm: { enable: false, message: ""}        

        //    });
        //  }
            
           });
         }, 
         [])  


         useEffect(() => {
          if (vForm && currentBranch)return;
          const loadDefault = () => {
          //  console.log(currentBranch)
           
            
          //  console.log(_id)
            if (currentBranch && (currentBranch.currentBranch._id ==null )  && user ){
              setstateData({askConfirm: { enable: true, message: ""}});
             // console.log(currentBranch,user,currentBranch.currentBranch._id)
             }
             setvForm(true);
          
            }
          loadDefault();
        })
        


 const handleCancleSubmit = () => {

    setstateData({askConfirm: { enable: false, message: ""}});
   // console.log(branchContext.currentBranch);

  }

 const handleonClick = () => {

   // this.loadBranchList();
   // console.log('Click ')
   setstateData({askConfirm: { enable: true, message: ""}});
    //this.setState({askConfirm: { enable: true, message: ""}})
   
   // this.loadBranchList();
  }
  //const [askConfirm , setaskConfirm] = useState(false);

//   render() { 
    const { user} = props;
    //const {pcurrentBranch} = props;

//     const { isModalLogoutOpen}  = this.state;
//     const { askConfirm } = this.state
//     const { data } = this.state;

//if ()
//console.log(currentBranch._id);

//   return(
//     <div>

//     </div>
//   )

//}

    return ( 
        <React.Fragment>  
        <ConfirmLogout isOpen={isModalLogoutOpen} onConfirm={onLogoutConfirm} onCancel= {onLogoutCancel}  />
   
       { currentBranch.currentBranch  && (currentBranch.currentBranch._id===''|| currentBranch.currentBranch._id==null)  ?  <Selectbranch 
        
        isOpen={stateData.askConfirm.enable}

       onConfirm={handleConfirmSubmit} onCancel={handleCancleSubmit} list = {branchList} onSelected={handleConfirmSubmit}  /> : 
       <Selectbranch 
        
       isOpen={stateData.askConfirm.enable}

      onConfirm={handleConfirmSubmit} onCancel={handleCancleSubmit} list = {branchList} onSelected={handleConfirmSubmit}  />
       }
        <nav className="navbar navbar-dark fixed-top flex-md-nowrap p-0 shadow">
          <button
              className="navbar-toggler d-inline d-lg-none"
              type="button"
              onClick={toggleNavbar}
            >
              <span className="navbar-toggler-icon" />
            </button>

          <Link className="navbar-brand ml-2 d-none d-md-inline ml-2" to="/">{'Grab & WMS'}</Link>

         { user && currentBranch.currentBranch  && < button type="button" onClick = {handleonClick} className="btn btn-dark mr-2 text-warning"> 
         {currentBranch.currentBranch ? currentBranch.currentBranch.name:"select"} </button>}
         {/* className="navbar-toggler d-inline d-lg-none" */}
        {/* <button type="button" className ="button.btn.btn-dark.btn-sm.py-0.px-2.mr-2.text-warning">
        <span className="navbar-toggler-icon" /> {user.name}
          </button>  */}
          {user && <div>
            <strong className="d-none d-xl-inline ml-2"><Clock format="ddd" ticking={true} interval={60000} /></strong>
            <strong className="d-none d-md-inline ml-2">
              <Clock format="D MMMM YYYY" ticking={true} interval={60000} />
            </strong>
            <strong className="d-none d-md-inline ml-2"><Clock format="HH:mm:ss" ticking={true} interval={1000} /></strong>
          </div>}
          {user && <span className="d-none d-sm-inline ml-auto text-white  text-small">{user.fullname} {" "}</span>}
          {user && 
          
<div className="d-none d-md-inline text-white text-muted">
                              <img
                                src={"https://wms.advanceagro.net/WSVIS/api/Face/GetImage?CardID=" + user.empid + "&size=320"}
                                className="rounded-circle mr-2 ml-4"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title=""
                                alt=""
                                style={{
                                  maxHeight: "50px",
                                  Width: "100%",
                                  maxWidth: "30px",
                                }}
                              ></img>
                   </div>   
          
          // <span className="d-none d-sm-inline ml-auto text-white">Login : {user.name}</span>
          
          }
          {user && <ul className="d-sm-inline navbar-nav col-auto px-3">
            <li className="nav-item text-nowrap">
              <Link className="nav-item nav-link" to="/logout" onClick={hide}>ออกจากระบบ</Link>
            </li>
          </ul>}
          {!user && (
                <React.Fragment>
                  <NavLink className="nav-item nav-link text-white" to="/login" onClick={hide}>Login</NavLink>
                </React.Fragment>
              )}
        </nav>
      </React.Fragment>
     );
  }

//NavBar.contextType= BranchContext;

export default NavBar;
