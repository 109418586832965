import React from 'react';

const RenderButton = ({label, onClick,className,value}) => {


    return (    
        <button 
        type="button"
        onClick={onClick}
        value ={value}
        className={className}>
        {label}
      </button>
    )  
    ;
}
 
export default RenderButton;