import http from "./httpService";
import {apiUrl} from '../config.json';

const apiEndpoint = "/JAWISWood";

export function getReportJawis(start='', end='',branchid ='',type='') {

    return http.get(apiEndpoint + "/ReportGrabWoodChip?startdate=" + start + "&enddate=" + end +"&id="+ branchid +"&type="+type);
  
 
}
export function getBranchReportJawis(start='', end='',branchid='',type='') {
  return http.get(apiEndpoint + "/GrabBranchWoodChip?startdate=" + start + "&enddate=" + end +"&id="+ branchid+"&type="+type);
}

export function downLoadExcelGrap(start='',end='',branch='',type=''){

  return http.get(apiUrl+"download/toexcel?reportid=R01&startdate="+start +"&enddate="+ end+"&branchid="+ branch+"&type="+type, {responseType: 'blob'})
  }
