import React from "react";
import { Link } from "react-router-dom";
import FontAwesome from 'react-fontawesome';


const RenderCompanyList = ({ list,lastBookElementRef,handleOnClick }) => {
 
  
 //console.log(list)
  return (
    <>
      {list &&
       
        list.map((item,index) => {
          if (list.length === index +1)
        { return (

          <div className="row" key={item.No}>
          <div className="container-fluid">
            <div className="card border-secondary m-1">
              <div className="card-body">
                <div ref = {lastBookElementRef} className="row">
                  <div className="container col-xs-12 col-sm-4">
                  <div style={{ fontSize: "15px" }}>
                          <span className="badge badge-pill badge-secondary">
                            {item.No}
                          </span>
                        </div>
                    <div style={{ fontSize: "14px" }}>{item.ComIdentityID}</div>
                    <div style={{ fontSize: "14px" }}>
                      {item.Comname}
                    </div>
                    <div style={{ fontSize: "14px" }}>
                          <h6>
                            contact:
                            <small style={{ fontSize: "14px" }}> {item.FnameT} {item.LnameT} </small>{" "}
                          </h6>
                        </div>
                  </div>

                 

                  <div className="container col-xs-12 col-sm-4">
                 
                  <div style={{ fontSize: "14px" }}>
                          <h6>
                            {"ขอเข้าทำงาน"}

                            <small style={{ fontSize: "18px" }}>
                            {" "}
                            { item.amount > 0 ? <span className="badge badge-pill badge-success"> 
                              {item.amount}
                            </span> : 
                            <span className="badge badge-pill badge-warning"> 
                            {item.amount}
                          </span>
                            }
                            {" ครั้ง"}
                          </small>
                          </h6>
                        </div>

                        <div style={{ fontSize: "14px" }}>
                          <h6>
                            {"ส่งสินค้า"}

                            <small style={{ fontSize: "18px" }}>
                            {" "}
                            { item.Delivey > 0 ? <span className="badge badge-pill badge-success"> 
                              {item.Delivey}
                            </span> : 
                            <span className="badge badge-pill badge-warning"> 
                            {item.Delivey}
                          </span>
                            }
                            {" ครั้ง"}
                          </small>
                          </h6>
                        </div>

                        <div style={{ fontSize: "14px" }}>
                          <h6>
                            {"ติดต่องาน"}

                            <small style={{ fontSize: "18px" }}>
                            {" "}
                            { item.visit > 0 ? <span className="badge badge-pill badge-success"> 
                              {item.visit}
                            </span> : 
                            <span className="badge badge-pill badge-warning"> 
                            {item.visit}
                          </span>
                            }
                            {" ครั้ง"}
                          </small>
                          </h6>
                        </div>
                  
                  </div>
                  <div className="container col-xs-12 col-sm-4">

                  <div style={{ fontSize: "14px" }}>
                          <h6>
                            {" "}
                            วันที่เริ่มใช้:
                            <small style={{ fontSize: "14px" }}>
                              {" "}
                              {item.createdate}
                            </small>
                          </h6>
                        </div>
                        <div style={{ fontSize: "14px" }}>
                          <h6>
                            {"Type"}
                                 :
                            <small style={{ fontSize: "14px" }}>
                             
                              {item.Supplier_type}
                            </small>
                          </h6>
                        </div>
                  </div>
                  <div className="d-flex justify-content-end"> 
 <button
                          className="btn btn-secondary btn-sm"
                          onClick={() => handleOnClick(item.face_id, "edit")}
                        >
                      <FontAwesome name="edit"/>  
                               
                        </button>
</div>

                </div>
              </div>
            </div>
          </div>
         
        </div>
        )} else 
        {
          return (

            <div className="row" key={item.No}>
              <div className="container-fluid">
                <div className="card border-secondary m-1">
                  <div className="card-body">
                    <div className="row">
                      <div className="container col-xs-12 col-sm-4">
                        <div style={{ fontSize: "15px" }}>
                          <span className="badge badge-pill badge-secondary">
                            {item.No}
                          </span>
                        </div>
                        <div style={{ fontSize: "14px" }}>{item.ComIdentityID}</div>
                        <div style={{ fontSize: "14px" }}>
                          {item.Comname}
                        </div>
                        <div style={{ fontSize: "14px" }}>
                          <h6>
                            contact:
                            <small style={{ fontSize: "14px" }}> {item.FnameT} {item.LnameT} </small>{" "}
                          </h6>
                        </div>
                      </div>
  
                     
  
                      <div className="container col-xs-12 col-sm-4">
                      
                        
  
                        <div style={{ fontSize: "14px" }}>
                          <h6>
                            {"ขอเข้าทำงาน"}

                            <small style={{ fontSize: "18px" }}>
                            {" "}
                            { item.amount > 0 ? <span className="badge badge-pill badge-success"> 
                              {item.amount}
                            </span> : 
                            <span className="badge badge-pill badge-warning"> 
                            {item.amount}
                          </span>
                            }
                            {" ครั้ง"}
                          </small>
                          </h6>
                        </div>

                        <div style={{ fontSize: "14px" }}>
                          <h6>
                            {"ส่งสินค้า"}

                            <small style={{ fontSize: "18px" }}>
                            {" "}
                            { item.Delivey > 0 ? <span className="badge badge-pill badge-success"> 
                              {item.Delivey}
                            </span> : 
                            <span className="badge badge-pill badge-warning"> 
                            {item.Delivey}
                          </span>
                            }
                            {" ครั้ง"}
                          </small>
                          </h6>
                        </div>

                        <div style={{ fontSize: "14px" }}>
                          <h6>
                            {"ติดต่องาน"}

                            <small style={{ fontSize: "18px" }}>
                            {" "}
                            { item.visit > 0 ? <span className="badge badge-pill badge-success"> 
                              {item.visit}
                            </span> : 
                            <span className="badge badge-pill badge-warning"> 
                            {item.visit}
                          </span>
                            }
                            {" ครั้ง"}
                          </small>
                          </h6>
                        </div>
                        <div></div>
                      </div>
                      <div className="container col-xs-12 col-sm-4">
                      <div style={{ fontSize: "14px" }}>
                          <h6>
                            {" "}
                            วันที่เริ่มใช้:
                            <small style={{ fontSize: "14px" }}>
                              {" "}
                              {item.createdate}
                            </small>
                          </h6>
                        </div>
                        <div style={{ fontSize: "14px" }}>
                          <h6>
                            {"Type"}
                                 :
                            <small style={{ fontSize: "14px" }}>
                             
                              {item.Supplier_type}
                            </small>
                          </h6>
                        </div>
                     
                        <div className="d-flex justify-content-end"> 
 <button
                          className="btn btn-secondary btn-sm"
                          onClick={() => handleOnClick(item.face_id, "edit")}
                        >
                      <FontAwesome name="edit"/>  
                               
                        </button>
</div>

                   </div>
                    </div>
                  </div>
                </div>
              </div>
             
            </div>
          )

        }}

        
        )}
    </>
  );
};

export default RenderCompanyList;
