import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Sidebar from "react-sidebar";
import Reportchart from './components/wms/reportchart';
import Stackedcolumnchart from './components/chart/stackedcolumn';
import auth from "./services/authService";
//import BranchContex from './components/context/branch';

import NavBar from "./components/menu/navBarF";

// import Live from './components/live/live';
// import History from './components/history/history';
// import Work from './components/work/work';
// import Templates from "./components/template/templates";
import NotFound from "./components/notFound";
import Showdetail from './components/wms/showdetail';
// import Staff from './components/staff/staffs';

import LoginForm from "./components/session/loginForm";
import Logout from "./components/session/logout";
import RegisterUser from './components/register/registerprofile';
//import RegisterUser from './components/wms/registeruser';
import ProtectedRoute from "./components/common/protectedRoute";
//import ScrollToTop from './components/scrolltotop/index';
// import Product from "./components/master/product";
// import ProductList from "./components/master/productlist";
// import DemoHook from "./components/master/demohook";
// import Customer from "./components/master/customer";
//import Hook from "./components/master/hook";
//import FormApplication from "./components/master/FormApplication";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";
// import PlanForm from './components/plan/planForm';
// import Plans from "./components/plan/plan";
import Profile from './components/user/profile';
import Itemlist from './components/product/itemlist';
import Faceinoutbyemp from './components/face/cockinout'

// import StaffForm from './components/staff/staffForm';
// import TemplateForm from './components/template/templateForm';
import SideNavBar from "./components/menu/sideBarF";
//import SideNavBarF from "./components/menu/sideBarF";
// import OptionManager from "./components/options/manage";
// import Branches from "./components/branch/branches";
import Search from './components/search/search';
import Facedevice from './components/face/device'
import Facemanage from './components/face/facemanage'
import DeviceClone from './components/face/deviceclone';


import Register from './components/wms/register';
import registerlist from './components/wms/registerlist';
import Woodreceive from './components/mis/wood';

import Shutdownlist from './components/vms/shutdownlist';
import Chemicalcom from './components/vms/chemicalcom';
import Supplierjob from './components/vms/supplierbyjob';
import Reportvmsregister from './components/vms/reportregister';
import Forapproved from './components/vms/forapproved';
//import Calendar from './components/wms/queue/calendar';
import Safetyrecord from './components/vms/safetyrecord';
import Weightreport from './components/wms/weightreport';
import Woodchip from './components/mis/woodchip';
import Reportccpedit from './components/ccp/reportccpedit'
//import Weightregister from './components/wms/weightregister';

//port productlist from "./components/master/productlist";
// import SimAdmin from "./components/sim/admin";
// import UploadBill from "./components/sim/admin/uploadBill";
// import SimSystemOnOff from "./components/sim/admin/systemOnOff";

// New App Master

import {getPrivilege} from './services/privilege';
import Employee from './components/face/employee';
import Cockinout from  './components/vms/cockinout';
import Safetypass from './components/vms/safetypass';
import FaceList from './components/face/facelist'
import UserOndevice from './components/face/userondevice'
import Permitgate from './components/gate/permitgate';

import Valueslist from './components/jobvalue/valueslist';
import BranchContext from "./components/context/branch";
import PrivilegeContext from './components/context/privilege';

//import { StoreContextProvider } from './components/context/store';
import UserContext from './components/context/user';
import Accountrequest from './components/vms/accountrequest';
import AccountList from './components/register/accountlist';
import Addface from './components/face/addface'
import ForApprove from './components/gate/forapprove'
import CCP from './components/ccp/reportccp'
import ScrollToTop from './components/scrolltotop';


const mql = window.matchMedia(`(min-width: 992px)`);

class App extends Component {
  state = {
    currentBranch : {_id:'',name:""},
    privilege :[]
  };

  constructor(props) {
    super(props);
    this.state = {
      sidebarDocked: mql.matches,
      sidebarOpen: false
    };

    this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
    
  }

  handelSelectedBranch = (brandid) => {
      // console.log("Get Branch", + brandid);
      // const branch = {  _id:505,name:"E85"};

      this.setState({currentBranch: brandid})
  } 
  handelOnChange =(event) => {

    this.setState({privilege : event});

  }

  componentWillUnmount() {
    mql.removeListener(this.mediaQueryChanged);
  }

  onSetSidebarOpen(open) {
    this.setState({ sidebarOpen: open });
  }

  mediaQueryChanged() {
    this.setState({ sidebarDocked: mql.matches, sidebarOpen: false });
  }

  componentDidMount() {
    mql.addListener(this.mediaQueryChanged);

    const user = auth.getCurrentUser();
    const currentBranch =auth.getCurrentBranch();
    this.loadPrivilege();   
// console.log(currentBranch);
    this.setState({currentBranch});
    this.setState({ user });
  }

  toggleSideBar = () => {

    const { sidebarOpen } = this.state;
  //  console.log(sidebarOpen)
  //  console.log(sidebarOpen)
    this.setState({ sidebarOpen: !sidebarOpen })


  }

  async loadPrivilege() {
    const { data: result } = await getPrivilege()

    if ( result) 
    {
      const privilege = result;    
      this.setState({ privilege: privilege })

     // if ( this._isMounted) this.setState( privilege);
   // console.log(privilege);
    }
  }
  getdata = () => {
  window.addEventListener('scroll',() =>{
console.log(window.scrollY);
  })
 }
 
//currentBranch = {currentBranch}
//console.log(currentBranch);
  render() {
    const { user } = this.state;
   const {currentBranch} = this.state;
   const {privilege} = this.state;
  

  if (!(privilege)){
    return <div>

    </div>
  }
   
 //  console.log(privilege);
    return (
      <UserContext.Provider value={user}>
      <PrivilegeContext.Provider value = {{privilege : privilege ,onChange:this.handelOnChange}}>
      <BranchContext.Provider
        value={{ currentBranch, onSelected: this.handelSelectedBranch }}
      >
        <React.Fragment>
          <ToastContainer />
          <NavBar user={user} onSidebarToggle={this.toggleSideBar} />
          <Sidebar
            sidebar={<SideNavBar onSidebarToggle={this.toggleSideBar} />}
            open={this.state.sidebarOpen && user !== undefined}
            docked={this.state.sidebarDocked && user !== undefined}
            onSetOpen={this.onSetSidebarOpen}
            styles={{ root: { top: 0 }, sidebar: { overflow: "hidden" } }}
          >

            {/* <ScrollToTop/> */}
            <div className="container-fluid">
              <div className="row justify-content-center">
                <main
                  role="main"
                  
                  className="container-fluid"
                  style={{ padding: "5px", marginTop: "45px" }}
                >
                  {/* <usestatelogin/> */}
                  <Switch>
                    <ProtectedRoute path="/valueslist" component={Valueslist} />
                    <ProtectedRoute path="/search" component={Search} />
                    <ProtectedRoute path="/register/:id" exact render={props => <Register {...props} user={user} />} />
                    <ProtectedRoute path="/registerlist" component = {registerlist}/>
                    <ProtectedRoute path="/showdetail" component={Showdetail}/>
                    <ProtectedRoute path="/Reportchart" component = {Reportchart}/>
                    <ProtectedRoute path="/Stackedcolumnchart" component = {Stackedcolumnchart}/> 
                    <ProtectedRoute path ="/itemlist" component= {Itemlist}/>
                    <ProtectedRoute path ="/shutdownlist" component= {Shutdownlist}/>
                    <ProtectedRoute path ="/supplierjob/:id"   component = {props => <Supplierjob {...props} /> } />
                    <ProtectedRoute path ="/accountrequest" component= {Accountrequest}/>  
                    <ProtectedRoute path ="/reportregister" component= {Reportvmsregister}/>  
                    <ProtectedRoute path ="/forapproved" component= {Forapproved}/>  
                    <ProtectedRoute path ="/cockinout" component= {Cockinout}/> 
                    <ProtectedRoute path ="/chemicalcom" component= {Chemicalcom}/> 
                    <ProtectedRoute path ="/safetypass" component= {Safetypass}/>    
                    <ProtectedRoute path ="/safetyrecord/:id" exact render={props => <Safetyrecord {...props} user={user} />}/>    
                    <ProtectedRoute path ="/weightreport" component= {Weightreport}/>
                    <ProtectedRoute path ="/woodrecive" component={Woodreceive} />; 
                    <ProtectedRoute path ="/woodchip" component={Woodchip} />;             
                    <ProtectedRoute path ="/facedevice" component= {Facedevice}/>
                    <ProtectedRoute exact path="/facemanage" component={Facemanage} />
                    <ProtectedRoute path ="/accountlist/" component = {AccountList} />  
                    <ProtectedRoute path ="/permitgate/" component = {Permitgate} />   
                    <ProtectedRoute exact path="/facelist" component={FaceList} />
                    <ProtectedRoute path ="/userondevice/:id" component = {props => <UserOndevice {...props} /> } />
                    <ProtectedRoute path ="/empprofile" component = {Employee}/> 
                    <ProtectedRoute path ="/approvedgate" component = {ForApprove}/>
                    <ProtectedRoute path ="/receivechemical" component = {CCP}/>
                    
                    <ProtectedRoute path ="/editccp/:id" exact render={props => <Reportccpedit {...props} />}/>
                    <ProtectedRoute path="/faceinout" exact render={props => <Faceinoutbyemp {...props} user={user} />} />
                    <ProtectedRoute path="/clonedevice/:id" exact render={props => <DeviceClone {...props} user={user} />} />
                    
                    <ProtectedRoute
                      path="/profile"
                      render={(props) => <Profile {...props} user={user} />}
                    />
                    <Route path="/login" component={LoginForm} />;
                    <Route path="/registeruser" component={RegisterUser} />;
                    <ProtectedRoute path="/logout" component={Logout} />;
                    <Route path="/not-found" component={NotFound} />
                    <Redirect from="/" exact to="/search" />
                    <Redirect to="/not-found" />
                  </Switch>
                </main>
              </div>
            </div>
          </Sidebar>
        </React.Fragment>
      </BranchContext.Provider>
      </PrivilegeContext.Provider>
      </UserContext.Provider>
    );
  }
}

export default App;
