import React from "react";
import Renderdevice from './renderdevice'
//import {getdevice} from './../../services/facescan';



const FaceList = () => {

  // const [hasMore, setHasMore] = useState(false);
  // const [pageNumber, setPageNumber] = useState(1);
  // const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(false);

  // const [pageSize, setPageSize] = useState(25);

  // useEffect(() => {
  //   getdevice([]);
  // }, [currentBranch, startDate, endDate, value, Id]);

  // useEffect(() => {
  //   if (!currentBranch) return;
  //   //console.log('call api')

  //   setLoading(true)
  //   setError(false)
  //   const loadData = async () => {
  //     //  console.log('resualt',Id,pageSize,pageNumber)
  //     getdevice(
  //       value,
  //       moment(startDate, "YYYY-MM-DD").add(0, "day").format("YYYY-MM-DD"),
  //       moment(endDate, "YYYY-MM-DD").add(0, "day").format("YYYY-MM-DD"),
  //       Id,
  //       pageSize,
  //       pageNumber
  //     ).then((result) => {
  //       //   console.log('resualt',result.data)
  //       getdevice((prevData) => {
  //         return [...new Set([...prevData, ...result.data])];
  //       });
  //       setHasMore(result.data.length > pageSize - 1);
  //       setLoading(false);
  //       // console.log('resualt',result.data)
  //     });
  //   };

  //   loadData();
  // }, [currentBranch, startDate, endDate, value, pageNumber, Id,pageSize]);

  return <>
   <Renderdevice
            list = ""
            handleChange = ""

            />
  </>
};

export default FaceList;
