import React, { useState, useEffect } from "react";
import DeviceTogle from "./renderdevicetogle";
import { getDeviceSeleced,UploadMultiDevice } from "./../../services/facescan";
import { toast } from "react-toastify";
import dlv from "dlv";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ProgressBar from './../common/progressbar';

const ModalAddToDevice = ({
  button1,
  isOpen,
  header,
  onCancel,
  onConfirm,
  message,
  list,
  onDisAprove,
  value,
  onChange,
  error,
}) => {
  const ojb = value;
  let pempid = dlv(ojb, "empid", "");
  let pempname = dlv(ojb, "empname", "");

 
  const [ip1, setIP1] = useState([]);
  const [ip2, setIP2] = useState([]);
  const [dap, setDap] = useState([]);
  const [bitec, setฺBitec] = useState([]);
  const [showprogress, setshowprogress] = useState(false)
  const [completed, setcompleted] = useState(50)
  const [redata, setRedata] = useState(new Date());
  

  
const cancleClck = () => {
 // console.log(redata);
  setRedata(new Date());
  onCancel();
}
const confirmClick =() => {
  SaveData();
}

const SaveData = async () => {
 const alldata = [...ip1,...ip2,...dap,...bitec];

 
 let addall =[];
 let deletall =[];
 for(const dataObj of alldata){
  
if (dataObj.chkselected){
  addall.push(parseInt( dataObj._id))
}else {
  deletall.push(parseInt( dataObj._id))
}
}

 const doc = {empid: pempid,delete : deletall ,add:addall};
   // console.log(doc);
   setshowprogress(true)
   const {data: result} = await UploadMultiDevice(doc);

  if (result.status==='success') {
    setshowprogress(false);
    toast.success("upload เสร็จเรียบร้อย");
    setRedata(new Date());
    onConfirm();

  } else {
    toast.error(result.message)
  }



}

  useEffect(() => {
    const loadRegister = async () => {
      try {
        if (!pempid) return;
        if (pempid === "") return;

        const { data: result } = await getDeviceSeleced(1, pempid);
        if (result.status === "success") {
          setIP1(result.data.list);
        } else {
          toast.error(result.message);
        }
      } catch (ex) {
        toast.error(ex.message);
      }
    };
    loadRegister();
  }, [pempid,redata]);

  useEffect(() => {
    const loadRegister = async () => {
      try {
        if (!pempid) return;
        if (pempid === "") return;

        const { data: result } = await getDeviceSeleced(2, pempid);
        if (result.status === "success") {
          setDap(result.data.list);
        } else {
          toast.error(result.message);
        }
      } catch (ex) {
        toast.error(ex.message);
      }
    };
    loadRegister();
  }, [pempid,redata]);

  useEffect(() => {
    const loadRegister = async () => {
      try {
        if (!pempid) return;
        if (pempid === "") return;

        const { data: result } = await getDeviceSeleced(5, pempid);
        if (result.status === "success") {
          setIP2(result.data.list);
        } else {
          toast.error(result.message);
        }
      } catch (ex) {
        toast.error(ex.message);
      }
    };
    loadRegister();
  }, [pempid,redata]);

  useEffect(() => {
    const loadRegister = async () => {
      try {
        if (!pempid) return;
        if (pempid === "") return;

        const { data: result } = await getDeviceSeleced(7, pempid);
        if (result.status === "success") {
          setฺBitec(result.data.list);
        } else {
          toast.error(result.message);
        }
      } catch (ex) {
        toast.error(ex.message);
      }
    };
    loadRegister();
  }, [pempid,redata]);
  

  const handleChangeToggleIP2 = (event, id, name) => {
    const { checked } = event.target;
    const toggle = {
      _id: id,
      name: name,
      chkselected: checked,
    };
    const subIP2 = [...ip2];
    const Index = subIP2.findIndex((x) => x._id === toggle._id);
    subIP2[Index] = toggle;
    setIP2(subIP2);
  };

  const handleChangeToggleDAP = (event, id, name) => {
    const { checked } = event.target;
    const toggle = {
      _id: id,
      name: name,
      chkselected: checked,
    };
    const subDAP = [...dap];
    const Index = subDAP.findIndex((x) => x._id === toggle._id);
    subDAP[Index] = toggle;
    setDap(subDAP);
  };

  const handleChangeToggleBitec = (event, id, name) => {
    const { checked } = event.target;
    const toggle = {
      _id: id,
      name: name,
      chkselected: checked,
    };
    const subBitec = [...bitec];
    const Index = subBitec.findIndex((x) => x._id === toggle._id);
    subBitec[Index] = toggle;
    setฺBitec(subBitec);
  };

  const handleChangeToggleIp1 = (event, id, name) => {
    const { checked } = event.target;
    const toggle = {
      _id: id,
      name: name,
      chkselected: checked,
    };
    const subIP1 = [...ip1];
    const Index = subIP1.findIndex((x) => x._id === toggle._id);
    subIP1[Index] = toggle;
    setIP1(subIP1);
  };

  //  console.log(ip2);
  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={onCancel}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalHeader>
          {pempid && (
            <div className="row no-gutters align-items-center justify-content-left">
              <div className="col-auto text-center mb-2 p-1 text-center">
                <img
                  src={
                    "https://wms.advanceagro.net/WSVIS/api/Face/GetImage?CardID=" +
                    pempid +
                    "&size=320"
                  }
                  className="mr-1"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title=""
                  alt=""
                  style={{
                    maxHeight: "150px",
                    Width: "100%",
                    maxWidth: "150px",
                  }}
                ></img>
              </div>

              {pempid && (
                <p
                  className="font-weight-normal badge badge-primary"
                  style={{ fontSize: "14px" }}
                >
                  {pempid} {"    "} {pempname}
                </p>
              )}
            </div>
          )}
        </ModalHeader>
        <ModalBody>
        { showprogress && <ProgressBar bgcolor={"#FF0066"} completed={completed} />}
          <div className="card border border-dark m-2">
            <p className="bg-info text-white">
              <span className="info-box-icon bg-info m-2">
                <i className="fa fa-users" />
              </span>
              <span> IP1 </span>
            </p>

            <DeviceTogle list={ip1} handleOnClick={handleChangeToggleIp1} />

            {ip2 && ip2.length > 0 && (
              <p className="bg-primary text-white mt-1">
                <span className="info-box-icon bg-primary m-2">
                  <i className="fa fa-users" />
                </span>
                <span> IP2 </span>
              </p>
            )}

            <DeviceTogle list={ip2} handleOnClick={handleChangeToggleIP2} />

            <p className="bg-success text-white mt-1">
              <span className="info-box-icon bg-success m-2">
                <i className="fa fa-users" />
              </span>
              <span> DAP </span>
            </p>

            <DeviceTogle list={dap} handleOnClick={handleChangeToggleDAP} />

            <p className="bg-warning text-black mt-1">
              <span className="info-box-icon bg-warning m-2">
                <i className="fa fa-users" />
              </span>
              <span> BITEC </span>
            </p>

            <DeviceTogle list={bitec}
             handleOnClick={handleChangeToggleBitec} />
          </div>
        </ModalBody>

        {error && <div className="col-md-12 alert alert-danger"> {error}</div>}

        {list && list.comment && <Button color="danger">{list.comment}</Button>}
        <ModalFooter>
          {
            <Button color="primary" onClick={confirmClick}>
              บันทึก
            </Button>
          }

          <Button color="secondary" onClick={cancleClck}>
            ยกเลิก
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalAddToDevice;
