import React,{useState,useEffect} from "react";
import { Modal, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Select from './select';
import { toast } from "react-toastify";
import moment from 'moment';
import SearchSupplier from './../vms/searchsupplier';
import {getBusiness,getAreas} from './../../services/vms';

const Modalshutdown = ({isOpen,oncanCel,title,onConfirm}) => {

    

    const [messageErros, setmessageErros] = useState({
      askAlert: {enable: false, message: ""}
   });
   

const handleChangoncanCel = () => {
  
  oncanCel();
}
 
   function handleUpdateData(value) {

             onConfirm(value);
 
   }

    return (  

        <Modal show={isOpen}
        onHide={oncanCel}
        size="col-12 col-md-10 col-lg-12 mb-1"
        aria-labelledby="contained-modal-title-vcenter"
     
        >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <SearchSupplier 
          handleSave={handleUpdateData}
          placeholder ="ค้นหาจากชื่อบริษัท"
          textProperty ="ComName"
          textProperty2= 'ComIdentityID'
          />

        </Modal.Body>
    { messageErros.askAlert.enable  &&  <div className="col-md-12 alert alert-danger">  { messageErros.askAlert.message}</div> }
        <Modal.Footer>
          <Button className="btn btn-secondary"  onClick={handleChangoncanCel}>
            Close
          </Button>
         
        </Modal.Footer>
      </Modal>

    );
}
 
export default Modalshutdown;