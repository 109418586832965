import React,{useEffect, useState,useContext, useRef,useCallback} from "react";
import Addface from './addface'
import Header from './headergrouphr';
import Input from "./../wms/input";
import BranchContext from "./../../components/context/branch";
import { getdevicehr} from './../../services/facescan';
import FontAwesome from 'react-fontawesome';
import ModalAddface from './searchface'
import ScrollToTop from "./../common/ScrollToTop";
import ProgressBar from './../common/progressbar';

const FaceManage = () => {
  const {currentBranch } = useContext(BranchContext);
  const [name, setname] = useState('')
  const [search, setsearch] = useState('')
  const [siteid, setsiteid] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false)
  const [pageSize,setPageSize] = useState(15);
  const [total,setTotal] = useState(0);
  const [devicelist, setsdevicelist] = useState([]);
  const [lastupdate, setlastupdate] = useState(new Date());
  const [isOpen, setisOpen] = useState(false);
  const [values ,setvalues] =useState({face_id:0,facename:''});
  const [showonscroll, setshowonscroll] = useState(false)
  const [showprogress, setshowprogress] = useState(false)
  const [completed, setcompleted] = useState(50)
  

  useEffect(() => {
    if (!currentBranch) return;
    setsdevicelist([]);
  }, [currentBranch, pageSize,search,siteid,lastupdate,showonscroll]);


  useEffect(() => {
    if (!currentBranch) return;
 
    if (!pageSize || !pageNumber  ) return;
    setLoading(true)
    setError(false)

        const loadData = async () => {

          setshowprogress(true);
            const { data: result } = await getdevicehr(0,siteid,search,pageSize,pageNumber);
            
            if ( result.status==='success') {
           
              setshowprogress(false);
            
                setTotal(result.data.total);
       //  console.log(result.data.list,search,siteid,pageSize,pageNumber);
                setsdevicelist(prevData => {   
         return [...new Set([...prevData, ...result.data.list])]
                      })
               setHasMore(result.data.list.length > pageSize-1)
               setLoading(false)                 
            }

    };
    loadData();
  }         
  , [currentBranch,siteid,search,pageSize,pageNumber,lastupdate,showonscroll]);

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      //  console.log('node = ' ,node);
      if (node && node.offsetTop > 0){
        setshowonscroll(true);
       }
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
       //   console.log(entries[0].isIntersecting,hasMore);

        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

const handleTextchange = (event) => {
  setname(event.currentTarget.value);

}
const handleSearchClick = () => {
  setsearch(name);
}
const handleKeyPress = (event) => {
  if (event.key === "Enter") {
    setsearch(name)
    setPageNumber(1);
  }
}
const handleOnClick = (id,name) => {
  setisOpen(true);
  setvalues( { face_id: id, facename: name });

}
const handleChange =(event) => {
 setsearch(name)
  setsiteid(event);
  setPageNumber(1);

}
const handleCancleSubmit = () => {

  setisOpen(false);
}

const handleConfirmSubmit = () => {
setlastupdate(new Date());
 setisOpen(false);
// console.log('confirm')
} 

const scrollToTop = () => { 
  setPageNumber(1);
  setshowonscroll(false);
}

//console.log(currentBranch._id)
if (currentBranch._id !=='0'){
  return <>
  </>
  }
  return <>

<div className="row">
<ModalAddface
        isOpen={isOpen}
        button1="บันทึก"
        onCancel={handleCancleSubmit}
        onChange={handleTextchange} 
        onConfirm={handleConfirmSubmit}
       
        value = {values}
        header ="เลือกพนักงาน เพื่อส่งเข้าเครื่อง Face Scan"      
        // grouplist={grouplist}
        // sitelist ={sitelist}
        // error={messageerror}
      />
           
<div className="col-lg-6  col-sm-10 col-xs-12">
  <div className="form-group">
  <Input
          name="devicename"
          value={name}
          label="ชื่อเครื่อง"
          placeholder="ระบุชื่อเครื่องเพื่อค้นหา"
          onChange = {handleTextchange}
          onClick = {handleSearchClick}
          onKeyPress={handleKeyPress}
          search="ค้นหา"
        />
  </div>
</div>

<div className="col-lg-6  col-sm-10 col-xs-12">
  <div className="form-group">

 
    <i>
      พื้นที่ <span className="label label-default"></span>
    </i>
    <div className="mt-2" style={{ marginTop: 20, marginBottom: 20 }}>
      <Header onChange={handleChange} />
    </div>
 


  </div>
</div>

</div>

<div className="col-12 col-sm-6 col-md-6 mt-2" >
          <div className="container">
            <button
              className="btn btn-primary"
              onClick={() => handleOnClick(0, "new")}
            >
              <FontAwesome name="plus" />
              <i /> Add New
                          </button>
            <br />
            <br />
          
          </div>
        </div>
<p className="text-success">
        แสดงข้อมูล {devicelist ? devicelist.length : 0}  /  {total} รายการ
</p>
{ showprogress && <ProgressBar bgcolor={"#6a1b9a"} completed={completed} />}
{pageNumber > 1 && showonscroll &&
        <ScrollToTop 
        onClick ={scrollToTop}
        />}
    <Addface 
    list ={ devicelist}
    handleOnClick = {handleOnClick}
    error={error}
    lastBookElementRef={lastBookElementRef}
    />
  </>
};

export default FaceManage;
