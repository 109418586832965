import React, { useState } from 'react'
import { toast } from 'react-toastify';
//import ReactDOM from 'react-dom'
// npm install react-filepond filepond --save
// Import React FilePond
import { FilePond, File, registerPlugin } from 'react-filepond'

// Import FilePond styles
import 'filepond/dist/filepond.min.css'

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'

//npm i filepond-plugin-file-validate-size --save
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
  //npm i filepond-plugin-file-encode --save
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';


// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview,FilePondPluginFileEncode,FilePondPluginFileValidateSize)

// Our app
function ImageSelected({notifyFileUpload}) {

const [files, setFiles] = useState([])
const  checkMimeType= type =>{
    const types = ['image/png', 'image/jpeg']
    return types.indexOf(type)>=0

}

  const handleSelectFile = files => {
    if(files.length===0) {
      setFiles([]);
        if ( typeof notifyFileUpload=== 'function') {
          notifyFileUpload(files);
        }
      return
    }
    const { file } = files.find(x=>x) || {file: null};
    if ( file ) {
       if (checkMimeType(file.type)) {

         if ( typeof notifyFileUpload=== 'function') {
          notifyFileUpload(files);
      }
      } else {
        toast.error("กรุณาเลือก fils นามสกุล (JPEG  PNG)");
      }
    }
  }

//console.log(files);

  return (
    <div className="App">
      <FilePond
         files={files}
        // onupdatefiles={setFiles}
        // allowMultiple={true}
        // maxFiles={3}
        // server="/api"
        // name="files" 

        // labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
        labelIdle="อัพโหลดรูปภาพ"
       // ref={ref => (this.pond = ref)}
        maxFileSize ="1MB"
        maxFiles="1"
        labelMaxFileSize ='ขนาด file เกิน  1 MB'
        imagePreviewHeight="500"
        imageResizeTargetWidth="500"
        imageResizeTargetHeight="500"
        imageResizeMode="cover"
        allowFileEncode="true"
        acceptedFileTypes={['image/png', 'image/jpeg']}
        styleLoadIndicatorPosition="center bottom"
        styleButtonRemoveItemPosition="center bottom"
        // oninit={() => this.handleInit()}
        onupdatefiles={fileItems => handleSelectFile(fileItems)}


      />
    </div>
  )
}
export default  ImageSelected;