import React,{useState} from 'react'
import FontAwesome from 'react-fontawesome';
import Modalsupplier from './modaladdsupplier';
import { Link } from "react-router-dom";


const SupplierAdd = ({value,onConfirm}) => {

const [addItemModal, setaddItemModal] = useState(false);

// const [Id, setId] = useState(0);
// const [planId, setplanId] = useState(0);

const handelOnCancel =() => {
  setaddItemModal(false)
}
const handelOnConfirm =(id) => {
  setaddItemModal(false)
  onConfirm(id);

  
}







//console.log(value);
const handleChange = () => {
  setaddItemModal(true);
}

    return (  
        <div >
       
          <Modalsupplier 
          isOpen = {addItemModal}
          oncanCel ={handelOnCancel}
          onConfirm = { handelOnConfirm}
          title ="ข้อมูล Vendor "
          
          />
        <div className="form-group input-group">
          <label className="col-md-3 control-label" htmlFor="jobname">
            ชื่องาน
          </label>
          <div className="col-md-9">
          <label className="col-md-10 control-label" htmlFor="jobname">
          {value}
          </label>

          </div>
        </div>
        <div className="m-2">
        <div className="row">
        <div className="m-2">
         <Link  
         
         className="btn btn-info btn-lg active"
        
          to={`/shutdownlist`}
         >

         <FontAwesome name="arrow-left"/>    
          <i/> Black
         
         </Link>
         </div>
      <div className="m-2">
        <button
        className="btn btn-primary btn-lg active"
        onClick={handleChange }
         >
         <FontAwesome name="plus"/>    
          <i/> Add New
         </button>  
         </div>
      
         </div>
         </div>
         </div>
        
    );
}
 
export default SupplierAdd;