import React from 'react';
import FontAwesome from 'react-fontawesome';


const Rendersupplier = ({list,snackMessage,handleChange}) => {


    return ( 

        <div>
        <div className="container"> 
     
    

          <table className="table table-hover">
            <thead>
              <tr>
              <th scope="col">Item</th>
                <th scope="col">TaxID</th>
                <th scope="col">CompanyName</th>
                <th scope="col">VendorGroup</th>
               
                <th />
              </tr>
            </thead>
            <tbody>{
                 list &&
                 list.map((item) => (
                      <tr key={item.autoid}>
                         <td  style={{ fontSize: "13px" }}>{item.id}</td>
                        <td  style={{ fontSize: "13px" }}>{item.comIdentityID}</td>
                        <td  style={{ fontSize: "13px" }}>{item.comName}</td>
                        <td  style={{ fontSize: "13px" }}>{item.ComEmail}</td>
                        <td style={{ fontSize: "13px" }} >  
                        <div className="form-group">
                        <div className="col-sm">
                          <div className="row">
                          <small>
                           {item.ComEmail}
                           </small>
                          </div>

                          <div className="row">
                            <small>
                              {item.Supplier_type}
                            </small>
                          </div>
                          <div className="row">
                            <small>
                              {item.FnameT +' ' + item.LnameT}
                            </small>
                          </div>
                        </div>
                      </div>

                        </td>
                        {/* <td className="col-md-2" style={{ fontSize: "13px" }}>{item.Supplier_type}</td>
                        <td className="col-md-2" style={{ fontSize: "13px" }} >{item.ComEmail}</td>   
                        <td className="col-md-2" style={{ fontSize: "13px" }}>{item.FnameT +' ' + item.LnameT}</td> */}

                     

                        {/* <td className="col-md-4">
                        <button
                          className="btn btn-secondary"
                          onClick={() => handleChange(item.planid, "edit")}
                        >
                      <FontAwesome name="edit"/>    
                        <i/>
                          </button>
                        </td> */}

                        <td className="col-md-4">
                        <button
                          className="btn btn-danger"
                          onClick={() => handleChange(item.planid, "delete")}
                        >
                      <FontAwesome name="trash"/>    
                        <i/>
                          </button>
                        </td>
                      </tr>
                    ))
                
                }</tbody>

          </table>
        </div>
   
       
      </div>

     );
}
 
export default Rendersupplier;