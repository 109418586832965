import http from "./httpService";

export async function gettruckperhour(start = "",id) { 
   
    return http.get("/report/truckinperhour?_id="+ id +"&startdate="+ start);
}
export async function gettruckoutperhour(start = "",id) { 
   
    return http.get("/report/truckoutperhour?_id="+ id +"&startdate="+ start);
}
export async function gettruckcompare(start = "",id) { 
   
    return http.get("/report/truckcompare?_id="+ id +"&startdate="+ start);
}
export default {

    gettruckperhour,
    gettruckoutperhour,
    gettruckcompare
};