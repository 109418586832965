import http from "./httpService";
import jwtDecode from "jwt-decode";
import md5 from 'md5';
import { detect } from 'detect-browser'
const browser = detect();

const apiEndpoint = "/JAWIS";
const tokenKey = "token"; 
const branchKey = "_id";
const branchName = "_name";
//const currentBranch = {_id:'',name:""};


http.setJwt(getJwt());

export async function login(account, password) {

  try {
    const device = (browser) ? `${browser.name} [OS=${browser.os}][V=${browser.version}]` : "Unknown"

    const data = { account: account, password: md5(password), role: 'web', device};
    const { data: result } = await http.post(apiEndpoint + "/authen", data);
    
    if ( result.status === 'success') {

      const jwt = result.data.token;
      localStorage.setItem(tokenKey, jwt);
    
      return "OK";
    } else {
      return result.message;
    }
  } catch(err) {
    return err.message;
  }
  
}

export function loginWithJwt(jwt) {
  localStorage.setItem(tokenKey, jwt);
}

export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem(tokenKey);
    return jwtDecode(jwt);
  } catch (ex) {
    return undefined;
  }
}



export function getCurrentBranchID () {
 // console.log('current branch key',branchKey);
  try {
    const _id = localStorage.getItem(branchKey);
    return {_id};
  } catch (ex) {
    return undefined;
  }

}

export function getCurrentBranchName () {
  console.log('current branch key',branchName);
  try {
    const name = localStorage.getItem(branchName);
    return {name};
  } catch (ex) {
    return undefined;
  }

}

export function setCurrentBranchID(selectBranch) {
  localStorage.setItem(branchKey,selectBranch);
}
export function setCurrentBranchName(selectBranch) {
  localStorage.setItem(branchName,selectBranch);
}
export function getCurrentBranch() {
  const _id = localStorage.getItem(branchKey);
  const name = localStorage.getItem(branchName);
  return {_id,name};

}
export function getJwt() {
  return localStorage.getItem(tokenKey);
}

export function logout() {
  localStorage.removeItem(tokenKey);
}

export default {
  login,
  loginWithJwt,
  logout,
  getCurrentUser,
  getJwt,
  getCurrentBranchID,
  getCurrentBranchName,
  setCurrentBranchID,
  setCurrentBranchName,
  getCurrentBranch
};
