import React,{useState} from 'react';
import FontAwesome from "react-fontawesome";
import { Link } from "react-router-dom";
//import ScrollToTopBtn from './../common/ScrollToTop';
//import ScrollToTop from "./../common/ScrollToTop";

import Modaldevice from './modaldevicebyuser'
import ModalUpload from './modalupload';
import ModalTodevice from './modaladdtodevice'
import ModalLogscan from './modaldelogscan';
//arrow-circle-up

const UserInfo = ({
  lastBookElementRef,
  list,
  snackMessage,
  handleOnClick,
  search,
  onUpdate,
  scrollToTop
}) => {


  //console.log(list)

  const [empData, setempData] = useState({
  empid:'',empname:''
  });
  const [isOpen, setisOpen] = useState(false);
  const [openUpload, setopenUpload] = useState(false);
  const [openToDevice, setopenToDevice] = useState(false);
  const [openGetlog, setopenGetlog] = useState(false);
  // console.log(list);


const handleCancle = () => {
setisOpen(false);
}
const handleCancleUpload = () => {
  setopenUpload(false);
}

const handleOpen =(id,name) => {
  setempData({ empid:id ,empname:name });

  setopenUpload(true);
}
const handleDeviceClick = (id,name) => {
setempData({ empid:id ,empname:name });
//console.log(id,name);
setisOpen(true);
}
const onConfirm = () => {
setisOpen(false);
onUpdate();
}

const onConfirmUpload =()=>{
  setopenUpload(false);
}
const onConfirmAddTodevivce =()=>{
  setopenToDevice(false)
}
const handleAddTodevice = (id,name) => {
  setempData({ empid:id ,empname:name });
  setopenToDevice(true);
}

const handleGetLog = (id,name) => {
  setempData({ empid:id ,empname:name });
  setopenGetlog(true);
}
const onAddDeivceCallback =() =>{
  setopenToDevice(false)
}
//console.log(empData)
const onClick =() => {
  scrollToTop();
  
}
const cancelGetlog = () => {
  setopenGetlog(false);
}

// const scrollToTop =() =>{
//   console.log("you click on top")

//     window.scrollTo({
//       top: 0,
//       behavior: "smooth"
//     });
//   }



return (  
<div className ="row course-list list m-1">
<Modaldevice
      isOpen={isOpen}
      onCancel={handleCancle}
      value ={empData}
      header ={empData.empname} 
      onConfirm ={onConfirm}     
    //
  
    />
    <ModalUpload
    isOpen={openUpload}
    onCancel={handleCancleUpload}
    value ={empData}
    header ={empData.empname} 
    onConfirm ={onConfirmUpload}   
    />

    <ModalTodevice
     isOpen={openToDevice}
     value ={empData}
    header ={empData.empname} 
    onCancel ={onAddDeivceCallback}
    onConfirm ={onConfirmAddTodevivce} 
    />

<ModalLogscan

isOpen={openGetlog}
value ={empData}
header ={empData.empname} 
onCancel ={cancelGetlog}


/>
{/* <ScrollToTop 
        onClick ={scrollToTop}
        /> */}

    {list &&
      list.map((item, index) => {
        if (list.length === index + 1) {
          return (
                 
<div  ref={lastBookElementRef} key={item.no} className="card text-dark p-0 mb-2 mr-2  col-xs-12 col-sm-3">       
<div className="container-fluid">
<div className="d-flex justify-content-between mt-2">
    <span className="badge badge-pill badge-secondary">{item.no}</span>
  
      </div>
  <div className="row no-gutters align-items-center justify-content-left">
    <div className="col-auto text-center mb-2 p-1 text-center">
      <img
        src={item.imageUrl + "&size=320"}
        className="rounded-circle mr-1"
        data-toggle="tooltip"
        data-placement="bottom"
        title=""
        alt=""
        style={{
          maxHeight: "100px",
          Width: "100%",
          maxWidth: "100px",
        }}
      ></img>
    </div>


    <div className="col-auto ml-2">
      <div className="card-body p-1">
        <div className="card-text">
        <h6 className="card-title d-inline mr-2" style={{ fontSize: "14px" }}>
        {item.FnameT} {" "} {item.LnameT}
          </h6>
        </div>
        <div className="card-text">
          <h6 className="card-text d-inline mr-2" style={{ fontSize: "14px" }}>
            <span
              aria-hidden="true"
              className="fa fa-briefcase"
            />{" "}
           {item.position}
          </h6>
          <h6 className="card-text d-inline" style={{ fontSize: "14px" }}>
            <span
              aria-hidden="true"
              className="fa fa-building"
            />{" "}
            {item.company_code}
          </h6>
        </div>
        <h6>
        <div className="card-text" style={{ fontSize: "14px" }}>
          <span className="card-text">
            <span
              aria-hidden="true"
             
             /> {"ID:"}
            {search ?  item.ID_Emp :
         item.card_id
         }


            { item.amount > 1 ?  <span className="badge badge-pill badge-warning" style={{ cursor: "pointer" , fontSize: "12px"}} 
               onClick={() =>
                handleDeviceClick(item.ID_Emp, item.FnameT +'  ' + item.LnameT)
              }
            
            >{"  "}{item.amount}
    
            </span>
       
       : item.amount ===1 ? <span className="badge badge-pill badge-info" style={{ cursor: "pointer" , fontSize: "12px"}}
       onClick={() =>
        handleDeviceClick(item.ID_Emp, item.FnameT +'  ' + item.LnameT)
      }
       
       >{item.amount}
  
       </span> : <span className="badge badge-pill badge-secondary" style={{ cursor: "pointer" , fontSize: "12px"}}
       onClick={() =>
        handleDeviceClick(item.ID_Emp, item.FnameT +'  ' + item.LnameT)
      }
       
       >{item.amount}
  
       </span> }

          </span>
        </div>

        
        </h6>

       
        <button className="btn btn-info btn-sm m-1" style={{ fontSize: "12px" }}
        onClick={() =>
          handleOpen(item.ID_Emp,item.FnameT +'  ' + item.LnameT)
        }
        > 
         <FontAwesome name="camera" />
        <span>Image</span>
        </button> 
           <button className="btn btn-primary btn-sm m-1" style={{ fontSize: "12px" }}
        onClick={() =>
          handleAddTodevice(item.ID_Emp,item.FnameT +'  ' + item.LnameT)
        }
        > 
        <span>Edit</span>
        <FontAwesome name="edit" />
        </button> 
        
        <button className="btn btn-secondary btn-sm m-1" style={{ fontSize: "12px" }}
                              onClick={() =>
                                handleGetLog(item.ID_Emp,item.FnameT +'  ' + item.LnameT)
                              }
                              > 
                              <span>Log</span>
                              <FontAwesome name="id-card" />
          </button> 
        

      </div>
    </div>
  </div>
</div>

</div> 
          );
        } else {
          return (
             

  

                <div    key={item.no} className="card text-dark p-0 mb-2 mr-2  col-xs-12 col-sm-3">       
                      <div className="container-fluid">
                      <div className="d-flex justify-content-between mt-2">
                          <span className="badge badge-pill badge-secondary">{item.no}</span>
                        
                            </div>
                        <div className="row no-gutters align-items-center justify-content-left">
                          <div className="col-auto text-center mb-2 p-1 text-center">
                            <img
                              src={item.imageUrl + "&size=320"}
                              className="rounded-circle mr-1"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title=""
                              alt=""
                              style={{
                                maxHeight: "100px",
                                Width: "100%",
                                maxWidth: "100px",
                              }}
                            ></img>
                          </div>

                   
                          <div className="col-auto ml-2">
                            <div className="card-body p-1">
                              <div className="card-text">
                              <h6 className="card-title d-inline mr-2" style={{ fontSize: "14px" }}>
                              {item.FnameT} {" "} {item.LnameT}
                                </h6>
                              </div>
                              <div className="card-text">
                                <h6 className="card-text d-inline mr-2" style={{ fontSize: "14px" }}>
                                  <span
                                    aria-hidden="true"
                                    className="fa fa-briefcase"
                                  />{" "}
                                 {item.position}
                                </h6>
                                <h6 className="card-text d-inline" style={{ fontSize: "14px" }}>
                                  <span
                                    aria-hidden="true"
                                    className="fa fa-building"
                                  />{" "}
                                  {item.company_code}
                                </h6>
                              </div>
                              <h6>
                              <div className="card-text" style={{ fontSize: "14px" }}>
                                <span className="card-text">
                                  <span
                                    aria-hidden="true"
                                   
                                   /> {"ID:"}
                                  {search ?  item.ID_Emp :
                               item.card_id
                               }


                                  { item.amount > 1 ?  <span className="badge badge-pill badge-warning" style={{ cursor: "pointer" , fontSize: "12px"}} 
                                     onClick={() =>
                                      handleDeviceClick(item.ID_Emp, item.FnameT +'  ' + item.LnameT)
                                    }
                                  
                                  >{"  "}{item.amount}
                          
                                  </span>
                             
                             : item.amount ===1 ? <span className="badge badge-pill badge-info" style={{ cursor: "pointer" , fontSize: "12px"}}
                             onClick={() =>
                              handleDeviceClick(item.ID_Emp, item.FnameT +'  ' + item.LnameT)
                            }
                             
                             >{item.amount}
                        
                             </span> : <span className="badge badge-pill badge-secondary" style={{ cursor: "pointer" , fontSize: "12px"}}
                             onClick={() =>
                              handleDeviceClick(item.ID_Emp, item.FnameT +'  ' + item.LnameT)
                            }
                             
                             >{item.amount}
                        
                             </span> }

                                </span>
                              </div>

                              
                              </h6>

                             
                              <button className="btn btn-info btn-sm m-1" style={{ fontSize: "12px" }}
                              onClick={() =>
                                handleOpen(item.ID_Emp,item.FnameT +'  ' + item.LnameT)
                              }
                              > 
                               <FontAwesome name="camera" />
                              <span>Image</span>
                              </button> 
                                 <button className="btn btn-primary btn-sm m-1" style={{ fontSize: "12px" }}
                              onClick={() =>
                                handleAddTodevice(item.ID_Emp,item.FnameT +'  ' + item.LnameT)
                              }
                              > 
                              <span>Edit</span>
                              <FontAwesome name="edit" />
                              </button> 
                              
                              <button className="btn btn-secondary btn-sm m-1" style={{ fontSize: "12px" }}
                              onClick={() =>
                                handleGetLog(item.ID_Emp,item.FnameT +'  ' + item.LnameT)
                              }
                              > 
                              <span>Log</span>
                              <FontAwesome name="id-card" />
                              </button> 
      
                            </div>
                          </div>
                        </div>
                      </div>
                   
                  </div> 
                
            
           
           
          );
        }
      })}

{/* <ScrollToTopBtn

onClick={onClick}

/> */}
  </div>


  );
}
export default UserInfo;