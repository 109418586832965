import React ,{useState,useEffect,useContext} from 'react';
import Select from './select';
import Input from './inputcustom';
import BranchContext from './../context/branch';
import dlv from 'dlv';

import {   getTruckType,  getProvince} from './../../services/grabconcept';
const Truckcard = ({ secdriven, firstdriven, head,  handleChangeTruck, allEditData,  handleChageFProvinceId, handleChageSProvinceId, onf1Change, onf2Change, ons1Change, ons2Change}) => {
 
  const {currentBranch} = useContext(BranchContext);
  const ojb = allEditData[0];
//  console.log(ojb);
   let editTruckId = dlv(ojb, 'trucktype1',"0");
   let editFirtProvId = dlv(ojb, 'first_driven_prov',0);
   let editSecProvId = dlv(ojb, 'second_driven_prov',0);

   if (editSecProvId==null){
     editSecProvId =0;
   }
   //console.log(ojb);
   let edtiFullFirstDriven = dlv(ojb, 'first_driven_no',"");
   let editFullSecDriven = dlv(ojb, 'second_driven_no',"");
     let ef1='';
     let ef2='';
     let es1='';
     let es2='';
    
    // console.log(edtiFullFirstDriven);
     if (!edtiFullFirstDriven =="" && edtiFullFirstDriven){   
       var res = edtiFullFirstDriven.split("-");
       ef1 = res[0];
       ef2=res[1];
     //  console.log(res);
     }
     if (!editFullSecDriven =="" && editFullSecDriven){   
      var res1 = editFullSecDriven.split("-");
      es1 = res1[0];
      es2=res1[1];
     // console.log(res);
    }


  const [truckId, settruckId] = useState(editTruckId);
  const [firstProviceId, setfirstProviceId] = useState(editFirtProvId);
  const [secProvinceId, setsecProvinceId] = useState(editSecProvId);

  const [firstProvince, setfirstProvince] = useState([]);
  const [secProvince, setsecProvince] = useState([]);
  const [truckType, settruckType] = useState([]);
  const [f1, setf1] = useState(ef1);
  const [f2, setf2] = useState(ef2);
  const [s1, sets1] = useState(es1);
  const [s2, sets2] = useState(es2);

 // console.log(edtiFullFirstDriven,editFullSecDriven);
// console.log(allEditData);

  useEffect(() => {
    if (! currentBranch ) return; 
    const loadData = async () => {

    const { data: result } = await getTruckType(currentBranch && currentBranch._id);  
if (result){
  settruckType(result);
}
    
    // getTruckType((currentBranch._id)).then (result=>{              
    // settruckType(result.data); 
  };
  loadData();           
   // });
}, 
 [currentBranch])

useEffect(() => {
      if (! currentBranch ) return; 

      const loadData = async () => {

        const { data: result } = await getProvince(currentBranch && currentBranch._id);  
      // getProvince((currentBranch._id)).then (result=>{ 
        if (result){
         // console.log(result)
          setfirstProvince(result);   
        }             
       
    };
    loadData();            
     // });
 }, 
   [ currentBranch])
   useEffect(() => {
      if (! currentBranch ) return; 


      const loadData = async () => {

        const { data: result } = await getProvince(currentBranch && currentBranch._id);  
      // getProvince((currentBranch._id)).then (result=>{  
        if (result) {  
          setsecProvince(result);  
        }            
       
    };
    loadData();               
     // });
 }, 
   [ currentBranch])

  const handleChangeTruckId =(event) =>{
    settruckId(event.currentTarget.value)
    handleChangeTruck(event.currentTarget.value);
  }

  const handleChangeF1 =({currentTarget:input}) => {
    setf1(input.value);
    onf1Change(input.value);

  }
  const handleChangeF2 =({currentTarget:input}) => {
    setf2(input.value);
onf2Change(input.value);
  }
  const handleChangeS1 =({currentTarget:input}) => {
    sets1(input.value);
ons1Change(input.value);
  }
  const handleChangeS2 =({currentTarget:input}) => {
    sets2(input.value);
    ons2Change(input.value);

  }
  const handleChangeFProvinceId =({currentTarget:input}) => {
    setfirstProviceId(input.value);
    handleChageFProvinceId(input.value);
  }
  const handleChangeSProvinceId =({currentTarget:input}) => {
    setsecProvinceId(input.value);
   handleChageSProvinceId(input.value);
  }

  if (! currentBranch  || secProvinceId ==null ) {
    return (
      <div>

      </div>
    )
  }
  
    return ( 
       
        <section className="content">
        <div className="container-fluid">
        <div className="row" >       
       
          <div className="col-lg-12">
            <div className="card mb-4" >
              {/* <div className="card-header">{head}</div> */}
              <p className="bg-secondary text-white">
                <i className="fa fa-truck" />
                <span> {head} </span>
                </p>
              <div className="card-body">
                <div className="form-group" >
                  {/* <label htmlFor="typename">{typename}</label> */}

                  <div>
                    <Select 
                      data={truckType}
                      value={truckId}
                      textProperty="truck_typename"
                      valueProperty="truck_typeid"
                      onChange={handleChangeTruckId}
                    />

                  </div>
                </div>
                <div className="form-group" >
                  <label htmlFor="name">{firstdriven}</label>
                  <div className="col-xs-12">
                    <div className="row">

                      <Input
                        name="f1"
                        columns="col-sm-3"
                        id ="f1"
                        label=""
                        value={f1}
                        onChange={handleChangeF1}
                        width="650px"
                        placeholder="xx"
                        maxlength={3}

                      />

                      <label htmlFor="name">-</label>

                      <Input
                        name="f2"
                        columns="col-sm-4"
                        label=""
                        id ="f2"
                        value={f2}
                        onChange={handleChangeF2}
                        width="650px"
                        placeholder="xxxx"
                        maxlength={4}

                      />


                      <label htmlFor="name"> -</label>

                      <div className="col-sm-4">
                        <Select
                         data={firstProvince}
                          value={firstProviceId}
                          textProperty="ProvinceName"
                          valueProperty="ProvinceID"
                          onChange={handleChangeFProvinceId}
                        />
      

                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group" >
                  <label id="drivename" htmlFor="name">{secdriven}</label>
                  <div className="row">
                    <Input
                      name="s1"
                      columns="col-sm-3"
                      label=""
                      id ="s1"
                      value={s1}
                      onChange={handleChangeS1}
                      width="650px"
                      placeholder="xx"
                      maxlength={3}
                  
                    />
                    <label htmlFor="name">-</label>
                    <Input
                      name="s2"
                      columns="col-sm-4"
                     
                    
                      value={s2}
                      onChange={handleChangeS2}
                      width="650px"
                      placeholder="xxxx"
                      maxlength={4}
                  
                    />


                    <label htmlFor="name">-</label>
                    <div className="col-sm-4">
                      <Select 
                        data={secProvince}
                        value={secProvinceId}
                        textProperty="ProvinceName"
                        valueProperty="ProvinceID"
                        onChange={handleChangeSProvinceId}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
          </div>
        </div>
      </div>

    </section>

  );
}
 
export default Truckcard;