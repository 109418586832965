import React from 'react';
import Chart from 'react-apexcharts'
const mql = window.matchMedia(`(min-width: 425px)`);

const ReportCreator = ({data,charHeight ='450',stacked =false,type='bar'}) => {

 const  lotaders =mql.matches;
 //console.log(lotaders);

  if(!data) return (<div></div>)
  const yLabels = data.yLabels
  const series = data.series
  const header = data.headers
 
  //const stacked = data.stacked
 
//console.log(stacked)
  const options = {  
    


      chart: {
      type: type,
      height: 350,
      stacked: stacked,
      toolbar: {
          show: true
        }
       
    },

    stroke: {
      width: 1,
      colors: ["#fff"]
    },
    responsive: [
      {
        breakpoint: 1000,
        options: {
          plotOptions: {
            bar: {
              horizontal: true,
            }
          },
          legend: {
            position: "bottom"
          }
        }
      }
    ],

    plotOptions: {
      bar: {
        position: 'top', // top, center, bottom
       
        horizontal: false,
      }
    },
  
    legend: {
      position: "bottom"
    },
    // legend: {
    //   position: "right",
    //   verticalAlign: "top",
    //   containerMargin: {
    //     left: 35,
    //     right: 60
    //   }
    // },

      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val ;
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ["#304758"]
        }
      },        
      xaxis: {
        categories: yLabels,
       // position: 'top',
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#D8E3F0',
              colorTo: '#BED1E6',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            }
          }
        },
        tooltip: {
          enabled: true,
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val) {
            return val;
          }
        }
      
      },
      title: {
       
        floating: true,
        offsetY: 330,
        align: 'center',
        style: {
          color: '#444'
        }
      }

    


      //   chart: {
      //   type: type,
      //   height: {charHeight},
      //   stacked: stacked,
      //   toolbar: {
      //       show: true
      //     }
      // },
      // dataLabels: {
      //   enabled: true,

      //   formatter: function (val) {
      //     return val ;
      //   }
      //   // formatter: function(value, { seriesIndex, dataPointIndex, w}) {
      //   //   return w.globals.stackedSeriesTotals[dataPointIndex];
      //   // }
      //   ,
      //   offsetY: -20,
      //   style: {
      //     fontSize: '12px',
      //     colors: ["#304758"]
      //   }
      // },
      // plotOptions: {
      //   bar: {
      //     position: 'top', // top, center, bottom
      //   }
      // }
      // ,
      // responsive: [{
      //   breakpoint: 480,
      //   options: {
      //     legend: {
      //       position: 'top',
      //       offsetX: -10,
      //       offsetY: 0
      //     }
      //   }
      // }],
      // xaxis: {
      //   categories: yLabels,
      // },

      // tooltip: {
      //   enabled: true,
      // },
      
      // fill: {
      //   opacity: 1
      // },
      // legend: {
      //   position: 'right',
      //   offsetX: 0,
      //   offsetY: 50
      // },
      
    }

      // const options = {
      //   chart: {
      //     stacked: stacked,
      //     toolbar: { show: true }
       
      //   },
      //   dataLabels: {
      //     enabled: true,
      //     formatter: function (val) {
      //       return val ;
      //     },
      //     offsetY: -20,
      //     style: {
      //       fontSize: '12px',
      //       colors: ["#304758"]
      //     }
      //   },
      //   plotOptions: {
      //     bar: {
      //       horizontal: !lotaders,
      //       endingShape: 'flat',
      //       barHeight: '100%',
      //     },

      //   },
      //   xaxis: {
      //     categories: yLabels,
      //     lines: { show: false }
      //   },
      //   yaxis: {
      //     lines: { show: false }
      //           ,
      //     axisBorder: {
      //       show: false
      //     },
      //     axisTicks: {
      //       show: false,
      //     },
      //     labels: {
      //       show: false,
      //       formatter: function (val) {
      //         return val ;
      //       }
      //     }
      //   },
      //   tooltip: {
      //     y: {
      //       formatter: function (val) {
      //         return val
      //       }
      //     }
      //   },
      //   fill: {
      //     opacity: 1

      //   },
        

  
      // }




      //const charHeight =(yLabels.length * 50 + 50).toString();

   //   const charHeight;
 //     console.log('xdata',series,'series',series);
  
if (yLabels &&  yLabels.length==0 && series.length==0){
  return;
}

    return (
      <div className="row">
        <div className="col-12">
            <div className="card">
                <div className="card-header text-center">
                    {header}
                </div>
                <div className="card-body text-center">

                  {series.length>0 && <Chart options={options} series={series} type= {type} height={charHeight} />}
                  {series.length===0 && <span className="text-muted">ไม่มีข้อมูล</span>}
                </div>
            </div>
        </div> 
      </div>
    );
  


}
 
export default ReportCreator;