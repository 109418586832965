import React ,{useEffect,useState,useContext} from 'react';
import Select from './select';
import Input from './input';
import dlv from 'dlv';
import BranchContext from './../context/branch';
import Joi from "joi-browser";
import { getCustomerType,getCustomer} from './../../services/grabconcept';
import ModalSelect from './../common/modalselect';
import ModalSearch from './../common/modalSearch';

//import SelectCus from './selectcustomer';

const RegisterForm = ({ handleChangeCustype, head, typename,  name, drivenno, drivenname,id, handleChangeCustomer,  onChange,onDrivenNameChange,onDrivenTelChange, allEditData,registerError,handleSearch}) => {
  const {currentBranch} = useContext(BranchContext);

  const ojb = allEditData[0];
   let editCustID = dlv(ojb, 'supplier_id',0);
   let editCustTypeId = dlv(ojb, 'customerType_id',"00");
   let editDirivenId = dlv(ojb, 'driven_no','');
   let editDirivenName = dlv(ojb, 'driven_name','');
   let editDirivenTel = dlv(ojb, 'driver_tel','');

   if (editCustID==null){
    editCustID =0;
  }
  if (editDirivenId==null){
    editDirivenId='';
  }
 // console.log(editCustID);

  const [custypeId, setcustypeId] = useState(editCustTypeId);
  const [custId, setcustId] = useState(editCustID);
  const [texId, setidentityId] = useState(editDirivenId);
  const [drivenName, setdrivenName] = useState(editDirivenName);
  const [drivenTel, setdrivenTel] = useState(editDirivenTel);
  const [customerType, setcustomerType] = useState([]);
  const [customer, setcustomer] = useState([]);
  const [vForm, setvForm] = useState(false);
  const [openModalSearch, setOpenModalSearch] = useState(false);
  const [openModalDriven, setOpenModalDriven] = useState(false);
  const [stateData, setstateData] = useState({ 
    data: {identityId:"" } , errors: {enable: false, message: ""}
   });

  let schema1 = {
    identityId: Joi.string().min(0).max(99999999).required().label("ใบขับขี่"),
  };
  

  useEffect(() => {

    if (! currentBranch  ) return; 
    

    const loadData = async () => {
      const { data: result } = await getCustomerType(
        currentBranch && currentBranch._id
      );

      if (result) {
        setcustomerType(result);
      }
    };
 
          // getCustomerType((currentBranch && currentBranch._id)).then (result=>{        
          //       setcustomerType(result.data);      
                
             
            
                      
          //        });
          //      }, 


          loadData();
        }, [currentBranch]);

               useEffect(() => {
                if (! currentBranch ) return; 
                getCustomer((currentBranch && currentBranch._id)).then(result => {
                   setcustomer(result.data);
                 //  console.log(result.data);
                });
             },
                [ currentBranch])


                useEffect(() => {
 
                  if (vForm) return;
                  if (!currentBranch) return;
                  
              //    console.log(registerError)
                  
                  const onload = () => {
                  
                    if (registerError) {
                      const data = { ...stateData.data};
                      

                      setstateData( {data,errors: { enable: false, message: registerError.identityId }});
                      setvForm(true);
                    } else 
                    {
                     // setvForm(true);
                    }
                   
                   
                  }
                  onload();
                  
                  })



                  const validateProperty = ({ name, value }) => {
                    try {
                      const obj = { [name]: value };
                      const schema = { [name]: schema1[name] };
                      const options = { abortEarly: true };
                      const { error } = Joi.validate(obj, schema, options);
                      return error ? error.details[0].message : null;
                    } catch(err) {
                      console.log('validateProperty', name, value, err);
                  
                      return err.message;
                    }
                    
                  };

const handleChangeCustypeId = (event) =>{
  setcustypeId(event.currentTarget.value);
  handleChangeCustype(event.currentTarget.value);
}

const handleChangCusId = (event) => {
  
  // console.log(event.currentTarget.value)
   setcustId(event.currentTarget.value);
  handleChangeCustomer(event.currentTarget.value);
}

const handleTextchange = ({currentTarget:input}) => {

  const data = { ...stateData.data};
    const errors = { ...stateData.errors}
     if ( input.name in data) { 
   const errorMessage = validateProperty(input);
     if (errorMessage)    
       errors[input.name] = errorMessage;
       else
        delete errors[input.name];
       data[input.name] = input.value;  
     setstateData( {data,errors: { enable: false, message: errorMessage }});
    if (errorMessage && !input.value ==='') return;

  
  setidentityId(input.value);
  onChange(input.value);
    }
}
const handleSearchClick = () => {
  setOpenModalSearch(true);
 
}

const handleSearchDrinvenClick = () => {

  setOpenModalDriven(true);
 // console.log(openModalDriven);
}
const handleTextdrivenNamechange = ({currentTarget:input}) => {

  setdrivenName(input.value);
  onDrivenNameChange(input.value);
}

const handleConfirmSubmit = ({currentTarget:input}) => {
 // console.log(input);
  setOpenModalSearch(false);
  
  setcustId(input.value);
  handleChangeCustomer(input.value);
}


const handleConfirmDrivenSubmit = (value) => {
   //console.log(value);
   setOpenModalDriven(false);
   
   setidentityId(value.IdentityID);
   onChange(value.IdentityID);

   setdrivenName(value.FullName);
   onDrivenNameChange(value.FullName);

   setdrivenTel(value.Telephone);
   onDrivenTelChange(value.Telephone);
   //console.log(value.telephone);

  //setcustId(input.value);
  // handleChangeCustomer(input.value);
 }

const handleCancleSubmit =()=>{
  setOpenModalSearch(false);
}
const handleCancleDrivenSubmit =()=>{
  setOpenModalDriven(false);
}
const handleTextdrivenTelchange = ({currentTarget:input}) => {

  setdrivenTel(input.value);
  onDrivenTelChange(input.value);
}

if (! currentBranch){
  return (
    <p>

    </p>
  )
}
    return (

     
      <section className="content">
        <div className="container-fluid">
          <ModalSelect isOpen={openModalSearch} label ="ค้นหาลูกค้า"
          onCancel={handleCancleSubmit}
          onSelected={handleConfirmSubmit} 
          list = {customer}
          placeholder ="รหัส หรือชื่อลูกค้า"
          textProperty="Customer_Name"
          valueProperty="Customer_id"
          />
       <ModalSearch isOpen = {openModalDriven} label ="ค้นหาคนขับ"
           onCancel={handleCancleDrivenSubmit}
           onSelected={handleConfirmDrivenSubmit} 
           placeholder ="ชื่อ-สกุล"
           value={texId}
          // list = {productType}
          // textProperty="type_name"
          // valueProperty="Type_ID" 
          />


          <div className="row">
            {/* /.info-box */}
            <div className="col-lg-12">
              <div className="card mb-4">
              
                <p className="bg-info text-white"> 
                  <span className="info-box-icon bg-info">
                    <i className="fa fa-users" />
                  </span>
                 <span> {head} </span>
                </p>
                <div className="card-body mb-4">
                  <div className="form-group">
                    {/* <Combobox/> */}

                    <Select
                      label={typename}
                      data={customerType}
                      value={custypeId}
                      textProperty="CustomerTypeName"
                      valueProperty="CustomerType_ID"
                      onChange={handleChangeCustypeId}
                     
                    />
                  </div>
                  <Select
                    label={name}
                    data={customer}
                    value={custId}
                    textProperty="Customer_Name"
                    valueProperty="Customer_id"
                    onChange={handleChangCusId}
                    onClick ={handleSearchClick}
                    search="ค้นหา"
                  /> 
                  

                  <Input
                    name="identityId"
                    value={texId}
                    label={drivenno}
                    onChange = {handleTextchange}
                    onClick = {handleSearchDrinvenClick}
                    error={stateData.errors.message}
                    search="ค้นหา"
                  />
                  <div className="col-xs-12">
                    <div className="row">
                      <div className="col-sm-6 my-1">
                        <Input
                          name="drivername"
                          value={drivenName}
                          label={drivenname}
                          onChange={handleTextdrivenNamechange}
                      
                        />
                      </div>
                      <div className="col-sm-6 my-1">
                        <Input
                          name="drivertel"
                          value={drivenTel}
                          label="เบอร์โทร"
                          onChange={handleTextdrivenTelchange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div></div>
          </div>
        </div>
      </section>
    );
}
 
export default RegisterForm;