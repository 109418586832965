
import React from 'react';

const Input = ({name,label,value,onChange,error,type='text',search,onClick,placeholder="",onKeyPress,onKeyDown}) => {
  const handleOnclick =()=>{
    onClick(true);
  }
  // console.log(value);
    return ( 
        <div className="from-group" >
         <label htmlFor={name} >{ label } </label>   
         <div className="input-group">
            <input
            value = {value}
            onChange ={onChange}
            id={name}
            name={name}
            type ={type}
            placeholder={placeholder}
            onKeyPress ={onKeyPress}
            onKeyDown ={onKeyDown}
            className="form-control">
  

            </input>
   
            {search && <div className="input-group-addon input-group-button col-auto  px-1">
  <button type="button" 
  id="show-contact-modal-button" 
  className="btn btn-primary"
  onClick={handleOnclick}
  >
    
    {search}
    </button>
  </div>}
  </div>
          
            {error && <div className="alert alert-danger">{error}</div>}


        </div>
     );
}
 
export default Input;