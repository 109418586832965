import React from "react";
import FontAwesome from "react-fontawesome";
import { Link } from "react-router-dom";

const Addface = ({ lastBookElementRef, list, snackMessage, handleOnClick }) => {
  return (
    <div className="row course-list list m-1">
      {list &&
        list.map((item, index) => {
          if (list.length === index + 1) {
            return (
              <div key={item.no} className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="card mb-2">
                      <div className="card-body p-2">
                        <div
                          ref={lastBookElementRef}
                          className="row align-items-center justify-content-between"
                        >
                          <div className="col-auto ml-2">
                            <span>
                              {" "}
                              {item.no} {"  "}
                            </span>
                            <span aria-hidden="true" className="fa fa-users" />
                            <p className="nav-item nav-link text-dark text-bold font-weight-bold d-inline">
                              {item.name}
                            </p>
                            <span>จำนวน {"  "}</span>
                            {item.amount > 0 ? (
                              <span
                                className="badge badge-pill badge-primary"
                                style={{ fontSize: "14px" }}
                              >
                                {" "}
                                {item.amount}{" "}
                              </span>
                            ) : (
                              <span
                                className="badge badge-pill badge-secondary"
                                style={{ fontSize: "14px" }}
                              >
                                {" "}
                                {item.amount}{" "}
                              </span>
                            )}
                          </div>
                          <div className="col-auto pr-4">
                            <div className="row  align-items-center">
                              <Link to={`/userondevice/${item.face_id}`}>
                                <button className="btn btn-warning btn-sm m-1">
                                  <FontAwesome name="users" />
                                </button>
                              </Link>
                              <div className="col-auto p-1">
                                <button
                                  className="btn btn-success btn-sm m-1"
                                  onClick={() =>
                                    handleOnClick(item.face_id, item.name)
                                  }
                                >
                                  <FontAwesome name="plus" />
                                  <i />
                                </button>
                              </div>
                              <div>
                                <Link to={`/clonedevice/${item.face_id}`}>
                                  {item.amount > 0 ? (
                                    <button className="btn btn-primary btn-sm m-1">
                                      <FontAwesome name="clone" />
                                    </button>
                                  ) : (
                                    <button
                                      className="btn btn-primary btn-sm m-1"
                                      disabled
                                    >
                                      <FontAwesome name="clone" />
                                    </button>
                                  )}
                                </Link>
                              </div>

                              {/* <div className="col-auto p-1">
                              <button
                                type="button"
                                className="btn btn-outline-primary btn-sm m-2"
                              >
                                EDIT
                              </button>
                            </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          } else {
            return (
              <div key={item.no} className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="card mb-2">
                      <div className="card-body p-2">
                        <div className="row align-items-center justify-content-between">
                          <div className="col-auto ml-2">
                            <span>
                              {" "}
                              {item.no} {"  "}
                            </span>
                            <span aria-hidden="true" className="fa fa-users" />
                            <p className="nav-item nav-link text-dark text-bold font-weight-bold d-inline">
                              {item.name}
                            </p>
                            <span>จำนวน {"  "}</span>
                            {item.amount > 0 ? (
                              <span
                                className="badge badge-pill badge-primary"
                                style={{ fontSize: "14px" }}
                              >
                                {" "}
                                {item.amount}{" "}
                              </span>
                            ) : (
                              <span
                                className="badge badge-pill badge-secondary"
                                style={{ fontSize: "14px" }}
                              >
                                {" "}
                                {item.amount}{" "}
                              </span>
                            )}
                          </div>
                          <div
                            className="col-auto pr-4"
                            style={{ cursor: "pointer" }}
                          >
                            <div className="row  align-items-center">
                              <div className="col-auto p-1">
                                <Link to={`/userondevice/${item.face_id}`}>
                                  {item.amount > 0 ? (
                                    <button className="btn btn-warning btn-sm m-1">
                                      <FontAwesome name="users" />
                                    </button>
                                  ) : (
                                    <button
                                      className="btn btn-warning btn-sm m-1"
                                      disabled
                                    >
                                      <FontAwesome name="users" />
                                    </button>
                                  )}
                                </Link>
                              </div>

                              <div className="col-auto p-1">
                                <button
                                  className="btn btn-success btn-sm m-1"
                                  onClick={() =>
                                    handleOnClick(item.face_id, item.name)
                                  }
                                >
                                  <FontAwesome name="plus" />
                                  <i />
                                </button>
                              </div>
                              <div>
                                <Link to={`/clonedevice/${item.face_id}`}>
                                  {item.amount > 0 ? (
                                    <button className="btn btn-primary btn-sm m-1">
                                      <FontAwesome name="clone" />
                                    </button>
                                  ) : (
                                    <button
                                      className="btn btn-primary btn-sm m-1"
                                      disabled
                                    >
                                      <FontAwesome name="clone" />
                                    </button>
                                  )}
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        })}
    </div>
  );
};

export default Addface;
