import React, { useState, useEffect, useRef,useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import makeAnimated from "react-select/animated";
import { FormInput } from "../common/forminput";
import DatePicker from "react-datepicker";
import BranchContext from "./../../components/context/branch";
//import "./../../register.css";
import { components } from "react-select";
import MySelect from "../common/myselect";
import { getSafetyRegisters } from "../../services/vms";
import { getBranchSelected } from "../../services/branchselected";
import PopupAlert from "../common/popupAlert";
import { toast } from "react-toastify";
import FontAwesome from "react-fontawesome";
import PersonGroup from "./Formpersongroup";
import "react-datepicker/dist/react-datepicker.css";
import dlv from "dlv";


//import socketIOClient from './../../services/socketioService';
//socketIOClient = socketIOClient(ENDPOINT, { transports: ['polling'] })
// "apiUrl": "https://api-wms.advanceagro.net"
export const Safetyrecord = (props) => {
  const { currentBranch } = useContext(BranchContext);
  // console.log(props);
  // console.log(props.location.state);
  const allEditData = props.location.state;

  const [name, setname] = useState("");

  const ojb = allEditData[0];

  let permitId = dlv(ojb, "permittype", 0);
  let comId = dlv(ojb, "ProductID", 0);
  let lastname = dlv(ojb, "Lname", "");
  let firstname = dlv(ojb, "Fname", "");

  const PreloadedValues = {
    firstname: firstname,
    lastname: lastname,
  };
  //const data = props.location.state;

  // if (data){
  //   PreloadedValues = {

  //   };
  // }else {
  //   PreloadedValues = {

  //   };
  // }

  // const PreloadedValues = {

  // };
  // if (data){
  //   console.log(data[0].Fname);
  //  // setname(data[0].Fname)
  // const PreloadedValues1 = {

  // firstname : data[0].Fname,

  // }
  // PreloadedValues = PreloadedValues1;
  // }else {
  //   const PreloadedValues1 ={
  //     firstname:'1234'
  //   }
  //   PreloadedValues = PreloadedValues1;
  // }

  // function PreloadedValues ()  {

  //   if (data) {
  //     const values = {
  //       firstname:data[0].Fname,
  //     };

  //     return values;

  //   }else {
  //     const values = {firstname:1};

  //     return values;
  //   }

  //  };

  //console.log(PreloadedValues.values);

  const [Id, setId] = useState(props.match.params.id);
  const [startDate, setStartDate] = useState(new Date());
  // const [vForm1, setVForm1] = useState(false);
  // const [permitId, setpermitId] = useState(0);
  // const [comId, setcomId] = useState(0);
  // const [profileId, setprofileId] = useState(0);
  // const [startDate, setStartDate] = useState(new Date());
  // const [endDate, setEndDate] = useState(new Date());
  //
  // const [lastname, setlastname] = useState('')
  // const [alldata, setalldata] = useState([]);

  //   useEffect(() => {

  //     const loadRegister = async() => {
  //        try {

  //        //   console.log('in get',Id);

  //            if (!Id) return;
  //            if(Id==='new') {
  //              setalldata([]);
  //              return;
  //            }
  //           const { data: result } = await getSafetyRegisters(Id);
  //              if (result) {

  //                //   console.log(result);

  //                  result.filter(item => item.RegisterID === Id)

  //                 setalldata(result);

  //            } else {
  //                toast.error(result.message)
  //            }
  //        } catch (ex) {  toast.error(ex.message)   }
  //      }

  //      loadRegister();

  //  },
  //  [Id])

  //   useEffect (() => {
  //     const getalldata =() =>{
  //        setId(props.match.params.id);

  //     if (vForm1) return ;
  //     if (alldata&& alldata.length ===1) {

  //      //  console.log(alldata);

  //        setname(alldata[0].Fname);
  //        setlastname(alldata[0].Lname);
  //       setpermitId(alldata[0].PermitType);
  //       const createdate = new Date(alldata[0].CreatDate);
  //       const expiredate = new Date(alldata[0].ExpiredDate);
  //       setStartDate(createdate);
  //       setEndDate(expiredate);
  //       setcomId(alldata[0].companyid);
  //       setprofileId(alldata[0].ID);

  //     setVForm1(true);

  //  } else
  //  {

  //        if (Id==="new"){
  //           setVForm1(true);
  //     }

  //  }

  //  }
  //     getalldata();

  //  })

  

  const { register, errors, handleSubmit, setValue, watch } = useForm({
    mode: "all",
    defaultValues: PreloadedValues,
  });


  const dateReceived = watch("expiryAt");

  const onSubmit = (data) => {
    console.log(data);
    setmessageErros({
      askAlert: {
        enable: true,
        message: "ยังไม่ระบุสิทธิ์ที่ต้องการ\nกรุณาระบุก่อนค่ะ",
      },
    });
    return;
  };

  // const password = useRef({});
  // password.current = watch("password", "");

  const [messageErros, setmessageErros] = useState({
    askAlert: { enable: false, message: "" },
  });

  const handlePopupAlertClick = () => {
    setmessageErros({ askAlert: { enable: false, message: "" } });
  };

  const handleChange = (value) => {
    onSubmit();
  };
  if (currentBranch.id !== 'vms'){
    return <>
    </>
  }
  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <PopupAlert
        isOpen={messageErros.askAlert.enable}
        onOk={handlePopupAlertClick}
        message={messageErros.askAlert.message}
      />

      <div className="content-wrapper">
        <div className="row">
          <div className="col-12 col-sm-6 col-md-6">
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card mb-4">
                      <p className="bg-info text-white">
                        <span className="info-box-icon bg-info">
                          <i className="fa fa-users" />
                        </span>
                        <span> ข้อมูลส่วนบุคคล </span>
                      </p>
                      <div className="card-body col-auto mb-4">
                        <div className="col-xs-12">
                          <div className="row">
                            <div className="col-sm-6 my-0">
                              <FormInput
                                id="firstname"
                                name="firstname"
                                type="text"
                                label="ชื่อ"
                                placeholder="ชื่อ"
                                register={register({
                                  required: "ระบุชื่อ",
                                  maxLength: {
                                    value: 100,
                                    message: "Max length is 100",
                                  },
                                  minLength: {
                                    value: 2,
                                    message: "Min length is 2",
                                  },
                                })}
                                error={errors.firstname}
                              />
                            </div>
                            <div className="col-sm-6 my-0">
                              <FormInput
                                id="lastname"
                                name="lastname"
                                type="text"
                                label="สกุล"
                                placeholder="สกุล"
                                register={register({
                                  required: "ระบุนามสกุล",
                                  maxLength: {
                                    value: 100,
                                    message: "Max length is 100",
                                  },
                                  minLength: {
                                    value: 2,
                                    message: "Min length is 2",
                                  },
                                })}
                                error={errors.lastname}
                              />
                            </div>
                          </div>
                        </div>
                        <FormInput
                          id="identityid"
                          name="identityid"
                          type="text"
                          label="เลขบัตรประชาชน"
                          placeholder="เลขบัตรประชาชน"
                          register={register({
                            required: "this is a required",
                            maxLength: {
                              value: 100,
                              message: "Max length is 100",
                            },
                            minLength: {
                              value: 2,
                              message: "Min length is 2",
                            },
                          })}
                          error={errors.address}
                        />
                        <FormInput
                          id="address"
                          name="address"
                          type="text"
                          label="ที่อยู่"
                          placeholder="ที่อยู่"
                          register={register({
                            required: "this is a required",
                            maxLength: {
                              value: 100,
                              message: "Max length is 100",
                            },
                            minLength: {
                              value: 2,
                              message: "Min length is 2",
                            },
                          })}
                          error={errors.address}
                        />

                        <div className="col-xs-12">
                          <div className="row">
                            <div className="col-sm-6 my-0">
                              <FormInput
                                id="mobile"
                                name="mobile"
                                type="text"
                                label="mobile"
                                placeholder="เบอร์ติดต่อ"
                                register={register({
                                  required: "this is a required",
                                  maxLength: {
                                    value: 100,
                                    message: "Max length is 100",
                                  },
                                  minLength: {
                                    value: 2,
                                    message: "Min length is 2",
                                  },
                                })}
                                error={errors.mobile}
                              />
                            </div>
                            <div className="col-sm-6 my-0">
                              <FormInput
                                id="email"
                                name="email"
                                type="text"
                                label="email"
                                placeholder="อีเมล"
                                register={register({
                                  required: "this is a required",
                                  maxLength: {
                                    value: 100,
                                    message: "Max length is 100",
                                  },
                                  minLength: {
                                    value: 2,
                                    message: "Min length is 2",
                                  },
                                })}
                                error={errors.email}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="form-group">
                          <label htmlFor="typename">วันเดือนปีเกิด</label>
                          <div>
                          <DatePicker
              isClearable
              innerRef={register({ required: true })}
              name="datetime1"
              className={"form-control"}
              selected={startDate}
              onChange={val => {
                setStartDate(val);
                setValue("start", val);
              }}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="time"
              dateFormat="MM-dd-yyyy h:mm"
            />

                          {/* <Controller
             as={ReactDatePicker}
             control={control}
             valueName="selected" // DateSelect value's name is selected
             onChange={([selected]) => selected}
             name="ReactDatepicker"
             className="input"
             placeholderText="Select date"
            /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
            </section>
          </div>

      
          <div className="col-12 col-sm-6 col-md-6">
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card mb-4">
                      <p className="bg-info text-white">
                        <span className="info-box-icon bg-info">
                          <i className="fa fa-users" />
                        </span>
                        <span> ข้อมูลบริษัท </span>
                      </p>

                      <div className="card-body mb-4">
                        <FormInput
                          id="company"
                          name="company"
                          type="text"
                          label="company"
                          placeholder="บริษัท"
                          register={register({
                            required: "this is a required",
                            maxLength: {
                              value: 1000,
                              message: "Max length is 1000",
                            },
                            minLength: {
                              value: 2,
                              message: "Min length is 2",
                            },
                          })}
                          error={errors.company}
                        />

                        <FormInput
                          id="taxid"
                          name="taxid"
                          type="text"
                          label="taxid"
                          placeholder="เลขนิติบุคคล"
                          register={register({
                            required: "this is a required",
                            maxLength: {
                              value: 100,
                              message: "Max length is 100",
                            },
                            minLength: {
                              value: 2,
                              message: "Min length is 2",
                            },
                          })}
                          error={errors.taxid}
                        />

                        <FormInput
                          id="comaddress"
                          name="comaddress"
                          type="text"
                          label="ที่อยู่"
                          placeholder="ที่อยู่"
                          register={register({
                            required: "this is a required",
                            maxLength: {
                              value: 1000,
                              message: "Max length is 1000",
                            },
                            minLength: {
                              value: 2,
                              message: "Min length is 2",
                            },
                          })}
                          error={errors.comaddress}
                        />

                        <FormInput
                          id="telephone"
                          name="telephone"
                          type="text"
                          label="เบอร์โทร"
                          placeholder="เบอร์โทร"
                          register={register({
                            required: "this is a required",
                            maxLength: {
                              value: 1000,
                              message: "Max length is 1000",
                            },
                            minLength: {
                              value: 2,
                              message: "Min length is 2",
                            },
                          })}
                          error={errors.telephone}
                        />
                      </div>
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
            </section>
          </div>

          <div className="col-12 col-sm-6 col-md-6">
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="m-auto">
                    <i />
                    <button
                      className="btn btn-success"
                      onClick={handleSubmit(onSubmit)}
                    >
                      <FontAwesome name="floppy-o" />
                      <i /> บันทึก
                    </button>
                  </div>

                  <div></div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </form>
  );
};
export default Safetyrecord;

