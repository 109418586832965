import React,{useState,useEffect,useContext} from 'react'
import DatePicker from "react-datepicker";
import ChartColumn from '../chart/column';


import moment from 'moment';
import RenderButton from '../common/button';
import BranchContext from '../context/branch';

    
import {WoodReceiveWeeklyAA} from '../../services/wms';

const Woodchip = () => {

    const {currentBranch } = useContext(BranchContext);
    const [startDate, setStartDate] = useState(new Date())

    const [linechart, setlinechart] = useState('btn btn-primary');
    const [columnchart, setColumnchart]= useState('btn btn-outline-dark');
    const [areachart, setAreachart]= useState('btn btn-outline-dark');
    const [charttype, setcharttype] = useState('line');
    const [chartDatacompare, setchartDatacompare] = useState([]);
   
    const[ amountcompare , setAmountcompare] = useState([]);
   
    



    const[ chartdata , setchartdata] = useState([]);

    const[ chartdatacompare , setchartdatacompare] = useState([]);


   

 useEffect(() => {
  if (! currentBranch ) return; 

  WoodReceiveWeeklyAA(moment(startDate, "YYYY-MM-DD").add(0, 'day').format("YYYY-MM-DD")).then
 (result=>{  
  
  setchartdatacompare(result.data);   
   
   
  });
}, 
[currentBranch,startDate])







 
   
 

   useEffect(() => {

    

    if ((!chartdatacompare) || !currentBranch || !charttype ) return;

   // console.log(chartdatapermit);

   
     
      const chart =() =>{
        let phour =[];
        let AmountA =[];
        let AmountB =[];
        let AmountC =[];
        let AmountD =[];
        let AmountE =[];
        for(const dataObj of chartdatacompare){
  
            phour.push(( dataObj.Date))
            AmountA.push(parseInt( dataObj.TargetValue))
            AmountB.push(parseInt( dataObj.Receive))
            AmountC.push(parseInt( dataObj.Direct))
            AmountD.push(parseInt( dataObj.ToStock))
            AmountE.push(parseInt( dataObj.StockToProduction))

      }
    
      setchartDatacompare({
    series: [{
        name: 'เป้าหมาย(T)',
        data: AmountA
      }, {
        name: 'รับ(T)',
        data: AmountB
      },
      {
        name: 'เข้าผลิต(T)',
        data: AmountC
      },
      {
        name: 'ลงสต๊อก(T)',
        data: AmountD
      },{
        name: 'เบิกเข้าผลิต(T)',
        data: AmountD
      }
      
    ],
        chart: {
        type: 'bar',
        height: 350,
       
        toolbar: {
            show: true
          }
      },
      plotOptions: {
        bar: {
          position: 'top', // top, center, bottom
        }
      },
   
        // plotOptions: {
        //   bar: {
        //     dataLabels: {
        //       position: 'top', // top, center, bottom
        //     },

        //     colors: {
        //       ranges: [{
        //           from: 0,
        //           to: 10,
        //           color: "#FFB200"
        //       }],
        //       backgroundBarColors: [],
        //       backgroundBarOpacity: 1,
        //       backgroundBarRadius: 0,
        //   }
        //   }
        // },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val ;
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          }
        },        
        xaxis: {
          categories: phour,
         // position: 'top',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              }
            }
          },
          tooltip: {
            enabled: true,
          }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            }
          }
        
        },
        title: {
          text: 'ไม้ท่อนย้อนหลัง 7 วัน',
          floating: true,
          offsetY: 330,
         
          align: "left",
         
          style: {
            color: '#444'
          }
        }

      }
     )
    
    }
    chart();

   },[chartdatacompare,currentBranch,charttype])


   const handleonClick = ({ currentTarget: input },event) => 
    {
     
 //     console.log(input)
        if (input.value=='line'){
        //     disabledlabel();
        setlinechart('btn btn-primary'); 
        setColumnchart('btn btn-outline-dark');
        setAreachart('btn btn-outline-dark');

         }else if (input.value=='bar'){
          setAreachart('btn btn-outline-dark');

          setlinechart('btn btn-outline-dark'); 
          setColumnchart('btn btn-primary');
         
        }else if (input.value=='area'){
          setlinechart('btn btn-outline-dark'); 
          setColumnchart('btn btn-outline-dark');
          setAreachart('btn btn-primary');
        //     disabledlabel();
        //     setall('btn btn-primary'); 
        // }else if (input.value==2){
        //     disabledlabel();
        //     setaa('btn btn-primary'); 
        // }else if (input.value==5){
        //     disabledlabel();
        //     setnps('btn btn-primary'); 
        // }else if (input.value==6){
        //     disabledlabel();
        //     sete85('btn btn-primary'); 
        // }else if (input.value==3){
        //     disabledlabel();
        //     setagf('btn btn-primary'); 
         }
         setcharttype(input.value)
    };




    if ((!currentBranch)  || (!chartdata) ) {

        return (<div></div>)

    
    }
    
return (  
        <div className='row container-fluid'> 
        <div className="col-12">
          <div className='container'>
            <div className="row">
              <div className='m-4'>
                 
                <div className='mt-2' style={{ marginTop: 20, marginBottom: 20 }} >
                  <DatePicker
                    todayButton="Today"
                    dateFormat="dd/MM/yyyy"
                    selected={startDate}
                    onChange={date => setStartDate(date)} />
                </div>

              </div>   
              <div className="row justify-content-end align-items-center">
              <RenderButton 
                     label ="กราฟเส้น"
                     value="line"
                     onClick ={handleonClick}
                     className = {linechart}
                      />
                    <RenderButton 
                     value="bar"
                     label ="กราฟแท่ง"
                     onClick ={handleonClick}
                     className = {columnchart}
                      />
                   <RenderButton 
                     value="area"
                     label ="กราฟพื้นที่"
                     onClick ={handleonClick}
                     className = {areachart}
                      />       
          </div>
        </div>
           <ChartColumn 
           options = {chartDatacompare}
           type={charttype}
           height= "350"       
          />

         </div>
       

       </div>
      </div>

    );


}

export default Woodchip;
