import React,{useState} from 'react';
import RenderButton from '../common/button';
import DatePicker from "react-datepicker";


const Headder = ({onChange}) => {

    const [all, setall] = useState('btn btn-primary');
    const [AA, setAA] = useState('btn btn-outline-dark');
    const [NPS, seteNPS] = useState('btn btn-outline-dark');
    const [ITH, setITH] = useState('btn btn-outline-dark');
    const [MIB, setMIB] = useState('btn btn-outline-dark');


    const [startDate, setStartDate] = useState(new Date());

    const handleonClick = ({ currentTarget: input }) => {
        if (input.value==0){
            disabledlabel();
            setall('btn btn-primary'); 
        }else if (input.value==1){
            disabledlabel();
            setAA('btn btn-primary'); 
        }else if (input.value==2){
            disabledlabel();
            seteNPS('btn btn-primary'); 
        }else if (input.value==3){
            disabledlabel();
            setITH('btn btn-primary'); 
        }else if (input.value==4){
          disabledlabel();
          setMIB('btn btn-primary'); 
      }
        onChange(input.value);
    }
  


const disabledlabel = () => {
    setall('btn btn-outline-dark')
    setAA('btn btn-outline-dark') ;
    seteNPS('btn btn-outline-dark') ;
    setITH('btn btn-outline-dark') ;
    setMIB('btn btn-outline-dark') ;
}



    return ( 
<div className='container'>
            <div className="row">
              {/* <div className='m-4'>
                 
                <div className='mt-2' style={{ marginTop: 20, marginBottom: 20 }} >
                  <DatePicker
                    todayButton="Today"
                    dateFormat="dd/MM/yyyy"
                    selected={startDate}
                    onChange={handleChange} />
                </div>

              </div>    */}

              <div className="row justify-content-end align-items-center">
                  <div className="col-auto mb-3 px-0"><ul className="btn-group p-0 m-0 ">
                     <RenderButton 
                     label ="All"
                     value={0}
                     onClick ={handleonClick}
                     className = {all}
                      />
                    <RenderButton 
                     value={1}
                     label ="Pulp_Paper"
                     onClick ={handleonClick}
                     className = {AA}
                      />
                         <RenderButton 
                           value={2}
                          label ="POWER"
                     onClick ={handleonClick}
                     className = {NPS}
                      />
                        <RenderButton 
                           value={3}
                          label ="Real Estate"
                     onClick ={handleonClick}
                     className = {ITH}
                      />

                        <RenderButton 
                           value={4}
                          label ="Logistics"
                     onClick ={handleonClick}
                     className = {MIB}
                      />
                    
                      </ul>
                      </div>
                      </div>


            </div>
          </div>

     );
}
 
export default Headder;

