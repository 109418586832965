import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import auth from "../../services/authService";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";

class LoginForm extends Form {
  //   username = React.createRef();

  state = {
    data: { username: "", password: "" },
    errors: {}
  };

  schema = {
    username: Joi.string()
      .required()
      .label("Username"),
    password: Joi.string()
      .required()
      .label("Password")
  };

  getAllData() {
    return {...this.state.data}
  }

  getAllSchema() {
    return {...this.schema}
  }

  onRegister = () =>{
    //console.log('click register' )
    const urlGoBack = "/registeruser/";       
    setTimeout(this.props.history.replace(urlGoBack), 1000);
  }

  doSubmit = async () => {
    try {
      const { data } = this.state;
      const result = await auth.login(data.username, data.password);

      if ( result === "OK") {
        const { state } = this.props.location;

        window.location = state ? state.from.pathname : "/"; //Full refresh
      } else {
        toast.error(result);
      }
      
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }
  };

  render() {
    // console.log(auth.getCurrentUser())
    if (auth.getCurrentUser()) return <Redirect to="/" />;

    return (
      <div>
        <div style={{height:"100px"}}></div>
        <div className="row justify-content-center">
          <div className="col-10 col-sm-8 col-md-6 col-lg-5 col-xl-4">
            <h3>Login</h3>
            <div className="mt-4">
              <form action="" onSubmit={this.handleSubmit}>
                {this.renderInput("username", "Username")}
                {this.renderInput("password", "Password", "password")}
                <li className="d-flex justify-content-between align-items-center my-1">
                {this.renderButton("Login")}
                {this.renderInfoButton("Register",this.onRegister)} 

                </li>

              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LoginForm;
