import React from "react";
import FontAwesome from "react-fontawesome";

const Renderwaitting = ({
  list,
  snackMessage,
  handleChange,
  lastBookElementRef,
  loading,
  error,
}) => {
  //console.log(list);

  return (
    <div className="container-fluid">


      
      <div>
       
            {list &&
              list.map((item, index) => {
                if (list.length === index + 1) {
                  return (
                    <div ref={lastBookElementRef} className="row" key={item.No}>
                    <div className="container-fluid">
                      <div className="card border-secondary mt-1 ">
                       
                          <div  className="row ml-1">
                            <div className="col-sm-4">
                              <div className="border border-top-0 border-right-0 border-left-0"  style={{ fontSize: "14px" }}>
                                <span className="badge badge-pill badge-primary">
                                  {item.No}
                                </span>
                              </div>
                              <div className="border border-top-0 border-right-0 border-left-0"  style={{ fontSize: "14px" }}>
                                {item.doccode}
                              </div>
                              <div className="border border-top-0 border-right-0 border-left-0"   style={{ fontSize: "13px" }}>
                              {item.permittype=="เข้าทำงาน" ?   <span className="badge badge-danger" style={{ fontSize: "13px" }}> 
                              {item.permittype} </span> 
                              : item.permittype=="ส่งสินค้า" ?   <span className="badge badge-warning" style={{ fontSize: "13px" }}> 
                              {item.permittype} </span> :<span className="badge badge-success" style={{ fontSize: "13px" }}> 
                              {item.permittype} </span>}
                                
                              </div>
                              <div className="border border-top-0 border-right-0 border-left-0"   style={{ fontSize: "14px" }}>
                               {"บริษัท:"}  {item.company}
                              </div>
                            </div>

                            <div className="col-sm-4">
                          
                            <div className="border border-top-0 border-right-0 border-left-0"  style={{ fontSize: "14px" }}>
                                <span >
                              {"พื้นที่:"}    {item.Area}
                                </span>
                              </div>
                              <div className="border border-top-0 border-right-0 border-left-0"  style={{ fontSize: "14px" }}>
                                <span >
                              {"แผนก:"}    {item.Department}
                                </span>
                              </div>
                              <div className="border border-top-0 border-right-0 border-left-0"  style={{ fontSize: "14px"}}>
                                <span >
                                {"จำนวน:"}    {item.Person}  {" คน"}
                                </span>
                              </div>
                              <div className="border border-top-0 border-right-0 border-left-0"  style={{ fontSize: "14px" }}>
                              {"ทะเบียนรถ:"}    <span className="text-primary" > {item.CARLICENSE} </span> 
                              </div>
                            </div>

                            <div className="col-sm-3">
                            <div className="border border-top-0 border-right-0 border-left-0"  style={{ fontSize: "14px" }}>
                             
                                 </div>
                            <div className="border border-top-0 border-right-0 border-left-0"   style={{ fontSize: "14px" }}>
                                {"วันเริ่มงาน:"}  {item.StartDate}
                              </div>
                              <div className="border border-top-0 border-right-0 border-left-0"   style={{ fontSize: "14px" }}>
                              {"วันสิ้นสุด:"}   {item.EndDate}
                              </div>
                            
                              <div className="border border-top-0 border-right-0 border-left-0"  style={{ fontSize: "14px" }}>
                                
                                {item.Status ==='Waiting' ?   <span className="badge badge-warning"   style={{ fontSize: "12px" }}>  {item.Status} </span> :
                                item.Status==='Approved' ? <span className="badge badge-success" >  {item.Status} </span>  :
                               <span className="badge badge-danger" >  {item.Status} </span>}

                              </div>
                            
                             
                            </div>
                            <div className="col-sm-1">
                          
                              <div className="mt-1">


                            <button
                                className="btn btn-secondary btn-sm"
                                onClick={() => handleChange(item.doccode, "approved")}
                                   >
                             <FontAwesome name="eyedropper"/>

                             </button>
                             </div>
                            </div>
                            </div>
                      
                      </div>
                    </div>
                  </div>
                  );
                } else {
                  return (
                    <div className="row" key={item.No}>
                      <div className="container-fluid">
                        <div className="card border-secondary border-bottom-0 ">
                         
                            <div className="row ml-1">
                              <div className="col-sm-4">
                                <div className="border border-light border-top-0 border-right-0 border-left-0"  style={{ fontSize: "14px" }}>
                                  <span className="badge badge-pill badge-primary">
                                    {item.No}
                                  </span>
                                </div>
                                <div className="border border-light border-top-0 border-right-0 border-left-0"  style={{ fontSize: "14px" }}>
                                  {item.doccode}
                                </div>
                                <div className="border border-light border-top-0 border-right-0 border-left-0"   style={{ fontSize: "13px" }}>
                                {item.permittype=="เข้าทำงาน" ?   <span className="badge badge-danger" style={{ fontSize: "13px" }}> 
                               {item.permittype} </span> 
                                : item.permittype=="ส่งสินค้า" ?   <span className="badge badge-warning" style={{ fontSize: "13px" }}> 
                               {item.permittype} </span> :<span className="badge badge-success" style={{ fontSize: "13px" }}> 
                                {item.permittype} </span>}
                                  
                                </div>
                                <div className="border border-light border-top-0 border-right-0 border-left-0"   style={{ fontSize: "14px" }}>
                                 {"บริษัท:"} {item.company}
                                </div>
                              </div>

                              <div className="col-sm-4">
                            
                              <div className="border border-light border-top-0 border-right-0 border-left-0"  style={{ fontSize: "14px" }}>
                                  <span >
                                {"พื้นที่:"}    {item.Area}
                                  </span>
                                </div>
                                <div className="border border-light border-top-0 border-right-0 border-left-0"  style={{ fontSize: "14px" }}>
                                  <span >
                                {"แผนก:"}    {item.Department}
                                  </span>
                                </div>
                                <div className="border border-light border-top-0 border-right-0 border-left-0"  style={{ fontSize: "14px"}}>
                                  <span >
                                  {"จำนวน:"}    {item.Person}  {" คน"}
                                  </span>
                                </div>
                                <div className="border border-light border-top-0 border-right-0 border-left-0"  style={{ fontSize: "14px" }}>
                                {"ทะเบียนรถ:"}    <span className="text-primary" > {item.CARLICENSE} </span> 
                                </div>
                              </div>

                              <div className="col-sm-3">
                              <div className="border border-light border-top-0 border-right-0 border-left-0"  style={{ fontSize: "14px" }}>
                               
                                   </div>
                              <div className="border border-light border-top-0 border-right-0 border-left-0"   style={{ fontSize: "14px" }}>
                                  {"วันเริ่มงาน:"}  {item.StartDate}
                                </div>
                                <div className="border border-light border-top-0 border-right-0 border-left-0"   style={{ fontSize: "14px" }}>
                                {"วันสิ้นสุด:"}   {item.EndDate}
                                </div>
                              
                                <div className="border border-light border-top-0 border-right-0 border-left-0"  style={{ fontSize: "14px" }}>
                                  
                                  {item.Status ==='Waiting' ?   <span className="badge badge-warning"   style={{ fontSize: "12px" }}>  {item.Status} </span> :
                                  item.Status==='Approved' ? <span className="badge badge-success" >  {item.Status} </span>  :
                                 <span className="badge badge-danger" >  {item.Status} </span>}

                                </div>
                              
                               
                              </div>
                              <div className="col-sm-1">
                            
                                <div className="mt-1">


                              <button
                                  className="btn btn-secondary btn-sm"
                                  onClick={() => handleChange(item.doccode, "approved")}
                                     >
                               <FontAwesome name="eyedropper"/>

                               </button>
                               </div>
                              </div>
                              </div>
                        
                        </div>
                      </div>
                    </div>
                  );

                  // return   <tr key={item.No}>
                  //     <td className="d-none d-xl-block" style={{ fontSize: "14px" }}>{item.No}</td>
                  //     <td cope="col" style={{ fontSize: "14px" }}>{item.doccode}</td>
                  //     <td cope="col" style={{ fontSize: "14px" }}>{item.permittype}</td>
                  //     <td cope="col" style={{ fontSize: "14px" }}  >{item.company}</td>
                  //   <td  style={{ fontSize: "12px" }}>{item.Area }
                  //     <div className="row">
                  //         <small style={{ fontSize: "12px" }}>
                  //           {item.Department}
                  //         </small>
                  //       </div>
                  //     </td>
                  //     <td  style={{ fontSize: "14px" }}>{item.Person } คน

                  //     <div className="row">
                  //         <small style={{ fontSize: "12px" }}>
                  //           {item.StartDate}
                  //         </small>
                  //       </div>

                  //       <div className="row">
                  //         <small style={{ fontSize: "12px" }}>
                  //           {item.EndDate}
                  //         </small>
                  //       </div>
                  //     </td>

                  //     <td cope="col" style={{ fontSize: "14px" }}>{item.CARLICENSE}</td>
                  //     <td  style={{ fontSize: "15px" }}>

                  //     {item.Status ==='Waiting' ?  <span className="badge badge-warning" >  {item.Status} </span> :
                  // item.Status==='Approved' ? <span className="badge badge-success" >  {item.Status} </span>  :
                  // <span className="badge badge-danger" >  {item.Status} </span>}

                  //     </td>
                  //     <td >
                  //     <button
                  //       className="btn btn-secondary"
                  //       onClick={() => handleChange(item.doccode, "approved")}
                  //     >
                  //   <FontAwesome name="eyedropper"/>

                  //     </button>
                  //     </td>

                  //   </tr>
                }
              })}
        
      </div>
      <div>{loading && "Loading..."}</div>
      <div>{error && "Error"}</div>
      <div id="snackbar">{snackMessage}</div>
    </div>
  );
};

export default Renderwaitting;
