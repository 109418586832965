import React from 'react';
import FontAwesome from 'react-fontawesome';


const Rendershutdown = ({list,snackMessage,handleChange}) => {


    return ( 

        <div>
        <div className="container"> 
        <button
                          className="btn btn-primary"
                          onClick={() => handleChange(0, "new") }
                        >
                      <FontAwesome name="plus"/>    
                        <i/> Add New
                          </button>  
          <br />
          <br />

  


          <table className="table table-hover">
            <thead >
              <tr>
                <th scope="col">ชื่องาน</th>
                <th scope="col">สายงาน</th>
                <th scope="col">พื้นที่</th>
                <th scope="col">เริ่มงาน</th>
                <th scope="col">สิ้นสุด</th>
                <th />
              </tr>
            </thead>
            <tbody>{
                 list &&
                 list.map((item) => (
                      <tr key={item.planid}>
                    
                        <td className="col-sd-4" style={{ fontSize: "13px" }}>{item.planname}</td>
                        <td className="col-md-2" style={{ fontSize: "13px" }}>{item.BUName}</td>
                        <td className="col-md-2" style={{ fontSize: "13px" }}>{item.AreaName}</td>
                        <td className="col-md-2" style={{ fontSize: "13px" }} >{item.StartDate}</td>   
                        <td className="col-md-2" style={{ fontSize: "13px" }}>{item.Enddate}</td>

                       
                        <td className="col-md-2">
                        <button
                          className="btn btn-success"
                          onClick={() => handleChange(item.planid, "add")}
                        >
                      <FontAwesome name="plus"/>    
                        <i/>
                          </button>
                        </td>

                        <td className="col-md-2">
                        <button
                          className="btn btn-secondary"
                          onClick={() => handleChange(item.planid, "edit")}
                        >
                      <FontAwesome name="edit"/>    
                        <i/>
                          </button>
                        </td>

                        <td className="col-md-2">
                        <button
                          className="btn btn-danger"
                          onClick={() => handleChange(item.planid, "delete")}
                        >
                      <FontAwesome name="trash"/>    
                        <i/>
                          </button>
                        </td>
                      </tr>
                    ))
                
                }</tbody>

          </table>
        </div>
   
        <div id="snackbar">{snackMessage}</div>
      </div>

     );
}
 
export default Rendershutdown;