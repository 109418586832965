import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Input from "./../wms/input";
import FontAwesome from 'react-fontawesome';
import Permitlist from './renderpermit';

const ConfirmSubmit = ({
  button1,
  isOpen,
  header,
  onCancel,
  onConfirm,
  message,
  list,
  onDisAprove,
  value,
  onChange,
  error,size ="xl"
}) => {
  // const worklist = list.WorkerList;

 //console.log(list);

  // console.log(worklist)
  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={onCancel}
        size={size}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalHeader> 
        {header}
        {list && list.ComName && 
      
         <p className="font-weight-normal">
          {list.ComName}
       </p>
        }
        </ModalHeader>
        <ModalBody>

          { list && <Permitlist
          list ={list}
          
          />}
         

          {!list && message}
        </ModalBody>
        { error && <div className="col-md-12 alert alert-danger">  { error}</div>}

        {list && list.comment &&
            <Button color="danger">
              {list.comment}
            </Button>
          }
        <ModalFooter>
          {list && (
            <div className="col-lg-4  col-sm-6 col-xs-12">
              {" "}
              <Input
                name="remark"
                value={value}
                placeholder="ระบุเหตุผล"
                onChange={onChange}
              />
            </div>
          )}
          <Button color="primary" onClick={onConfirm}>
            {button1}
          </Button>{" "}
          {list && (
            <Button color="danger" onClick={onDisAprove}>
              ไม่อนุมัติ
            </Button>
          )}
          <Button color="secondary" onClick={onCancel}>
            ยกเลิก
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ConfirmSubmit;
