import http from "./httpService";
import { apiUrl } from "../config.json";

//const url = "https://wms.advanceagro.net/wsface/api";
const url = "https://maxmobile.advanceagro.net/wsface/api";

//const url = "http://localhost:3001/";

export async function saveclonedevice(doc){
  return http.post(apiUrl + "face/clonedevice", doc);
}
export async function UploadMultiDevice(doc) {
  //console.log(doc)
  return http.post(apiUrl + "face/uploadmultidevice", doc);
}
export async function uploadFaceImage(doc) {
  // console.log(doc);
  return http.post("https://wms.advanceagro.net/wsface/api/face/UploadFaceImage", doc);
}
export async function savedevice(doc) {
  // console.log(doc);
  return http.post(url + "/face/savedevice", doc);
}
export async function updatefacedata(doc) {
  // console.log(SendFaceMultiuser);
  return http.post(url + "/face/UpdateFaceData", doc);
}
export async function deleteface(doc) {
  return http.post(apiUrl + "face/deleteface", doc);
}
export async function SendFaceMultiuser(doc) {
  // console.log(SendFaceMultiuser);
  return http.post(apiUrl + "face/senddatafacehr", doc);
}
export async function getdevice(
  id = 0,
  siteid = 0,
  name = "",
  groupid = 0,
  pageSize = 1000,
  pageNumber = 1
) {
  //console.log(id,'site= ', siteid);
  return http.get(
    url +
      "/face/getfacedevice?_id=" +
      id +
      "&siteid=" +
      siteid +
      "&name=" +
      name +
      "&groupid=" +
      groupid +
      "&pageSize=" +
      pageSize +
      "&pageNumber=" +
      pageNumber
  );
}
export async function getfacegroup(type = "") {
  return http.get(url + "/face/getfacegroup?type=" + type);
}
export async function getfacesite(type = "") {
  return http.get(url + "/face/getsite?type=" + type);
}
export async function getdevicebyuser(empid = "") {
  return http.get(url + "/face/getDeviceByUser?empid=" + empid);
}
//GetTotalUserInfor
export async function getuserondevice(
  _id = "0",
  status = 0,
  search = "",
  pageSize = 1000,
  pageNumber = 1
) {
  return http.get(
    url +
      "/face/getUseronDevice?_id=" +
      _id +
      "&status=" +
      status +
      "&search=" +
      search +
      "&pageSize=" +
      pageSize +
      "&pageNumber=" +
      pageNumber
  );
}

export async function getdevicehr(
  id = 0,
  site = 0,
  search = "",
  pageSize = 30,
  pageNumber = 1
) {
  return http.get(
    url +
      "/face/deviceHR?_id=" +
      id +
      "&site=" +
      site +
      "&search=" +
      search +
      "&pageSize=" +
      pageSize +
      "&pageNumber=" +
      pageNumber
  );
}
export async function getEmployee(
  search = "",
  pageSize = 1000,
  pageNumber = 1
) {
  // console.log(search);
  return http.get(
    url +
      "/face/GetUserInfo?search=" +
      search +
      "&pageSize=" +
      pageSize +
      "&pageNumber=" +
      pageNumber
  );
}

export async function getEmployees(
  search = "",
  site = 0,
  pageSize = 1000,
  pageNumber = 1,
  BUID = 0,
  DIP = "",
  comID = 0
) {
  //console.log(BUID,DIP,comID,pageNumber,pageSize);
  return http.get(
    url +
      "/face/GetTotalUserInfor?search=" +
      search +
      "&site=" +
      site +
      "&pageSize=" +
      pageSize +
      "&pageNumber=" +
      pageNumber +
      "&BUID=" +
      BUID +
      "&ComID=" +
      comID +
      "&department=" +
      DIP
  );
}
export async function getlogscanbyemp(
  empid = "",
  startdate = "",
  enddate = "",
  pageSize = 1000,
  pageNumber = 1
) {
  return http.get(
    url +
      "/face/getlogbyemp?empid=" +
      empid +
      "&startdate=" +
      startdate +
      "&enddate=" +
      enddate +
      "&pageSize=" +
      pageSize +
      "&pageNumber=" +
      pageNumber
  );
}
export async function getlogscan(
  empid = "",
  startdate = "",
  enddate = "",
  pageSize = 1000,
  pageNumber = 1
) {
  return http.get(
    apiUrl +
      "face/getlogbyemp?empid=" +
      empid +
      "&startdate=" +
      startdate +
      "&enddate=" +
      enddate +
      "&pageSize=" +
      pageSize +
      "&pageNumber=" +
      pageNumber
  );
}

export async function getDeviceSeleced(site = 0, empid = "") {
  //console.log(site,empid)
  return http.get(
    url + "/face/getdeviceselect?siteid=" + site + "&empid=" + empid
  );
}
export async function getcompanyByBU(_id = "0") {
  // console.log(SendFaceMultiuser);
  return http.get(url + "/face/getcompanybybu?_id=" + _id);
}
export async function getDepertment(_id = "0") {
  // console.log(SendFaceMultiuser);
  return http.get(url + "/face/getdepartment?_id=" + _id);
}
export async function getdeviceforclone(
  _id = "0",
  site = 0,
  search = 0,
  pageSize = 1000,
  pageNumber = 1
) {
  // console.log(SendFaceMultiuser);
  return http.get(
    url +
      "/face/getdeviceforclone?_id=" +
      _id +
      "&site=" +
      site +
      "&search=" +
      search +
      "&pageSize=" +
      pageSize +
      "&pageNumber=" +
      pageNumber
  );
}
export default {
  getdevice,
  getfacegroup,
  getfacesite,
  savedevice,
  getdevicehr,
  getEmployee,
  updatefacedata,
  getdevicebyuser,
  deleteface,
  getEmployees,
  uploadFaceImage,
  getDeviceSeleced,
  UploadMultiDevice,
  getlogscanbyemp,
  getlogscan,
  getcompanyByBU,
  getDepertment,
  getdeviceforclone,
  saveclonedevice
};
